import { GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE, GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_FAILURE, UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILURE, DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAILURE, BLOCK_CUSTOMER_REQUEST, BLOCK_CUSTOMER_SUCCESS, BLOCK_CUSTOMER_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        loading: false,
        items: action.customers.customers,
        total: action.customers.total
      };
    case GET_CUSTOMERS_FAILURE:
      return {
        error: action.error
      };
    case GET_CUSTOMER_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        loading: false,
        item: action.customer
      };
    case GET_CUSTOMER_FAILURE:
      return {
        error: action.error
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case ADD_CUSTOMER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_CUSTOMER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_CUSTOMER_REQUEST:
      // add 'deleting:true' property to customer being deleted
      return {
        ...state,
        items: state.items.map(customer =>
          customer.id === action.id
            ? { ...customer, deleting: true }
            : customer
        )
      };
    case DELETE_CUSTOMER_SUCCESS:
      // remove deleted customer from state
      return {
        items: state.items.filter(customer => customer.id !== action.id)
      };
    case DELETE_CUSTOMER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to customer 
      return {
        ...state,
        items: state.items.map(customer => {
          if (customer.id === action.id) {
            // make copy of customer without 'deleting:true' property
            const { deleting, ...customerCopy } = customer;
            // return copy of customer with 'deleteError:[error]' property
            return { ...customerCopy, deleteError: action.error };
          }
 
          return customer;
        })
      };
    case BLOCK_CUSTOMER_REQUEST:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, blocking: true }
            : user
        )
      };
    case BLOCK_CUSTOMER_SUCCESS:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, blocked: action.blocked, blocking: false }
            : user
        )
      };
    case BLOCK_CUSTOMER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }
 
          return user;
        })
      };
    default:
      return state
  }
}