import { GET_CASH_CREDITS_REQUEST, GET_CASH_CREDITS_SUCCESS, GET_CASH_CREDITS_FAILURE, GET_CASH_CREDIT_REQUEST, GET_CASH_CREDIT_SUCCESS, GET_CASH_CREDIT_FAILURE, DELETE_CASH_CREDIT_REQUEST, DELETE_CASH_CREDIT_SUCCESS, DELETE_CASH_CREDIT_FAILURE, ADD_CASH_CREDIT_REQUEST, ADD_CASH_CREDIT_SUCCESS, ADD_CASH_CREDIT_FAILURE, UPDATE_CASH_CREDIT_REQUEST, UPDATE_CASH_CREDIT_SUCCESS, UPDATE_CASH_CREDIT_FAILURE } from '../constants';
import { cashCreditService } from '../services/cash_credit.services';
 
export default {
  add,
  update,
  getCashCredits,
  getCashCredit,
  delete: _delete
};
 
function add(cashCredit) {
  return dispatch => {
    dispatch(request(cashCredit));

    cashCreditService.add(cashCredit)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(cashCredit) { return { type: ADD_CASH_CREDIT_REQUEST, cashCredit } }
  function success(cashCredit) { return { type: ADD_CASH_CREDIT_SUCCESS, cashCredit } }
  function failure(error) { return { type: ADD_CASH_CREDIT_FAILURE, error } }
}

function update(id, cashCredit) {
  return dispatch => {
    dispatch(request(cashCredit));

    cashCreditService.update(id, cashCredit)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(cashCredit) { return { type: UPDATE_CASH_CREDIT_REQUEST, cashCredit } }
  function success() { return { type: UPDATE_CASH_CREDIT_SUCCESS } }
  function failure(error) { return { type: UPDATE_CASH_CREDIT_FAILURE, error } }
}
 
function getCashCredits(filters) {
  return dispatch => {
    dispatch(request());
 
    cashCreditService.getCashCredits(filters)
      .then(
        cashCredits => dispatch(success(cashCredits)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_CASH_CREDITS_REQUEST } }
  function success(cashCredits) { return { type: GET_CASH_CREDITS_SUCCESS, cashCredits } }
  function failure(error) { return { type: GET_CASH_CREDITS_FAILURE, error } }
}

function getCashCredit(id) {
  return dispatch => {
    dispatch(request());
 
    cashCreditService.getCashCredit(id)
      .then(
        cashCredit => dispatch(success(cashCredit)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_CASH_CREDIT_REQUEST } }
  function success(cashCredit) { return { type: GET_CASH_CREDIT_SUCCESS, cashCredit } }
  function failure(error) { return { type: GET_CASH_CREDIT_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    cashCreditService.delete(id)
      .then(
        cashCredit => dispatch(success(cashCredit.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_CASH_CREDIT_REQUEST, id } }
  function success(id) { return { type: DELETE_CASH_CREDIT_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_CASH_CREDIT_FAILURE, id, error } }
}