import config from 'config';
import authHeader from '../helpers/authHeaders';
 
export const stopService = {
  add,
  update,
  getStops,
  getStop,
  delete: _delete
};

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
 
function getStops(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/stops/${qs}`, requestOptions).then(handleResponse);
}
 
function getStop(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/stops/${id}`, requestOptions).then(handleResponse);
}
 
function add(stop) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(stop)
  };
 
  return fetch(`${config.apiUrl}/stops`, requestOptions).then(handleResponse);
}
 
function update(id, stop) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(stop)
  };
 
  return fetch(`${config.apiUrl}/stops/${id}`, requestOptions).then(handleResponse);
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/stops/${id}`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
 
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}