import { GET_RESERVATIONS_REQUEST, GET_RESERVATIONS_SUCCESS, GET_RESERVATIONS_FAILURE, GET_MAIN_RESERVATIONS_REQUEST, GET_MAIN_RESERVATIONS_SUCCESS, GET_MAIN_RESERVATIONS_FAILURE, GET_LOST_RESERVATIONS_REQUEST, GET_LOST_RESERVATIONS_SUCCESS, GET_LOST_RESERVATIONS_FAILURE, GET_RESERVATION_REQUEST, GET_RESERVATION_SUCCESS, GET_RESERVATION_FAILURE, ADD_RESERVATION_REQUEST, ADD_RESERVATION_SUCCESS, ADD_RESERVATION_FAILURE, UPDATE_RESERVATION_REQUEST, UPDATE_RESERVATION_SUCCESS, UPDATE_RESERVATION_FAILURE, DELETE_RESERVATION_REQUEST, DELETE_RESERVATION_SUCCESS, DELETE_RESERVATION_FAILURE, DELETE_RESERVATIONS_REQUEST, DELETE_RESERVATIONS_SUCCESS, DELETE_RESERVATIONS_FAILURE } from '../constants';

function getActiveItems(items, deleted) {
  for(let i = 0; i < items.length; i++) {
    for(let k = 0; k < items[i].reservations.length; k++) {
      if(deleted.indexOf(items[i].reservations[k].id) > -1) {
        items[i].reservations[k].status = 'deleted';
      }
    }
  }
  return items;
}
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_RESERVATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GET_RESERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.reservations.reservations,
        total: action.reservations.total
      };
    case GET_RESERVATIONS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case GET_MAIN_RESERVATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        mainItems: (action.append ? state.mainItems : [] ),
        error: ''
      };
    case GET_MAIN_RESERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        mainItems: (action.append && state.mainItems ? [...state.mainItems, ...action.reservations] : action.reservations)
      };
    case GET_MAIN_RESERVATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case GET_LOST_RESERVATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        lost: [],
        error: ''
      };
    case GET_LOST_RESERVATIONS_SUCCESS:
      return {
        loading: false,
        lost: action.reservations
      };
    case GET_LOST_RESERVATIONS_FAILURE:
      return {
        error: action.error
      };
    case GET_RESERVATION_REQUEST:
      return {
        loading: true,
        item: null,
        error: ''
      };
    case GET_RESERVATION_SUCCESS:
      return {
        loading: false,
        item: action.reservation
      };
    case GET_RESERVATION_FAILURE:
      return {
        error: action.error
      };
    case ADD_RESERVATION_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_RESERVATION_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_RESERVATION_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_RESERVATION_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_RESERVATION_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_RESERVATION_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_RESERVATIONS_REQUEST:
      // add 'deleting:true' property to reservation being deleted
      return {
        ...state,
        loading: true,
        error: '',
        success: false
      };
    case DELETE_RESERVATIONS_SUCCESS:
      // remove deleted reservation from state
      return {
        ...state,
        loading: false,
        success: true,
        mainItems: getActiveItems(state.mainItems, action.data.ids)
      };
    case DELETE_RESERVATIONS_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to reservation 
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case DELETE_RESERVATION_REQUEST:
      // add 'deleting:true' property to reservation being deleted
      return {
        ...state,
        items: state.items.map(reservation =>
          reservation.id === action.id
            ? { ...reservation, deleting: true }
            : reservation
        )
      };
    case DELETE_RESERVATION_SUCCESS:
      // remove deleted reservation from state
      return {
        items: state.items.filter(reservation => reservation.id !== action.id)
      };
    case DELETE_RESERVATION_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to reservation 
      return {
        ...state,
        items: state.items.map(reservation => {
          if (reservation.id === action.id) {
            // make copy of reservation without 'deleting:true' property
            const { deleting, ...reservationCopy } = reservation;
            // return copy of reservation with 'deleteError:[error]' property
            return { ...reservationCopy, deleteError: action.error };
          }
 
          return reservation;
        })
      };
    default:
      return state
  }
}