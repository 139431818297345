import config from 'config';
import authHeader from '../helpers/authHeaders';
 
export const reservationService = {
  add,
  update,
  getReservations,
  getReservation,
  getMainReservations,
  getLostReservations,
  getScheduleReservations,
  moveLostReservations,
  searchReservations,
  addMainReservation,
  getByMonthForLastYear,
  getByHourAndDeparture,
  getByCityLastMonth,
  getLegByNumber,
  delete: _delete,
  deleteReservations: deleteReservations,
  cancelMainSeats: cancelMainSeats,
  rescheduleMainReservation: rescheduleMainReservation
};

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
 
function getReservations(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/reservations/${qs}`, requestOptions).then(handleResponse);
}

function getByMonthForLastYear() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/reservations/reports/by-month-for-last-year`, requestOptions).then(handleResponse);
}

function getByHourAndDeparture() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/reservations/reports/by-hour-and-departure`, requestOptions).then(handleResponse);
}

function getByCityLastMonth() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/reservations/reports/by-city-last-month`, requestOptions).then(handleResponse);
}

function getLegByNumber(number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/main/reservation-leg/${number}`, requestOptions).then(handleResponse);
}

function getMainReservations(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/main/reservations/${qs}`, requestOptions).then(handleResponse);
}

function getScheduleReservations(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(query);
  return fetch(`${config.apiUrl}/main/schedule-reservations${qs}`, requestOptions).then(handleResponse);
}

function getLostReservations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/main/lost-reservations`, requestOptions).then(handleResponse);
}

function moveLostReservations(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/move-lost-reservations`, requestOptions).then(handleResponse);
}

function searchReservations(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/main/search-reservations/${qs}`, requestOptions).then(handleResponse);
}

function deleteReservations(ids) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({id: ids})
  };
  return fetch(`${config.apiUrl}/main/reservations`, requestOptions).then(handleResponse);
}
 
function getReservation(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/reservations/${id}`, requestOptions).then(handleResponse);
}
 
function add(reservation) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(reservation)
  };
 
  return fetch(`${config.apiUrl}/reservations`, requestOptions).then(handleResponse);
}

function addMainReservation(reservation) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(reservation)
  };
 
  return fetch(`${config.apiUrl}/main/reservation`, requestOptions).then(handleResponse);
}

function rescheduleMainReservation(reservation) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(reservation)
  };
 
  return fetch(`${config.apiUrl}/main/reservation/reschedule`, requestOptions).then(handleResponse);
}

function cancelMainSeats(reservationNumber, count, reservationGroup = null) {
  console.log("Group servuce", reservationGroup)
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
 
  return fetch(`${config.apiUrl}/main/reservation/${reservationNumber}/${count}/${reservationGroup}`, requestOptions).then(handleResponse);
}
 
function update(id, reservation) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(reservation)
  };
 
  return fetch(`${config.apiUrl}/reservations/${id}`, requestOptions).then(handleResponse);
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/reservations/${id}`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
      const error = text || response.statusText;
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}