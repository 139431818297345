import React from 'react'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import './BigCalendar.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';

// remember the browser's local timezone, as it might by used later on
console.log(moment.tz.guess())
BigCalendar.tz = moment.tz.guess();
// format all dates in BigCalendar as they would be rendered in browser's local timezone (even if later on they won't)
const m = (...args) => moment.tz(...args, BigCalendar.tz);
m.localeData = moment.localeData;

BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(m)
);



const convertDateTimeToDate = (datetime, timeZoneName) => {
  const m = moment.tz(datetime, timeZoneName);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0)
};

const convertDateToDateTime = (date, timeZoneName) => {
  const dateM = moment.tz(date, BigCalendar.tz);
  return moment.tz({
    year: dateM.year(),
    month: dateM.month(),
    date: dateM.date(),
    hour: dateM.hour(),
    minute: dateM.minute(),
  }, BigCalendar.tz);
};


const TimeZoneAgnosticBigCalendar = ({ events, onSelectSlot, onEventDrop, timeZoneName, ...props }) => {
  const bigCalendarProps = {
      ...props,
      events: events.map(event => ({
        ...event,
        start: convertDateTimeToDate(event.start, timeZoneName),
        end: convertDateTimeToDate(event.end, timeZoneName),
      })),
      onSelectSlot: onSelectSlot && (({ start, end, slots }) => {
        onSelectSlot({
          start: convertDateToDateTime(start, timeZoneName),
          end: convertDateToDateTime(end, timeZoneName),
          slots: slots.map(date => convertDateToDateTime(date, timeZoneName)),
        })
      }),
      onEventDrop: onEventDrop && (({ event, start, end }) => {
        onEventDrop({
          event,
          start: convertDateToDateTime(start, timeZoneName),
          end: convertDateToDateTime(end, timeZoneName),
        })
      }),
  }
  return <BigCalendar {...bigCalendarProps} />
}

export default TimeZoneAgnosticBigCalendar;