import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import customerActions from 'actions/customer.actions';
import { connect } from 'react-redux';

//import avatar from "assets/img/faces/face-3.jpg";

class AddCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      customer: {
        receiveOffers: true,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        password: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let customer = this.state.customer;
    customer[name] = value;
    this.setState({ customer: customer });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.customer.email && this.state.customer.phone && this.state.customer.firstName && this.state.customer.lastName && this.state.customer.address && this.state.customer.city && this.state.customer.state && this.state.customer.zip && this.state.customer.country && this.state.customer.password) {
      dispatch(
        customerActions.add({
          email: this.state.customer.email,
          firstName: this.state.customer.firstName,
          lastName: this.state.customer.lastName,
          phone: this.state.customer.phone,
          address: this.state.customer.address,
          city: this.state.customer.city,
          state: this.state.customer.state,
          zip: this.state.customer.zip,
          country: this.state.customer.country,
          receiveOffers: this.state.customer.receiveOffers,
          password: this.state.customer.password
        })
      );
    }
  }

  componentWillUpdate(props) {
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Customer Added successfully'});
      this.setState({
        customer: {
          receiveOffers: true,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          password: ''
        },  
        submitted: false
      });
    }
  }

  handleCheckbox = event => {
    let customer = this.state.customer;
    customer.receiveOffers = !customer.receiveOffers;
    this.setState({customer: customer});
  };

  render() {
    const { submitted, customer } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Add Customer"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "First Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First Name",
                          value: customer.firstName,
                          name: 'firstName',
                          onChange: this.handleChange
                        },
                        {
                          label: "Last Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last Name",
                          value: customer.lastName,
                          name: 'lastName',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.firstName && <div className="error">First name is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.lastName && <div className="error">Last name is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: customer.email,
                          name: 'email',
                          onChange: this.handleChange
                        },
                        {
                          label: "Phone",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Phone",
                          value: customer.phone,
                          name: 'phone',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.email && <div className="error">Email is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.phone && <div className="error">Phone is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Password",
                          type: "password",
                          bsClass: "form-control",
                          placeholder: "Password",
                          value: customer.password,
                          name: 'password',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !customer.password && <div className="error">Password is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Address",
                          value: customer.address,
                          name: 'address',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !customer.address && <div className="error">Address is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      proprieties={[
                        {
                          label: "City",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "City",
                          value: customer.city,
                          name: 'city',
                          onChange: this.handleChange
                        },
                        {
                          label: "State",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "State",
                          value: customer.state,
                          name: 'state',
                          onChange: this.handleChange
                        },
                        {
                          label: "ZIP Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: customer.zip,
                          name: 'zip',
                          onChange: this.handleChange
                        },
                        {
                          label: "Country",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Country",
                          value: customer.country,
                          name: 'country',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={3}>{submitted && !customer.city && <div className="error">City is required</div>}</Col>
                      <Col md={3}>{submitted && !customer.state && <div className="error">State is required</div>}</Col>
                      <Col md={3}>{submitted && !customer.zip && <div className="error">Zipcode is required</div>}</Col>
                      <Col md={3}>{submitted && !customer.country && <div className="error">Country is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Checkbox
                          number='check1'
                          value={customer.receiveOffers}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={customer.receiveOffers}
                          label='Would you like to receive offers from tripperbus?'
                        />
                      </Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Add Customer
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.customers;
  return {
    loading, error, success
  };
}
 
export default connect(mapStateToProps)(AddCustomer);
