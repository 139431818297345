import _ from 'lodash';

function constructPricesMap(departStops, destinationStops, prices, stops, priceOverride = null){

    let combinationOfStops = []
    let price = null;
        departStops.forEach((departStop)=>{
        destinationStops.forEach((destinationStop)=>{

          let departStopValue = _.find(stops, {id: departStop.stop})
          let destinationStopValue = _.find(stops, {id: destinationStop.stop})
          if(prices && prices.length && departStopValue && destinationStopValue){
             price = _.find(prices, {from_stop_id: departStop.stop, to_stop_id: destinationStop.stop})
          }
          if(departStopValue && destinationStopValue){
            combinationOfStops.push({
                departStop: {...departStop, address: departStopValue.address}, 
                destinationStop: {...destinationStop, address: destinationStopValue.address},
                price: price && price.price || '',
                customRouteId: price && price.id,
                priceOverride

            })
          }
        })
    })

    return combinationOfStops;
}


export {constructPricesMap}