import { GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE, GET_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE, DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAILURE, ADD_COUPON_REQUEST, ADD_COUPON_SUCCESS, ADD_COUPON_FAILURE, UPDATE_COUPON_REQUEST, UPDATE_COUPON_SUCCESS, UPDATE_COUPON_FAILURE } from '../constants';
import { couponService } from '../services/coupon.services';
 
export default {
  add,
  update,
  getCoupons,
  getCoupon,
  delete: _delete
};
 
function add(coupon) {
  return dispatch => {
    dispatch(request(coupon));

    couponService.add(coupon)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(coupon) { return { type: ADD_COUPON_REQUEST, coupon } }
  function success(coupon) { return { type: ADD_COUPON_SUCCESS, coupon } }
  function failure(error) { return { type: ADD_COUPON_FAILURE, error } }
}

function update(id, coupon) {
  return dispatch => {
    dispatch(request(coupon));

    couponService.update(id, coupon)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(coupon) { return { type: UPDATE_COUPON_REQUEST, coupon } }
  function success() { return { type: UPDATE_COUPON_SUCCESS } }
  function failure(error) { return { type: UPDATE_COUPON_FAILURE, error } }
}
 
function getCoupons(filters) {
  return dispatch => {
    dispatch(request());
 
    couponService.getCoupons(filters)
      .then(
        coupons => dispatch(success(coupons)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_COUPONS_REQUEST } }
  function success(coupons) { return { type: GET_COUPONS_SUCCESS, coupons } }
  function failure(error) { return { type: GET_COUPONS_FAILURE, error } }
}

function getCoupon(id) {
  return dispatch => {
    dispatch(request());
 
    couponService.getCoupon(id)
      .then(
        coupon => dispatch(success(coupon)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_COUPON_REQUEST } }
  function success(coupon) { return { type: GET_COUPON_SUCCESS, coupon } }
  function failure(error) { return { type: GET_COUPON_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    couponService.delete(id)
      .then(
        coupon => dispatch(success(coupon.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_COUPON_REQUEST, id } }
  function success(id) { return { type: DELETE_COUPON_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_COUPON_FAILURE, id, error } }
}