import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";

import config from 'config';
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import giftCardActions from 'actions/gift_card.actions';

class GiftCards extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, 1000);
    this.state = {
      criteria: {
        limit: config.pageSize,
        offset: 0,
        filtered: "",
        sorted: ""
      }
    };
  }

  componentWillUpdate(props) {
    if(props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
  }

  fetchData = (state, instance) => {
    let criteria = this.state.criteria;
    let newFilter = state.filtered.length > 0 ? JSON.stringify(state.filtered) : "";
    let newOrderer = state.sorted.length > 0 ? JSON.stringify(state.sorted) : "";
    if(criteria.filtered !== newFilter) {
      criteria['offset'] = 0;
      criteria['filtered'] = newFilter;
    }
    if(criteria.sorted !== newOrderer) {
      criteria['offset'] = 0;
      criteria['sorted'] = newOrderer;
    }
    if(criteria.limit !== state.pageSize) {
      criteria.limit = state.pageSize;
      criteria['offset'] = 0;
    }
    else {
      criteria['offset'] = Math.ceil(state.page * state.pageSize);
    }
    this.setState({criteria: criteria}, () => {
      this.props.dispatch(giftCardActions.getGiftCards(criteria));
    });
  };

  deleteItem = item => {
    confirmAlert({
      title: 'Delete Gift Card',
      message: 'Are you sure you want to delete this gift card?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(giftCardActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const giftCards = this.props.giftCards || [];
    const loading = this.props.loading || false;
    const total = this.props.total || 0;
    const pageCount = Math.ceil(total / this.state.criteria.limit);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Gift Cards"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <ReactTable
                    columns={[
                      {
                        Header: "Owner",
                        accessor: "owner",
                        Cell: row => (
                          <NavLink target="_blank" to={"/dashboard/users/"+row.original.owner.id}>{row.original.owner.firstName + " " + row.original.owner.lastName}</NavLink>
                        ),
                        filterable: false
                      },
                      {
                        Header: "Sender Name",
                        accessor: "senderName"
                      },
                      {
                        Header: "Sender Email",
                        accessor: "senderEmail"
                      },
                      {
                        Header: "Recipient Name",
                        accessor: "recipientName"
                      },
                      {
                        Header: "Recipient Email",
                        accessor: "email"
                      },
                      {
                        Header: "Amount",
                        accessor: "amount"
                      },
                      {
                        Header: "Code",
                        accessor: "code"
                      },
                      {
                        Header: "Redeemed",
                        accessor: "redeemed",
                        Cell: row => (
                          row.original.redeemed ? 'Yes' : 'No'
                        ),
                        filterable: false
                      },
                      {
                        Header: "Consumer",
                        accessor: "consumer",
                        Cell: row => (
                          row.original.consumer && <NavLink target="_blank" to={"/dashboard/users/"+row.original.consumer.id}>{row.original.consumer.firstName + " " + row.original.consumer.lastName}</NavLink>
                        ),
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        width: 100,
                        Cell: row => (
                          <div className="table-actions">
                            <NavLink to={"/dashboard/gift_cards/"+row.original.id}><Button bsStyle="info" disabled={row.original.deleting} fill type="button"><i className="fa fa-edit"></i></Button></NavLink>
                            <Button bsStyle="danger" disabled={row.original.deleting} fill type="button" onClick={this.deleteItem.bind(this, row)}><i className="fa fa-trash"></i></Button>
                          </div>
                        )
                      },
                    ]}
                    manual
                    data={giftCards}
                    pages={pageCount} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchDataWithDebounce}
                    filterable
                    defaultPageSize={this.state.criteria.limit}
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { items, loading, total, error } = state.giftCards;
  return { giftCards: items, loading, total, error };
}

export default connect(mapStateToProps)(GiftCards);
