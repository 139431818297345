import { GET_BUSES_REQUEST, GET_BUSES_SUCCESS, GET_BUSES_FAILURE, GET_BUS_REQUEST, GET_BUS_SUCCESS, GET_BUS_FAILURE, DELETE_BUS_REQUEST, DELETE_BUS_SUCCESS, DELETE_BUS_FAILURE, ADD_BUS_REQUEST, ADD_BUS_SUCCESS, ADD_BUS_FAILURE, UPDATE_BUS_REQUEST, UPDATE_BUS_SUCCESS, UPDATE_BUS_FAILURE } from '../constants';
import { busService } from '../services/bus.services';
 
export default {
  add,
  update,
  getBuses,
  getBus,
  delete: _delete
};
 
function add(bus) {
  return dispatch => {
    dispatch(request(bus));

    busService.add(bus)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(bus) { return { type: ADD_BUS_REQUEST, bus } }
  function success(bus) { return { type: ADD_BUS_SUCCESS, bus } }
  function failure(error) { return { type: ADD_BUS_FAILURE, error } }
}

function update(id, bus) {
  return dispatch => {
    dispatch(request(bus));

    busService.update(id, bus)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(bus) { return { type: UPDATE_BUS_REQUEST, bus } }
  function success() { return { type: UPDATE_BUS_SUCCESS } }
  function failure(error) { return { type: UPDATE_BUS_FAILURE, error } }
}
 
function getBuses(filters) {
  return dispatch => {
    dispatch(request());
 
    busService.getBuses(filters)
      .then(
        buses => dispatch(success(buses)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_BUSES_REQUEST } }
  function success(buses) { return { type: GET_BUSES_SUCCESS, buses } }
  function failure(error) { return { type: GET_BUSES_FAILURE, error } }
}

function getBus(id) {
  return dispatch => {
    dispatch(request());
 
    busService.getBus(id)
      .then(
        bus => dispatch(success(bus)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_BUS_REQUEST } }
  function success(bus) { return { type: GET_BUS_SUCCESS, bus } }
  function failure(error) { return { type: GET_BUS_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    busService.delete(id)
      .then(
        bus => dispatch(success(bus.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_BUS_REQUEST, id } }
  function success(id) { return { type: DELETE_BUS_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_BUS_FAILURE, id, error } }
}