import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { reservationService } from 'services/reservation.services';
import moment from 'moment';
import _ from 'lodash';
import store from "stores/index";

import { Card } from "components/Card/Card.jsx";
import {
  optionsSales,
  responsiveSales,
  optionsBar,
  responsiveBar,
  legendLocations
} from "variables/Variables.jsx";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSales: {},
      dataPie: {},
      dataBar: {},
      legendPie: {
        names: ["Manhattan", "Brooklyn", "Bethesda"],
        types: ["info", "danger", "warning"]
      }
    };
  }
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  componentDidMount() {
    const hasDashboardAccess = (store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('dashboard') > -1);
    if (!hasDashboardAccess) {
      return;
    }
    reservationService.getByMonthForLastYear()
    .then(data => {
      let dataBar = {
        labels: [],
        series: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ]
      };
      for(let i = 0; i < 12; i++) {
        dataBar.labels.push(moment().subtract(12-i, 'months').format('MMM'));
      }
      if(data.length > 0) {
        let manhattan = _.filter(data, {city: 'new york'});
        let brooklyn = _.filter(data, {city: 'arlington'});
        let bethesda = _.filter(data, {city: 'bethesda'});
        manhattan.map(item => {
          let index = dataBar.labels.indexOf(moment(item.month).format('MMM'));
          if(index > -1) {
            dataBar.series[0][index] = parseInt(item.count, 10);
          }
          return item;
        });
        brooklyn.map(item => {
          let index = dataBar.labels.indexOf(moment(item.month).format('MMM'));
          if(index > -1) {
            dataBar.series[1][index] = parseInt(item.count, 10);
          }
          return item;
        });
        bethesda.map(item => {
          let index = dataBar.labels.indexOf(moment(item.month).format('MMM'));
          if(index > -1) {
            dataBar.series[2][index] = parseInt(item.count, 10);
          }
          return item;
        });
      }
      console.log(dataBar)
      this.setState({dataBar: dataBar});
    })
    .catch(err => {
      console.log(err);
    });

    reservationService.getByHourAndDeparture()
    .then(data => {
      if(data.length > 0) {
        let labels = [], series = [[], [], []];
        let allLabels = [];
        for(let i = 1; i <= 24; i+=2) {
          allLabels.push(moment().subtract(24-i, 'hours').set('minutes', 0).format('h:mmA'));
        }
        optionsSales.high = _.maxBy(data, function(o) { return parseInt(o.count, 10); }).count;
        for(let i = 1; i <= 24; i+=2) {
          let timeData = _.filter(data, (item) => {
            if(moment(item.hour).diff(moment().subtract(24-i-2, 'hours').set('minutes', 0), 'seconds') <= 0 && moment(item.hour).diff(moment().subtract(24-i, 'hours').set('minutes', 0), 'seconds') >= 0) {
              return true;
            }
          });
          if(timeData.length > 0) {
            labels = [];
            timeData.forEach(item => {
              if(labels.indexOf(moment(item.hour).format('h:mmA')) === -1) {
                labels.push(moment(item.hour).format('h:mmA'));
                if(item.city === 'new york') {
                  series[0].push(parseInt(item.count, 10));
                  series[1].push(0);
                  series[2].push(0);
                }
                else if(item.city === 'arlington') {
                  series[1].push(parseInt(item.count, 10));
                  series[0].push(0);
                  series[2].push(0);
                }
                else if(item.city === 'bethesda') {
                  series[2].push(parseInt(item.count, 10));
                  series[1].push(0);
                  series[0].push(0);
                }
              }
              else {
                let index = labels.indexOf(moment(item.hour).format('h:mmA'));
                if(item.city === 'new york') {
                  series[0][index] = parseInt(item.count, 10);
                }
                else if(item.city === 'arlington') {
                  series[1][index] = parseInt(item.count, 10);
                }
                else if(item.city === 'bethesda') {
                  series[2][index] = parseInt(item.count, 10);
                }
              }
            });
          }
          else {
            series[0].push(0);
            series[1].push(0);
            series[2].push(0);
          }
        }
        this.setState({dataSales: {labels: allLabels, series: series}});
      }
    })
    .catch(err => {
      console.log(err);
    });

    reservationService.getByCityLastMonth()
    .then(data => {
      if(data.length > 0) {
        let dataPie = {
          labels: [],
          series: []
        };
        let legendPie = {
          names: [],
          types: ['info', 'danger', 'warning', 'success', 'primary', 'muted']
        };
        let sum = _.sumBy(data, function(o) { return parseInt(o.count, 10); });
        let otherSum = 0;
        data.map((item, index) => {
          if(index < 5 && item.ip_city) {
            dataPie.labels.push(parseInt(parseInt(item.count, 10)*100/sum, 10) + '%');
            dataPie.series.push(parseInt(item.count, 10)*100/sum);
            legendPie.names.push(item.ip_city);
          }
          else {
            otherSum += parseInt(item.count, 10);
          }
          return item;
        });
        if(otherSum > 0) {
          dataPie.labels.push(parseInt(otherSum*100/sum, 10) + '%');
          dataPie.series.push(otherSum*100/sum);
          legendPie.names.push('Others');
        }

        if(legendPie.names.length < 6) {
          legendPie.types = legendPie.types.splice(0, legendPie.names.length);
        }
        this.setState({legendPie: legendPie, dataPie: dataPie});
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  render() {
    const hasDashboardAccess = (store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('dashboard') > -1);
    return (
      hasDashboardAccess ? <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Reservations Today"
                category="24 Hours performance"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={this.state.dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendLocations)}</div>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Customer Locations"
                category="Last Month Performance"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={this.state.dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(this.state.legendPie)}</div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                id="chartActivity"
                title="Annual Report"
                category="Last Year Performance"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={this.state.dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendLocations)}</div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div> : <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                statsIcon="fa fa-lock"
                id="noAccess"
                title="No Access"
                category=""
                content={
                  <h5>
                    Sorry, you do not have access to view dashboard stats!
                  </h5>
                }
              />
            </Col></Row></Grid></div>
    );
  }
}

export default Dashboard;
