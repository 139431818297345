import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";
import orderActions from 'actions/order.actions';
import userActions from 'actions/user.actions';
import orderItemActions from 'actions/order_item.actions';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

//import avatar from "assets/img/faces/face-3.jpg";

class EditOrder extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(orderActions.getOrder(this.props.match.params.id));
    this.state = {
      submitted: false,
      order: {
        user: '',
        email: '',
        userInput: ''
      },
      order_items: [],
      suggestions: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.userSelected = this.userSelected.bind(this);
    this.onUsersFetchRequested = this.onUsersFetchRequested.bind(this);
    this.onUsersClearRequested = this.onUsersClearRequested.bind(this);
    this.fetchUsersWithDebounce = _.debounce(this.onUsersFetchRequested, 1500);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let order = this.state.order;
    order[name] = value;
    this.setState({ order: order });
  }

  userSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    let order = this.state.order;
    order.user = suggestionValue;
    order.email = suggestion.email;
    order.userInput = suggestion.firstName + " " + suggestion.lastName;
    this.setState({order: order});
  }

  onUsersFetchRequested = ({ value }) => {
    if(this.state.order.user && value) {
      if(_.find(this.state.suggestions, {id: this.state.order.user, firstName: value.split(" ")[0], lastName: value.split(" ")[1]})) {
        this.setState({suggestions: []});
        return;
      }
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength === 0) {
      this.setState({suggestions: []});
      return;
    }
    if(inputLength >= 3) {
      if(inputValue.indexOf(" ") > -1) {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue.split(" ")[0]}, {id: 'lastName', value: inputValue.split(" ")[1]}])}));
      }
      else {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue}])})); 
      }
    }
  };

  onUsersClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.order.user && this.state.order.email) {
      dispatch(
        orderActions.update(
          this.props.match.params.id,
          {
            email: this.state.order.email,
            user: this.state.order.user
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.order && JSON.stringify(this.props.order) !== JSON.stringify(props.order)) {
      let order = props.order;
      order.userInput = props.order.user.firstName + " " + props.order.user.lastName;
      order.email = props.order.user.email;
      order.user = props.order.user.id;
      let items = order.order_items;
      delete order['order_items'];
      this.setState({order: order, order_items: items});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Order updated successfully'});
    }
    if(props.users && JSON.stringify(props.users) !== JSON.stringify(this.state.suggestions)) {
      this.setState({suggestions: props.users});
    }
  }

  deleteItem = item => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(orderItemActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const { submitted, order, suggestions } = this.state;
    const { loading } = this.props;
    const getSuggestionValue = suggestion => suggestion.id;
    const renderSuggestion = suggestion => (
      <div>
        {suggestion.firstName + " " + suggestion.lastName}
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Order"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Email",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: order.email,
                          name: 'email',
                          onChange: this.handleChange,
                          disabled: true
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !order.email && <div className="error">Email is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>User</ControlLabel>
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.fetchUsersWithDebounce}
                            onSuggestionsClearRequested={this.onUsersClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={this.userSelected}
                            inputProps={{
                              name: 'userInput',
                              value: order.userInput,
                              placeholder: 'User',
                              onChange: this.handleChange,
                              className: 'form-control'
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>{submitted && !order.user && <div className="error">User is required</div>}</Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Order
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Order Items"
                content={
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.order_items.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.type}</td>
                            <td>{(item.type === 'voucher' || item.type === 'credit' || item.type === 'coupon' || item.type === 'refund') && '-'}${item.amount}</td>
                            <td className="table-actions">
                              {item.type !== 'subtotal' && <NavLink to={"/dashboard/order_items/"+item.id}><Button bsStyle="info" fill type="button"><i className="fa fa-edit"></i></Button></NavLink>}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.orders;
  return {
    loading, order: item, error, success, users: state.users.items, order_items: state.orderItems.items
  };
}
 
export default connect(mapStateToProps)(EditOrder);
