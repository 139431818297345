import { GET_RESERVATION_REMARKS_REQUEST, GET_RESERVATION_REMARKS_SUCCESS, GET_RESERVATION_REMARKS_FAILURE, GET_RESERVATION_REMARK_REQUEST, GET_RESERVATION_REMARK_SUCCESS, GET_RESERVATION_REMARK_FAILURE, DELETE_RESERVATION_REMARK_REQUEST, DELETE_RESERVATION_REMARK_SUCCESS, DELETE_RESERVATION_REMARK_FAILURE, ADD_RESERVATION_REMARK_REQUEST, ADD_RESERVATION_REMARK_SUCCESS, ADD_RESERVATION_REMARK_FAILURE, UPDATE_RESERVATION_REMARK_REQUEST, UPDATE_RESERVATION_REMARK_SUCCESS, UPDATE_RESERVATION_REMARK_FAILURE } from '../constants';
import { reservationRemarkService } from '../services/reservation_remark.services';
 
export default {
  add,
  update,
  getReservationRemarks,
  getReservationRemark,
  delete: _delete
};
 
function add(reservationRemark) {
  return dispatch => {
    dispatch(request(reservationRemark));

    reservationRemarkService.add(reservationRemark)
    .then(
      data => { 
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(reservationRemark) { return { type: ADD_RESERVATION_REMARK_REQUEST, reservationRemark } }
  function success(data) { return { type: ADD_RESERVATION_REMARK_SUCCESS, data } }
  function failure(error) { return { type: ADD_RESERVATION_REMARK_FAILURE, error } }
}

function update(id, reservationRemark) {
  return dispatch => {
    dispatch(request(reservationRemark));

    reservationRemarkService.update(id, reservationRemark)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(reservationRemark) { return { type: UPDATE_RESERVATION_REMARK_REQUEST, reservationRemark } }
  function success() { return { type: UPDATE_RESERVATION_REMARK_SUCCESS } }
  function failure(error) { return { type: UPDATE_RESERVATION_REMARK_FAILURE, error } }
}
 
function getReservationRemarks(filters) {
  return dispatch => {
    dispatch(request());
 
    reservationRemarkService.getReservationRemarks(filters)
      .then(
        reservationRemarks => dispatch(success(reservationRemarks)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_RESERVATION_REMARKS_REQUEST } }
  function success(reservationRemarks) { return { type: GET_RESERVATION_REMARKS_SUCCESS, reservationRemarks } }
  function failure(error) { return { type: GET_RESERVATION_REMARKS_FAILURE, error } }
}

function getReservationRemark(id) {
  return dispatch => {
    dispatch(request());
 
    reservationRemarkService.getReservationRemark(id)
      .then(
        reservationRemark => dispatch(success(reservationRemark)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_RESERVATION_REMARK_REQUEST } }
  function success(reservationRemark) { return { type: GET_RESERVATION_REMARK_SUCCESS, reservationRemark } }
  function failure(error) { return { type: GET_RESERVATION_REMARK_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    reservationRemarkService.delete(id)
      .then(
        reservationRemark => dispatch(success(reservationRemark.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_RESERVATION_REMARK_REQUEST, id } }
  function success(id) { return { type: DELETE_RESERVATION_REMARK_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_RESERVATION_REMARK_FAILURE, id, error } }
}