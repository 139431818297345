import { GET_STOPS_REQUEST, GET_STOPS_SUCCESS, GET_STOPS_FAILURE, GET_STOP_REQUEST, GET_STOP_SUCCESS, GET_STOP_FAILURE, ADD_STOP_REQUEST, ADD_STOP_SUCCESS, ADD_STOP_FAILURE, UPDATE_STOP_REQUEST, UPDATE_STOP_SUCCESS, UPDATE_STOP_FAILURE, DELETE_STOP_REQUEST, DELETE_STOP_SUCCESS, DELETE_STOP_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_STOPS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_STOPS_SUCCESS:
      return {
        loading: false,
        items: action.stops.stops,
        total: action.stops.total
      };
    case GET_STOPS_FAILURE:
      return {
        error: action.error
      };
    case GET_STOP_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_STOP_SUCCESS:
      return {
        loading: false,
        item: action.stop
      };
    case GET_STOP_FAILURE:
      return {
        error: action.error
      };
    case ADD_STOP_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_STOP_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_STOP_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_STOP_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_STOP_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_STOP_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_STOP_REQUEST:
      // add 'deleting:true' property to stop being deleted
      return {
        ...state,
        items: state.items.map(stop =>
          stop.id === action.id
            ? { ...stop, deleting: true }
            : stop
        )
      };
    case DELETE_STOP_SUCCESS:
      // remove deleted stop from state
      return {
        items: state.items.filter(stop => stop.id !== action.id)
      };
    case DELETE_STOP_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to stop 
      return {
        ...state,
        items: state.items.map(stop => {
          if (stop.id === action.id) {
            // make copy of stop without 'deleting:true' property
            const { deleting, ...stopCopy } = stop;
            // return copy of stop with 'deleteError:[error]' property
            return { ...stopCopy, deleteError: action.error };
          }
 
          return stop;
        })
      };
    default:
      return state
  }
}