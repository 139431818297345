import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Row } from "react-bootstrap";

function FieldGroup({ label, ...props }) {
  if(props.options) {
    props.options = [{value: '', label: 'Select'}, ...props.options];
  }
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
     { props.type === 'custom'? props.custom :
      <FormControl {...props}>
        {props.options && props.options.map((option, key) => {
          return (
            <option key={key} value={(option.value !== undefined) ? option.value : option.label}>{option.label}</option>
          )
        })}
      </FormControl>
      }
    </FormGroup>
  );
}

export class FormInputs extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.proprieties[i]} />
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default FormInputs;
