import config from 'config';
 
export const locationService = {
  getGeo
};
 
function getGeo(address) {
  let qs = address + '.json?country=us&limit=1&access_token=' + config.geoKey;
  return fetch(`${config.geoUrl}${qs}`).then(handleResponse);
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}