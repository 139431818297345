import { GET_VOUCHERS_REQUEST, GET_VOUCHERS_SUCCESS, GET_VOUCHERS_FAILURE, GET_VOUCHER_REQUEST, GET_VOUCHER_SUCCESS, GET_VOUCHER_FAILURE, DELETE_VOUCHER_REQUEST, DELETE_VOUCHER_SUCCESS, DELETE_VOUCHER_FAILURE, ADD_VOUCHER_REQUEST, ADD_VOUCHER_SUCCESS, ADD_VOUCHER_FAILURE, UPDATE_VOUCHER_REQUEST, UPDATE_VOUCHER_SUCCESS, UPDATE_VOUCHER_FAILURE } from '../constants';
import { voucherService } from '../services/voucher.services';
 
export default {
  add,
  update,
  getVouchers,
  getVoucher,
  delete: _delete
};
 
function add(voucher) {
  return dispatch => {
    dispatch(request(voucher));

    voucherService.add(voucher)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(voucher) { return { type: ADD_VOUCHER_REQUEST, voucher } }
  function success(voucher) { return { type: ADD_VOUCHER_SUCCESS, voucher } }
  function failure(error) { return { type: ADD_VOUCHER_FAILURE, error } }
}

function update(id, voucher) {
  return dispatch => {
    dispatch(request(voucher));

    voucherService.update(id, voucher)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(voucher) { return { type: UPDATE_VOUCHER_REQUEST, voucher } }
  function success() { return { type: UPDATE_VOUCHER_SUCCESS } }
  function failure(error) { return { type: UPDATE_VOUCHER_FAILURE, error } }
}
 
function getVouchers(filters) {
  return dispatch => {
    dispatch(request());
 
    voucherService.getVouchers(filters)
      .then(
        vouchers => dispatch(success(vouchers)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_VOUCHERS_REQUEST } }
  function success(vouchers) { return { type: GET_VOUCHERS_SUCCESS, vouchers } }
  function failure(error) { return { type: GET_VOUCHERS_FAILURE, error } }
}

function getVoucher(id) {
  return dispatch => {
    dispatch(request());
 
    voucherService.getVoucher(id)
      .then(
        voucher => dispatch(success(voucher)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_VOUCHER_REQUEST } }
  function success(voucher) { return { type: GET_VOUCHER_SUCCESS, voucher } }
  function failure(error) { return { type: GET_VOUCHER_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    voucherService.delete(id)
      .then(
        voucher => dispatch(success(voucher.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_VOUCHER_REQUEST, id } }
  function success(id) { return { type: DELETE_VOUCHER_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_VOUCHER_FAILURE, id, error } }
}