import config from 'config';
import authHeader from '../helpers/authHeaders';
 
export const userService = {
  login,
  logout,
  add,
  getUsers,
  getUser,
  update,
  checkAuth,
  delete: _delete
};
 
function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };
 
  return fetch(`${config.apiUrl}/authenticate`, requestOptions)
    .then(handleResponse)
    .then(data => {
      // login successful if there's a jwt token in the response
      if (data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', data.token);
      }
      return data.token;
    });
}
 
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
 
function getUsers(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/users/${qs}`, requestOptions).then(handleResponse);
}

function checkAuth() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/check-auth`, requestOptions).then(handleResponse);
}
 
function getUser(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}
 
function add(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };
 
  return fetch(`${config.apiUrl}/users/employee`, requestOptions).then(handleResponse);
}
 
function update(id, user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };
 
  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401 && window.location.pathname !== '/login') {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
 
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    console.log(text)
    const data = text && JSON.parse(text);
    return data;
  });
}