import { GET_RESERVATIONS_REQUEST, GET_RESERVATIONS_SUCCESS, GET_RESERVATIONS_FAILURE, GET_MAIN_RESERVATIONS_REQUEST, GET_MAIN_RESERVATIONS_SUCCESS, GET_MAIN_RESERVATIONS_FAILURE, GET_LOST_RESERVATIONS_REQUEST, GET_LOST_RESERVATIONS_SUCCESS, GET_LOST_RESERVATIONS_FAILURE, GET_RESERVATION_REQUEST, GET_RESERVATION_SUCCESS, GET_RESERVATION_FAILURE, DELETE_RESERVATION_REQUEST, DELETE_RESERVATION_SUCCESS, DELETE_RESERVATION_FAILURE, DELETE_RESERVATIONS_REQUEST, DELETE_RESERVATIONS_SUCCESS, DELETE_RESERVATIONS_FAILURE, ADD_RESERVATION_REQUEST, ADD_RESERVATION_SUCCESS, ADD_RESERVATION_FAILURE, UPDATE_RESERVATION_REQUEST, UPDATE_RESERVATION_SUCCESS, UPDATE_RESERVATION_FAILURE } from '../constants';
import { reservationService } from '../services/reservation.services';
 
export default {
  add,
  update,
  getReservations,
  getLostReservations,
  getReservation,
  getMainReservations,
  searchReservations,
  delete: _delete,
  deleteReservations: deleteReservations
};
 
function add(reservation) {
  return dispatch => {
    dispatch(request(reservation));

    reservationService.add(reservation)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(reservation) { return { type: ADD_RESERVATION_REQUEST, reservation } }
  function success(reservation) { return { type: ADD_RESERVATION_SUCCESS, reservation } }
  function failure(error) { return { type: ADD_RESERVATION_FAILURE, error } }
}

function update(id, reservation) {
  return dispatch => {
    dispatch(request(reservation));

    reservationService.update(id, reservation)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(reservation) { return { type: UPDATE_RESERVATION_REQUEST, reservation } }
  function success() { return { type: UPDATE_RESERVATION_SUCCESS } }
  function failure(error) { return { type: UPDATE_RESERVATION_FAILURE, error } }
}
 
function getReservations(filters) {
  return dispatch => {
    dispatch(request());
 
    reservationService.getReservations(filters)
      .then(
        reservations => dispatch(success(reservations)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_RESERVATIONS_REQUEST } }
  function success(reservations) { return { type: GET_RESERVATIONS_SUCCESS, reservations } }
  function failure(error) { return { type: GET_RESERVATIONS_FAILURE, error } }
}

function getMainReservations(filters) {
  return dispatch => {
    dispatch(request());
 
    reservationService.getMainReservations(filters)
      .then(
        reservations => dispatch(success(reservations)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_MAIN_RESERVATIONS_REQUEST } }
  function success(reservations) { return { type: GET_MAIN_RESERVATIONS_SUCCESS, reservations } }
  function failure(error) { return { type: GET_MAIN_RESERVATIONS_FAILURE, error } }
}

function getLostReservations() {
  return dispatch => {
    dispatch(request());
 
    reservationService.getLostReservations()
      .then(
        reservations => dispatch(success(reservations)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_LOST_RESERVATIONS_REQUEST } }
  function success(reservations) { return { type: GET_LOST_RESERVATIONS_SUCCESS, reservations } }
  function failure(error) { return { type: GET_LOST_RESERVATIONS_FAILURE, error } }
}

function searchReservations(filters) {
  let append = filters.append || false;
  delete filters['append'];
  return dispatch => {
    dispatch(request());
 
    reservationService.searchReservations(filters)
      .then(
        reservations => dispatch(success(reservations)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_MAIN_RESERVATIONS_REQUEST, append: append } }
  function success(reservations) { return { type: GET_MAIN_RESERVATIONS_SUCCESS, reservations, append: append } }
  function failure(error) { return { type: GET_MAIN_RESERVATIONS_FAILURE, error } }
}

function deleteReservations(ids) {
  return dispatch => {
    dispatch(request());
 
    reservationService.deleteReservations(ids)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: DELETE_RESERVATIONS_REQUEST } }
  function success(data) { return { type: DELETE_RESERVATIONS_SUCCESS, data } }
  function failure(error) { return { type: DELETE_RESERVATIONS_FAILURE, error } }
}

function getReservation(id) {
  return dispatch => {
    dispatch(request());
 
    reservationService.getReservation(id)
      .then(
        reservation => dispatch(success(reservation)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_RESERVATION_REQUEST } }
  function success(reservation) { return { type: GET_RESERVATION_SUCCESS, reservation } }
  function failure(error) { return { type: GET_RESERVATION_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    reservationService.delete(id)
      .then(
        reservation => dispatch(success(reservation.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_RESERVATION_REQUEST, id } }
  function success(id) { return { type: DELETE_RESERVATION_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_RESERVATION_FAILURE, id, error } }
}