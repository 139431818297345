import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import busActions from 'actions/bus.actions';
import userActions from 'actions/user.actions';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

//import avatar from "assets/img/faces/face-3.jpg";

class AddBus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      suggestions: [],
      bus: {
        hasWifi: false,
        hasMovies: false,
        hasPowerOutlets: false,
        driverInput: '',
        type: '',
        name: '',
        company: '',
        capacity: '',
        driver: '',
        defaultPrice: '',
        defaultFirstSpecialSeats: '',
        defaultLastSpecialSeats: '',
        defaultFirstSpecialSeatsPrice: '',
        defaultLastSpecialSeatsPrice: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.driverSelected = this.driverSelected.bind(this);
    this.onDriversFetchRequested = this.onDriversFetchRequested.bind(this);
    this.onDriversClearRequested = this.onDriversClearRequested.bind(this);
    this.fetchDriversWithDebounce = _.debounce(this.onDriversFetchRequested, 1500);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let bus = this.state.bus;
    bus[name] = value;
    this.setState({ bus: bus });
  }

  driverSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    let bus = this.state.bus;
    bus.driver = suggestionValue;
    bus.driverInput = suggestion.firstName + " " + suggestion.lastName;
    this.setState({bus: bus});
  }

  onDriversFetchRequested = ({ value }) => {
    if(this.state.bus.driver && value) {
      if(_.find(this.state.suggestions, {id: this.state.bus.driver, firstName: value.split(" ")[0], lastName: value.split(" ")[1]})) {
        this.setState({suggestions: []});
        return;
      }
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength === 0) {
      this.setState({suggestions: []});
      return;
    }
    if(inputLength >= 3) {
      if(inputValue.indexOf(" ") > -1) {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue.split(" ")[0]}, {id: 'lastName', value: inputValue.split(" ")[1]}])}));
      }
      else {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue}])})); 
      }
    }
  };

  onDriversClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.bus.name && this.state.bus.company && this.state.bus.driver && this.state.bus.type && this.state.bus.capacity && this.state.bus.defaultPrice) {
      dispatch(
        busActions.add({
          hasWifi: this.state.bus.hasWifi,
          hasMovies: this.state.bus.hasMovies,
          hasPowerOutlets: this.state.bus.hasPowerOutlets,
          type: this.state.bus.type,
          name: this.state.bus.name,
          company: this.state.bus.company,
          capacity: this.state.bus.capacity,
          driver: this.state.bus.driver,
          defaultPrice: this.state.bus.defaultPrice,
          defaultFirstSpecialSeats: this.state.bus.defaultFirstSpecialSeats || null,
          defaultLastSpecialSeats: this.state.bus.defaultLastSpecialSeats || null,
          defaultFirstSpecialSeatsPrice: this.state.bus.defaultFirstSpecialSeatsPrice || null,
          defaultLastSpecialSeatsPrice: this.state.bus.defaultLastSpecialSeatsPrice || null
        })
      );
    }
  }

  componentWillUpdate(props) {
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Bus Added successfully'});
      this.setState({
        bus: {
          hasWifi: false,
          hasMovies: false,
          hasPowerOutlets: false,
          type: '',
          name: '',
          company: '',
          capacity: '',
          driver: '',
          driverInput: '',
          defaultPrice: '',
          defaultFirstSpecialSeats: '',
          defaultLastSpecialSeats: '',
          defaultFirstSpecialSeatsPrice: '',
          defaultLastSpecialSeatsPrice: ''
        },  
        submitted: false
      });
    }
    if(props.drivers && JSON.stringify(props.drivers) !== JSON.stringify(this.state.suggestions)) {
      this.setState({suggestions: props.drivers});
    }
  }

  handleCheckbox = event => {
    const target = event.target;
    let bus = this.state.bus;
    bus[target.name] = target.checked;
    this.setState({ bus: bus });
  };

  render() {
    const { submitted, bus, suggestions } = this.state;
    const { loading } = this.props;
    const getSuggestionValue = suggestion => suggestion.id;
    const renderSuggestion = suggestion => (
      <div>
        {suggestion.firstName + " " + suggestion.lastName}
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Add Bus"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Name",
                          value: bus.name,
                          name: 'name',
                          onChange: this.handleChange
                        },
                        {
                          label: "Company",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Company",
                          value: bus.company,
                          name: 'company',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !bus.name && <div className="error">Name is required</div>}</Col>
                      <Col md={6}>{submitted && !bus.company && <div className="error">Company is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>Driver</ControlLabel>
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.fetchDriversWithDebounce}
                            onSuggestionsClearRequested={this.onDriversClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={this.driverSelected}
                            inputProps={{
                              name: 'driverInput',
                              value: bus.driverInput,
                              placeholder: 'Driver',
                              onChange: this.handleChange,
                              className: 'form-control'
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>{submitted && !bus.driver && <div className="error">Driver is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Type",
                          componentClass: "select",
                          bsClass: "form-control",
                          placeholder: "Type",
                          value: bus.type,
                          name: 'type',
                          options: [{label: 'AC'}, {label: 'Non-AC'}, {label: 'Express'}, {label: 'Elite'}],
                          onChange: this.handleChange
                        },
                        {
                          label: "Capacity",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Capacity",
                          value: bus.capacity,
                          name: 'capacity',
                          onChange: this.handleChange
                        },
                        {
                          label: "Default Price",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Default Price",
                          value: bus.defaultPrice,
                          name: 'defaultPrice',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={4}>{submitted && !bus.type && <div className="error">Type is required</div>}</Col>
                      <Col md={4}>{submitted && !bus.capacity && <div className="error">Capacity is required</div>}</Col>
                      <Col md={4}>{submitted && !bus.defaultPrice && <div className="error">Price is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Default First Special Seats",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Default First Special Seats",
                          value: bus.defaultFirstSpecialSeats,
                          name: 'defaultFirstSpecialSeats',
                          onChange: this.handleChange
                        },
                        {
                          label: "Default First Special Seats Price",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Default First Special Seats Price",
                          value: bus.defaultFirstSpecialSeatsPrice,
                          name: 'defaultFirstSpecialSeatsPrice',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Default Last Special Seats",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Default Last Special Seats",
                          value: bus.defaultLastSpecialSeats,
                          name: 'defaultLastSpecialSeats',
                          onChange: this.handleChange
                        },
                        {
                          label: "Default Last Special Seats Price",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Default Last Special Seats Price",
                          value: bus.defaultLastSpecialSeatsPrice,
                          name: 'defaultLastSpecialSeatsPrice',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row>
                      <Col md={4}>
                        <Checkbox
                          number='hasWifi'
                          name='hasWifi'
                          value={bus.hasWifi}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={bus.hasWifi}
                          label='Wifi'
                        />
                      </Col>
                      <Col md={4}>
                        <Checkbox
                          number='hasMovies'
                          name='hasMovies'
                          value={bus.hasMovies}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={bus.hasMovies}
                          label='Movies'
                        />
                      </Col>
                      <Col md={4}>
                        <Checkbox
                          number='hasPowerOutlets'
                          name='hasPowerOutlets'
                          value={bus.hasPowerOutlets}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={bus.hasPowerOutlets}
                          label='Power Outlets'
                        />
                      </Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Add Bus
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.buses;
  const { items } = state.users;
  return {
    loading, error, success, drivers: items
  };
}
 
export default connect(mapStateToProps)(AddBus);
