import { GET_NOTICES_REQUEST, GET_NOTICES_SUCCESS, GET_NOTICES_FAILURE, GET_NOTICE_REQUEST, GET_NOTICE_SUCCESS, GET_NOTICE_FAILURE, ADD_NOTICE_REQUEST, ADD_NOTICE_SUCCESS, ADD_NOTICE_FAILURE, UPDATE_NOTICE_REQUEST, UPDATE_NOTICE_SUCCESS, UPDATE_NOTICE_FAILURE, DELETE_NOTICE_REQUEST, DELETE_NOTICE_SUCCESS, DELETE_NOTICE_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_NOTICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_NOTICES_SUCCESS:
      return {
        loading: false,
        items: action.notices.notices,
        total: action.notices.total
      };
    case GET_NOTICES_FAILURE:
      return {
        error: action.error
      };
    case GET_NOTICE_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_NOTICE_SUCCESS:
      return {
        loading: false,
        item: action.notice
      };
    case GET_NOTICE_FAILURE:
      return {
        error: action.error
      };
    case ADD_NOTICE_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_NOTICE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_NOTICE_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_NOTICE_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_NOTICE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_NOTICE_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_NOTICE_REQUEST:
      // add 'deleting:true' property to notice being deleted
      return {
        ...state,
        items: state.items.map(notice =>
          notice.id === action.id
            ? { ...notice, deleting: true }
            : notice
        )
      };
    case DELETE_NOTICE_SUCCESS:
      // remove deleted notice from state
      return {
        items: state.items.filter(notice => notice.id !== action.id)
      };
    case DELETE_NOTICE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to notice 
      return {
        ...state,
        items: state.items.map(notice => {
          if (notice.id === action.id) {
            // make copy of notice without 'deleting:true' property
            const { deleting, ...noticeCopy } = notice;
            // return copy of notice with 'deleteError:[error]' property
            return { ...noticeCopy, deleteError: action.error };
          }
 
          return notice;
        })
      };
    default:
      return state
  }
}