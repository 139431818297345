import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { connect } from 'react-redux';
import permissionActions from 'actions/permission.actions';

export class Permissions extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(permissionActions.getPermissions({user: this.props.user}));

    this.state = {
      id: null,
      availablePermissions: {
        dashboard: false,
        users: false,
        customers: false,
        bus_stops: false,
        site_search: false,
        driver_sheet: false,
        personalization: false,
        topbar: false,
        notices: false,
        schedules: false,
        move_people: false,
        prices: false,
        reservations: false,
        max_capacity: false,
        promos: false,
        gift_cards: false,
        bus_types: false,
      }
    };
  }

  handleCheckbox = event => {
    const target = event.target;
    let available = this.state.availablePermissions;
    available[target.name] = target.checked;
    this.setState({ availablePermissions: available }, () => {
      console.log(this.state.availablePermissions)
      let data = [];
      Object.keys(this.state.availablePermissions).map(key => {
        if(this.state.availablePermissions[key]) {
          data.push(key);
        }
        return key;
      });

      if(!this.state.id) {
        this.props.dispatch(permissionActions.add({
          user: this.props.user,
          permissions: data
        }));
      }
      else {
        this.props.dispatch(permissionActions.update(this.state.id, {permissions: JSON.stringify(data)}));
      }
    });
  };

  componentWillUpdate(props) {
    if(props.permissions && props.permissions.id) {
      if(!this.props.permissions || JSON.stringify(props.permissions) !== JSON.stringify(this.props.permissions)) {
        const available = this.state.availablePermissions;
        props.permissions.permissions.map(per => {
          available[per] = true;
          return per;
        });
        this.setState({availablePermissions: available, id: props.permissions.id});
      }
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Permissions updated successfully'});
    }
  }

  render() {

    let { availablePermissions } = this.state;
    let keys = Object.keys(availablePermissions);

    return (
      <Card
        title="Edit User Permissions"
        content={
          <div className="permissions-box">
            {keys.map((per, i) => {
              return (
                <div className="permission-block" key={i}>
                  <Checkbox
                    number = {per + i}
                    name = {per}
                    key = {i}
                    checked = {availablePermissions[per]}
                    label = {per}
                    onChange = {this.handleCheckbox.bind(this)}
                    value = {availablePermissions[per]}
                  />
                </div>
              )
            })}
          </div>
        }
      />
    )
  }
}

function mapStateToProps(state) {
  const { loading, items, success, error } = state.permissions;
  return {
    loading, error, success, permissions: items
  };
}
 
export default connect(mapStateToProps)(Permissions);
