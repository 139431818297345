import { GET_SCHEDULES_REQUEST, GET_SCHEDULES_SUCCESS, GET_SCHEDULES_FAILURE, GET_SCHEDULESUMMARY_SUCCESS, GET_SCHEDULESUMMARY_FAILURE, GET_SCHEDULE_REQUEST, GET_SCHEDULE_SUCCESS, GET_SCHEDULE_FAILURE, ADD_SCHEDULE_REQUEST, ADD_SCHEDULE_SUCCESS, ADD_SCHEDULE_FAILURE, UPDATE_SCHEDULE_REQUEST, UPDATE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FAILURE, DELETE_SCHEDULE_REQUEST, DELETE_SCHEDULE_SUCCESS, DELETE_SCHEDULE_FAILURE } from '../constants';

export default (state = {activeBuses: []}, action) => {
  switch (action.type) {
    case GET_SCHEDULES_REQUEST:
      return {
        ...state,
        loading: true,
        activeBuses: []
      };
    case GET_SCHEDULES_SUCCESS:
      return {
        loading: false,
        items: action.schedule
      };
    case GET_SCHEDULES_FAILURE:
      return {
        error: action.error
      };
    case GET_SCHEDULESUMMARY_SUCCESS:
      action.summary.start = action.start;
      action.summary.event = action.event;
      return {
        ...state,
        summary: action.summary
      };
    case GET_SCHEDULESUMMARY_FAILURE:
      return {
        error: action.error
      };
    case GET_SCHEDULE_REQUEST:
      return {
        loading: true,
        item: null,
        activeBuses: []
      };
    case GET_SCHEDULE_SUCCESS:
      console.log(action.schedule);
      return {
        loading: false,
        item: action.schedule
      };
    case GET_SCHEDULE_FAILURE:
      return {
        error: action.error
      };
    case ADD_SCHEDULE_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
        activeBuses: []
      };
    case ADD_SCHEDULE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_SCHEDULE_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_SCHEDULE_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
        activeBuses: []
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: '',
        activeBuses: action.activeBuses
      };
    case UPDATE_SCHEDULE_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_SCHEDULE_REQUEST:
      // add 'deleting:true' property to schedule being deleted
      return {
        ...state,
        items: state.items.map(schedule =>
          schedule.id === action.id
            ? { ...schedule, deleting: true }
            : schedule
        )
      };
    case DELETE_SCHEDULE_SUCCESS:
      // remove deleted schedule from state
      return {
        items: state.items.filter(schedule => schedule.id !== action.id)
      };
    case DELETE_SCHEDULE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to schedule 
      return {
        ...state,
        items: state.items.map(schedule => {
          if (schedule.id === action.id) {
            // make copy of schedule without 'deleting:true' property
            const { deleting, ...scheduleCopy } = schedule;
            // return copy of schedule with 'deleteError:[error]' property
            return { ...scheduleCopy, deleteError: action.error };
          }
 
          return schedule;
        })
      };
    default:
      return state
  }
}