import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import topbarActions from 'actions/topbar.actions';
import { connect } from 'react-redux';

//import avatar from "assets/img/faces/face-3.jpg";

class EditTopbar extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(topbarActions.getTopbar());

    this.state = {
      submitted: false,
      topbar: {
        title: '',
        text: '',
        link: '',
        id: '',
        visible: true
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let topbar = this.state.topbar;
    topbar[name] = value;
    this.setState({ topbar: topbar });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    let data = {
      title: this.state.topbar.title,
      text: this.state.topbar.text,
      link: this.state.topbar.link,
      visible: this.state.topbar.visible
    };
    dispatch(
      topbarActions.update(this.state.topbar.id, data)
    );
  }

  componentWillUpdate(props) {
    if(props.topbar && JSON.stringify(this.props.topbar) !== JSON.stringify(props.topbar)) {
      let topbar = props.topbar;
      this.setState({topbar: topbar});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Topbar updated successfully'});
    }
  }

  clearField(arg) {
    let topbar = this.state.topbar;
    if(arg === 1) {
      topbar.title = '';
    }
    else if(arg === 2) {
      topbar.text = '';
    }
    else if(arg === 3) {
      topbar.link = '';
    }
    this.setState({topbar: topbar});
  }

  handleCheckbox = event => {
    let topbar = this.state.topbar;
    topbar.visible = !topbar.visible;
    this.setState({topbar: topbar});
  };

  render() {
    const { submitted, topbar } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Topbar"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <div className="special-check"><Checkbox
                      number='visible'
                      value={topbar.visible}
                      onChange={this.handleCheckbox.bind(this)}
                      checked={topbar.visible}
                      label='Show'
                    /></div>
                    <Row>
                      <Col md={10}>
                        <FormGroup>
                          <ControlLabel>Title</ControlLabel>
                          <FormControl placeholder="Title" type="text" bsClass= "form-control" name="title" value={topbar.title} onChange={this.handleChange}></FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={2} className="error text-clear" onClick={this.clearField.bind(this, 1)}>Clear</Col>
                    </Row>
                    <Row>
                      <Col md={10}>
                        <FormGroup>
                          <ControlLabel>Text</ControlLabel>
                          <FormControl placeholder="Text" type="text" bsClass= "form-control" name="text" value={topbar.text} onChange={this.handleChange}></FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={2} className="error text-clear" onClick={this.clearField.bind(this, 2)}>Clear</Col>
                    </Row>
                    <Row>
                      <Col md={10}>
                        <FormGroup>
                          <ControlLabel>Link</ControlLabel>
                          <FormControl placeholder="Link" type="text" bsClass= "form-control" name="link" value={topbar.link} onChange={this.handleChange}></FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={2} className="error text-clear" onClick={this.clearField.bind(this, 3)}>Clear</Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Top Bar
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.topbar;
  return {
    loading, topbar: item, error, success
  };
}
 
export default connect(mapStateToProps)(EditTopbar);
