export default {
  apiUrl: process.env.REACT_APP_API_URL,
  geoUrl: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
  geoKey: "pk.eyJ1IjoiYW50d2FuIiwiYSI6ImNqZmFkNDlzdjEwemMzM29iNGx3czV1dHMifQ.rVPXiWLCyEkaDwZ6stZ_aQ",
  pageSize: 100,
  cities: [
    {
      code: "NY",
      value: "new york",
      label: "New York, NY  (Penn Station)",
      address: "Opposite Side Of 320 W 31st St Btw 8th And 9th, NY",
      metro:
        "MTA subway lines A, C, E, [8th Ave] 1,2,3, [7th Ave] outside of the Penn Station. B, D, F, M, [6th Ave.]",
      zip: 10001,
      state: "New York",
    },
    {
      code: "WASB",
      value: "bethesda",
      label: "Bethesda, MD",
      address: "4681 Willow Ln, Bethesda, MD",
      metro: "Bethesda Station, Red Line",
      zip: 20814,
      state: "Maryland",
    },
    {
      code: "WASA",
      value: "arlington",
      label: "Arlington, VA (Rosslyn)",
      address: " 1101 Wilson Blvd Arlington, Va",
      metro: "Rosslyn Station, Blue and Orange Line",
      zip: 22209,
      state: "Virginia",
    },
    {
      code: "WASH",
      value: "washington",
      label: "Washington, DC",
      address: "1610 Connecticut Ave NW (Corner Q Street NW), Washington DC",
      metro: "",
      zip: 20009,
      state: "DC ",
    },
    {
		code: "BR",
		value: "brooklyn",
		label: "Brooklyn, NY",
		dropoffAddress: "40 Empire Blvd, Brooklyn, NY",
		address: "40 Empire Blvd, Brooklyn, NY",
		metro: "",
		zip: 11225,
		state: "New York",
    },
  ],
  formatReservationNumber: function (number) {
    if (!number) {
      return "";
    }
    let str = "";
    let initialChars = 7;
    if (
      (number.length > 15 && number.indexOf("WAS") === -1) ||
      number.length > 17
    ) {
      initialChars = 9;
    }
    str = number.substr(0, initialChars) + "-";
    str += number.substr(initialChars, 1) + "-";
    str += number.substr(initialChars + 1, 4) + "-";
    if (number.indexOf("NY") > -1 || number.indexOf("BR") > -1) {
      str += number.substr(initialChars + 5, 2) + "-";
      str += number.substr(initialChars + 7);
    } else if (number.indexOf("WA") > -1) {
      str += number.substr(initialChars + 5, 4) + "-";
      str += number.substr(initialChars + 9);
    }
    return str;
  },
};
