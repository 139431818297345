import { GET_PAYMENTS_REQUEST, GET_PAYMENTS_SUCCESS, GET_PAYMENTS_FAILURE, GET_PAYMENT_REQUEST, GET_PAYMENT_SUCCESS, GET_PAYMENT_FAILURE, ADD_PAYMENT_REQUEST, ADD_PAYMENT_SUCCESS, ADD_PAYMENT_FAILURE, UPDATE_PAYMENT_REQUEST, UPDATE_PAYMENT_SUCCESS, UPDATE_PAYMENT_FAILURE, DELETE_PAYMENT_REQUEST, DELETE_PAYMENT_SUCCESS, DELETE_PAYMENT_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_PAYMENTS_SUCCESS:
      return {
        loading: false,
        items: action.payments.payments,
        total: action.payments.total
      };
    case GET_PAYMENTS_FAILURE:
      return {
        error: action.error
      };
    case GET_PAYMENT_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_PAYMENT_SUCCESS:
      return {
        loading: false,
        item: action.payment
      };
    case GET_PAYMENT_FAILURE:
      return {
        error: action.error
      };
    case ADD_PAYMENT_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_PAYMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_PAYMENT_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_PAYMENT_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_PAYMENT_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_PAYMENT_REQUEST:
      // add 'deleting:true' property to payment being deleted
      return {
        ...state,
        items: state.items.map(payment =>
          payment.id === action.id
            ? { ...payment, deleting: true }
            : payment
        )
      };
    case DELETE_PAYMENT_SUCCESS:
      // remove deleted payment from state
      return {
        items: state.items.filter(payment => payment.id !== action.id)
      };
    case DELETE_PAYMENT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to payment 
      return {
        ...state,
        items: state.items.map(payment => {
          if (payment.id === action.id) {
            // make copy of payment without 'deleting:true' property
            const { deleting, ...paymentCopy } = payment;
            // return copy of payment with 'deleteError:[error]' property
            return { ...paymentCopy, deleteError: action.error };
          }
 
          return payment;
        })
      };
    default:
      return state
  }
}