import { GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE, GET_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE, ADD_COUPON_REQUEST, ADD_COUPON_SUCCESS, ADD_COUPON_FAILURE, UPDATE_COUPON_REQUEST, UPDATE_COUPON_SUCCESS, UPDATE_COUPON_FAILURE, DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_COUPONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_COUPONS_SUCCESS:
      return {
        loading: false,
        items: action.coupons.coupons,
        total: action.coupons.total
      };
    case GET_COUPONS_FAILURE:
      return {
        error: action.error
      };
    case GET_COUPON_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_COUPON_SUCCESS:
      return {
        loading: false,
        item: action.coupon
      };
    case GET_COUPON_FAILURE:
      return {
        error: action.error
      };
    case ADD_COUPON_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_COUPON_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_COUPON_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_COUPON_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_COUPON_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_COUPON_REQUEST:
      // add 'deleting:true' property to coupon being deleted
      return {
        ...state,
        items: state.items.map(coupon =>
          coupon.id === action.id
            ? { ...coupon, deleting: true }
            : coupon
        )
      };
    case DELETE_COUPON_SUCCESS:
      // remove deleted coupon from state
      return {
        items: state.items.filter(coupon => coupon.id !== action.id)
      };
    case DELETE_COUPON_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to coupon 
      return {
        ...state,
        items: state.items.map(coupon => {
          if (coupon.id === action.id) {
            // make copy of coupon without 'deleting:true' property
            const { deleting, ...couponCopy } = coupon;
            // return copy of coupon with 'deleteError:[error]' property
            return { ...couponCopy, deleteError: action.error };
          }
 
          return coupon;
        })
      };
    default:
      return state
  }
}