import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import stopActions from 'actions/stop.actions';
import { connect } from 'react-redux';
import config from 'config';

//import avatar from "assets/img/faces/face-3.jpg";

class AddStop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      cities: config.cities,
      stop: {
        active: true,
        address: "",
        city: "",
        description: "",
        state: "",
        zipCode: "",
        country: "US",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let stop = this.state.stop;
    stop[name] = value;
    this.setState({ stop: stop });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.stop.address && this.state.stop.city && this.state.stop.state && this.state.stop.zipCode && this.state.stop.country) {
      dispatch(
        stopActions.add({
          address: this.state.stop.address,
          city: this.state.stop.city,
          state: this.state.stop.state,
          zipCode: this.state.stop.zipCode,
          active: this.state.stop.active,
          country: this.state.stop.country,
          description: this.state.stop.description
        })
      );
    }
  }

  componentWillUpdate(props) {
    if (props.error && !this.props.error) {
      this.props.addNotification({ type: "error", message: props.error });
    }
    if (props.success && !this.props.success) {
      this.props.addNotification({
        type: "success",
        message: "Stop Added successfully",
      });
      this.setState({
        stop: {
          address: "",
          city: "",
          state: "",
          zipCode: "",
          active: true,
          country: "US",
          description: ""
        },
        submitted: false,
      });
    }
  }

  handleCheckbox = (event) => {
    let stop = this.state.stop;
    stop.active = !stop.active;
    this.setState({ stop: stop });
  };

  getUniqueCities(cities){

    let uniqueCities = [];
    let uniqueCitiesObjectArray = [];

    cities.map((city)=>{
      if(!uniqueCities.includes(city.value)){
        uniqueCities.push(city.value)
        uniqueCitiesObjectArray.push(city)
      }
    });

    return uniqueCitiesObjectArray;
  }

  render() {
    const { submitted, stop } = this.state;
    const { loading } = this.props;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Add Stop"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              label: "Address",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Address",
                              value: stop.address,
                              name: "address",
                              onChange: this.handleChange,
                            },
                          ]}
                        />
                        <Row className="error-row">
                          <Col md={12}>
                            {submitted && !stop.address && (
                              <div className="error">Address is required</div>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col md={6}>
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              label: "Address Description",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Address Description",
                              value: stop.description,
                              name: "description",
                              onChange: this.handleChange,
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>City</ControlLabel>
                          <FormControl
                            componentClass="select"
                            bsClass="form-control"
                            name="city"
                            value={stop.city}
                            onChange={this.handleChange}
                          >
                            <option value="">Select</option>
                            {this.getUniqueCities(this.state.cities).map((stop, key) => {
                              return (
                                <option key={key} value={stop.value}>
                                  {stop.label}
                                </option>
                              );
                            })}
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>State</ControlLabel>
                          <FormControl
                            bsClass="form-control"
                            name="state"
                            value={stop.state}
                            onChange={this.handleChange}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={6}>
                        {submitted && !stop.city && (
                          <div className="error">City is required</div>
                        )}
                      </Col>
                      <Col md={6}>
                        {submitted && !stop.state && (
                          <div className="error">State is required</div>
                        )}
                      </Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "ZIP Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: stop.zipCode,
                          name: "zipCode",
                          onChange: this.handleChange,
                        },
                        {
                          label: "Country",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Country",
                          value: stop.country,
                          name: "country",
                          disabled: true,
                          onChange: this.handleChange,
                        },
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>
                        {submitted && !stop.zipCode && (
                          <div className="error">Zipcode is required</div>
                        )}
                      </Col>
                      <Col md={6}>
                        {submitted && !stop.country && (
                          <div className="error">Country is required</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Checkbox
                          number="check1"
                          value={stop.active}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={stop.active}
                          label="Active"
                        />
                      </Col>
                    </Row>
                    <Button
                      bsStyle="info"
                      disabled={loading}
                      fill
                      type="submit"
                    >
                      Add Stop
                    </Button>
                    {loading && submitted && (
                      <img
                        alt=""
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.stops;
  return {
    loading,
    error,
    success,
  };
}

export default connect(mapStateToProps)(AddStop);
