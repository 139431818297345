import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import giftCardActions from 'actions/gift_card.actions';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { connect } from 'react-redux';

//import avatar from "assets/img/faces/face-3.jpg";

class EditGiftCard extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(giftCardActions.getGiftCard(this.props.match.params.id));

    this.state = {
      submitted: false,
      giftCard: {
        consumer: '',
        amount: '',
        code: '',
        owner: '',
        description: '',
        order_item: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let giftCard = this.state.giftCard;
    giftCard[name] = value;
    this.setState({ giftCard: giftCard });
  }

  handleCheckbox = event => {
    let giftCard = this.state.giftCard;
    giftCard.redeemed = !giftCard.redeemed;
    this.setState({giftCard: giftCard});
  };
  
  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.giftCard.owner && this.state.giftCard.description && this.state.giftCard.amount && this.state.giftCard.code && this.state.giftCard.order_item) {
      dispatch(
        giftCardActions.update(
          this.props.match.params.id,
          {
            owner: this.state.giftCard.owner.id,
            consumer: (this.state.giftCard.consumer ? this.state.giftCard.consumer.id : null),
            code: this.state.giftCard.code,
            amount: this.state.giftCard.amount,
            description: this.state.giftCard.description,
            order_item: this.state.giftCard.order_item
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.giftCard && JSON.stringify(this.props.giftCard) !== JSON.stringify(props.giftCard)) {
      let giftCard = props.giftCard;
      this.setState({giftCard: giftCard});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'GiftCard updated successfully'});
    }
  }

  render() {
    const { submitted, giftCard } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Credit"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Owner",
                          bsClass: "form-control",
                          placeholder: "Owner",
                          value: (giftCard.owner.firstName + " " + giftCard.owner.lastName),
                          name: 'owner',
                          disabled: true,
                          onChange: this.handleChange
                        },
                        {
                          label: "Consumer",
                          bsClass: "form-control",
                          placeholder: "Consumer",
                          value: (giftCard.consumer ? giftCard.consumer.firstName + " " + giftCard.consumer.lastName : ''),
                          name: 'consumer',
                          disabled: true,
                          onChange: this.handleChange
                        },
                        {
                          label: "Order Item",
                          bsClass: "form-control",
                          placeholder: "Order Item",
                          value: giftCard.order_item,
                          name: 'order_item',
                          disabled: true,
                          onChange: this.handleChange
                        }

                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Amount",
                          bsClass: "form-control",
                          placeholder: "Amount",
                          type: "number",
                          value: giftCard.amount,
                          name: 'amount',
                          onChange: this.handleChange
                        },
                        {
                          label: "Code",
                          bsClass: "form-control",
                          placeholder: "Code",
                          value: giftCard.code,
                          name: 'code',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !giftCard.amount && <div className="error">Amount is required</div>}</Col>
                      <Col md={6}>{submitted && !giftCard.code && <div className="error">Code is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Description",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          placeholder: "Description",
                          value: giftCard.description,
                          name: 'description',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !giftCard.description && <div className="error">Description is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Checkbox
                          number='check1'
                          disabled={true}
                          value={giftCard.redeemed}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={giftCard.redeemed}
                          label='Redeemed'
                        />
                      </Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Gift Card
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.giftCards;
  return {
    loading, giftCard: item, error, success
  };
}
 
export default connect(mapStateToProps)(EditGiftCard);
