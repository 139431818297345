import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import userActions from '../../actions/user.actions';
import { history } from '../../helpers/history';
import { connect } from "react-redux";
import store from "stores/index";

function queryStringToJSON(param) {            
  var pairs = param.slice(1).split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
}

class HeaderLinks extends Component {
  
  constructor(props) {
    super(props);
    console.log(store.getState())
    this.state = {
      searchIn: 'reservations',
      column: '',
      value: '',
      options: [
        {label: 'First Name', value: 'firstName'},
        {label: 'Last Name', value: 'lastName'},
        {label: 'Phone Number', value: 'phone'},
        {label: 'Reservation Number', value: 'reservationNumber'},
        {label: 'Email Address', value: 'email'},
        {label: 'IP Address', value: 'user_ip'},
        {label: 'Date Made', value: 'created_at'}
      ]
    };
  }

  componentDidMount() {
    if(window.location.search && queryStringToJSON(window.location.search).searchIn && queryStringToJSON(window.location.search).column && queryStringToJSON(window.location.search).value) {
      this.setState({
        searchIn: queryStringToJSON(window.location.search).searchIn,
        column: queryStringToJSON(window.location.search).column,
        value: queryStringToJSON(window.location.search).value
      })
    }
  }

  handleChange(e) {
    if(e.target.name === 'value' && e.target.value && !(/[a-zA-Z0-9@$.]/.test(e.target.value))) {
      return;
    }
    this.setState({[e.target.name]: e.target.value});
    if(e.target.name === 'searchIn' && e.target.value === 'reservations') {
      this.setState({
        options: [
          {label: 'First Name', value: 'firstName'},
          {label: 'Last Name', value: 'lastName'},
          {label: 'Phone Number', value: 'phone'},
          {label: 'Reservation Number', value: 'reservationNumber'},
          {label: 'Email Address', value: 'email'},
          {label: 'IP Address', value: 'IP'},
          {label: 'Date Made', value: 'created_at'},
          {label: 'Notes', value: 'notes'},
          {label: 'Groupon Code', value: 'coupon'}
        ]
      })
    }
    else if(e.target.name === 'searchIn' && e.target.value === 'accounts') {
      this.setState({
        options: [
          {label: 'First Name', value: 'firstName'},
          {label: 'Last Name', value: 'lastName'},
          {label: 'Phone Number', value: 'phone'},
          {label: 'Email Address', value: 'email'}
        ]
      })
    }
  }

  handleLogout() {
    this.props.dispatch(userActions.logout());
    history.push('/login');
  }

  search(e) {
    e.preventDefault();
    if(!this.state.column || !this.state.value || !this.state.searchIn) {
      return;
    }
    else if(this.state.searchIn === 'reservations') {
      if(this.state.column === 'reservationNumber' && this.state.value) {
        history.push('/dashboard/search-reservations?column='+this.state.column+'&searchIn='+this.state.searchIn+'&value='+this.state.value.split('-').join(''));
      }
      else {
        history.push('/dashboard/search-reservations?column='+this.state.column+'&searchIn='+this.state.searchIn+'&value='+this.state.value);
      }
    }
    else {
      history.push('/dashboard/customers?column='+this.state.column+'&searchIn='+this.state.searchIn+'&value='+this.state.value);
    }
  }

  checkEnter(event) {
    let code = event.keyCode || event.which;
    if(code === 13) {
      this.search(event);
    }
  }

  render() {
    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href="#">
            {store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('site_search') > -1 && <table>
              <tbody>
                <tr>
                  <td align="right" className="top">
                    <span className="topLabel">Search in:</span> <select name="searchIn" size="1" value={this.state.searchIn} onChange={this.handleChange.bind(this)}>
                    <option value="reservations">Reservations</option>
                    <option value="accounts">Accounts</option>
                    </select> <span className="topLabel">for a:</span> &nbsp;<select size="1" name="column" value={this.state.column} onChange={this.handleChange.bind(this)}>
                        <option value="">Select....</option>
                        {
                          this.state.options.map((option, index) => {
                            return (
                              <option key={index} value={option.value}>{option.label}</option>
                            )
                          })
                        }                                                        
                      </select>&nbsp;<span className="topLabel">that contains:</span>&nbsp;
                      <input type="text" name="value" value={this.state.value} onKeyPress={this.checkEnter.bind(this)} onChange={this.handleChange.bind(this)} size="20" maxLength="40" />
                      <button type="submit" onClick={this.search.bind(this)}>Find</button>
                  </td>
                </tr>
              </tbody>
            </table>}
          </NavItem>
        </Nav>
        <Nav pullRight>
          <NavItem eventKey={3} onClick={this.handleLogout.bind(this)}>
            Log out
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default connect()(HeaderLinks);
