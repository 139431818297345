import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import scheduleActions from 'actions/schedule.actions';
import config from 'config';
import Popup from "reactjs-popup";
import { history } from '../../helpers/history';
import Button from "components/CustomButton/CustomButton.jsx";
import BigCalendar from 'components/BigCalendar/BigCalendar.jsx';
import _ from 'lodash';
import elite from "assets/img/elite svg.svg";
import moment from 'moment-timezone';
//moment.tz.setDefault('America/New_York');
//BigCalendar.momentLocalizer(moment);

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: new Date().getMonth()+1,
      schedule: {},
      open: false,
      loaded: false,
      cities: [{'label': 'New York / Brooklyn', value: 'new york'}, {'label': 'Arlington / Washington / Bethesda', value: 'arlington'}],
      activeDate: moment(),
      activeCity: '',
      activeCityCode: '',
      name: '',
      summary: null
    };
    this.state.cities.map(stop => {
      this.props.dispatch(scheduleActions.getSchedules({month: this.state.month, stop: stop.value, year: new Date().getFullYear()}));
      return stop;
    });
  }

  componentWillUnmount(){
    // document.body.style.overflow = 'auto'
  }

  componentDidUpdate(props) {
    if(this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(this.props.summary && (!props.summary || (JSON.stringify(this.props.summary) !== JSON.stringify(props.summary)))) {
      if(this.props.summary.summary) {
        let summ = JSON.parse(JSON.stringify(this.props.summary));
        summ.summary = _.sortBy(summ.summary, item => {
          let picks = _.filter(item.stops, {pickOrDrop: 'PU'});
          let data = _.orderBy(picks, (item) => {
            return moment(item.time, 'HH:mm:ss')
          });
          return data[0].time;
        });
        this.setState({summary: summ});
      }
      else {
        this.setState({summary: this.props.summary});
      }
    }

    if(this.props.schedule && Object.keys(this.props.schedule).length > 0 && (!props.schedule || JSON.stringify(props.schedule) !== JSON.stringify(this.props.schedule))) {
      let schedule = this.state.schedule;
      let change = false;
      Object.keys(this.props.schedule).map(key => {
        if(!schedule[key] || JSON.stringify(schedule[key]) !== JSON.stringify(this.props.schedule[key])) {
          schedule[key] = this.props.schedule[key];
          for(let i = 0; i < schedule[key].length; i++) {
            schedule[key][i].start = moment.utc(schedule[key][i].start, 'YYYY-MM-DD');
            schedule[key][i].end = moment.utc(schedule[key][i].end, 'YYYY-MM-DD').add(1, 'hour');
            schedule[key][i].startString = moment(schedule[key][i].startString, 'YYYY-MM-DD').format('MM/DD/YY');
            // if(schedule[key][i].isSpecial){
            //   this.setSpecialWeek(key, i, schedule)
            // }
          }
          change = true;
        }
        return key;
      });
      if(change) {
        this.setState({schedule: schedule});
      }
    }  
  }

   isInSpecialWeek(schedule, dayOfWeek) {
    for(let s=0; s< schedule.length; s++){
      try{

      if(schedule && schedule[s] && schedule[s].start && schedule[s].start && (dayOfWeek === moment(schedule[s].start).format('dddd'))){
        schedule[s].isInSpecialWeek = true
      }}catch(err){
        continue;
      }
    }

  }

  setSpecialWeek(key, i, schedule){
    if(schedule[key][i].isSpecial){
    let dayOfWeek = schedule[key][i].start.format('dddd')
    console.log(dayOfWeek)
    if(!(schedule[key][i].isInSpecialWeek)){
     this.isInSpecialWeek(schedule[key], dayOfWeek);
    }
  }
    console.log("Check", schedule)
  }

  sortStops(stops) {
    let picks = _.filter(stops, {pickOrDrop: 'PU'});
    let drops = _.filter(stops, {pickOrDrop: 'DO'});
    let data = [], data2 = [];
    data = _.orderBy(picks, (item) => {
      return moment(item.time, 'HH:mm:ss')
    });
    data2 = _.orderBy(drops, (item) => {
      return moment(item.time, 'HH:mm:ss')
    });
    return data.concat(data2);
  }

  closeModal() {
    this.setState({ open: false, summary: null });
    // document.body.style.overflow = 'auto'
  }


  deleteItem = item => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(scheduleActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  navigate(stop, date) {
    let month = new Date(date).getMonth() + 1;
    let year = new Date(date).getFullYear();
    this.setState({month: month});
    let criteria = {
      month: month,
      stop: stop,
      year: year
    }
    this.props.dispatch(scheduleActions.getSchedules(criteria));
  }

  eventClicked(data, city, cityCode) {
    if(!data.scheduleId) {
      history.push("/dashboard/schedule?day="+this.getWeekDay(moment(data.start))+"&departCity="+city+"&date="+moment(moment(data.start)).format('MM/DD/YY'))
    }
    else {
      this.setState({activeDate: moment(data.start), activeCity: city, activeCityCode: cityCode,  name: data.name, summary: null, open: true, isSpecial: data.isSpecial});
      // document.body.style.overflow='hidden';

      if(this.props.summary && this.props.summary.scheduleId === data.scheduleId) {
        if(this.props.summary.summary) {
          let summ = JSON.parse(JSON.stringify(this.props.summary));
          summ.summary = _.sortBy(summ.summary, item => {
            let picks = _.filter(item.stops, {pickOrDrop: 'PU'});
            let data = _.orderBy(picks, (item) => {
              return moment(item.time, 'HH:mm:ss')
            });
            return data[0].time;
          });
          this.setState({summary: summ});
        }
        else {
          this.setState({summary: this.props.summary});
        }
      }
      else {
        this.props.dispatch(scheduleActions.getScheduleSummary(data.scheduleId, data.startString, data.start, moment(data.start).format('YYYY-MM-DD')));
      }
    }
  }

  eventTemplate({ event }) {
    const specialClass = event.isSpecial ? 'special-schedule' : event.isInSpecialWeek ?  'special-week' : '';
    return (
      <div className={specialClass}>
        {event.name && <div className="special-name">{event.name}</div>}
        <strong>{event.title}</strong>
      </div>
    )
  }

  monthTemplate({event}){
    console.log(event)
    return <div></div>
  }

  getWeekDay(date) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[moment(this.state.activeDate).day()];
  }

  checkFuture(date) {
    if(moment().diff(date, 'ms') < 0) {
      return true;
    }
    return false;
  }

  render() {
    const schedule = this.state.schedule;
    console.log(schedule);
    const stops = this.state.cities;
    console.log(stops);

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Schedule"
                content={
                  stops.map((stop, index) => {
                    let eventValue = schedule[stop.value] ? schedule[stop.value] : []

                    return(
                      <Row key={index} style={{marginBottom: '40px'}}>
                        <Col md={12}>
                          <h3>{stop.label}</h3>
                          <BigCalendar
                            popup
                            components = {{
                              event: this.eventTemplate,
                            }}
                            views={['month']}
                            events={schedule[stop.value] ? schedule[stop.value] : []}
                            onNavigate={(date) => this.navigate(stop.value, date)}
                            onSelectEvent={(data) => this.eventClicked(data, stop.value, stop.code)}
                          />
                        </Col>
                      </Row>
                    )
                  })
                }
              />
            </Col>
          </Row>
          {/* <div className="divSelector"></div> */}
          <Popup
            open={this.state.open}
            closeOnDocumentClick
            onClose = {this.closeModal.bind(this)}
          >
            <div className="summary-container">
              <a className="close" onClick={this.closeModal.bind(this)}>
                &times;
              </a>
              {!this.state.summary && <div>Loading...</div>} 
              {this.state.summary && <div className="schedule-summary">
                {this.state.isSpecial ?
                  <div className="special-heading">Special Schedule for {this.getWeekDay(this.state.activeDate)} {moment(this.state.activeDate).format('MM/DD/YY')}</div>
                  :
                  <div className="non-special-heading">Schedule for {this.getWeekDay(this.state.activeDate)} {moment(this.state.activeDate).format('MM/DD/YY')}</div>
                }
                <div className="existing-schedule-start">Has Existing Schedule as of {this.state.summary.start}</div>
                <div>
                  <NavLink className="summary-btns" to={`/dashboard/schedules/${this.state.summary.scheduleId}?departCity=${this.state.activeCity}&activeDate=${moment(this.state.activeDate).format('YYYY/MM/DD')}`}><Button bsStyle="warning" className="btn-fill btn-sm" type="button">Edit Existing</Button></NavLink>
                  <NavLink style={{'margin':'0 10px 0 0'}} to={"/dashboard/schedule?departCity="+this.state.activeCity+"&date="+moment(this.state.activeDate).format('MM/DD/YY')+"&id="+this.state.summary.scheduleId}><Button bsStyle="success" disabled={this.state.isSpecial} className="btn-fill btn-sm" type="button">New Schedule</Button></NavLink>
                  {this.checkFuture(this.state.activeDate) && <NavLink to={"/dashboard/schedule?isSpecial=true&date="+moment(this.state.activeDate).format('MM/DD/YY')+"&departCity="+this.state.activeCity+"&id="+this.state.summary.scheduleId}><Button disabled={this.state.isSpecial} bsStyle="info" className="btn-fill btn-sm" type="button">New Special Schedule</Button></NavLink>}
                </div>
                {this.state.summary.summary.map((bus, index) => {
                  return (<div key={index}>
                    <strong className="bus-number">Bus: #{bus.number}</strong>
                    <table className="table table-condensed table-striped">
                      <tbody>
                        {this.sortStops(bus.stops).map((stop, count) => {
                          return (
                            <tr key={count}>
                              <td>{stop.pickOrDrop === 'PU' && <i className="fa fa-arrow-up"></i>}</td>
                              <td>{moment(stop.time, 'HH:mm:ss').format('hh:mm A')}</td>
                              <td>{stop.stop.address}</td>
                              <td>{stop.pickOrDrop === 'DO' && <i className="fa fa-arrow-down"></i>}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {/* <div><strong className="bus-capacity">Max Capacity:</strong> {bus.capacity}<strong className='bus-price'>Price:</strong> <span className={'' + (bus.priceOverride ? ' highlight-price' : '')}>${bus.price}</span>{bus.elite && <span className="deptarri-elite"><img alt="elite" height="20px" src={elite} /></span>}</div> */}
                    <div><strong className="bus-capacity">Max Capacity:</strong> {bus.capacity}<strong className='bus-price'>Price:</strong> <span className={'' + (bus.priceChanged || bus.priceOverride  ? ' highlight-price' : '')}>{bus.priceChanged ? 'Price Changed' : '$' + bus.price}</span>{bus.elite && <span className="deptarri-elite"><img alt="elite" height="20px" src={elite} /></span>}</div>
                    <div className="bus-margin" />
                  </div>)
                })}
                {false && this.checkFuture(this.state.activeDate) && <div><NavLink to={"/dashboard/schedule?isSpecial=true&date="+moment(this.state.activeDate).format('MM/DD/YY')+"&departCity="+this.state.activeCity+"&id="+this.state.summary.scheduleId}>Add New Special Schedule for {moment(this.state.activeDate).format('MM/DD/YY')}</NavLink></div>}
              </div>}
            </div>
          </Popup>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { items, loading, error, summary } = state.schedule;
  return { schedule: items, loading, error, summary };
}

export default connect(mapStateToProps)(Schedule);
