import config from 'config';
import authHeader from '../helpers/authHeaders';
 
export const topbarService = {
  add,
  update,
  getTopbar
};
 
function getTopbar() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/topbar`, requestOptions).then(handleResponse);
}
  
function update(id, topbar) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(topbar)
  };
 
  return fetch(`${config.apiUrl}/topbar/${id}`, requestOptions).then(handleResponse);
}

function add(topbar) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(topbar)
  };
 
  return fetch(`${config.apiUrl}/topbar`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
 
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}