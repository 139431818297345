import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import mainActions from 'actions/main.actions';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { Loader } from "components/Loader/Loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import config from 'config';
import _ from 'lodash';
import { history } from '../../helpers/history';

import moment from 'moment-timezone';
import CustomPrice from "../../components/Popup/CustomPrice";
import stopActions  from 'actions/stop.actions';
import { constructPricesMap } from "../../helpers/utility";
//moment.tz.setDefault('America/New_York');

class MainResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departDate: moment(),
      departCity: '',
      arriveCity: '',
      returnDepartCity: '',
      returnArriveCity: '',
      returnDate: null,
      departingSchedules: [],
      arrivingSchedules: [],
      departStop: null,
      returnStop: null,
      arriveStop: null,
      returnArriveStop: null,
      stopNotSelected: '',
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      showModal: false,
    };
    this.props.dispatch(stopActions.getStops({}));
  }

  componentDidMount() {
    if(this.props.departCity) {
      this.setState({
        departCity: this.props.departCity,
        arriveCity: this.props.arriveCity,
        departDate: this.props.departDate,
        mode: this.props.mode,
      });
      if(this.props.returnDate) {
        this.setState({
          returnDate: this.props.returnDate,
          returnArriveCity: this.props.returnArriveCity,
          returnDepartCity: this.props.returnDepartCity        
        });
      }
      let data = {
        departCity: this.props.departCity,
        departDate: this.props.departDate,
        arriveCity: this.props.arriveCity
      }
      this.props.dispatch(mainActions.getMain(data));
      if(this.props.returnDate && this.props.returnDepartCity) {
        debugger;
        this.props.dispatch(mainActions.getMainReturn({
          departCity: this.props.returnDepartCity,
          arriveCity: this.props.returnArriveCity,
          departDate: this.props.returnDate
        }));
      }
    }
  }

  sortStops(data) {
    for(let i = 0; i < data.length; i++) {
      if(data[i].stops.length === 3) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
      }
      else if(data[i].stops.length === 2) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[0]
          ];
        }
      }
    }
    return data;
  }
  
  componentWillUpdate(props) {
    if(props.departingSchedules && props.departingSchedules.length > 0 && this.state.departingSchedules.length === 0) {
      let data = this.sortStops(props.departingSchedules);
      data = _.orderBy(data, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
      data = _.orderBy(data, (item) => {
        return item.deleted;
      });
      this.setState({departingSchedules: data});
    }
    if(props.arrivingSchedules && props.arrivingSchedules.length > 0 && this.state.arrivingSchedules.length === 0) {
      let data = this.sortStops(props.arrivingSchedules);
      data = _.orderBy(data, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
      data = _.orderBy(data, (item) => {
        return item.deleted;
      });
      this.setState({arrivingSchedules: data});
    }
    if(!props.arrivingSchedules || props.arrivingSchedules.length === 0) {
      if(this.state.arrivingSchedules.length > 0) {
        this.setState({arrivingSchedules: []});
      }
    }
    if(!props.departingSchedules || props.departingSchedules.length === 0) {
      if(this.state.departingSchedules.length > 0) {
        this.setState({departingSchedules: []});
      }
    }
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  setTab(mode) {
    this.setState({mode: mode});
  }

  selectDepartStop(bus, stop) {
    stop.bus = bus;
    let arriveStop = null;
    for(let i = 0; i < bus.stops.length; i++) {
      if(this.state.arriveCity === bus.stops[i].stop.city) {
        arriveStop = bus.stops[i];
        arriveStop.bus = bus;
      }
    } 
        this.setState({departStop: stop, arriveStop: arriveStop});
  }

  selectArriveStop(bus, stop) {
    stop.bus = bus;
    this.setState({arriveStop: stop, stopNotSelected: ''});
  }

  selectReturnStop(bus, stop) {
    stop.bus = bus;
    let arriveStop = null;
    for(let i = 0; i < bus.stops.length; i++) {
      if(this.state.returnArriveCity === bus.stops[i].stop.city) {
        arriveStop = bus.stops[i];
        arriveStop.bus = bus;
      }
    }
    this.setState({returnStop: stop, returnArriveStop: arriveStop});
  }

  selectReturnArriveStop(bus, stop) {
    stop.bus = bus;
    this.setState({returnArriveStop: stop, stopNotSelected: ''});
  }

  findCustomPrice(departStop, arriveStop){
    let departStopId = departStop && departStop.stop && departStop.stop.id;
    let arriveStopId =  arriveStop && arriveStop.stop && arriveStop.stop.id;
    let prices = departStop && departStop.bus && departStop.bus.prices;
    let customPrice = null

    if(prices){
      customPrice = _.find(prices, {from_stop_id: departStopId, to_stop_id: arriveStopId});
    }
    return customPrice  ? customPrice.price : departStop.bus.price;
  }


  addReservation() {
    this.setState({stopNotSelected: ''});
    if(!this.state.departStop || !this.state.arriveStop) {
      this.setState({stopNotSelected: 'Please select departure and arrival stops'});
      return;
    }
    if(!this.state.departStop.bus.isOpen) {
      this.setState({stopNotSelected: 'This bus is currently closed'});
      return;
    }

    let finalPrice = this.findCustomPrice(this.state.departStop, this.state.arriveStop); 

    let link = '/dashboard/main-reservation?slot='+this.state.departStop.time+'&schedule_bus='+this.state.departStop.bus.schedule_bus+'&departCity='+this.state.departStop.stop.city+'&arriveCity='+this.state.arriveStop.stop.city+'&date='+moment(this.state.departDate).format('YYYY-MM-DD')+'&depart_stop='+this.state.departStop.id+'&depart_address='+this.state.departStop.stop.address+'&arrive_stop='+this.state.arriveStop.id+'&price='+finalPrice+'&depart_stop_total='+this.state.departStop.reservations+'&depart_bus_total='+this.state.departStop.bus.reservations+'&letter='+this.state.departStop.bus.letter+'&bus_type='+this.state.departStop.bus.bus_type.type+'&remainingSeats='+this.state.departStop.bus.remainingSeats;
    if(this.state.returnStop) {
    
      let finalReturnPrice = this.findCustomPrice(this.state.returnStop, this.state.returnArriveStop); 

      if(!this.state.returnArriveStop) {
        this.setState({stopNotSelected: 'Please select departure and arrival stops for return trip'});
        return;
      }
      if(!this.state.returnStop.bus.isOpen) {
        this.setState({stopNotSelected: 'This bus is currently closed'});
        return;
      }
      if(moment(this.state.departDate).format('YYYY-MM-DD') === moment(this.state.returnDate).format('YYYY-MM-DD')) {
        if(moment(this.state.returnStop.time, 'HH:mm:ss').diff(moment(this.state.arriveStop.time, 'HH:mm:ss'), 'seconds') < 0) {
          this.setState({stopNotSelected: 'Arrival time of depart trip must be less than depart time of return trip'});
          return;
        }
      }
      link += '&returnDepartCity='+ this.state.returnStop.stop.city + '&returnArriveCity='+ this.state.returnArriveCity +'&return_slot='+this.state.returnStop.time+'&return_schedule_bus='+this.state.returnStop.bus.schedule_bus+'&return_depart_stop='+this.state.returnStop.id+'&return_arrive_stop='+this.state.returnArriveStop.id+'&return_address='+this.state.returnStop.stop.address+'&return_date='+moment(this.state.returnDate).format('YYYY-MM-DD')+'&return_price='+finalReturnPrice+'&return_stop_total='+this.state.returnStop.reservations+'&return_bus_total='+this.state.returnStop.bus.reservations+'&letter1='+this.state.returnStop.bus.letter+'&return_bus_type='+this.state.returnStop.bus.bus_type.type+'&remainingSeatsReturn='+this.state.returnStop.bus.remainingSeats;
    }
    history.push(link);
  }

  listReservations() {
    this.setState({stopNotSelected: ''});
    if(!this.state.departStop || !this.state.arriveStop) {
      this.setState({stopNotSelected: 'Please select departure and arrival stops'});
      return;
    }
    let finalPrice = this.findCustomPrice(this.state.departStop, this.state.arriveStop); 
    let link = '/dashboard/main-reservations?slot='+this.state.departStop.time+'&schedule_bus='+this.state.departStop.bus.schedule_bus+'&departCity='+this.state.departCity+'&arriveCity='+this.state.arriveCity+'&date='+moment(this.state.departDate).format('YYYY-MM-DD')+'&depart_stop='+this.state.departStop.id+'&arrive_stop='+this.state.arriveStop.id+'&price='+finalPrice+'&depart_stop_total='+this.state.departStop.reservations+'&depart_bus_total='+this.state.departStop.bus.reservations+'&is_open='+(this.state.departStop.bus.isOpen ? 'yes' : 'no')+'&letter='+this.state.departStop.bus.letter+'&bus_type='+this.state.departStop.bus.bus_type.type+'&capacity='+this.state.departStop.bus.capacity;
    history.push(link);
  }
  addParams() {
    return '?slot='+this.state.departStop.time+'&schedule_bus='+this.state.departStop.bus.schedule_bus+'&departCity='+this.state.departCity+'&arriveCity='+this.state.arriveCity+'&date='+moment(this.state.departDate).format('YYYY-MM-DD')+'&depart_stop='+this.state.departStop.id+'&arrive_stop='+this.state.arriveStop.id+'&price='+this.state.departStop.bus.price+'&is_open='+(this.state.departStop.bus.isOpen ? 'yes' : 'no')+'&letter='+this.state.departStop.bus.letter+'&bus_type='+this.state.departStop.bus.bus_type.type;
  }



  showEditPrice(schedule_bus, index){
    let departStops = schedule_bus.stops.filter((stop)=> stop.type === "PU" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let destinationStops = schedule_bus.stops.filter((stop)=> stop.type === "DO" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let prices = schedule_bus.prices;
    let priceOverride = schedule_bus.priceOverride ? schedule_bus.priceOverride : schedule_bus.specialPrice;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.props.stops, priceOverride);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index});
  }
  


  render() {
    const { departDate, returnDate, departCity, arriveCity, departingSchedules, arrivingSchedules, returnArriveCity, returnDepartCity } = this.state;

    return (
      <div className="content main-results">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  !this.props.loading ? <div>
                    <div className="align-center cut-space">{departingSchedules.length > 0 && this.props.me.permissions.indexOf('schedules') > -1 && <div className="move-btn">
                      <NavLink to={"/dashboard/schedules/"+departingSchedules[0].scheduleId}><Button bsStyle="info" fill >Edit Depart Schedule</Button></NavLink>
                    </div>}
                    {this.props.me && this.props.me.permissions.indexOf('move_people') > -1 && <div className="move-btn"><NavLink to="/dashboard/move-people"><Button bsStyle="info" fill >Move People</Button></NavLink></div>}
                    {this.props.me && this.props.me.permissions.indexOf('bus_stops') > -1 &&  <div className="move-btn"><NavLink to="/dashboard/edit-schedule-bus"><Button bsStyle="info" fill >Edit Bus</Button></NavLink></div>}
                    {arrivingSchedules.length > 0 && this.props.me.permissions.indexOf('schedules') > -1 && <div className="move-btn">
                      <NavLink to={"/dashboard/schedules/"+arrivingSchedules[0].scheduleId}><Button bsStyle="info" fill >Edit Return Schedule</Button></NavLink>
                    </div>}</div>
                    <Row className="main-box-container">
                      <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="head">
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && !departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{departingSchedules[0].name}</div>
                            }
                            From {this.getCityLabel(departCity)} - {moment(departDate).format('dddd, MMM D YYYY')} </div>
                          {departingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' &&
                                      <div key={i} className={'stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        Time ID: {stop.timeId}
                                        <input className="stop-radio" value={''} type="radio" name="departStop" checked={this.state.departStop && this.state.departStop.id === stop.id} onChange={this.selectDepartStop.bind(this, bus, stop)} />
                                        {moment(stop.time, 'HH:mm:ss').format('hh:mm A')} from {stop.stop.address}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  {!bus.deleted && <div className="row bus-info-grid">
                                <div className="col-md-12">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {bus.capacity} {bus.bus_type.type === 'Elite' ? ' | ' : ''} {bus.bus_type.type === 'Elite' ? (<span className="elite-res">Elite</span>) : ''}</div>
                                  <div className="col-md-6">
                                    {bus.prices ?<div>Price:  <button onClick={()=>{this.showEditPrice(bus, index)}}>View Price</button> </div> :<span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp;
                                      {bus.bus_type.defaultPrice === bus.price && <Fragment>${bus.price} (unchanged)</Fragment>}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {bus.prices && bus.bus_type.defaultPrice !== bus.price ? <Fragment>${bus.price} (custom price for this route)</Fragment> : bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''}
                                    </span>}
                                  </div>
                                  <div className={'col-md-6 ' + (!bus.isOpen ? 'red-text-price' : '')}>Bus is <b>{bus.isOpen ? 'Open' : 'Closed'}</b></div>
                                  <div className="col-md-6"><b>Driver:</b> {bus.driver ? bus.driver.firstName + ' ' + bus.driver.lastName : 'Not Specified'}</div>
                                  <div className="col-md-6"><b>Company:</b> {bus.company}</div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {bus.couponLimit ? bus.couponLimit : 'Not Specified'}</div>
                                  {bus.notes && <div className="col-md-6"><b>Notes:</b> {bus.notes}</div>}
                                  {/* <div className="total-row">Total: {bus.reservations} On Bus: {bus.checked} Max Capacity: {bus.capacity}</div> */}
                                  {/* <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div> */}
                                  {/* <div className="main-driver">Company: {bus.bus_type.company}</div> */}
                                  {/* <div className="main-price-label">Coupons Allowed:</div>
                                  <div className="main-price-value">{bus.couponLimit}</div> */}
                                  {/* <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                                  {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                                  <div className="main-price-label">Bus is:</div>
                                  <div className={'main-price-value' + (!bus.isOpen ? ' red' : '')}>{bus.isOpen ? 'Open' : 'Closed'}</div>
                                  {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                                  {bus.notes && <div className="main-price-value">{bus.notes}</div>} */}
                                  </div>}
                                  {!bus.deleted && <div>Destinations:
                                    <div className="stops-group">
                                      {bus.stops.map((stop, i) => {
                                        return (
                                          stop.type === 'DO' &&
                                          <div key={i} className={'stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                            <b>{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</b> at {stop.stop.address} {(this.state.arriveStop && this.state.arriveStop.id === stop.id) && <i className='fa fa-check'></i>}
                                            <div className="view-res-link">{stop.reservations}</div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                      {returnDate && <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="border-black head">
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && !arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{arrivingSchedules[0].name}</div>
                            }
                            From {this.getCityLabel(returnDepartCity)} - {moment(returnDate).format('dddd, MMM D YYYY')}
                          </div>
                          {arrivingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="border-black bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' && <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        Time ID: {stop.timeId}
                                        <input className="stop-radio" value={''} type="radio" name="returnStop" checked={this.state.returnStop && this.state.returnStop.id === stop.id} onChange={this.selectReturnStop.bind(this, bus, stop)} />
                                        {moment(stop.time, 'HH:mm:ss').format('hh:mm A')} from {stop.stop.address}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  {!bus.deleted && <div className="row bus-info-grid">
                                  <div className="col-md-12">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {bus.capacity} {bus.bus_type.type === 'Elite' ? ' | ' : ''} {bus.bus_type.type === 'Elite' ? (<span className="elite-res">Elite</span>) : ''}</div>
                                  <div className="col-md-6">
                                  {bus.prices ?<div>Price:  <button onClick={()=>{this.showEditPrice(bus, index)}}>View Price</button> </div> :<span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp;
                                      {bus.bus_type.defaultPrice === bus.price && <Fragment>${bus.price} (unchanged)</Fragment>}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {bus.prices && bus.bus_type.defaultPrice !== bus.price ? <Fragment>${bus.price} (custom price for this route)</Fragment> : bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''}
                                    </span>}
                                  </div>
                                  <div className={'col-md-6 ' + (!bus.isOpen ? 'red-text-price' : '')}>Bus is <b>{bus.isOpen ? 'Open' : 'Closed'}</b></div>
                                  <div className="col-md-6"><b>Driver:</b> {bus.driver ? bus.driver.firstName + ' ' + bus.driver.lastName : 'Not Specified'}</div>
                                  <div className="col-md-6"><b>Company:</b> {bus.company}</div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {bus.couponLimit ? bus.couponLimit : 'Not Specified'}</div>
                                  {bus.notes && <div className="col-md-6"><b>Notes:</b> {bus.notes}</div>}
                                  {/* <div className="total-row">Total: {bus.reservations} On Bus: {bus.checked} Max Capacity: {bus.capacity}</div> */}
                                  {/* <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div> */}
                                  {/* <div className="main-driver">Company: {bus.bus_type.company}</div> */}
                                  {/* <div className="main-price-label">Coupons Allowed:</div>
                                  <div className="main-price-value">{bus.couponLimit}</div> */}
                                  {/* <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                                  {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                                  <div className="main-price-label">Bus is:</div>
                                  <div className={'main-price-value' + (!bus.isOpen ? ' red' : '')}>{bus.isOpen ? 'Open' : 'Closed'}</div>
                                  {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                                  {bus.notes && <div className="main-price-value">{bus.notes}</div>} */}
                                  </div>}
                                  {!bus.deleted && <div>Destinations:
                                    <div className="stops-group">
                                      {bus.stops.map((stop, i) => {
                                        return (
                                          stop.type === 'DO' &&
                                          <div key={i} className={'stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                            <b>{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</b> at {stop.stop.address} {(this.state.returnArriveStop && this.state.returnArriveStop.id === stop.id) && <i className='fa fa-check'></i>}
                                            <div className="view-res-link">{stop.reservations}</div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>}
                    </Row>
                  </div> :
                  <Loader />
                }
              />
            </Col>
          </Row>
          {!this.props.loading && <Row>
            <Col md={12} className="text-center">
              {this.props.me && this.props.me.permissions.indexOf('driver_sheet') > -1 && this.props.me.permissions.indexOf('reservations') === -1 && this.state.departStop && this.state.arriveStop && <NavLink to={'/driver-sheet'+this.addParams()} target="_blank"><Button type="button" fill bsStyle="info">Click For Driver Sheet</Button></NavLink>}
              {this.props.me && this.props.me.permissions.indexOf('reservations') > -1 && <Button className="next-step-btn" bsStyle="info" fill onClick={this.listReservations.bind(this)}>View Reservations</Button>}
              {this.props.me && this.props.me.permissions.indexOf('reservations') > -1 && <Button bsStyle="info" fill onClick={this.addReservation.bind(this)}>Create New Reservation</Button>}
            </Col>
          </Row>}
          {this.state.stopNotSelected && <Row>
            <Col md={12} className="no-stop-selected">
              {this.state.stopNotSelected}
            </Col>
          </Row>}
          {this.props.error && <div className="error">{this.props.error}</div>}
        </Grid>

        {<CustomPrice updatePrice={()=>{}} open={this.state.showModal} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={()=>{}} update={false}/>}

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, departingSchedules, arrivingSchedules, departCity, arriveCity, returnDepartCity, returnArriveCity,departDate, returnDate, mode } = state.main;
  return {
    loading,
    error,
    departingSchedules,
    arrivingSchedules,
    departCity,
    arriveCity,
    returnDepartCity, 
    returnArriveCity,
    departDate,
    returnDate,
    mode,
    stops: state.stops.items, 
    me: state.authentication.me
  };
}
 
export default connect(mapStateToProps)(MainResults);
