import React from "react";
import Popup from "reactjs-popup";
import { Col, ControlLabel, FormControl, FormGroup } from "react-bootstrap";

export default class CustomPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  closeModal() {
    
    if(this.props.update === false){
      this.props.close();
      document.body.style.overflow = "auto";
      return;
    }
    
    this.props.updatePrice(this.props.combinationOfStops);
    this.props.close();
    document.body.style.overflow = "auto";
  }

  render() {

    return (
      <Popup
        open={this.props.open}
        onClose={this.closeModal.bind(this)}
      >
        <div className="custom-price-container">
          {/* <div className="row headers">
            <div className="col-sm-4">
              <p className="text-center bold">Departure</p>
            </div>
            <div className="col-sm-4">
              <p  className="text-center bold">Dropoff</p>
            </div>
            <div className="col-sm-4">
              <p  className="text-center">Price</p>
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-12">
              <p className="text-center bold">Edit Prices</p>
            </div>
          </div>

          {this.props.combinationOfStops &&
            this.props.combinationOfStops.length &&
            this.props.combinationOfStops.map((combination, index) => {
              return (
                <React.Fragment>
                <div className="row" key={index}>
                  <div className="col-sm-12 col-md-4">
                    <Col md={12} className="stop-block">
                      <FormGroup>
                      <ControlLabel className="custom-label">From</ControlLabel>
                        <FormControl
                          componentClass="input"
                          disabled
                          bsClass="form-control"
                          name="departstop"
                          value={combination.departStop.address}
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <Col md={12} className="stop-block">
                      <FormGroup>
                      <ControlLabel className="custom-label">To</ControlLabel>
                        <FormControl
                          componentClass="input"
                          disabled
                          bsClass="form-control"
                          name="dropoffstop"
                          value={combination.destinationStop.address}
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <Col md={12} className="stop-block">
                      <FormGroup>
                      <ControlLabel className="custom-label">Price</ControlLabel>
                        <FormControl
                          type='number'
                          componentClass="input"
                          disabled={this.props.update === false}
                          onChange={(e) => this.props.updateStopPrice(e, index)}
                          bsClass="form-control"
                          name="price"
                          value={combination.price || ""}
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    

                  </div>             
                  
                  <div className="col-sm-12">

                  <Col md={12} className="stop-block">
                  {!combination.price && <p>{combination.priceOverride ? `Note: Override of $${combination.priceOverride} applied as custom price has not been created for this route.` : 'Default Price Applied'}</p>}

                  </Col>
                  </div>
                  </div>
                  <hr/>
                  </React.Fragment>
              );
            })}
          {this.props.update === false ? null : <div className="row">
          <div className="col-sm-4">
            <Col md={12} className="stop-block">
              <button className="btn btn-primary"
                onClick={()=>{this.props.updatePrice(this.props.combinationOfStops); this.closeModal()}}
              >
                Update Price
              </button>
            </Col>
          </div>
          </div>}
        </div>
      </Popup>
    );
  }
}
