import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import { Row, Col } from "react-bootstrap";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { connect } from 'react-redux';
import reservationRemarkActions from 'actions/reservation_remark.actions';
import moment from 'moment-timezone';
import config from 'config';
//moment.tz.setDefault('America/New_York');

class Notes extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.props.dispatch(reservationRemarkActions.getReservationRemarks({reservation: this.props.data.reservation.id}));
    this.state = {
      submitted: false,
      note: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentWillUpdate(props) {
    if(props.error) {
      //this.props.addNotification({type: 'error', message: props.error});
    }
  }

  addNote(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.props.data.reservation.user.id && this.state.note && this.props.data.reservation.id) {
      dispatch(
        reservationRemarkActions.add(
          {
            user: this.props.me.id,
            note: this.state.note,
            reservation_leg: this.props.data.reservation.id,
            createdBy: 'manual'
          }
        )
      );
      this.setState({note: '', submitted: false});
    }
  }

  render() {
    const remarks = this.props.remarks || [];
    return (
      <Modal open={this.props.open} onClose={this.props.onCloseModal} center>
        <div>
          <div className="bold">
            <div>Reservation #: {config.formatReservationNumber(this.props.data.reservation.reservationNumber)}</div>
            <div>Name: {this.props.data.reservation.user.firstName + ' ' +this.props.data.reservation.user.lastName}</div>
            <div>Made By: {this.props.data.reservation.made_by ? this.props.data.reservation.made_by.firstName + ' ' +this.props.data.reservation.user.lastName : ''}</div>
            <div>Date of Travel #: {moment(this.props.data.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')}</div>
            <div>From: {this.props.data.stop} at {moment(this.props.data.time, 'HH:mm:ss').format('hh:mm a')}</div>
            <div>Seats: {this.props.data.reservation.reservations.length}</div>
            <div>Notes:</div>
          </div>
          <ul>
            {
              remarks.map((item, index) => {
                return (
                  <li key={index}>{item.createdBy === 'manual' ? (moment(item.created_at).format('MM/DD/YY hh:mm A') + ' ' + item.user.firstName) + ': ' : (moment(item.created_at).format('MM/DD/YY hh:mm A') + ': ')}{item.note}</li>
                )
              })
            }
          </ul>
          <FormInputs
            ncols={["col-md-12"]}
            proprieties={[
              {
                label: "Remark",
                componentClass: "textarea",
                bsClass: "form-control",
                placeholder: "Note",
                value: this.state.note,
                name: 'note',
                onChange: this.handleChange
              }
            ]}
          />
          <Row className="error-row">
            <Col md={12}>{this.state.submitted && !this.state.note && <div className="error">Note is required</div>}</Col>
          </Row>
          <Button bsStyle="info" disabled={this.props.loading} fill type="button" onClick={this.addNote.bind(this)}>
            Add Note
          </Button>
          {this.props.loading && this.state.submitted && 
            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          }
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  let { items, loading, error } = state.reservationRemarks;
  return { remarks: items, loading, error, me: state.authentication.me };
}

export default connect(mapStateToProps)(Notes);
