import { GET_TOPBAR_REQUEST, GET_TOPBAR_SUCCESS, GET_TOPBAR_FAILURE, UPDATE_TOPBAR_REQUEST, UPDATE_TOPBAR_SUCCESS, UPDATE_TOPBAR_FAILURE } from '../constants';
import { topbarService } from '../services/topbar.services';
 
export default {
  update,
  getTopbar
};
 
function update(id, topbar) {
  return dispatch => {
    dispatch(request(topbar));
    if(id) {
      topbarService.update(id, topbar)
      .then(
        data => { 
          dispatch(success());
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
    }
    else {
      topbarService.add(topbar)
      .then(
        data => { 
          dispatch(success());
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
    }
  };
 
  function request(topbar) { return { type: UPDATE_TOPBAR_REQUEST, topbar } }
  function success() { return { type: UPDATE_TOPBAR_SUCCESS } }
  function failure(error) { return { type: UPDATE_TOPBAR_FAILURE, error } }
}

function getTopbar() {
  return dispatch => {
    dispatch(request());
 
    topbarService.getTopbar()
      .then(
        data => dispatch(success(data.topbar[0])),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_TOPBAR_REQUEST } }
  function success(topbar) { return { type: GET_TOPBAR_SUCCESS, topbar } }
  function failure(error) { return { type: GET_TOPBAR_FAILURE, error } }
}