import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import config from 'config';
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import userActions from 'actions/user.actions';

class Users extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, 1000);
    this.state = {
      criteria: {
        limit: config.pageSize,
        offset: 0,
        filtered: "",
        sorted: ""
      }
    };
  }

  fetchData = (state, instance) => {
    console.log(state)
    let criteria = this.state.criteria;
    let newFilter = state.filtered.length > 0 ? JSON.stringify(state.filtered) : "";
    let newOrderer = state.sorted.length > 0 ? JSON.stringify(state.sorted) : "";
    if(criteria.filtered !== newFilter) {
      criteria['offset'] = 0;
      criteria['filtered'] = newFilter;
    }
    if(criteria.sorted !== newOrderer) {
      criteria['offset'] = 0;
      criteria['sorted'] = newOrderer;
    }
    if(criteria.limit !== state.pageSize) {
      criteria.limit = state.pageSize;
      criteria['offset'] = 0;
    }
    else {
      criteria['offset'] = Math.ceil(state.page * state.pageSize);
    }
    this.setState({criteria: criteria}, () => {
      this.props.dispatch(userActions.getUsers(criteria));
    });
  };

  componentWillUpdate(props) {
    if(props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
  }

  deleteItem = item => {
    confirmAlert({
      title: 'Delete User',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  blockItem(user) {
    this.props.dispatch(userActions.block(user.id, !user.blocked));
  }

  render() {
    const users = this.props.users || [];
    const loading = this.props.loading || false;
    const total = this.props.total || 0;
    const pageCount = Math.ceil(total / this.state.criteria.limit);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Users"
                rightButton={
                  <NavLink to="/dashboard/user"><Button type="button" fill bsStyle="info"><i className="fa fa-plus"></i>Add User</Button></NavLink>
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  <ReactTable
                    columns={[
                      {
                        Header: "First Name",
                        accessor: "firstName"
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName"
                      },
                      {
                        Header: "Email",
                        accessor: "email"
                      },
                      {
                        Header: "Phone",
                        accessor: "phone"
                      },
                      {
                        Header: "Address",
                        accessor: "address"
                      },
                      {
                        Header: "City",
                        accessor: "city"
                      },
                      {
                        Header: "ZIP",
                        accessor: "zip"
                      },
                      {
                        Header: "State",
                        accessor: "state"
                      },
                      {
                        Header: "Actions",
                        width: 150,
                        Cell: row => (
                          <div className="table-actions">
                            <NavLink to={"/dashboard/users/"+row.original.id}><Button bsStyle="info" disabled={row.original.deleting} fill type="button"><i className="fa fa-edit"></i></Button></NavLink>
                            <Button bsStyle="danger" disabled={row.original.deleting} fill type="button" onClick={this.deleteItem.bind(this, row)}><i className="fa fa-trash"></i></Button>
                            <Button bsStyle={row.original.blocked ? 'info' : 'danger'} disabled={row.original.deleting || row.original.blocking} fill type="button" onClick={this.blockItem.bind(this, row.original)}>{row.original.blocked ? 'Unblock' : 'Block'}</Button>
                          </div>
                        )
                      },
                    ]}
                    manual
                    data={users}
                    pages={pageCount} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchDataWithDebounce}
                    filterable
                    defaultPageSize={this.state.criteria.limit}
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { items, loading, total, error } = state.users;
  return { users: items, loading, total, error };
}

export default connect(mapStateToProps)(Users);
