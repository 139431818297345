import { GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE, DELETE_PERMISSION_REQUEST, DELETE_PERMISSION_SUCCESS, DELETE_PERMISSION_FAILURE, ADD_PERMISSION_REQUEST, ADD_PERMISSION_SUCCESS, ADD_PERMISSION_FAILURE, UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS, UPDATE_PERMISSION_FAILURE } from '../constants';
import { permissionService } from '../services/permission.services';
 
export default {
  add,
  getPermissions,
  update,
  delete: _delete
};
 
function add(permission) {
  return dispatch => {
    dispatch(request(permission));
    permissionService.add(permission)
      .then(
        permission => { 
          dispatch(success(permission));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
 
  function request(permission) { return { type: ADD_PERMISSION_REQUEST, permission } }
  function success(permission) { return { type: ADD_PERMISSION_SUCCESS, permission } }
  function failure(error) { return { type: ADD_PERMISSION_FAILURE, error } }
}

function update(id, permission) {
  return dispatch => {
    dispatch(request(permission));
    permissionService.update(id, permission)
      .then(
        permission => { 
          dispatch(success(permission));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
 
  function request(permission) { return { type: UPDATE_PERMISSION_REQUEST, permission } }
  function success(permission) { return { type: UPDATE_PERMISSION_SUCCESS, permission } }
  function failure(error) { return { type: UPDATE_PERMISSION_FAILURE, error } }
}
 
function getPermissions(filters) {
  return dispatch => {
    dispatch(request());
 
    permissionService.getPermissions(filters)
      .then(
        permissions => dispatch(success(permissions)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_PERMISSIONS_REQUEST } }
  function success(permissions) { return { type: GET_PERMISSIONS_SUCCESS, permissions } }
  function failure(error) { return { type: GET_PERMISSIONS_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    permissionService.delete(id)
      .then(
        permission => dispatch(success(permission.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_PERMISSION_REQUEST, id } }
  function success(id) { return { type: DELETE_PERMISSION_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_PERMISSION_FAILURE, id, error } }
}