import { GET_ORDER_ITEMS_REQUEST, GET_ORDER_ITEMS_SUCCESS, GET_ORDER_ITEMS_FAILURE, GET_ORDER_ITEM_REQUEST, GET_ORDER_ITEM_SUCCESS, GET_ORDER_ITEM_FAILURE, DELETE_ORDER_ITEM_REQUEST, DELETE_ORDER_ITEM_SUCCESS, DELETE_ORDER_ITEM_FAILURE, ADD_ORDER_ITEM_REQUEST, ADD_ORDER_ITEM_SUCCESS, ADD_ORDER_ITEM_FAILURE, UPDATE_ORDER_ITEM_REQUEST, UPDATE_ORDER_ITEM_SUCCESS, UPDATE_ORDER_ITEM_FAILURE } from '../constants';
import { orderItemService } from '../services/order_item.services';
 
export default {
  add,
  update,
  getOrderItems,
  getOrderItem,
  delete: _delete
};
 
function add(orderItem) {
  return dispatch => {
    dispatch(request(orderItem));

    orderItemService.add(orderItem)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(orderItem) { return { type: ADD_ORDER_ITEM_REQUEST, orderItem } }
  function success(orderItem) { return { type: ADD_ORDER_ITEM_SUCCESS, orderItem } }
  function failure(error) { return { type: ADD_ORDER_ITEM_FAILURE, error } }
}

function update(id, orderItem) {
  return dispatch => {
    dispatch(request(orderItem));

    orderItemService.update(id, orderItem)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(orderItem) { return { type: UPDATE_ORDER_ITEM_REQUEST, orderItem } }
  function success() { return { type: UPDATE_ORDER_ITEM_SUCCESS } }
  function failure(error) { return { type: UPDATE_ORDER_ITEM_FAILURE, error } }
}
 
function getOrderItems(filters) {
  return dispatch => {
    dispatch(request());
 
    orderItemService.getOrderItems(filters)
      .then(
        orderItems => dispatch(success(orderItems)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_ORDER_ITEMS_REQUEST } }
  function success(orderItems) { return { type: GET_ORDER_ITEMS_SUCCESS, orderItems } }
  function failure(error) { return { type: GET_ORDER_ITEMS_FAILURE, error } }
}

function getOrderItem(id) {
  return dispatch => {
    dispatch(request());
 
    orderItemService.getOrderItem(id)
      .then(
        orderItem => dispatch(success(orderItem)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_ORDER_ITEM_REQUEST } }
  function success(orderItem) { return { type: GET_ORDER_ITEM_SUCCESS, orderItem } }
  function failure(error) { return { type: GET_ORDER_ITEM_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    orderItemService.delete(id)
      .then(
        orderItem => dispatch(success(orderItem.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_ORDER_ITEM_REQUEST, id } }
  function success(id) { return { type: DELETE_ORDER_ITEM_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_ORDER_ITEM_FAILURE, id, error } }
}