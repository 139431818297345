import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Autosuggest from 'react-autosuggest';
import userActions from 'actions/user.actions';
import mainActions from 'actions/main.actions';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import config from 'config';
import _ from 'lodash';
import { history } from '../../helpers/history';

import moment from 'moment-timezone';
import CustomPrice from "../../components/Popup/CustomPrice";
import stopActions from 'actions/stop.actions';
import { constructPricesMap } from "../../helpers/utility";
//moment.tz.setDefault('America/New_York');

class EditScheduleBus extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(stopActions.getStops({}));

    this.state = {
      departDate: moment(),
      departCity: '',
      arriveCity: '',
      returnDate: null,
      departingSchedules: [],
      arrivingSchedules: [],
      busChanges: {},
      oldData: {},
      suggestions: [],
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      isDepartingBus: true,
      showModal: false
    };

    this.driverSelected = this.driverSelected.bind(this);
    this.onDriversFetchRequested = this.onDriversFetchRequested.bind(this);
    this.onDriversClearRequested = this.onDriversClearRequested.bind(this);
    this.fetchDriversWithDebounce = _.debounce(this.onDriversFetchRequested, 1500);
  }

  sortStops(data) {
    for(let i = 0; i < data.length; i++) {
      if(data[i].stops.length === 3) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
      }
      else if(data[i].stops.length === 2) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[0]
          ];
        }
      }
    }
    return data;
  }

  driverSelected(id, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    let data = this.state.busChanges;
    data[id]["driver"] = suggestionValue;
    data[id]["driverName"] = suggestion.firstName + " " + suggestion.lastName;
    this.setState({busChanges: data});
  }

  onDriversFetchRequested = (id, { value }) => {
    if(this.state.busChanges[id].driver && value) {
      if(_.find(this.state.suggestions, {id: this.state.busChanges[id].driver, firstName: value.split(" ")[0], lastName: value.split(" ")[1]})) {
        this.setState({suggestions: []});
        return;
      }
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength === 0) {
      this.setState({suggestions: []});
      return;
    }
    if(inputLength >= 3) {
      if(inputValue.indexOf(" ") > -1) {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue.split(" ")[0]}, {id: 'lastName', value: inputValue.split(" ")[1]}])}));
      }
      else {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue}])})); 
      }
    }
  };

  onDriversClearRequested = (id) => {
    let data = this.state.busChanges;
    data[id]["driver"] = null;
    data[id]["driverName"] = '';
    this.setState({
      suggestions: [],
      busChanges: data
    });
  };

  componentWillUpdate(props) {
    if(props.drivers && JSON.stringify(props.drivers) !== JSON.stringify(this.state.suggestions)) {
      this.setState({suggestions: props.drivers});
    }
  }

  componentDidMount() {
    let busChanges = {};
    if(this.props.departingSchedules && this.props.departingSchedules.length) {
      let data = this.sortStops(this.props.departingSchedules);
      data = _.orderBy(data, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
            this.setState({departingSchedules: data});
            for(let i = 0; i < data.length; i++) {
        busChanges[data[i].schedule_bus] = {
          capacityOverride: data[i].capacity,
          isOpen: (data[i].isOpen ? 'open' : 'closed'),
          notes: data[i].notes || '',
          priceOverride: (data[i].specialPrice ? data[i].price : ''),
          firstSpecialSeats: data[i].firstSpecialSeats || '',
          lastSpecialSeats: data[i].lastSpecialSeats || '',
          firstSpecialSeatsPrice: data[i].firstSpecialSeatsPrice || '',
          lastSpecialSeatsPrice: data[i].lastSpecialSeatsPrice || '',
          couponLimit: data[i].couponLimit || '',
          isSpecial: data[i].isSpecial,
          driver: data[i].driver ? data[i].driver.id : '',
          company: data[i].company || '',
          date: this.props.departDate,
          driverName: data[i].driver ? `${data[i].driver.firstName} ${data[i].driver.lastName}` : '',
          prices: data[i].prices,
          stops: data[i].stops.map((stop)=>({deleted: stop.deleted, id: stop.id, reservations: stop.reservations, stop: stop.stop, time: stop.time, type: stop.type, timeId: stop.timeId}))
        }
      }
    }
    if(this.props.arrivingSchedules && this.props.arrivingSchedules.length) {
      let data = this.sortStops(this.props.arrivingSchedules);
      data = _.orderBy(data, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
      this.setState({arrivingSchedules: data});
      for(let i = 0; i < data.length; i++) {
        busChanges[data[i].schedule_bus] = {
          capacityOverride: data[i].capacity,
          isOpen: (data[i].isOpen ? 'open' : 'closed'),
          notes: data[i].notes || '',
          priceOverride: (data[i].specialPrice ? data[i].price : ''),
          firstSpecialSeats: data[i].firstSpecialSeats || '',
          lastSpecialSeats: data[i].lastSpecialSeats || '',
          firstSpecialSeatsPrice: data[i].firstSpecialSeatsPrice || '',
          lastSpecialSeatsPrice: data[i].lastSpecialSeatsPrice || '',
          couponLimit: data[i].couponLimit || '',
          isSpecial: data[i].isSpecial,
          date: this.props.returnDate,
          driver: data[i].driver ? data[i].driver.id : '',
          company: data[i].company || '',
          driverName: data[i].driver ? `${data[i].driver.firstName} ${data[i].driver.lastName}` : '',
          prices: data[i].prices,
          stops: data[i].stops.map((stop)=>({deleted: stop.deleted, id: stop.id, reservations: stop.reservations, stop: stop.stop, time: stop.time, type: stop.type, timeId: stop.timeId}))
        }
      }
    }
    this.setState({busChanges: busChanges, oldData: _.cloneDeep(busChanges)});
    if(this.props.departCity) {
      this.setState({
        departCity: this.props.departCity,
        arriveCity: this.props.arriveCity,
        departDate: this.props.departDate,
        mode: this.props.mode,
      });
      if(this.props.returnDate) {
        this.setState({returnDate: this.props.returnDate});
      }
    }
  }

  changeBusData(id, e) {
    let data = this.state.busChanges;
    data[id][e.target.name] = e.target.value;
    this.setState({busChanges: data});
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  goBack() {
    history.push('/dashboard/main-results');
  }

  getNumericValue(value) {
    return parseInt(value, 10);
  }

  checkNaN(value) {
    return isNaN(this.getNumericValue(value));
  }

  save() {
    const clonedBusChanges = _.cloneDeep(this.state.busChanges);
    const clonedOldData = _.cloneDeep(this.state.oldData);
    
    if (_.isEqual(clonedBusChanges, clonedOldData)) {
      this.goBack();
      return;
    }
    let data = {};
    for(let key in this.state.busChanges) {
      if (!(_.isEqual(clonedBusChanges[key], clonedOldData[key]))) {
        data[key] = {
          isOpen: (this.state.busChanges[key].isOpen === 'open' ? true : false),
          notes: this.state.busChanges[key].notes || null,
          priceOverride: (!this.checkNaN(this.state.busChanges[key].priceOverride) ? this.getNumericValue(this.state.busChanges[key].priceOverride) : null),
          firstSpecialSeats: (!this.checkNaN(this.state.busChanges[key].firstSpecialSeats) ? this.getNumericValue(this.state.busChanges[key].firstSpecialSeats) : null),
          firstSpecialSeatsPrice: (!this.checkNaN(this.state.busChanges[key].firstSpecialSeatsPrice) ? this.getNumericValue(this.state.busChanges[key].firstSpecialSeatsPrice) : null),
          lastSpecialSeats: (!this.checkNaN(this.state.busChanges[key].lastSpecialSeats) ? this.getNumericValue(this.state.busChanges[key].lastSpecialSeats) : null),
          lastSpecialSeatsPrice: (!this.checkNaN(this.state.busChanges[key].lastSpecialSeatsPrice) ? this.getNumericValue(this.state.busChanges[key].lastSpecialSeatsPrice) : null),
          couponLimit: (!this.checkNaN(this.state.busChanges[key].couponLimit) ? this.getNumericValue(this.state.busChanges[key].couponLimit) : null),
          capacityOverride: (!this.checkNaN(this.state.busChanges[key].capacityOverride) ? this.getNumericValue(this.state.busChanges[key].capacityOverride) : null),
          isSpecial: this.state.busChanges[key].isSpecial,
          date: this.state.busChanges[key].date,
          driver: this.state.busChanges[key].driver || null,
          company: this.state.busChanges[key].company || "",
          prices: this.state.busChanges[key].prices || null,
          stops: this.state.busChanges[key].stops
        };
      }
    }
    console.log(data);
    if (Object.keys(data).length) {
      this.props.dispatch(mainActions.saveScheduleBus({buses: data}));
    }
  }


  showEditPrice(schedule_bus, index, isDepartingBus){
    let departStops = schedule_bus.stops.filter((stop)=> stop.type === "PU" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let destinationStops = schedule_bus.stops.filter((stop)=> stop.type === "DO" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let prices = schedule_bus.prices;
    let priceOverride = schedule_bus.priceOverride ? schedule_bus.priceOverride: schedule_bus.specialPrice;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.props.stops, priceOverride);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index, isDepartingBus});
  }

  updatePrice(priceMap, currentlySelectedBusIndex = this.state.currentSelectedBusIndex){
    let busChanges = this.state.busChanges;
    let newPrices = [];
    if(priceMap && priceMap.length){
      priceMap.map((price)=>{
        let p = {
          from_stop_id: price.departStop.stop,
          to_stop_id: price.destinationStop.stop, 
          price: price.price,
          id: price.customRouteId ? price.customRouteId: undefined
        }

        newPrices.push(p)      
      })


      if(this.state.isDepartingBus){
        let departingSchedules = this.state.departingSchedules;
        departingSchedules[currentlySelectedBusIndex].prices = newPrices;
        busChanges[this.state.departingSchedules[currentlySelectedBusIndex].schedule_bus].prices = newPrices;
        this.setState({busChanges, departingSchedules})
      }else{
        let arrivingSchedules = this.state.arrivingSchedules;
        arrivingSchedules[currentlySelectedBusIndex].prices = newPrices;
        busChanges[this.state.arrivingSchedules[currentlySelectedBusIndex].schedule_bus].prices = newPrices;
        this.setState({busChanges, arrivingSchedules})
      }


  }    
  }

  updateStopPrice(e, index) {
    const { value } = e.target;
    let combinationOfStops = this.state.currentSelectedBus;
    combinationOfStops[index].price = value;
    this.setState({ currentSelectedBus: combinationOfStops });
  }
  
  updatePriceData(currentlySelectedBusIndex, schedule_bus){
    let departStops = schedule_bus.stops.filter((stop)=> stop.type === "PU" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let destinationStops = schedule_bus.stops.filter((stop)=> stop.type === "DO" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let prices = schedule_bus.prices;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.props.stops, 'id');
    this.updatePrice(stopCombination, currentlySelectedBusIndex);
  }

  render() {
    const { departDate, returnDate, departCity, arriveCity, departingSchedules, arrivingSchedules, suggestions } = this.state;
    const getSuggestionValue = suggestion => suggestion.id;
    const renderSuggestion = suggestion => (
      <div>
        {suggestion.firstName + " " + suggestion.lastName}
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Bus Edit Mode"
                rightButton={
                  <NavLink to="/dashboard/main-results"><Button type="button" fill bsStyle="info">Back To Search Results</Button></NavLink>
                }
                content={
                  <div>
                    <Row className="main-box-container">
                      <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="border-black head">
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && !departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{departingSchedules[0].name}</div>
                            }
                            From {this.getCityLabel(departCity)} - {moment(departDate).format('dddd, MMM D YYYY')} </div>
                          {departingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="border-black bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' &&
                                      <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        Time ID: {stop.timeId}&nbsp;
                                        {moment(stop.time, 'HH:mm:ss').format('hh:mm A')} from {stop.stop.address}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  <div className="row bus-info-grid">
                                  <div className="col-md-12 align-center">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {this.props.me.permissions.indexOf('max_capacity') > -1 ? <input type="number" className="edit-bus-number" name="capacityOverride" value={this.state.busChanges[bus.schedule_bus].capacityOverride} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> : bus.capacity}</div>
                                  <div className="col-md-6">
                                      <div>Price:  <button onClick={()=>{this.showEditPrice(bus, index, true)}}>Edit Price</button> </div> 
                                      {/* <span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp; */}
                                      {/* <pre>{JSON.stringify(bus.prices)}</pre> */}

                                      {/* {!bus.prices ? bus.bus_type.defaultPrice === bus.price &&  <Fragment>${bus.price} (unchanged)</Fragment> : null} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''} */}
                                    {/* </span> */}
                                  
                                  </div>
                                  <div className="col-md-6"><b>Bus is</b> <select className="edit-bus-number" name="isOpen" value={this.state.busChanges[bus.schedule_bus].isOpen} onChange={this.changeBusData.bind(this, bus.schedule_bus)}>
                                      <option value="open">open</option>
                                      <option value="closed">closed</option>
                                    </select></div>
                                  <div className="col-md-6 driver-input"><b>Driver:</b> 
                                    <Autosuggest
                                      suggestions={suggestions}
                                      onSuggestionsFetchRequested={(target) => this.fetchDriversWithDebounce(bus.schedule_bus, target)}
                                      onSuggestionsClearRequested={() => this.onDriversClearRequested(bus.schedule_bus)}
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={renderSuggestion}
                                      onSuggestionSelected={(event, value) => this.driverSelected(bus.schedule_bus, value)}
                                      inputProps={{
                                        name: 'driverName',
                                        value: this.state.busChanges[bus.schedule_bus].driverName,
                                        placeholder: 'Driver',
                                        onChange: this.changeBusData.bind(this, bus.schedule_bus),
                                        className: 'form-control'
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6"><b>Company:</b> <input type="text" className="bus-notes-input" name="company" value={this.state.busChanges[bus.schedule_bus].company} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {this.props.me.permissions.indexOf('promos') > -1 ? <input className="edit-bus-number" type="number" name="couponLimit" value={this.state.busChanges[bus.schedule_bus].couponLimit} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> : bus.couponLimit}</div>
                                  <div className="col-md-6"><b>Notes:</b> <input type="text" className="bus-notes-input" name="notes" value={this.state.busChanges[bus.schedule_bus].notes} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                  </div>
                                  {this.props.me.permissions.indexOf('prices') > -1 && <div className="row bus-info-grid">
                                    <div className="col-md-12"><b>Price for the first</b> <input className="edit-bus-number" type="number" name="firstSpecialSeats" value={this.state.busChanges[bus.schedule_bus].firstSpecialSeats} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> <b>seats:</b> <input className="edit-bus-number" type="number" name="firstSpecialSeatsPrice" value={this.state.busChanges[bus.schedule_bus].firstSpecialSeatsPrice} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                    <div className="col-md-12"><b>
                                      Price for the last</b> <input className="edit-bus-number" type="number" name="lastSpecialSeats" value={this.state.busChanges[bus.schedule_bus].lastSpecialSeats} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> <b>seats:</b> <input className="edit-bus-number" type="number" name="lastSpecialSeatsPrice" value={this.state.busChanges[bus.schedule_bus].lastSpecialSeatsPrice} onChange={this.changeBusData.bind(this, bus.schedule_bus)} />
                                    </div>
                                    {/* <div className="col-md-12"><b>Special price for whole bus:</b> <input className="edit-bus-number" type="number" name="priceOverride" value={this.state.busChanges[bus.schedule_bus].priceOverride} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div> */}
                                  </div>}
                                  <div>Destinations:
                                    <div className="stops-group">
                                      {bus.stops.map((stop, i) => {
                                        return (
                                          stop.type === 'DO' &&
                                          <div key={i} className={'stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                            <b>{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</b> at {stop.stop.address} {(this.state.arriveStop && this.state.arriveStop.id === stop.id) && <i className='fa fa-check'></i>}
                                            <div className="view-res-link">{stop.reservations}</div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                      {returnDate && <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="border-black head">
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && !arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{arrivingSchedules[0].name}</div>
                            }
                            From {this.getCityLabel(arriveCity)} - {moment(returnDate).format('dddd, MMM D YYYY')}
                          </div>
                          {arrivingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="border-black bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' && <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        Time ID: {stop.timeId}&nbsp;
                                        {moment(stop.time, 'HH:mm:ss').format('hh:mm A')} from {stop.stop.address}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  <div className="row bus-info-grid">
                                  <div className="col-md-12 align-center">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {this.props.me.permissions.indexOf('max_capacity') > -1 ? <input type="number" className="edit-bus-number" name="capacityOverride" value={this.state.busChanges[bus.schedule_bus].capacityOverride} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> : bus.capacity}</div>
                                  <div className="col-md-6">
                                  <div>Price:  <button onClick={()=>{this.showEditPrice(bus, index, false)}}>Edit Price</button> </div> 
                                      {/* <span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp; */}
                                      
                                      {/* {!bus.prices ? bus.bus_type.defaultPrice === bus.price &&  <Fragment>${bus.price} (unchanged)</Fragment> : null} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''} */}
                                    {/* </span> */}
                                  </div>
                                  <div className="col-md-6"><b>Bus is</b> <select className="edit-bus-number" name="isOpen" value={this.state.busChanges[bus.schedule_bus].isOpen} onChange={this.changeBusData.bind(this, bus.schedule_bus)}>
                                      <option value="open">open</option>
                                      <option value="closed">closed</option>
                                    </select></div>
                                  <div className="col-md-6 driver-input"><b>Driver:</b> 
                                    <Autosuggest
                                      suggestions={suggestions}
                                      onSuggestionsFetchRequested={(target) => this.fetchDriversWithDebounce(bus.schedule_bus, target)}
                                      onSuggestionsClearRequested={() => this.onDriversClearRequested(bus.schedule_bus)}
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={renderSuggestion}
                                      onSuggestionSelected={(event, value) => this.driverSelected(bus.schedule_bus, value)}
                                      inputProps={{
                                        name: 'driver',
                                        value: this.state.busChanges[bus.schedule_bus].driverName,
                                        placeholder: 'Driver',
                                        onChange: this.changeBusData.bind(this, bus.schedule_bus),
                                        className: 'form-control'
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6"><b>Company:</b> <input type="text" className="bus-notes-input" name="company" value={this.state.busChanges[bus.schedule_bus].company} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {this.props.me.permissions.indexOf('promos') > -1 ? <input className="edit-bus-number" type="number" name="couponLimit" value={this.state.busChanges[bus.schedule_bus].couponLimit} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> : bus.couponLimit}</div>
                                  <div className="col-md-6"><b>Notes:</b> <input type="text" className="bus-notes-input" name="notes" value={this.state.busChanges[bus.schedule_bus].notes} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                  </div>
                                  {this.props.me.permissions.indexOf('prices') > -1 && <div className="row bus-info-grid">
                                    <div className="col-md-12"><b>Price for the first</b> <input className="edit-bus-number" type="number" name="firstSpecialSeats" value={this.state.busChanges[bus.schedule_bus].firstSpecialSeats} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> <b>seats:</b> <input className="edit-bus-number" type="number" name="firstSpecialSeatsPrice" value={this.state.busChanges[bus.schedule_bus].firstSpecialSeatsPrice} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div>
                                    <div className="col-md-12"><b>
                                      Price for the last</b> <input className="edit-bus-number" type="number" name="lastSpecialSeats" value={this.state.busChanges[bus.schedule_bus].lastSpecialSeats} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /> <b>seats:</b> <input className="edit-bus-number" type="number" name="lastSpecialSeatsPrice" value={this.state.busChanges[bus.schedule_bus].lastSpecialSeatsPrice} onChange={this.changeBusData.bind(this, bus.schedule_bus)} />
                                    </div>
                                    {/* <div className="col-md-12"><b>Special price for whole bus:</b> <input className="edit-bus-number" type="number" name="priceOverride" value={this.state.busChanges[bus.schedule_bus].priceOverride} onChange={this.changeBusData.bind(this, bus.schedule_bus)} /></div> */}
                                  </div>}
                                  <div>Destinations:
                                    <div className="stops-group">
                                      {bus.stops.map((stop, i) => {
                                        return (
                                          stop.type === 'DO' &&
                                          <div key={i} className={'stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                            <b>{moment(stop.time, 'HH:mm:ss').format('hh:mm a')}</b> at {stop.stop.address} {(this.state.arriveStop && this.state.arriveStop.id === stop.id) && <i className='fa fa-check'></i>}
                                            <div className="view-res-link">{stop.reservations}</div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>}
                    </Row>
                    {this.props.saveBusError && <div>{this.props.saveBusError}</div>}
                    {this.props.saveBusSuccess && this.goBack() && <div>Changes Saved Successfully</div>}
                    <div className="move-btn"><Button bsStyle="info" fill onClick={this.save.bind(this)} disabled={this.props.loading}>Save Changes</Button></div>
                  </div>
                }
              />
            </Col>
          </Row>
          {<CustomPrice updatePrice={this.updatePrice.bind(this)} open={this.state.showModal} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={this.updateStopPrice.bind(this)}/>}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, departingSchedules, arrivingSchedules, departCity, arriveCity, departDate, returnDate, mode, saveBusError, saveBusSuccess } = state.main;
  return {
    loading,
    error,
    departingSchedules,
    arrivingSchedules,
    departCity,
    arriveCity,
    departDate,
    returnDate,
    mode,
    saveBusError,
    saveBusSuccess,
    me: state.authentication.me,
    drivers: state.users.items,
    stops: state.stops.items, 
  };
}
 
export default connect(mapStateToProps)(EditScheduleBus);
