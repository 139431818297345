import { GET_STOPS_REQUEST, GET_STOPS_SUCCESS, GET_STOPS_FAILURE, GET_STOP_REQUEST, GET_STOP_SUCCESS, GET_STOP_FAILURE, DELETE_STOP_REQUEST, DELETE_STOP_SUCCESS, DELETE_STOP_FAILURE, ADD_STOP_REQUEST, ADD_STOP_SUCCESS, ADD_STOP_FAILURE, UPDATE_STOP_REQUEST, UPDATE_STOP_SUCCESS, UPDATE_STOP_FAILURE } from '../constants';
import { stopService } from '../services/stop.services';
import { locationService } from '../services/location.services';
 
export default {
  add,
  update,
  getStops,
  getStop,
  delete: _delete
};
 
function add(stop) {
  return dispatch => {
    dispatch(request(stop));
    locationService.getGeo(stop.address.replace(/ /g, '+') + "+" + stop.city.replace(/ /g, '+') + "+" + stop.state.replace(/ /g, '+') + "+" + stop.zipCode)
      .then(
        data => {
          if(data && data.features && data.features.length > 0) {
            stop.geo = {
              lat: data.features[0].geometry.coordinates[1],
              lng: data.features[0].geometry.coordinates[0]
            };
            stopService.add(stop)
            .then(
              data => { 
                dispatch(success());
              },
              error => {
                dispatch(failure(error.toString()));
              }
            );
          }
          else {
            dispatch(failure("Unable to locate this address"));
          }
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
 
  function request(stop) { return { type: ADD_STOP_REQUEST, stop } }
  function success(stop) { return { type: ADD_STOP_SUCCESS, stop } }
  function failure(error) { return { type: ADD_STOP_FAILURE, error } }
}

function update(id, stop) {
  return dispatch => {
    dispatch(request(stop));
    locationService.getGeo(stop.address.replace(/ /g, '+') + "+" + stop.city.replace(/ /g, '+') + "+" + stop.state.replace(/ /g, '+') + "+" + stop.zipCode)
      .then(
        data => { 
          console.log(data)
          if(data && data.features && data.features.length > 0) {
            stop.geo = {
              lat: data.features[0].geometry.coordinates[1],
              lng: data.features[0].geometry.coordinates[0]
            };
            stopService.update(id, stop)
            .then(
              data => { 
                dispatch(success());
              },
              error => {
                dispatch(failure(error.toString()));
              }
            );
          }
          else {
            dispatch(failure("Unable to locate this address"));
          }
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };
 
  function request(stop) { return { type: UPDATE_STOP_REQUEST, stop } }
  function success() { return { type: UPDATE_STOP_SUCCESS } }
  function failure(error) { return { type: UPDATE_STOP_FAILURE, error } }
}

const filterStops = (stops) => {
  let filteredStops = []
    if(stops && stops.stops && stops.stops.length){
       filteredStops =  stops.stops.filter((stop)=>{
        return stop.id !== "92c2a532-9701-43e0-9f6d-c59b8241a550" 
      })
    }
    return  {stops: filteredStops, total: filteredStops.total};
}
 
function getStops(filters) {
  return dispatch => {
    dispatch(request());
 
    stopService.getStops(filters)
      .then(
        stops =>  dispatch(success(stops)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_STOPS_REQUEST } }
  function success(stops) { return { type: GET_STOPS_SUCCESS, stops } }
  function failure(error) { return { type: GET_STOPS_FAILURE, error } }
}

function getStop(id) {
  return dispatch => {
    dispatch(request());
 
    stopService.getStop(id)
      .then(
        stop => dispatch(success(stop)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_STOP_REQUEST } }
  function success(stop) { return { type: GET_STOP_SUCCESS, stop } }
  function failure(error) { return { type: GET_STOP_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    stopService.delete(id)
      .then(
        stop => dispatch(success(stop.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_STOP_REQUEST, id } }
  function success(id) { return { type: DELETE_STOP_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_STOP_FAILURE, id, error } }
}