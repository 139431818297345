import React, { Component } from "react";
import { connect } from 'react-redux';
import mainActions from 'actions/main.actions';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config from 'config';

import moment from 'moment-timezone';
//moment.tz.setDefault('America/New_York');

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departDate: moment(),
      cities: config.cities,
      submitted: false,
      departCity: '',
      returnArriveCity: '',
      returnDepartCity: '',
      returnDate: null,
      arriveCity: '',
      arriveCities: [], 
      returnArriveCities: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if(name === 'departCity'){
      this.setState({ [name]: value });
      let arriveCities = [];
      let returnArriveCities = [];
      if(e.target.value === 'new york' || e.target.value === 'brooklyn') {
        config.cities.map((item) => {
          if(!(['new york', 'brooklyn'].includes(item.value))) {
            arriveCities.push(item);
          }else{
            returnArriveCities.push(item);
          }
          return item;
        });
      }
      else {
        config.cities.map((item) => {
          if(item.value === 'new york' || item.value == 'brooklyn') {
            arriveCities.push(item);
          }else{
            returnArriveCities.push(item);
          }
          return item;
        });
      }
    this.setState({arriveCities, returnArriveCities})
  }else{
    this.setState({ [name]: value });
  }
  }

  handleDepartDate(date) {
    this.setState({ departDate: date });
  }

  handleReturnDate(date) {
    this.setState({ returnDate: date });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { departDate, departCity, arriveCity, returnDate, returnDepartCity, returnArriveCity } = this.state;
    const { dispatch } = this.props;
    if (departDate && departCity && arriveCity) {
      let data = {
        departCity: departCity,
        departDate: moment(departDate).format('YYYY-MM-DD'),
        arriveCity: arriveCity
      }
      if(this.state.returnDate) {
        data.returnDate = moment(returnDate).format('YYYY-MM-DD');
        data.returnDepartCity = returnDepartCity;
        data.returnArriveCity = returnArriveCity;
        localStorage.setItem('returnDate', data.returnDate);
      }
      localStorage.setItem('departCity', data.departCity);
      localStorage.setItem('departDate', data.departDate);
      localStorage.setItem('arriveCity', data.arriveCity);
      localStorage.setItem('returnDepartCity', data.returnDepartCity);
      localStorage.setItem('returnArriveCity', data.returnArriveCity);
      dispatch(mainActions.setMainProps(data));
    }
    else {
      this.setState({
        departDate: moment(),
        cities: config.cities,
        submitted: false,
        departCity: '',
        returnDepartCity: '',
        returnArriveCity: '',
        returnDate: null,
        arriveCity: '',
        arriveCities: []
      })
      return false;
    }
  }

  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  componentDidMount() {
    let data = {};
    let arriveCities = [];
    let returnArriveCities = [];
    
    let group1 = ['new york', 'brooklyn'];
    let group2 = ['arlington', 'washington', 'bethesda'];

    if(localStorage.getItem('departCity')) {
      data.departCity = localStorage.getItem('departCity');
    }
    if(localStorage.getItem('arriveCity')) {
      data.arriveCity = localStorage.getItem('arriveCity');
    }
    if(localStorage.getItem('returnDepartCity')) {
      data.returnDepartCity = localStorage.getItem('returnDepartCity');
    }
    if(localStorage.getItem('returnArriveCity')) {
      data.returnArriveCity = localStorage.getItem('returnArriveCity');
    }
    if(localStorage.getItem('departDate')) {
      data.departDate = moment(localStorage.getItem('departDate'), 'YYYY-MM-DD');
    }
    if(localStorage.getItem('returnDate')) {
      data.returnDate = moment(localStorage.getItem('returnDate'), 'YYYY-MM-DD');
    }
    if(Object.keys(data).length > 0) {
      if(group1.includes(data.departCity)) {
        config.cities.map((item) => {
          if((group2.includes(item.value))) {
            arriveCities.push(item);
          }else{
            returnArriveCities.push(item)
          }
          return item;
        });
      }
      else {
        config.cities.map((item) => {
          if(group1.includes(item.value)) {
            arriveCities.push(item);
          }else{
            returnArriveCities.push(item)
          }
          return item;
        });
      }
      this.setState({...data, arriveCities, returnArriveCities});
    }
    localStorage.removeItem('departCity');
    localStorage.removeItem('arriveCity');
    localStorage.removeItem('returnArriveCity');
    localStorage.removeItem('returnDepartCity');
    localStorage.removeItem('departDate');
    localStorage.removeItem('returnDate');
  }

  checkDisabled(){
    if(!this.state.returnDate){
      if(!this.state.departCity || !this.state.arriveCity || !this.state.departDate){
        return true
      }else{
        return false
      }
    }else{
      if(!this.state.departCity || !this.state.arriveCity || !this.state.departDate || !this.state.returnDepartCity || !this.state.returnArriveCity){
        return true
      }else{
        return false
      }
    }
  }


  render() {
    const { loading } = this.props;
    const { departDate, returnDate, departCity, submitted, arriveCity, returnArriveCity, returnDepartCity } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Control Panel Main Page"
                category="To book a reservation or for any other date-specific process:"
                content={
                  <form name="mainForm" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md={3}>
                        <label>Choose Date</label>
                      </Col>
                      <Col md={9}>
                        <DatePicker
                          readOnly
                          className="form-control date-readonly-pointer"
                          selected={departDate}
                          onChangeRaw={this.handleDateChangeRaw.bind(this)}
                          onChange={this.handleDepartDate.bind(this)}
                          isClearable={true}
                          shouldCloseOnSelect={false}
                        />
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}>{submitted && !departDate && <div className="help-block">Depart Date is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <label>Leaving From</label>
                      </Col>
                      <Col md={9}>
                        <select
                          className = "form-control "
                          value = {departCity}
                          name = "departCity"
                          onChange = {this.handleChange.bind(this)}
                        >
                          <option value=""></option>
                          {
                            this.state.cities.map((stop, key) => {
                              return(<option key={key} value={stop.value}>{stop.label}</option>)
                            })
                          }
                        </select>
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}>{submitted && !departCity && <div className="help-block">Depart City is required</div>}</Col>
                    </Row>

                    <Row>
                      <Col md={3}>
                        <label>Arriving To</label>
                      </Col>
                      <Col md={9}>
                        <select
                          className = "form-control"
                          value = {arriveCity}
                          name = "arriveCity"
                          // disabled = {arriveCity === 'new york' && departCity !== 'new york'}
                          onChange = {this.handleChange.bind(this)}
                        >
                          <option value=""></option>
                          {
                            this.state.arriveCities.map((stop, key) => {
                              return(<option key={key} value={stop.value}>{stop.label}</option>)
                            })
                          }
                        </select>
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}>{submitted && !arriveCity && <div className="help-block">Arriving city is required</div>}</Col>
                    </Row>

                    <Row>
                      <Col md={3}>
                        <label>Return Date (optional)</label>
                      </Col>
                      <Col md={9}>
                        <DatePicker
                          readOnly
                          className="form-control date-readonly-pointer"
                          minDate={departDate}
                          selected={returnDate}
                          onChangeRaw={this.handleDateChangeRaw.bind(this)}
                          onChange={this.handleReturnDate.bind(this)}
                          isClearable={true}
                          shouldCloseOnSelect={false}
                        />
                      </Col>
                    </Row>

                    {this.state.returnDate && <Row>
                      <Col md={3}>
                        <label>Leaving From</label>
                      </Col>
                      <Col md={9}>
                        <select
                          className = "form-control "
                          value = {returnDepartCity}
                          name = "returnDepartCity"
                          onChange = {this.handleChange.bind(this)}
                        >
                          <option value=""></option>
                          {
                            this.state.arriveCities.map((stop, key) => {
                              return(<option key={key} value={stop.value}>{stop.label}</option>)
                            })
                          }
                        </select>
                      </Col>
                    </Row>
                    }
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}>{submitted && !departCity && <div className="help-block">Depart City is required</div>}</Col>
                    </Row> 
                    

                     {this.state.returnDate && <Row>
                      <Col md={3}>
                        <label>Arriving To</label>
                      </Col>
                      <Col md={9}>
                        <select
                          className = "form-control"
                          value = {returnArriveCity}
                          name = "returnArriveCity"
                          // disabled = {arriveCity === 'new york' && departCity !== 'new york'}
                          onChange = {this.handleChange.bind(this)}
                        >
                          <option value=""></option>
                          {
                            this.state.returnArriveCities.map((stop, key) => {
                              return(<option key={key} value={stop.value}>{stop.label}</option>)
                            })
                          }
                        </select>
                      </Col>
                    </Row>}
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}>{submitted && !arriveCity && <div className="help-block">Arriving city is required</div>}</Col>
                    </Row>        
                


                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}></Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={3}></Col>
                      <Col md={9}></Col>
                    </Row>
                    <div className="align-center"><Button className="go-btn btn-fill" disabled={loading || this.checkDisabled()} bsStyle="info" fill type="submit">
                      Go
                    </Button></div>
                    {loading && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    {this.props.error && <div className="error">{this.props.error}</div>}
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error } = state.main;
  return {
    loading,
    error
  };
}
 
export default connect(mapStateToProps)(MainPage);
