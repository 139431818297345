import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Permissions from "components/Permissions/Permissions.jsx";
import userActions from 'actions/user.actions';
import { connect } from 'react-redux';

//import avatar from "assets/img/faces/face-3.jpg";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.getUser(this.props.match.params.id));

    this.state = {
      submitted: false,
      user: {
        receiveOffers: true,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        newPassword: '',
      },
      errors: null,
      touched: {
        newPassword: false
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  validate(name, value){

    let passwordErrorMessage = '';

    if(name === 'newPassword') {
        if(value.length == 0) {
          passwordErrorMessage = '';
        }else if(value.length < 6){
          passwordErrorMessage = 'Password must be at least 6 characters';
        }
      } else {
        passwordErrorMessage = '';
      }
    

    if(passwordErrorMessage){
      this.setState({errors: {...this.state.errors, [name]:{message: passwordErrorMessage}}});
    }
    else{
      this.setState({errors: null});
    }

  }

  handleChange(e) {
    const { name, value } = e.target;
    let user = this.state.user;
    user[name] = value;
    this.validate(name, value);
    this.setState({ user: user });
  }

  handleBlur(e) {
    const { name, value } = e.target;
    this.setState({ touched: {...this.state.touched, [name]: true} }, ()=>{
      this.validate(name, value);
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if(this.state.errors) return;
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.user.email && this.state.user.phone && this.state.user.firstName && this.state.user.lastName && this.state.user.address && this.state.user.city && this.state.user.state && this.state.user.zip && this.state.user.country) {
      dispatch(
        userActions.update(
          this.props.match.params.id,
          {
            email: this.state.user.email,
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            phone: this.state.user.phone,
            address: this.state.user.address,
            city: this.state.user.city,
            state: this.state.user.state,
            zip: this.state.user.zip,
            country: this.state.user.country,
            receiveOffers: this.state.user.receiveOffers,
            password: this.state.user.newPassword,
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.user && JSON.stringify(this.state.user) !== JSON.stringify(props.user)) {
      this.setState({user: props.user});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.setState({user: {...this.state.user, newPassword: ''}})
      this.props.addNotification({type: 'success', message: 'User updated successfully'});
    }
  }

  handleCheckbox = event => {
    let user = this.state.user;
    user.receiveOffers = !user.receiveOffers;
    this.setState({user: user});
  };

  render() {
    const { submitted, user } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Edit User"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "First Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First Name",
                          value: user.firstName,
                          name: 'firstName',
                          onChange: this.handleChange
                        },
                        {
                          label: "Last Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last Name",
                          value: user.lastName,
                          name: 'lastName',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !user.firstName && <div className="error">First name is required</div>}</Col>
                      <Col md={6}>{submitted && !user.lastName && <div className="error">Last name is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: user.email,
                          name: 'email',
                          onChange: this.handleChange
                        },
                        {
                          label: "Phone",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Phone",
                          value: user.phone,
                          name: 'phone',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !user.email && <div className="error">Email is required</div>}</Col>
                      <Col md={6}>{submitted && !user.phone && <div className="error">Phone is required</div>}</Col>
                    </Row>

                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Update Password",
                          type: "password",
                          bsClass: "form-control",
                          placeholder: "Update Password",
                          value: this.state.user.newPassword,
                          name: 'newPassword',
                          onChange: this.handleChange,
                          onBlur: this.handleBlur
                        }
                      ]}
                    />

                    <Row className="error-row">
                      <Col md={12}>{this.state.errors && this.state.errors.newPassword && this.state.touched.newPassword && this.state.errors.newPassword.message && <div className="error">{this.state.errors.newPassword.message}</div>}</Col>
                    </Row>

                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Address",
                          value: user.address,
                          name: 'address',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !user.address && <div className="error">Address is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "City",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "City",
                          value: user.city,
                          name: 'city',
                          onChange: this.handleChange
                        },
                        {
                          label: "State",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "State",
                          value: user.state,
                          name: 'state',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !user.city && <div className="error">City is required</div>}</Col>
                      <Col md={6}>{submitted && !user.state && <div className="error">State is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "ZIP Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: user.zip,
                          name: 'zip',
                          onChange: this.handleChange
                        },
                        {
                          label: "Country",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Country",
                          value: user.country,
                          name: 'country',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !user.zip && <div className="error">Zipcode is required</div>}</Col>
                      <Col md={6}>{submitted && !user.country && <div className="error">Country is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Checkbox
                          number='check1'
                          value={user.receiveOffers}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={user.receiveOffers}
                          label='Would you like to receive offers from tripperbus?'
                        />
                      </Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update User
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <Permissions user={this.props.match.params.id} addNotification={this.props.addNotification} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.users;
  return {
    loading, user: item, error, success
  };
}
 
export default connect(mapStateToProps)(EditUser);
