import Dashboard from "views/Dashboard/Dashboard";
import EditUser from "views/Users/EditUser";
import AddUser from "views/Users/AddUser";
import Users from "views/Users/Users";
import EditStop from "views/Stops/EditStop";
import AddStop from "views/Stops/AddStop";
import Stops from "views/Stops/Stops";
import EditBus from "views/Buses/EditBus";
import AddBus from "views/Buses/AddBus";
import Buses from "views/Buses/Buses";
import EditSchedule from "views/Schedule/EditSchedule";
import AddSchedule from "views/Schedule/AddSchedule";
import Schedule from "views/Schedule/Schedule";
import EditCoupon from "views/Coupons/EditCoupon";
import AddCoupon from "views/Coupons/AddCoupon";
import Coupons from "views/Coupons/Coupons";
//import EditPayment from "views/Payments/EditPayment";
//import Payments from "views/Payments/Payments";
import EditOrder from "views/Orders/EditOrder";
//import Orders from "views/Orders/Orders";
import EditOrderItem from "views/Orders/EditOrderItem";
import EditVoucher from "views/Vouchers/EditVoucher";
import AddVoucher from "views/Vouchers/AddVoucher";
//import Vouchers from "views/Vouchers/Vouchers";
import EditCashCredit from "views/CashCredits/EditCashCredit";
import AddCashCredit from "views/CashCredits/AddCashCredit";
//import CashCredits from "views/CashCredits/CashCredits";
import EditGiftCard from "views/GiftCards/EditGiftCard";
import GiftCards from "views/GiftCards/GiftCards";
//import EditReservation from "views/Reservations/EditReservation";
//import AddReservation from "views/Reservations/AddReservation";
import Reservations from "views/Reservations/Reservations";
import EditReservationRemark from "views/Reservations/EditReservationRemark";
import AddReservationRemark from "views/Reservations/AddReservationRemark";
import EditCustomer from "views/Customers/EditCustomer";
import AddCustomer from "views/Customers/AddCustomer";
import Customers from "views/Customers/Customers";
//import EditNotice from "views/Notices/EditNotice";
//import AddNotice from "views/Notices/AddNotice";
//import Notices from "views/Notices/Notices";
import MainPage from "views/Main/MainPage";
import MainResults from "views/Main/MainResults";
import MainReservations from "views/Main/MainReservations";
import AddMainReservation from "views/Main/AddMainReservation";
import MovePeople from "views/Main/MovePeople";
import LostReservations from "views/LostReservations/LostReservations";
import EditScheduleBus from "views/Main/EditScheduleBus";
import EditTopbar from "views/Topbar/EditTopbar";
import SearchReservations from "views/Main/SearchReservations";
import store from "stores/index";

console.log(store.getState().authentication.me)

let dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  

  {
    path: "/dashboard/main-results",
    name: "Main Results",
    component: MainResults,
    notInNav: true
  },
  {
    path: "/dashboard/orders/:id",
    name: "Edit Order",
    component: EditOrder,
    notInNav: true
  },
  /*{
    path: "/dashboard/orders",
    name: "Orders",
    icon: "pe-7s-cart",
    component: Orders
  },*/
  {
    path: "/dashboard/order_items/:id",
    name: "Edit Order Item",
    component: EditOrderItem,
    notInNav: true
  },
  /*{
    path: "/dashboard/reservations/:id",
    name: "Edit Reservation",
    component: EditReservation,
    notInNav: true
  },*/
  /*{
    path: "/dashboard/reservation",
    name: "Add Reservation",
    component: AddReservation,
    notInNav: true
  },*/
  {
    path: "/dashboard/reservation_remarks/:id",
    name: "Edit Reservation Remark",
    component: EditReservationRemark,
    notInNav: true
  },
  {
    path: "/dashboard/reservation_remark/:reservation",
    name: "Add Reservation Remark",
    component: AddReservationRemark,
    notInNav: true
  },
  {
    path: "/dashboard/coupons/:id",
    name: "Edit Coupon",
    component: EditCoupon,
    notInNav: true
  },

  {
    path: "/dashboard/coupon",
    name: "Add Coupon",
    component: AddCoupon,
    notInNav: true
  },
  {
    path: "/dashboard/vouchers/:id",
    name: "Edit Point",
    component: EditVoucher,
    notInNav: true
  },
  /*{
    path: "/dashboard/vouchers",
    name: "Points",
    icon: "pe-7s-cash",
    component: Vouchers
  },*/
  {
    path: "/dashboard/voucher",
    name: "Add Points",
    component: AddVoucher,
    notInNav: true
  },
  {
    path: "/dashboard/credits/:id",
    name: "Edit Credit",
    component: EditCashCredit,
    notInNav: true
  },
  /*{
    path: "/dashboard/credits",
    name: "Credits",
    icon: "pe-7s-cash",
    component: CashCredits
  },*/
  {
    path: "/dashboard/credit",
    name: "Add Credit",
    component: AddCashCredit,
    notInNav: true
  },
  /*{
    path: "/dashboard/payments/:id",
    name: "Edit Payment",
    component: EditPayment,
    notInNav: true
  },
  {
    path: "/dashboard/payments",
    name: "Payments",
    icon: "pe-7s-cash",
    component: Payments
  },*/
  {
    path: "/",
    from: "/",
    to: "/dashboard",
    component: Dashboard,
    notInNav: true
  },
  {
    path: "/dashboard/edit-schedule-bus",
    name: "Edit Schedule Bus",
    component: EditScheduleBus,
    notInNav: true
  }
];
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('driver_sheet') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/main-page",
    name: "Main Page",
    icon: "pe-7s-home",
    component: MainPage
  },);
}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('bus_types') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/buses",
    name: "Bus Types",
    icon: "fa fa-bus",
    component: Buses
  });

  dashboardRoutes.push({
    path: "/dashboard/bus",
    name: "Add Bus Type",
    component: AddBus,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/buses/:id",
    name: "Edit Bus Type",
    component: EditBus,
    notInNav: true
  });
}

if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('gift_cards') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/gift_cards",
    name: "Gift Cards",
    icon: "pe-7s-gift",
    component: GiftCards
  });

  dashboardRoutes.push({
    path: "/dashboard/gift_cards/:id",
    name: "Edit Gift Card",
    component: EditGiftCard,
    notInNav: true
  });
}

if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('bus_stops') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/stops",
    name: "Stops",
    icon: "fa fa-street-view",
    component: Stops
  });

  dashboardRoutes.push({
    path: "/dashboard/stop",
    name: "Add Stop",
    component: AddStop,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/stops/:id",
    name: "Edit Stop",
    component: EditStop,
    notInNav: true
  });
}

if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('schedules') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/schedules",
    name: "Schedules",
    icon: "fa fa-calendar",
    component: Schedule
  });

  dashboardRoutes.push({
    path: "/dashboard/schedule",
    name: "Add Schedule",
    component: AddSchedule,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/schedules/:id",
    name: "Edit Schedule",
    component: EditSchedule,
    notInNav: true
  });
}

if(store.getState().authentication.me && store.getState().authentication.me.permissions && (store.getState().authentication.me.permissions.indexOf('personalization') > -1 || store.getState().authentication.me.permissions.indexOf('topbar') > -1)) {
  dashboardRoutes.push({
    path: "/dashboard/topbar",
    name: "Topbar",
    component: EditTopbar,
    icon: "pe-7s-note2"
  });

  /*dashboardRoutes.push({
    path: "/dashboard/notices",
    name: "Notices",
    icon: "pe-7s-note2",
    component: Notices
  });

  dashboardRoutes.push({
    path: "/dashboard/notices/:id",
    name: "Edit Notice",
    component: EditNotice,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/notice",
    name: "Add Notice",
    component: AddNotice,
    notInNav: true
  });*/
}

if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('users') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/users",
    name: "Users",
    icon: "pe-7s-users",
    component: Users
  });

  dashboardRoutes.push({
    path: "/dashboard/user",
    name: "Add User",
    component: AddUser,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/users/:id",
    name: "Edit User",
    component: EditUser,
    notInNav: true
  });
}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('customers') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/customer",
    name: "Add Customer",
    component: AddCustomer,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/customers",
    name: "Customers",
    icon: "pe-7s-users",
    component: Customers
  });

  dashboardRoutes.push({
    path: "/dashboard/customers/:id",
    name: "Edit Customer",
    component: EditCustomer,
    notInNav: true
  });
}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('reservations') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/reservations",
    name: "Reservations",
    icon: "pe-7s-date",
    component: Reservations
  });

  dashboardRoutes.push({
    path: "/dashboard/main-reservations",
    name: "Main Reservations",
    component: MainReservations,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/main-reservation",
    name: "Main Reservation",
    component: AddMainReservation,
    notInNav: true
  });

  dashboardRoutes.push({
    path: "/dashboard/lost-reservations",
    name: "Lost Reservations",
    icon: "pe-7s-filter",
    component: LostReservations
  });

}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('move_people') > -1) {
  console.log(1)
  dashboardRoutes.push({
    path: "/dashboard/move-people",
    name: "Move People",
    component: MovePeople,
    notInNav: true
  });
}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('site_search') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/search-reservations",
    name: "Search Reservations",
    component: SearchReservations,
    notInNav: true
  });
 
  
}
if(store.getState().authentication.me && store.getState().authentication.me.permissions && store.getState().authentication.me.permissions.indexOf('promos') > -1) {
  dashboardRoutes.push({
    path: "/dashboard/coupons",
    name: "Coupons",
    icon: "pe-7s-cash",
    component: Coupons
  });
}


export default dashboardRoutes;