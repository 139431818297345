import { GET_VOUCHERS_REQUEST, GET_VOUCHERS_SUCCESS, GET_VOUCHERS_FAILURE, GET_VOUCHER_REQUEST, GET_VOUCHER_SUCCESS, GET_VOUCHER_FAILURE, ADD_VOUCHER_REQUEST, ADD_VOUCHER_SUCCESS, ADD_VOUCHER_FAILURE, UPDATE_VOUCHER_REQUEST, UPDATE_VOUCHER_SUCCESS, UPDATE_VOUCHER_FAILURE, DELETE_VOUCHER_REQUEST, DELETE_VOUCHER_SUCCESS, DELETE_VOUCHER_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_VOUCHERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_VOUCHERS_SUCCESS:
      return {
        loading: false,
        items: action.vouchers.vouchers,
        total: action.vouchers.total
      };
    case GET_VOUCHERS_FAILURE:
      return {
        error: action.error
      };
    case GET_VOUCHER_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_VOUCHER_SUCCESS:
      return {
        loading: false,
        item: action.voucher
      };
    case GET_VOUCHER_FAILURE:
      return {
        error: action.error
      };
    case ADD_VOUCHER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_VOUCHER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_VOUCHER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_VOUCHER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_VOUCHER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_VOUCHER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_VOUCHER_REQUEST:
      // add 'deleting:true' property to voucher being deleted
      return {
        ...state,
        items: state.items.map(voucher =>
          voucher.id === action.id
            ? { ...voucher, deleting: true }
            : voucher
        )
      };
    case DELETE_VOUCHER_SUCCESS:
      // remove deleted voucher from state
      return {
        items: state.items.filter(voucher => voucher.id !== action.id)
      };
    case DELETE_VOUCHER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to voucher 
      return {
        ...state,
        items: state.items.map(voucher => {
          if (voucher.id === action.id) {
            // make copy of voucher without 'deleting:true' property
            const { deleting, ...voucherCopy } = voucher;
            // return copy of voucher with 'deleteError:[error]' property
            return { ...voucherCopy, deleteError: action.error };
          }
 
          return voucher;
        })
      };
    default:
      return state
  }
}