import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-table/react-table.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import reservationActions from 'actions/reservation.actions';
import { reservationService } from 'services/reservation.services';
import { mainService } from 'services/main.services';
import moment from 'moment-timezone';
import Modal from 'react-responsive-modal';
import config from 'config';
import _ from 'lodash';

//moment.tz.setDefault('America/New_York');

/*eslint-disable no-script-url*/

class LostReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMove: false,
      cities: config.cities,
      departStop: '',
      arriveStop: '',
      message: '',
      remark: '',
      openSuccess: false,
      selectedLeg: null,
      selectedBus: null,
      submitted: false,
      loading: false,
      schedules: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentWillUpdate(props) {
    if(props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  componentDidMount() {
    this.props.dispatch(reservationActions.getLostReservations());
  }

  getCityCode(city) {
    return _.find(config.cities, {value: city}).code;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, error: '' });
    if(this.state.schedules[this.state.selectedBus].remainingSeats < this.state.selectedLeg.reservations) {
      this.setState({ submitted: false, error: 'This bus does not have enough available seats.' });
      return;
    }
    let data = {
      id: this.state.selectedLeg.id,
      schedule_bus: this.state.schedules[this.state.selectedBus].schedule_bus,
      schedule: this.state.schedules[this.state.selectedBus].scheduleId,
      depart_stop: this.state.departStop,
      arrive_stop: this.state.arriveStop,
      remark: `${this.state.message} - ${this.state.remark}`,
      reason: this.state.selectedLeg.reason
    }
    this.setState({loading: true});
    reservationService.moveLostReservations(data)
    .then(result => {
      console.log(result);
      this.setState({loading: false, openSuccess: true});
    })
    .catch(err => {
      console.log(err);
      this.setState({loading: false, error: err});
    });
  }

  getStopLabel(city) {
    if(!city) {
      return '';
    }
    const item = _.find(config.cities, {value: city});
    return item.address + ' ' + item.zip;
  }

  selectDepartStop(stop, index) {
    let arriveStop = '', message = '';
    for(let i = 0; i < this.state.schedules[index].stops.length; i++) {
      if(this.state.selectedLeg.arrive_stop.stop.city === this.state.schedules[index].stops[i].stop.city) {
        arriveStop = this.state.schedules[index].stops[i].id;
        message = `Reservation moved to ${moment(stop.time, 'HH:mm:ss').format('hh:mm A')} bus from ${this.getStopLabel(stop.stop.city)}.`;
      }
    }
    this.setState({departStop: stop.id, arriveStop: arriveStop, selectedBus: index, message: message});
  }

  closeSuccess() {
    window.location.reload();
  }

  toggleSelectAll(e) {
    if(e.target.checked) {
      let selected = [];
      (this.props.lost || []).map(item => {
        selected.push(item.id);
        return item;
      });
      this.setState({selected: selected});
    }
    else {
      this.setState({selected: []});
    }
  }

  toggleSelect(id) {
    let selected = this.state.selected;
    let index = this.state.selected.indexOf(id);
    if(index === -1) {
      selected.push(id);
    }
    else {
      selected.splice(index, 1);
    }
    this.setState({selected: selected});
  }

  cancel(leg) {
    this.setState({openCancel: true, selectedLeg: leg});
  }

  closeCancel() {
    this.setState({openCancel: false, selectedLeg: null});
  }

  move(leg) {
    console.log(leg)
    this.setState({ loading: true, selectedLeg: leg, remark: '' });
    let data = {
      departCity: leg.depart_stop.stop.city,
      departDate: leg.slot,
      arriveCity: leg.arrive_stop.stop.city
    }
    mainService.getMain(data)
    .then(data => {
      this.setState({ loading: false });
      console.log(data)
      if(data && data.schedules.length > 0) {
        this.setState({openMove: true, schedules: data.schedules});
      }
    })
    .catch(err => {
      console.log(err);
      this.setState({error: err, loading: false});
    })
  }

  closeMove() {
    this.setState({openMove: false, selectedLeg: null, remark: ''});
  }

  proceedToCancel(leg) {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({loading: true});
            reservationService.cancelMainSeats(leg.reservationNumber, leg.reservations)
            .then(() => {
              window.location.reload();
            })
            .catch(err => {
              console.log(err);
              this.setState({loading: false});
            });
          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const lost = this.props.lost || [];
    return (
      <div className="content">
        <Grid fluid>
          {lost && <Row>
            <Col md={12}>
              <Card
                title="Lost Reservations"
                content={
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date And Time</th>
                        <th>Seats</th>
                        <th>Lost Reason</th>
                        <th>Reservation #</th>
                        <th>Actions</th>
                      </tr>
                      {lost.map((res, num) => {
                        return (
                          <tr key={num}>
                            <td><NavLink to={"/dashboard/search-reservations?column=firstName&value="+res.user.firstName+"&searchIn=reservations"}>{res.user.firstName + " " + res.user.lastName}</NavLink></td>
                            <td><NavLink to={"/dashboard/search-reservations?column=email&value="+res.user.email+"&searchIn=reservations"}>{res.user.email}</NavLink></td>
                            <td><NavLink to={"/dashboard/search-reservations?column=phone&value="+res.user.phone+"&searchIn=reservations"}>{res.user.phone}</NavLink></td>
                            <td>{moment(res.slot, 'YYYY-MM-DD').format('ddd MM-DD-YY')} at {moment(res.depart_stop.time, 'HH:mm:ss').format('hh:mm A')} from {res.depart_stop.stop.address}</td>
                            <td>{res.reservations}</td>
                            <td>{res.reason}</td>
                            <td>{config.formatReservationNumber(res.reservationNumber)}</td>
                            <td>
                              <div className="table-actions res-actions">
                                <a href="javascript:void(0)" onClick={this.move.bind(this, res)}>Move</a>
                                <a className="red" href="javascript:void(0)" onClick={this.proceedToCancel.bind(this, res)}>Cancel</a>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>}
          <Modal open={this.state.openMove} onClose={this.closeMove.bind(this)} center>
            <div className="cancelSeatsModal bookingModal">
              {this.state.selectedLeg && <h4 className="edit-existing-title">You are moving {this.state.selectedLeg.reservations} seats of the existing reservation for {this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName}, leaving from {this.state.selectedLeg.depart_stop.stop.address} at {moment(this.state.selectedLeg.depart_stop.time, 'HH:mm:ss').format('hh:mm a')} on {this.state.selectedLeg.slot}</h4>}
              {this.state.selectedLeg && <Row className="main-box-container">
                <Col md={12}>
                  <div className="main-schedule-box">
                    <div className="border-black head">
                    {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && !this.state.schedules[0].specialPrice &&
                      <div className="special-main-schedule">--Date has special schedule--</div>
                    }
                    {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && this.state.schedules[0].specialPrice &&
                      <div className="special-main-schedule">--Date has special schedule and price--</div>
                    }
                    Leaving from {this.getCityLabel(this.state.selectedLeg.depart_stop.stop.city)} on <br/> {moment(this.state.selectedLeg.slot, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')}
                    </div>
                    {this.state.schedules && this.state.schedules.map((bus, index) => {
                      return (
                        <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                          <div className="border-black bus-number">Bus #{index+1}:</div>
                          <div className="stops-group">
                            {bus.stops.map((stop, i) => {
                              return (
                                stop.type === 'PU' &&
                                <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                  {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} from {stop.stop.address}
                                  <input checked={this.state.departStop === stop.id} className="res-slot" type="radio" name="departStop" value={this.state.departStop} onChange={this.selectDepartStop.bind(this, stop, index)} />
                                </div>
                              )
                            })}
                          </div>
                          <div className="border-black">
                            <div className="total-row">Total: {bus.reservations} Max Capacity: {bus.capacity}</div>
                            <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div>
                            <div className="main-driver">Company: {bus.bus_type.company}</div>
                            <div className="main-price-label">Coupons Allowed:</div>
                            <div className="main-price-value">{bus.couponLimit}</div>
                            <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                            {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                            {bus.bus_type.defaultPrice !== bus.price && bus.firstSpecialSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSpecialSeats} seats)</div> : ''}
                            {bus.bus_type.defaultPrice !== bus.price && bus.lastSpecialSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSpecialSeats} seats)</div> : ''}
                            {bus.bus_type.defaultPrice !== bus.price && !bus.firstSpecialSeats && !bus.lastSpecialSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                            <div className="main-price-label">Bus is:</div>
                            <div className="main-price-value">{bus.isOpen ? 'Open' : 'Closed'}</div>
                            {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                            {bus.notes && <div className="main-price-value">{bus.notes}</div>}
                            <div>Destinations:
                              <div className="stops-group">
                                {bus.stops.map((stop, i) => {
                                  return (
                                    stop.type === 'DO' &&
                                    <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                      {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} at {stop.stop.address}
                                      {(this.state.arriveStop === stop.id) && <i className='fa fa-check'></i>}
                                      <div className="view-res-link">{stop.reservations}</div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}  
                  </div>
                </Col>
              </Row>}
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "Remark",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "Remark",
                    value: this.state.remark,
                    name: 'remark',
                    onChange: this.handleChange
                  }
                ]}
              />
              <Row><Col md={12}><Button disabled={this.state.loading || !this.state.departStop || !this.state.arriveStop || !this.state.remark} bsStyle="info" fill type="button" onClick={this.handleSubmit.bind(this)}>
                Move
              </Button></Col></Row>
              {this.state.loading && 
                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
              {(this.props.error || this.state.error) && <div className="error">{this.props.error || this.state.error}</div>}
              <div className="clearfix" />
            </div>
          </Modal>
          <Modal open={this.state.openSuccess} onClose={this.closeSuccess.bind(this)} center>
            <div className="cancelSeatsModal">
              <h4>Moved successfully..</h4>
              <Button bsStyle="info" fill type="button" onClick={this.closeSuccess.bind(this)}>
                OK
              </Button>
            </div>
          </Modal>
          {this.state.loading && 
            <div className="lost-loader"><img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /></div>
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { lost, loading, error } = state.reservations;
  return { lost, loading, error };
}

export default connect(mapStateToProps)(LostReservations);
