import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import couponActions from 'actions/coupon.actions';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment-timezone';
//moment.tz.setDefault('America/New_York');

//import avatar from "assets/img/faces/face-3.jpg";

class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(couponActions.getCoupon(this.props.match.params.id));

    this.state = {
      submitted: false,
      coupon: {
        active: true,
        blockForElite: false,
        code: '',
        limit: '',
        type: '',
        amount: '',
        start: moment(),
        end: moment(),
        description: '',
        couponUses: [],
        blackoutDays: []
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let coupon = this.state.coupon;
    coupon[name] = value;
    this.setState({ coupon: coupon });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.coupon.code && this.state.coupon.type && this.state.coupon.amount && this.state.coupon.limit && this.state.coupon.limit > 0 && this.state.coupon.start && this.state.coupon.end && this.state.coupon.description) {
      let data = {
        code: this.state.coupon.code,
        type: this.state.coupon.type,
        amount: this.state.coupon.amount,
        limit: this.state.coupon.limit,
        start: this.state.coupon.start.startOf('day'),
        end: this.state.coupon.end.endOf('day'),
        active: this.state.coupon.active,
        blockForElite: this.state.coupon.blockForElite,
        description: this.state.coupon.description
      };
      if(this.state.coupon.blackoutDays.length > 0) {
        for(let i = 0; i < this.state.coupon.blackoutDays.length; i++) {
          if(!this.state.coupon.blackoutDays[i].start || !this.state.coupon.blackoutDays[i].end) {
            return; 
          }
          else{
            if(!data['blackoutDays']) {
              data['blackoutDays'] = [];
            }
            var blackoutday = {
              ...this.state.coupon.blackoutDays[i],
            }
            blackoutday.start = this.state.coupon.blackoutDays[i].start.startOf('day');
            blackoutday.end = this.state.coupon.blackoutDays[i].end.endOf('day');
            data['blackoutDays'].push(blackoutday);
          }
        }
      }
      dispatch(
        couponActions.update(
          this.props.match.params.id, data
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.coupon && JSON.stringify(this.props.coupon) !== JSON.stringify(props.coupon)) {
      let coupon = props.coupon;
      coupon.start = moment(coupon.start);
      coupon.end = moment(coupon.end);
      coupon.blackoutDays = [];
      if(coupon.coupon_ranges && coupon.coupon_ranges.length) {
        for(let i = 0; i < coupon.coupon_ranges.length; i++) {
          coupon.blackoutDays[i] = {};
          coupon.blackoutDays[i].start = moment(coupon.coupon_ranges[i].start);
          coupon.blackoutDays[i].end = moment(coupon.coupon_ranges[i].end);
        }
      }
      this.setState({coupon: coupon});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Coupon updated successfully'});
    }
  }

  handleCheckbox = event => {
    let coupon = this.state.coupon;
    coupon.active = !coupon.active;
    this.setState({coupon: coupon});
  };

  handleBlock = event => {
    let coupon = this.state.coupon;
    coupon.blockForElite = !coupon.blockForElite;
    this.setState({coupon: coupon});
  };

  handleStartDate(date) {
    let coupon = this.state.coupon;
    coupon.start = date;
    this.setState({ coupon: coupon });
  }

  handleEndDate(date) {
    let coupon = this.state.coupon;
    coupon.end = date;
    this.setState({ coupon: coupon });
  }

  handleBlackStartDate(date, index) {
    //add current time to date

    let coupon = this.state.coupon;
    coupon.blackoutDays[index].start = date;
    this.setState({ coupon: coupon });
  }

  handleBlackEndDate(date, index) {
    let coupon = this.state.coupon;
    coupon.blackoutDays[index].end = date;
    this.setState({ coupon: coupon });
  }

  getReservationNumbers(group) {
    if(!group) {
      return '';
    }
    else {
      let val = group.reservation_legs[0].reservationNumber;
      if(group.reservation_legs[1]) {
        val += ' , ' + group.reservation_legs[1].reservationNumber;
      }
      return val;
    }
  }

  addBlack() {
    let coupon = this.state.coupon;
    coupon.blackoutDays.push({start: undefined, end: undefined});
    this.setState({coupon: coupon});
  }


  removeBlack(index) {
    let coupon = this.state.coupon;
    coupon.blackoutDays.splice(index, 1);
    this.setState({coupon: coupon});
  }

  render() {
    const { submitted, coupon } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Coupon"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Code",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Code",
                          value: coupon.code,
                          name: 'code',
                          onChange: this.handleChange
                        },
                        {
                          label: "Type",
                          type: "text",
                          componentClass: "select",
                          bsClass: "form-control",
                          placeholder: "Type",
                          value: coupon.type,
                          name: 'type',
                          options: [{label: "%"}, {label: "$"}],
                          onChange: this.handleChange
                        },
                        {
                          label: "Value",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Value",
                          value: coupon.amount,
                          name: 'amount',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={4}>{submitted && !coupon.code && <div className="error">Code is required</div>}</Col>
                      <Col md={4}>{submitted && !coupon.type && <div className="error">Type is required</div>}</Col>
                      <Col md={4}>{submitted && !coupon.amount && <div className="error">Value is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <ControlLabel>Start Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={coupon.start}
                          onChange={this.handleStartDate}
                        />
                      </Col>
                      <Col md={4}>
                        <ControlLabel>End Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={coupon.end}
                          onChange={this.handleEndDate}
                        />
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Limit</ControlLabel>
                          <FormControl type="number" bsClass= "form-control" min={1} name="limit" value={coupon.limit} onChange={this.handleChange}>
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={4}>{submitted && !coupon.start && <div className="error">Start date is required</div>}</Col>
                      <Col md={4}>{submitted && !coupon.end && <div className="error">End date is required</div>}</Col>
                      <Col md={4}>{submitted && (!coupon.limit || coupon.limit < 1) && <div className="error">Limit is invalid</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Description",
                          type: "number",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          placeholder: "Description",
                          value: coupon.description,
                          name: 'description',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !coupon.description && <div className="error">Description is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Checkbox
                          number='check1'
                          value={coupon.active}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={coupon.active}
                          label='Active'
                        />
                      </Col>
                      <Col md={6}>
                        <Checkbox
                          number='check2'
                          value={coupon.blockForElite}
                          onChange={this.handleBlock.bind(this)}
                          checked={coupon.blockForElite}
                          label='Block For Elite Bus'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h5 className="add-black">Add Blackout date ranges: <i className='fa fa-plus-circle' onClick={this.addBlack.bind(this)}></i></h5>
                      </Col>
                    </Row>
                    {coupon.blackoutDays.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col md={4}>
                            <ControlLabel>Start Date</ControlLabel>
                            <DatePicker
                              className="form-control"
                              selected={item.start}
                              onChange={(date) => this.handleBlackStartDate(date, index)}
                            />
                          </Col>
                          <Col md={4}>
                            <ControlLabel>End Date</ControlLabel>
                            <DatePicker
                              className="form-control"
                              selected={item.end}
                              onChange={(date) => this.handleBlackEndDate(date, index)}
                            />
                          </Col>
                          <Col md={4}>
                            <Button className="remove-black" bsStyle="danger" fill onClick={this.removeBlack.bind(this, index)}>Remove</Button>
                          </Col>
                        </Row>
                      )
                    })}
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Coupon
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Coupon Use Details"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <ReactTable
                    columns={[
                      {
                        Header: "Customer",
                        accessor: "user",
                        Cell: row => (
                          <NavLink target="_blank" to={"/dashboard/customers/"+row.original.user.id}>{row.original.user.firstName + " " + row.original.user.lastName}</NavLink>
                        )
                      },
                      {
                        Header: "Reservation Legs",
                        accessor: "reservation_group",
                        Cell: row => (
                          this.getReservationNumbers(row.original.reservation_group)
                        )
                      },
                      {
                        Header: "Used At",
                        accessor: "created_at",
                        Cell: row => (
                          <div>{moment(row.original.created_at).format('MM/DD/YY HH:mm')}</div>
                        )
                      }
                    ]}
                    data={this.state.coupon.couponUses}
                    defaultPageSize={5}
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.coupons;
  return {
    loading, coupon: item, error, success
  };
}
 
export default connect(mapStateToProps)(EditCoupon);
