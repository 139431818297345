import { GET_MAIN_REQUEST, GET_MAIN_SUCCESS, GET_MAIN_RETURN_SUCCESS, GET_MAIN_RETURN_REQUEST, GET_MAIN_FAILURE, MOVE_REQUEST, MOVE_FAILURE, MOVE_SUCCESS, SAVE_BUS_REQUEST, SAVE_BUS_SUCCESS, SAVE_BUS_FAILURE, GET_SHEET_REQUEST, GET_SHEET_SUCCESS, GET_SHEET_FAILURE, SET_MAIN_PROPS } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case SET_MAIN_PROPS:
      return {
        ...state,
        departDate: action.main.departDate,
        departCity: action.main.departCity,
        arriveCity: action.main.arriveCity,
        returnDate: action.main.returnDate,
        returnArriveCity: action.main.returnArriveCity,
        returnDepartCity: action.main.returnDepartCity
      };
    case GET_MAIN_REQUEST:
      return {
        ...state,
        loading: true,
        saveBusSuccess: false,
        moveSuccess: false,
        departingSchedules: null,
        error: ''
      };
    case GET_MAIN_RETURN_REQUEST:
      return {
        ...state,
        loading: true,
        arrivingSchedules: null
      };
    case GET_MAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        departingSchedules: action.main.data.schedules
      };
    case GET_MAIN_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        arrivingSchedules: action.main.data.schedules
      };
    case GET_MAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case MOVE_REQUEST:
      return {
        ...state,
        loading: true,
        moveError: '',
        moveSuccess: false
      };
    case MOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        moveSuccess: true
      };
    case MOVE_FAILURE:
      return {
        ...state,
        loading: false,
        moveError: action.error
      };
    case SAVE_BUS_REQUEST:
      return {
        ...state,
        loading: true,
        saveBusSuccess: false,
        saveBusError: ''
      };
    case SAVE_BUS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveBusSuccess: true
      };
    case SAVE_BUS_FAILURE:
      return {
        ...state,
        loading: false,
        saveBusError: action.error
      };
    case GET_SHEET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        sheet: action.data
      };
    case GET_SHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}