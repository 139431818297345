import { GET_BUSES_REQUEST, GET_BUSES_SUCCESS, GET_BUSES_FAILURE, GET_BUS_REQUEST, GET_BUS_SUCCESS, GET_BUS_FAILURE, ADD_BUS_REQUEST, ADD_BUS_SUCCESS, ADD_BUS_FAILURE, UPDATE_BUS_REQUEST, UPDATE_BUS_SUCCESS, UPDATE_BUS_FAILURE, DELETE_BUS_REQUEST, DELETE_BUS_SUCCESS, DELETE_BUS_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_BUSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_BUSES_SUCCESS:
      return {
        loading: false,
        items: action.buses.bus_types,
        total: action.buses.total
      };
    case GET_BUSES_FAILURE:
      return {
        error: action.error
      };
    case GET_BUS_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_BUS_SUCCESS:
      return {
        loading: false,
        item: action.bus
      };
    case GET_BUS_FAILURE:
      return {
        error: action.error
      };
    case ADD_BUS_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_BUS_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_BUS_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_BUS_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_BUS_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_BUS_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_BUS_REQUEST:
      // add 'deleting:true' property to bus being deleted
      return {
        ...state,
        items: state.items.map(bus =>
          bus.id === action.id
            ? { ...bus, deleting: true }
            : bus
        )
      };
    case DELETE_BUS_SUCCESS:
      // remove deleted bus from state
      return {
        items: state.items.filter(bus => bus.id !== action.id)
      };
    case DELETE_BUS_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to bus 
      return {
        ...state,
        items: state.items.map(bus => {
          if (bus.id === action.id) {
            // make copy of bus without 'deleting:true' property
            const { deleting, ...busCopy } = bus;
            // return copy of bus with 'deleteError:[error]' property
            return { ...busCopy, deleteError: action.error };
          }
 
          return bus;
        })
      };
    default:
      return state
  }
}