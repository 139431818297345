import { GET_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE, GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE, DELETE_ORDER_REQUEST, DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE, ADD_ORDER_REQUEST, ADD_ORDER_SUCCESS, ADD_ORDER_FAILURE, UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE } from '../constants';
import { orderService } from '../services/order.services';
 
export default {
  add,
  update,
  getOrders,
  getOrder,
  delete: _delete
};
 
function add(order) {
  return dispatch => {
    dispatch(request(order));

    orderService.add(order)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(order) { return { type: ADD_ORDER_REQUEST, order } }
  function success(order) { return { type: ADD_ORDER_SUCCESS, order } }
  function failure(error) { return { type: ADD_ORDER_FAILURE, error } }
}

function update(id, order) {
  return dispatch => {
    dispatch(request(order));

    orderService.update(id, order)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(order) { return { type: UPDATE_ORDER_REQUEST, order } }
  function success() { return { type: UPDATE_ORDER_SUCCESS } }
  function failure(error) { return { type: UPDATE_ORDER_FAILURE, error } }
}
 
function getOrders(filters) {
  return dispatch => {
    dispatch(request());
 
    orderService.getOrders(filters)
      .then(
        orders => dispatch(success(orders)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_ORDERS_REQUEST } }
  function success(orders) { return { type: GET_ORDERS_SUCCESS, orders } }
  function failure(error) { return { type: GET_ORDERS_FAILURE, error } }
}

function getOrder(id) {
  return dispatch => {
    dispatch(request());
 
    orderService.getOrder(id)
      .then(
        order => dispatch(success(order)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_ORDER_REQUEST } }
  function success(order) { return { type: GET_ORDER_SUCCESS, order } }
  function failure(error) { return { type: GET_ORDER_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    orderService.delete(id)
      .then(
        order => dispatch(success(order.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_ORDER_REQUEST, id } }
  function success(id) { return { type: DELETE_ORDER_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_ORDER_FAILURE, id, error } }
}