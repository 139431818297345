import { GET_CASH_CREDITS_REQUEST, GET_CASH_CREDITS_SUCCESS, GET_CASH_CREDITS_FAILURE, GET_CASH_CREDIT_REQUEST, GET_CASH_CREDIT_SUCCESS, GET_CASH_CREDIT_FAILURE, ADD_CASH_CREDIT_REQUEST, ADD_CASH_CREDIT_SUCCESS, ADD_CASH_CREDIT_FAILURE, UPDATE_CASH_CREDIT_REQUEST, UPDATE_CASH_CREDIT_SUCCESS, UPDATE_CASH_CREDIT_FAILURE, DELETE_CASH_CREDIT_REQUEST, DELETE_CASH_CREDIT_SUCCESS, DELETE_CASH_CREDIT_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_CASH_CREDITS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CASH_CREDITS_SUCCESS:
      return {
        loading: false,
        items: action.cashCredits.cashCredits,
        total: action.cashCredits.total
      };
    case GET_CASH_CREDITS_FAILURE:
      return {
        error: action.error
      };
    case GET_CASH_CREDIT_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_CASH_CREDIT_SUCCESS:
      return {
        loading: false,
        item: action.cashCredit
      };
    case GET_CASH_CREDIT_FAILURE:
      return {
        error: action.error
      };
    case ADD_CASH_CREDIT_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_CASH_CREDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_CASH_CREDIT_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_CASH_CREDIT_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_CASH_CREDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_CASH_CREDIT_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_CASH_CREDIT_REQUEST:
      // add 'deleting:true' property to cashCredit being deleted
      return {
        ...state,
        items: state.items.map(cashCredit =>
          cashCredit.id === action.id
            ? { ...cashCredit, deleting: true }
            : cashCredit
        )
      };
    case DELETE_CASH_CREDIT_SUCCESS:
      // remove deleted cashCredit from state
      return {
        items: state.items.filter(cashCredit => cashCredit.id !== action.id)
      };
    case DELETE_CASH_CREDIT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to cashCredit 
      return {
        ...state,
        items: state.items.map(cashCredit => {
          if (cashCredit.id === action.id) {
            // make copy of cashCredit without 'deleting:true' property
            const { deleting, ...cashCreditCopy } = cashCredit;
            // return copy of cashCredit with 'deleteError:[error]' property
            return { ...cashCreditCopy, deleteError: action.error };
          }
 
          return cashCredit;
        })
      };
    default:
      return state
  }
}