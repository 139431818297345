import { LOGOUT, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, BLOCK_USER_REQUEST, BLOCK_USER_SUCCESS, BLOCK_USER_FAILURE, ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from '../constants';
import { userService } from '../services/user.services';
 
export default {
  login,
  logout,
  add,
  update,
  getUsers,
  getUser,
  block,
  delete: _delete
};
 
function login(email, password) {
  return dispatch => {
    dispatch(request({ email }));
 
    userService.login(email, password)
      .then(
        token => { 
          dispatch(success(token));
          window.location.pathname = '/dashboard';
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };
 
  function request(user) { return { type: LOGIN_REQUEST, user } }
  function success(token) { return { type: LOGIN_SUCCESS, token } }
  function failure(error) { return { type: LOGIN_FAILURE, error } }
}
 
function logout() {
  userService.logout();
  return { type: LOGOUT };
}
 
function add(user) {
  return dispatch => {
    dispatch(request(user));
    userService.add(user)
      .then(
        user => { 
          dispatch(success());
          //dispatch(alertActions.success('User added successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };
 
  function request(user) { return { type: ADD_USER_REQUEST, user } }
  function success(user) { return { type: ADD_USER_SUCCESS, user } }
  function failure(error) { return { type: ADD_USER_FAILURE, error } }
}

function update(id, user) {
  return dispatch => {
    dispatch(request(user));
    userService.update(id, user)
      .then(
        data => { 
          dispatch(success());
          //dispatch(alertActions.success('User updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };
 
  function request(user) { return { type: UPDATE_USER_REQUEST, user } }
  function success(user) { return { type: UPDATE_USER_SUCCESS } }
  function failure(error) { return { type: UPDATE_USER_FAILURE, error } }
}
 
function getUsers(filters) {
  return dispatch => {
    dispatch(request());
 
    userService.getUsers(filters)
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_USERS_REQUEST } }
  function success(users) { return { type: GET_USERS_SUCCESS, users } }
  function failure(error) { return { type: GET_USERS_FAILURE, error } }
}

function getUser(id) {
  return dispatch => {
    dispatch(request());
 
    userService.getUser(id)
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_USER_REQUEST } }
  function success(user) { return { type: GET_USER_SUCCESS, user } }
  function failure(error) { return { type: GET_USER_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    userService.delete(id)
      .then(
        user => dispatch(success(user.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_USER_REQUEST, id } }
  function success(id) { return { type: DELETE_USER_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_USER_FAILURE, id, error } }
}

function block(id, val) {
  return dispatch => {
    dispatch(request(id));
 
    userService.update(id, {blocked: val})
      .then(
        user => dispatch(success(id, val)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: BLOCK_USER_REQUEST, id } }
  function success(id, val) { return { type: BLOCK_USER_SUCCESS, id, blocked: val } }
  function failure(id, error) { return { type: BLOCK_USER_FAILURE, id, error } }
}