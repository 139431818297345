export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';
export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const BLOCK_CUSTOMER_SUCCESS = 'BLOCK_CUSTOMER_SUCCESS';
export const BLOCK_CUSTOMER_REQUEST = 'BLOCK_CUSTOMER_REQUEST';
export const BLOCK_CUSTOMER_FAILURE = 'BLOCK_CUSTOMER_FAILURE';

export const ADD_STOP_REQUEST = 'ADD_STOP_REQUEST';
export const ADD_STOP_SUCCESS = 'ADD_STOP_SUCCESS';
export const ADD_STOP_FAILURE = 'ADD_STOP_FAILURE';
export const UPDATE_STOP_REQUEST = 'UPDATE_STOP_REQUEST';
export const UPDATE_STOP_SUCCESS = 'UPDATE_STOP_SUCCESS';
export const UPDATE_STOP_FAILURE = 'UPDATE_STOP_FAILURE';
export const GET_STOPS_SUCCESS = 'GET_STOPS_SUCCESS';
export const GET_STOPS_FAILURE = 'GET_STOPS_FAILURE';
export const GET_STOPS_REQUEST = 'GET_STOPS_REQUEST';
export const GET_STOP_SUCCESS = 'GET_STOP_SUCCESS';
export const GET_STOP_FAILURE = 'GET_STOP_FAILURE';
export const GET_STOP_REQUEST = 'GET_STOP_REQUEST';
export const DELETE_STOP_SUCCESS = 'DELETE_STOP_SUCCESS';
export const DELETE_STOP_REQUEST = 'DELETE_STOP_REQUEST';
export const DELETE_STOP_FAILURE = 'DELETE_STOP_FAILURE';

export const ADD_BUS_REQUEST = 'ADD_BUS_REQUEST';
export const ADD_BUS_SUCCESS = 'ADD_BUS_SUCCESS';
export const ADD_BUS_FAILURE = 'ADD_BUS_FAILURE';
export const UPDATE_BUS_REQUEST = 'UPDATE_BUS_REQUEST';
export const UPDATE_BUS_SUCCESS = 'UPDATE_BUS_SUCCESS';
export const UPDATE_BUS_FAILURE = 'UPDATE_BUS_FAILURE';
export const GET_BUSES_SUCCESS = 'GET_BUSES_SUCCESS';
export const GET_BUSES_FAILURE = 'GET_BUSES_FAILURE';
export const GET_BUSES_REQUEST = 'GET_BUSES_REQUEST';
export const GET_BUS_SUCCESS = 'GET_BUS_SUCCESS';
export const GET_BUS_FAILURE = 'GET_BUS_FAILURE';
export const GET_BUS_REQUEST = 'GET_BUS_REQUEST';
export const DELETE_BUS_SUCCESS = 'DELETE_BUS_SUCCESS';
export const DELETE_BUS_REQUEST = 'DELETE_BUS_REQUEST';
export const DELETE_BUS_FAILURE = 'DELETE_BUS_FAILURE';

export const ADD_SCHEDULE_REQUEST = 'ADD_SCHEDULE_REQUEST';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAILURE = 'ADD_SCHEDULE_FAILURE';
export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAILURE = 'UPDATE_SCHEDULE_FAILURE';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_FAILURE = 'GET_SCHEDULES_FAILURE';
export const GET_SCHEDULESUMMARY_SUCCESS = 'GET_SCHEDULESUMMARY_SUCCESS';
export const GET_SCHEDULESUMMARY_FAILURE = 'GET_SCHEDULESUMMARY_FAILURE';
export const GET_SCHEDULES_REQUEST = 'GET_SCHEDULES_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE';
export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE';

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE';
export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';
export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';
export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_FAILURE = 'ADD_PAYMENT_FAILURE';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';
export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';

export const ADD_RESERVATION_REQUEST = 'ADD_RESERVATION_REQUEST';
export const ADD_RESERVATION_SUCCESS = 'ADD_RESERVATION_SUCCESS';
export const ADD_RESERVATION_FAILURE = 'ADD_RESERVATION_FAILURE';
export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_FAILURE = 'UPDATE_RESERVATION_FAILURE';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_FAILURE = 'GET_RESERVATIONS_FAILURE';
export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST';
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS';
export const GET_RESERVATION_FAILURE = 'GET_RESERVATION_FAILURE';
export const GET_RESERVATION_REQUEST = 'GET_RESERVATION_REQUEST';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST';
export const DELETE_RESERVATION_FAILURE = 'DELETE_RESERVATION_FAILURE';
export const GET_MAIN_RESERVATIONS_SUCCESS = 'GET_MAIN_RESERVATIONS_SUCCESS';
export const GET_MAIN_RESERVATIONS_FAILURE = 'GET_MAIN_RESERVATIONS_FAILURE';
export const GET_MAIN_RESERVATIONS_REQUEST = 'GET_MAIN_RESERVATIONS_REQUEST';
export const GET_LOST_RESERVATIONS_SUCCESS = 'GET_LOST_RESERVATIONS_SUCCESS';
export const GET_LOST_RESERVATIONS_FAILURE = 'GET_LOST_RESERVATIONS_FAILURE';
export const GET_LOST_RESERVATIONS_REQUEST = 'GET_LOST_RESERVATIONS_REQUEST';
export const DELETE_RESERVATIONS_SUCCESS = 'DELETE_RESERVATIONS_SUCCESS';
export const DELETE_RESERVATIONS_REQUEST = 'DELETE_RESERVATIONS_REQUEST';
export const DELETE_RESERVATIONS_FAILURE = 'DELETE_RESERVATIONS_FAILURE';
export const SET_MAIN_PROPS = 'SET_MAIN_PROPS';

export const ADD_RESERVATION_REMARK_REQUEST = 'ADD_RESERVATION_REMARK_REQUEST';
export const ADD_RESERVATION_REMARK_SUCCESS = 'ADD_RESERVATION_REMARK_SUCCESS';
export const ADD_RESERVATION_REMARK_FAILURE = 'ADD_RESERVATION_REMARK_FAILURE';
export const UPDATE_RESERVATION_REMARK_REQUEST = 'UPDATE_RESERVATION_REMARK_REQUEST';
export const UPDATE_RESERVATION_REMARK_SUCCESS = 'UPDATE_RESERVATION_REMARK_SUCCESS';
export const UPDATE_RESERVATION_REMARK_FAILURE = 'UPDATE_RESERVATION_REMARK_FAILURE';
export const GET_RESERVATION_REMARKS_SUCCESS = 'GET_RESERVATION_REMARKS_SUCCESS';
export const GET_RESERVATION_REMARKS_FAILURE = 'GET_RESERVATION_REMARKS_FAILURE';
export const GET_RESERVATION_REMARKS_REQUEST = 'GET_RESERVATION_REMARKS_REQUEST';
export const GET_RESERVATION_REMARK_SUCCESS = 'GET_RESERVATION_REMARK_SUCCESS';
export const GET_RESERVATION_REMARK_FAILURE = 'GET_RESERVATION_REMARK_FAILURE';
export const GET_RESERVATION_REMARK_REQUEST = 'GET_RESERVATION_REMARK_REQUEST';
export const DELETE_RESERVATION_REMARK_SUCCESS = 'DELETE_RESERVATION_REMARK_SUCCESS';
export const DELETE_RESERVATION_REMARK_REQUEST = 'DELETE_RESERVATION_REMARK_REQUEST';
export const DELETE_RESERVATION_REMARK_FAILURE = 'DELETE_RESERVATION_REMARK_FAILURE';

export const ADD_ORDER_ITEM_REQUEST = 'ADD_ORDER_ITEM_REQUEST';
export const ADD_ORDER_ITEM_SUCCESS = 'ADD_ORDER_ITEM_SUCCESS';
export const ADD_ORDER_ITEM_FAILURE = 'ADD_ORDER_ITEM_FAILURE';
export const UPDATE_ORDER_ITEM_REQUEST = 'UPDATE_ORDER_ITEM_REQUEST';
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS';
export const UPDATE_ORDER_ITEM_FAILURE = 'UPDATE_ORDER_ITEM_FAILURE';
export const GET_ORDER_ITEMS_SUCCESS = 'GET_ORDER_ITEMS_SUCCESS';
export const GET_ORDER_ITEMS_FAILURE = 'GET_ORDER_ITEMS_FAILURE';
export const GET_ORDER_ITEMS_REQUEST = 'GET_ORDER_ITEMS_REQUEST';
export const GET_ORDER_ITEM_SUCCESS = 'GET_ORDER_ITEM_SUCCESS';
export const GET_ORDER_ITEM_FAILURE = 'GET_ORDER_ITEM_FAILURE';
export const GET_ORDER_ITEM_REQUEST = 'GET_ORDER_ITEM_REQUEST';
export const DELETE_ORDER_ITEM_SUCCESS = 'DELETE_ORDER_ITEM_SUCCESS';
export const DELETE_ORDER_ITEM_REQUEST = 'DELETE_ORDER_ITEM_REQUEST';
export const DELETE_ORDER_ITEM_FAILURE = 'DELETE_ORDER_ITEM_FAILURE';

export const ADD_VOUCHER_REQUEST = 'ADD_VOUCHER_REQUEST';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILURE = 'ADD_VOUCHER_FAILURE';
export const UPDATE_VOUCHER_REQUEST = 'UPDATE_VOUCHER_REQUEST';
export const UPDATE_VOUCHER_SUCCESS = 'UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAILURE = 'UPDATE_VOUCHER_FAILURE';
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_FAILURE = 'GET_VOUCHERS_FAILURE';
export const GET_VOUCHERS_REQUEST = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_FAILURE = 'GET_VOUCHER_FAILURE';
export const GET_VOUCHER_REQUEST = 'GET_VOUCHER_REQUEST';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHER_REQUEST';
export const DELETE_VOUCHER_FAILURE = 'DELETE_VOUCHER_FAILURE';

export const ADD_CASH_CREDIT_REQUEST = 'ADD_CASH_CREDIT_REQUEST';
export const ADD_CASH_CREDIT_SUCCESS = 'ADD_CASH_CREDIT_SUCCESS';
export const ADD_CASH_CREDIT_FAILURE = 'ADD_CASH_CREDIT_FAILURE';
export const UPDATE_CASH_CREDIT_REQUEST = 'UPDATE_CASH_CREDIT_REQUEST';
export const UPDATE_CASH_CREDIT_SUCCESS = 'UPDATE_CASH_CREDIT_SUCCESS';
export const UPDATE_CASH_CREDIT_FAILURE = 'UPDATE_CASH_CREDIT_FAILURE';
export const GET_CASH_CREDITS_SUCCESS = 'GET_CASH_CREDITS_SUCCESS';
export const GET_CASH_CREDITS_FAILURE = 'GET_CASH_CREDITS_FAILURE';
export const GET_CASH_CREDITS_REQUEST = 'GET_CASH_CREDITS_REQUEST';
export const GET_CASH_CREDIT_SUCCESS = 'GET_CASH_CREDIT_SUCCESS';
export const GET_CASH_CREDIT_FAILURE = 'GET_CASH_CREDIT_FAILURE';
export const GET_CASH_CREDIT_REQUEST = 'GET_CASH_CREDIT_REQUEST';
export const DELETE_CASH_CREDIT_SUCCESS = 'DELETE_CASH_CREDIT_SUCCESS';
export const DELETE_CASH_CREDIT_REQUEST = 'DELETE_CASH_CREDIT_REQUEST';
export const DELETE_CASH_CREDIT_FAILURE = 'DELETE_CASH_CREDIT_FAILURE';

export const ADD_GIFT_CARD_REQUEST = 'ADD_GIFT_CARD_REQUEST';
export const ADD_GIFT_CARD_SUCCESS = 'ADD_GIFT_CARD_SUCCESS';
export const ADD_GIFT_CARD_FAILURE = 'ADD_GIFT_CARD_FAILURE';
export const UPDATE_GIFT_CARD_REQUEST = 'UPDATE_GIFT_CARD_REQUEST';
export const UPDATE_GIFT_CARD_SUCCESS = 'UPDATE_GIFT_CARD_SUCCESS';
export const UPDATE_GIFT_CARD_FAILURE = 'UPDATE_GIFT_CARD_FAILURE';
export const GET_GIFT_CARDS_SUCCESS = 'GET_GIFT_CARDS_SUCCESS';
export const GET_GIFT_CARDS_FAILURE = 'GET_GIFT_CARDS_FAILURE';
export const GET_GIFT_CARDS_REQUEST = 'GET_GIFT_CARDS_REQUEST';
export const GET_GIFT_CARD_SUCCESS = 'GET_GIFT_CARD_SUCCESS';
export const GET_GIFT_CARD_FAILURE = 'GET_GIFT_CARD_FAILURE';
export const GET_GIFT_CARD_REQUEST = 'GET_GIFT_CARD_REQUEST';
export const DELETE_GIFT_CARD_SUCCESS = 'DELETE_GIFT_CARD_SUCCESS';
export const DELETE_GIFT_CARD_REQUEST = 'DELETE_GIFT_CARD_REQUEST';
export const DELETE_GIFT_CARD_FAILURE = 'DELETE_GIFT_CARD_FAILURE';

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAILURE = 'ADD_PERMISSION_FAILURE';
export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';

export const ADD_NOTICE_REQUEST = 'ADD_NOTICE_REQUEST';
export const ADD_NOTICE_SUCCESS = 'ADD_NOTICE_SUCCESS';
export const ADD_NOTICE_FAILURE = 'ADD_NOTICE_FAILURE';
export const UPDATE_NOTICE_REQUEST = 'UPDATE_NOTICE_REQUEST';
export const UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE_SUCCESS';
export const UPDATE_NOTICE_FAILURE = 'UPDATE_NOTICE_FAILURE';
export const GET_NOTICES_SUCCESS = 'GET_NOTICES_SUCCESS';
export const GET_NOTICES_FAILURE = 'GET_NOTICES_FAILURE';
export const GET_NOTICES_REQUEST = 'GET_NOTICES_REQUEST';
export const GET_NOTICE_SUCCESS = 'GET_NOTICE_SUCCESS';
export const GET_NOTICE_FAILURE = 'GET_NOTICE_FAILURE';
export const GET_NOTICE_REQUEST = 'GET_NOTICE_REQUEST';
export const DELETE_NOTICE_SUCCESS = 'DELETE_NOTICE_SUCCESS';
export const DELETE_NOTICE_REQUEST = 'DELETE_NOTICE_REQUEST';
export const DELETE_NOTICE_FAILURE = 'DELETE_NOTICE_FAILURE';

export const GET_MAIN_REQUEST = 'GET_MAIN_REQUEST';
export const GET_MAIN_RETURN_REQUEST = 'GET_MAIN_RETURN_REQUEST';
export const GET_MAIN_SUCCESS = 'GET_MAIN_SUCCESS';
export const GET_MAIN_RETURN_SUCCESS = 'GET_MAIN_RETURN_SUCCESS';
export const GET_MAIN_FAILURE = 'GET_MAIN_FAILURE';
export const MOVE_REQUEST = 'MOVE_REQUEST';
export const MOVE_SUCCESS = 'MOVE_SUCCESS';
export const MOVE_FAILURE = 'MOVE_FAILURE';
export const SAVE_BUS_REQUEST = 'SAVE_BUS_REQUEST';
export const SAVE_BUS_SUCCESS = 'SAVE_BUS_SUCCESS';
export const SAVE_BUS_FAILURE = 'SAVE_BUS_FAILURE';
export const GET_SHEET_REQUEST = 'GET_SHEET_REQUEST';
export const GET_SHEET_SUCCESS = 'GET_SHEET_SUCCESS';
export const GET_SHEET_FAILURE = 'GET_SHEET_FAILURE';

export const UPDATE_TOPBAR_REQUEST = 'UPDATE_TOPBAR_REQUEST';
export const UPDATE_TOPBAR_SUCCESS = 'UPDATE_TOPBAR_SUCCESS';
export const UPDATE_TOPBAR_FAILURE = 'UPDATE_TOPBAR_FAILURE';
export const GET_TOPBAR_SUCCESS = 'GET_TOPBAR_SUCCESS';
export const GET_TOPBAR_FAILURE = 'GET_TOPBAR_FAILURE';
export const GET_TOPBAR_REQUEST = 'GET_TOPBAR_REQUEST';

export const LOGOUT = 'LOGOUT';
