import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import voucherActions from 'actions/voucher.actions';
import userActions from 'actions/user.actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';

//import avatar from "assets/img/faces/face-3.jpg";

class EditVoucher extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(voucherActions.getVoucher(this.props.match.params.id));

    this.state = {
      submitted: false,
      voucher: {
        user: '',
        userInput: '',
        points: '',
        description: ''
      },
      suggestions: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.userSelected = this.userSelected.bind(this);
    this.onUsersFetchRequested = this.onUsersFetchRequested.bind(this);
    this.onUsersClearRequested = this.onUsersClearRequested.bind(this);
    this.fetchUsersWithDebounce = _.debounce(this.onUsersFetchRequested, 1500);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let voucher = this.state.voucher;
    voucher[name] = value;
    this.setState({ voucher: voucher });
  }

  userSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    let voucher = this.state.voucher;
    voucher.user = suggestionValue;
    voucher.userInput = suggestion.firstName + " " + suggestion.lastName;
    this.setState({voucher: voucher});
  }

  onUsersFetchRequested = ({ value }) => {
    if(this.state.voucher.user && value) {
      if(_.find(this.state.suggestions, {id: this.state.voucher.user, firstName: value.split(" ")[0], lastName: value.split(" ")[1]})) {
        this.setState({suggestions: []});
        return;
      }
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength === 0) {
      this.setState({suggestions: []});
      return;
    }
    if(inputLength >= 3) {
      if(inputValue.indexOf(" ") > -1) {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue.split(" ")[0]}, {id: 'lastName', value: inputValue.split(" ")[1]}])}));
      }
      else {
        this.props.dispatch(userActions.getUsers({filtered: JSON.stringify([{id: 'firstName', value: inputValue}])})); 
      }
    }
  };

  onUsersClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.voucher.user && this.state.voucher.description && this.state.voucher.points) {
      dispatch(
        voucherActions.update(
          this.props.match.params.id,
          {
            user: this.state.voucher.user,
            points: this.state.voucher.points,
            description: this.state.voucher.description
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.voucher && JSON.stringify(this.props.voucher) !== JSON.stringify(props.voucher)) {
      let voucher = props.voucher;
      voucher.userInput = voucher.user.firstName + " " + voucher.user.lastName;
      voucher.user = voucher.user.id;
      this.setState({voucher: voucher});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Voucher updated successfully'});
    }
    if(props.users && JSON.stringify(props.users) !== JSON.stringify(this.state.suggestions)) {
      this.setState({suggestions: props.users});
    }
  }

  render() {
    const { submitted, voucher, suggestions } = this.state;
    const { loading } = this.props;
    const getSuggestionValue = suggestion => suggestion.id;
    const renderSuggestion = suggestion => (
      <div>
        {suggestion.firstName + " " + suggestion.lastName}
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Points"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>User</ControlLabel>
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.fetchUsersWithDebounce}
                            onSuggestionsClearRequested={this.onUsersClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={this.userSelected}
                            inputProps={{
                              name: 'userInput',
                              value: voucher.userInput,
                              placeholder: 'User',
                              onChange: this.handleChange,
                              className: 'form-control'
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>Points</ControlLabel>
                          <FormControl placeholder="Points" type="number" bsClass= "form-control" name="points" value={voucher.points} onChange={this.handleChange}></FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="error-row">
                      <Col md={6}>{submitted && !voucher.user && <div className="error">User is required</div>}</Col>
                      <Col md={6}>{submitted && !voucher.points && <div className="error">Points is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Description",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          placeholder: "Description",
                          value: voucher.description,
                          name: 'description',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !voucher.description && <div className="error">Description is required</div>}</Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Points
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.vouchers;
  return {
    loading, voucher: item, error, success, users: state.users.items
  };
}
 
export default connect(mapStateToProps)(EditVoucher);
