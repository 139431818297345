import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Badge
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import scheduleActions from 'actions/schedule.actions';
import stopActions from 'actions/stop.actions';
import busActions from 'actions/bus.actions';
import { reservationService } from 'services/reservation.services';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import _ from 'lodash';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment-timezone';
import CustomPrice from "../../components/Popup/CustomPrice";
import { constructPricesMap } from "../../helpers/utility";

//moment.tz.setDefault('America/New_York');

function queryStringToJSON(param) {            
  var pairs = param.slice(1).split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
}

//import avatar from "assets/img/faces/face-3.jpg";

class AddSchedule extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(stopActions.getStops({}));
    this.props.dispatch(busActions.getBuses({}));
    this.state = {
      submitted: false,
      formError: false,
      departCity: '',
      schedule: {
        dayOfWeek: '',
        isSpecial: false,
        name: '',
        specialDate: moment(),
        start: moment(),
        end: moment(),
        schedule_buses: []
      },
      stops: [],
      buses: [],
      activeReservations: 0,
      activeSeats: 0,
      legsAndBusesMapping: [],
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      showModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDepartStopChange = this.handleDepartStopChange.bind(this);
    this.handleDestinationStopChange = this.handleDestinationStopChange.bind(this);
    this.handleBusChange = this.handleBusChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleSpecialDate = this.handleSpecialDate.bind(this);
    this.handleDepartStopTime = this.handleDepartStopTime.bind(this);
    this.handleDestinationStopTime = this.handleDestinationStopTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getLetters(letter) {
    let arr = [];
    let usedLetters = this.state.schedule.schedule_buses.map((data)=>{
      return data.letter;
    })
    let filteredLetters = this.state.letters.filter((l)=>{
        if(l == letter){
          return true
        }
        return !usedLetters.includes(l);
    })

    for(let i = 0; i < filteredLetters.length; i++) {
      arr.push({label: filteredLetters[i], value: filteredLetters[i]});
    }

    return arr;
  }

  findBusTypeById(id){
    return _.find(this.state.buses, {id: id}).type
  }

  componentDidMount() {
    if(this.props.location.search) {
      let search = queryStringToJSON(this.props.location.search);
      let schedule = this.state.schedule;
      if(search) {
        if(search.departCity) {
          this.setState({departCity: search.departCity});
        }
        if(search.isSpecial) {
          schedule.isSpecial = true;
          schedule.specialDate = moment(search.date, 'MM/DD/YY');
          let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          schedule.dayOfWeek = days[moment(schedule.specialDate).day()];
        }
        if(search.date) {
          schedule.start = moment(search.date, 'MM/DD/YY');
          if(search.isSpecial) {
            schedule.end = moment(search.date, 'MM/DD/YY');
          }
          else {
            schedule.end = moment(search.date, 'MM/DD/YY').add(29, 'days');
          }
          let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          schedule.dayOfWeek = days[moment(schedule.start).day()];
        }
        this.setState({schedule: schedule}, () => {
          if(search.id) {
            this.props.dispatch(scheduleActions.getSchedule(search.id));
            reservationService.getScheduleReservations({schedule: search.id, start: search.date, end: moment(schedule.end).format('MM/DD/YY'), city: search.departCity, special: (search.isSpecial ? 'yes' : 'no'), day: schedule.dayOfWeek})
            .then(result => {
              console.log(result);
              this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
            })
            .catch(err => {
              console.log(err)
            })
          }
        });
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    schedule[name] = value;
    this.setState({ schedule: schedule });
  }

  handleDepartStopChange(e, index, busIndex) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    let selectedValue = _.find(schedule.schedule_buses[busIndex].departStops, {stop: value})
    if(name === 'stop' && !selectedValue  || selectedValue && selectedValue.deleted) {
      schedule.schedule_buses[busIndex].departStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
    else if(name !== 'stop') {
      schedule.schedule_buses[busIndex].departStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
  }

  handleDestinationStopChange(e, index, busIndex) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    let selectedValueDepart = _.find(schedule.schedule_buses[busIndex].departStops, {stop: value})
    let selectedValueDestination = _.find(schedule.schedule_buses[busIndex].destinationStops, {stop: value})
    if((name === 'stop' && !selectedValueDestination && !selectedValueDepart) ||  (selectedValueDepart && selectedValueDepart.deleted  || selectedValueDestination && selectedValueDestination.deleted )  ) {
      schedule.schedule_buses[busIndex].destinationStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
    else if(name !== 'stop') {
      schedule.schedule_buses[busIndex].destinationStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
  }

  handleBusChange(index, e) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    if(!value) return
    
    if(name==='bus_type' && this.findBusTypeById(value) === 'Elite' && schedule.schedule_buses[index].bookedSeats >= 34){
      alert("Cannot switch")
      return;
    }

    schedule.schedule_buses[index][name] = value;
    this.setState({ schedule: schedule });
  }

  handleStartDate(date) {
    let schedule = this.state.schedule;
    schedule.start = date;
    this.setState({ schedule: schedule });
    if(queryStringToJSON(this.props.location.search).id) {
      let search = queryStringToJSON(this.props.location.search);
      reservationService.getScheduleReservations({schedule: search.id, start: moment(date).format('MM/DD/YY'), end: moment(this.state.schedule.end).format('MM/DD/YY'), city: search.departCity, special: (schedule.isSpecial ? 'yes' : 'no'), day: schedule.dayOfWeek})
      .then(result => {
        console.log(result);
        this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  handleEndDate(date) {
    let schedule = this.state.schedule;
    schedule.end = date;
    this.setState({ schedule: schedule });
    if(queryStringToJSON(this.props.location.search).id) {
      let search = queryStringToJSON(this.props.location.search);
      reservationService.getScheduleReservations({schedule: search.id, end: moment(date).format('MM/DD/YY'), start: moment(this.state.schedule.start).format('MM/DD/YY'), city: search.departCity, special: (schedule.isSpecial ? 'yes' : 'no'), day: schedule.dayOfWeek})
      .then(result => {
        console.log(result);
        this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  handleSpecialDate(date) {
    let schedule = this.state.schedule;
    schedule.specialDate = date;
    schedule.start = date;
    schedule.end = date;
    this.setState({ schedule: schedule });
    if(queryStringToJSON(this.props.location.search).id) {
      let search = queryStringToJSON(this.props.location.search);
      reservationService.getScheduleReservations({schedule: search.id, start: moment(date).format('MM/DD/YY'), end: moment(date).format('MM/DD/YY'), city: search.departCity, special: (schedule.isSpecial ? 'yes' : 'no')})
      .then(result => {
        console.log(result);
        this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  handleDepartStopTime(time, index, busIndex) {
    let schedule = this.state.schedule;
    
    // let incrementDuration = {
    //   'new york': {
    //     'arlington': 4.667,
    //     'bethesda': 4,
    //     'washington': 4
    //   },
    //   'arlington': {
    //     'new york': 4.667,
    //     'bethesda': 0.667,
    //     'washington': 0.667
    //   },
    //   'bethesda': {
    //     'new york': 4,
    //     'arlington': 0.667,
    //     'washington': 0.667
    //   },
    //   'washington': {
    //     'new york': 4,
    //     'arlington': 0.667,
    //     'bethesda': 0.667
    //   }
    // }
  
    schedule.schedule_buses[busIndex].departStops[index].time = time;
    
    // let mainDepartStop = this.getStopFromCode(schedule.schedule_buses[busIndex].departStops[index].stop) || 'new york'

    // if(schedule.schedule_buses[busIndex] && schedule.schedule_buses[busIndex].destinationStops[index]){
    //   schedule.schedule_buses[busIndex].destinationStops.map((stop, index)=>{
    //     let stopName = this.getStopFromCode(stop.stop);
    //       stop.time = moment(time).add('hours', incrementDuration[mainDepartStop][stopName]);
    //   })
    // }

    // if(
    //   (mainDepartStop== 'arlington')
    //     &&
    //     schedule.schedule_buses[busIndex].departStops.length >= 2
    //   ){
    //   schedule.schedule_buses[busIndex].departStops[1].time = moment(time).add(0.667, 'hours');
    // }

    this.setState({ schedule: schedule });

  }

  handleDestinationStopTime(time, index, busIndex) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[busIndex].destinationStops[index].time = time;
    this.setState({ schedule: schedule });
  }

  handleCheckbox = event => {
    let schedule = this.state.schedule;
    schedule.isSpecial = !schedule.isSpecial;
    if(schedule.isSpecial) {
      schedule.end = schedule.start;
    }
    this.setState({schedule: schedule});
    if(queryStringToJSON(this.props.location.search).id) {
      let search = queryStringToJSON(this.props.location.search);
      if(schedule.isSpecial) {
        reservationService.getScheduleReservations({schedule: search.id, start: moment(schedule.specialDate).format('MM/DD/YY'), end: moment(schedule.specialDate).format('MM/DD/YY'), city: search.departCity, special: (schedule.isSpecial ? 'yes' : 'no')})
        .then(result => {
          console.log(result);
          this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
        })
        .catch(err => {
          console.log(err)
        })
      }
      else {
        reservationService.getScheduleReservations({schedule: search.id, start: moment(schedule.start).format('MM/DD/YY'), end: moment(schedule.end).format('MM/DD/YY'), city: search.departCity, special: (schedule.isSpecial ? 'yes' : 'no'), day: schedule.dayOfWeek})
        .then(result => {
          console.log(result);
          this.setState({activeReservations: result.reservations, activeSeats: result.seats, legsAndBusesMapping: result.legsAndBusesMapping});
        })
        .catch(err => {
          console.log(err)
        })
      }
    }
  };

  handleCouponCheckbox(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].couponAllowed = !schedule.schedule_buses[index].couponAllowed;
    this.setState({schedule: schedule});
  }

  handleOpenCheckbox(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].isOpen = !schedule.schedule_buses[index].isOpen;
    this.setState({schedule: schedule});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, formError: false });
    const { dispatch } = this.props;
    if (this.state.schedule.start && this.state.schedule.end) {
      if(this.state.schedule.schedule_buses.length === 0) {
        this.setState({ formError: true });
        return;
      }
      if(this.state.schedule.schedule_buses.length > 0) {
        let invalidBus = false;
        this.state.schedule.schedule_buses.map(bus => {
          if(!bus.bus_type) {
            invalidBus = true;
          }

          if(!bus.letter && bus.deleted === false){
            invalidBus = true;
          }
          else if(!bus.couponAllowed && bus.couponLimit !== '' && bus.couponLimit < 1 ) {
            invalidBus = true;
          }
          else if(bus.departStops.length === 0) {
            invalidBus = true;
          }
          else if(bus.destinationStops.length === 0) {
            invalidBus = true;
          }
          else if(bus.departStops.length > 0){
            bus.departStops.map(stop => {
              if(!stop.stop || !stop.time) {
                invalidBus = true;
              }
              return stop;
            }); 
          }
          else if(bus.destinationStops.length > 0){
            bus.destinationStops.map(stop => {
              if(!stop.stop || !stop.time) {
                invalidBus = true;
              }
              return stop;
            }); 
          }
          return bus;
        });
        if(invalidBus) {
          this.setState({ formError: true });
          return;
        }
      }

      if(!this.state.schedule.isSpecial && !this.state.schedule.dayOfWeek) {
        this.setState({ formError: true });
        return;
      }

      let dataToSend = {
        dayOfWeek: this.state.schedule.dayOfWeek,
        start: moment(this.state.schedule.start).format('YYYY-MM-DD'),
        end: moment(this.state.schedule.end).format('YYYY-MM-DD')
      };
      if(this.state.schedule.isSpecial){
        dataToSend.isSpecial = true;
        dataToSend.name = this.state.schedule.name || '';
        dataToSend.specialDate = moment(this.state.schedule.specialDate).format('YYYY-MM-DD');
      }
      if(queryStringToJSON(this.props.location.search).id && this.state.activeReservations > 0) {
        dataToSend.existing = queryStringToJSON(this.props.location.search).id;
        dataToSend.legIdsToMove = this.state.legsAndBusesMapping.map(item => item.id);
      }
      if(this.state.schedule.schedule_buses && this.state.schedule.schedule_buses.length > 0) {
        dataToSend.schedule_buses = JSON.parse(JSON.stringify(this.state.schedule.schedule_buses));
        dataToSend.schedule_buses = dataToSend.schedule_buses.map(bus => {
          bus.priceOverride = bus.priceOverride || null;
          bus.firstSpecialSeats = bus.firstSpecialSeats || null;
          bus.lastSpecialSeats = bus.lastSpecialSeats || null;
          bus.firstSpecialSeatsPrice = bus.firstSpecialSeatsPrice || null;
          bus.lastSpecialSeatsPrice = bus.lastSpecialSeatsPrice || null;
          bus.couponLimit = bus.couponLimit || null;
          bus.schedule_bus_stops = [];
          if(bus.departStops && bus.departStops.length > 0) {
            for(let i = 0; i < bus.departStops.length; i++) {
              bus.schedule_bus_stops.push({
                time: moment(bus.departStops[i].time).format('HH:mm:ss'),
                pickOrDrop: 'PU',
                stop: bus.departStops[i].stop
              })
            }
          }
          if(bus.destinationStops && bus.destinationStops.length > 0) {
            for(let i = 0; i < bus.destinationStops.length; i++) {
              bus.schedule_bus_stops.push({
                time: moment(bus.destinationStops[i].time).format('HH:mm:ss'),
                pickOrDrop: 'DO',
                stop: bus.destinationStops[i].stop
              })
            }
          }
          delete bus['departStops'];
          delete bus['destinationStops'];
          delete bus['bookedSeats']
          return bus;
        });
      }
      dispatch(scheduleActions.add(dataToSend));
    }
  }

  addDestinationStop(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].destinationStops.push({
      time: '',
      stop: ''
    });
    this.setState({schedule: schedule});
  }

    addMiddleDestinationBethesda(index) {
    let schedule = this.state.schedule;
    if(schedule.schedule_buses[index].destinationStops.length === 1){
      const stopName = this.getStopFromCode(schedule.schedule_buses[index].destinationStops[0].stop)
      if(stopName == 'arlington'){
        let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
        let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
        if(schedule.schedule_buses[index].destinationStops.length < 2){
          schedule.schedule_buses[index].destinationStops.unshift({
          time: moment(firstDepartureAddressTime).add(4,'hours'),
          stop: defaultStop1.id
        });
     }
    }
    else if(stopName == 'bethesda'){
      let defaultStop1 = _.find(this.state.stops, {city: 'arlington'});
      let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
      if(schedule.schedule_buses[index].destinationStops.length < 2){
        schedule.schedule_buses[index].destinationStops.push({
        time: moment(firstDepartureAddressTime).add(4.667,'hours'),
        stop: defaultStop1.id
      });
    }
    }
  }
  this.setState({schedule: schedule});

  }

  addDepartStop(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].departStops.push({
      time: '',
      stop: ''
    });
    this.setState({schedule: schedule});
  }

  addBethesdaStop(index) {
    let schedule = this.state.schedule;
    if(schedule.schedule_buses[index].departStops.length === 1){
      const stopName = this.getStopFromCode(schedule.schedule_buses[index].departStops[0].stop)
      if(stopName == 'arlington'){
        let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
        let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
        if(schedule.schedule_buses[index].departStops.length < 2){
          schedule.schedule_buses[index].departStops.push({
          time: moment(firstDepartureAddressTime).add(0.667,'hours'),
          stop: defaultStop1.id
        });
     }
    }
    else if(stopName == 'bethesda'){
      let defaultStop1 = _.find(this.state.stops, {city: 'arlington'});
      let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
      if(schedule.schedule_buses[index].departStops.length < 2){
        schedule.schedule_buses[index].departStops.unshift({
        time: moment(firstDepartureAddressTime).subtract(0.667, 'hours'),
        stop: defaultStop1.id
      });
    }
    }
  }
    this.setState({schedule: schedule});
  }

  removeDepartStop(index, busIndex) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[busIndex].departStops.splice(index, 1);
    this.updatePriceData(busIndex,   schedule.schedule_buses[busIndex])
    this.setState({schedule: schedule});
  }

  removeDestinationStop(index, busIndex) {
    // let schedule = this.state.schedule;
    // schedule.schedule_buses[busIndex].destinationStops.splice(index, 1);
    // this.setState({schedule: schedule});
    let schedule = this.state.schedule;
    
    if(schedule.schedule_buses[busIndex].destinationStops.length == 1){
      return;
    }
    else{
      schedule.schedule_buses[busIndex].destinationStops.splice(index, 1);
    }
    this.updatePriceData(busIndex,   schedule.schedule_buses[busIndex])
    this.setState({schedule: schedule});
  }

  addBus() {
    let schedule = this.state.schedule;
    let remainingLetters = this.getLetters()
    let obj = {
      bus_type: '',
      schedule: '',
      priceOverride: '',
      firstSpecialSeats: '',
      lastSpecialSeats: '',
      firstSpecialSeatsPrice: '',
      lastSpecialSeatsPrice: '',
      departStops: [],
      destinationStops: [],
      couponAllowed: true,
      couponLimit: '',
      isOpen: true,
      notes: '',
      letter: remainingLetters && remainingLetters.length > 0 ? remainingLetters[0].value : "A"
    };

    if(this.state.buses.length > 0) {
      let busType = _.find(this.state.buses, {type: 'Standard'});
      if(busType){
        obj.bus_type = busType.id
      }
    }
    
    if(this.state.departCity === 'new york') {
      let defaultStop = _.find(this.state.stops, {city: 'new york'});
      let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
      let defaultStop2 = _.find(this.state.stops, {city: 'arlington'});
      obj.departStops = [
        {time: null,
        stop: defaultStop.id,
      }
      ];
      obj.destinationStops = [
        {time: null,
        stop: defaultStop1.id},
        {time: null,
        stop: defaultStop2.id}
      ];
    }
    else if(this.state.departCity === 'arlington' || this.state.departCity === 'bethesda') {
      let defaultStop = _.find(this.state.stops, {city: 'arlington'});
      let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
      let defaultStop2 = _.find(this.state.stops, {city: 'new york'});
      obj.departStops = [
        {time: null,
        stop: defaultStop.id},
        {time: null,
        stop: defaultStop1.id},
      ];
      obj.destinationStops = [
        {time: null,
          stop: defaultStop2.id},
      ];
    }
    else {
      obj.departStops = [
        {time: null,
        stop: ''}
      ];
      obj.destinationStops = [
        {time: null,
        stop: ''}
      ];
    }
    schedule.schedule_buses.push(obj);
    this.setState({schedule: schedule});
  }

  removeBus(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses.splice(index, 1);
    this.setState({schedule: schedule});
  }

  setDefaultStop() {
    if(this.state.departCity === 'new york') {
      let defaultStop = _.find(this.state.stops, {city: 'new york'});
      let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
      if(defaultStop && defaultStop1 && this.state.schedule.schedule_buses.length === 0) {
        let schedule = this.state.schedule;
        schedule.schedule_buses = [{
          schedule: '',
          priceOverride: '',
          firstSpecialSeats: '',
          lastSpecialSeats: '',
          firstSpecialSeatsPrice: '',
          lastSpecialSeatsPrice: '',
          couponAllowed: true,
          couponLimit: '',
          isOpen: true,
          notes: '',
          letter: '',
          bus_type: this.state.buses[0].id,
          departStops: [
            {time: moment(),
            stop: defaultStop.id}
          ],
          destinationStops: [
            {time: moment(),
            stop: defaultStop1.id}
          ]
        }];
        this.setState({schedule: schedule});
      }
    }
    else if(this.state.departCity === 'arlington') {
      let defaultStop = _.find(this.state.stops, {city: 'arlington'});
      let defaultStop1 = _.find(this.state.stops, {city: 'new york'});
      if(defaultStop && defaultStop1 && this.state.schedule.schedule_buses.length === 0) {
        let schedule = this.state.schedule;
        schedule.schedule_buses = [{
          schedule: '',
          priceOverride: '',
          firstSpecialSeats: '',
          lastSpecialSeats: '',
          firstSpecialSeatsPrice: '',
          lastSpecialSeatsPrice: '',
          couponAllowed: true,
          couponLimit: '',
          isOpen: true,
          letter: '',
          bus_type: this.state.buses[0].id,
          departStops: [
            {time: moment(),
            stop: defaultStop.id}
          ],
          destinationStops: [
            {time: moment(),
            stop: defaultStop1.id}
          ]
        }];
        this.setState({schedule: schedule});
      }
    }
  }

  componentWillUpdate(props) {
    if(props.schedule && (!this.props.schedule || (JSON.stringify(props.schedule) !== JSON.stringify(this.props.schedule))) && queryStringToJSON(this.props.location.search).id) {
      let schedule = props.schedule;
      delete schedule['id'];
      schedule.isSpecial = this.state.schedule.isSpecial;
      schedule.start = this.state.schedule.start;
      schedule.end = this.state.schedule.end;
      schedule.specialDate = this.state.schedule.specialDate;
      schedule.dayOfWeek = this.state.schedule.dayOfWeek;
      if(schedule.schedule_buses && schedule.schedule_buses.length > 0) {
        schedule.schedule_buses.map(bus => {
          delete bus['created_at'];
          delete bus['updated_at'];
          // bus.deleted = false;
          delete bus['schedule'];
          bus.priceOverride = bus.priceOverride || '';
          bus.firstSpecialSeats = bus.firstSpecialSeats || '';
          bus.lastSpecialSeats = bus.lastSpecialSeats || '';
          bus.firstSpecialSeatsPrice = bus.firstSpecialSeatsPrice || '';
          bus.lastSpecialSeatsPrice = bus.lastSpecialSeatsPrice || '';
          bus.departStops = [];
          bus.destinationStops = [];
          if(!bus.couponLimit) {
            bus.couponLimit = '';
          }
          if(bus.schedule_bus_stops && bus.schedule_bus_stops.length > 0) {
            bus.schedule_bus_stops.map(stop => {
              const stopData = {
                time: moment(stop.time, 'HH:mm:ss'),
                stop: stop.stop,
                id: stop.id,
                deleted: stop.deleted,
                pickOrDrop: stop.pickOrDrop
              }
              if(stop.pickOrDrop === 'PU') {
                if(!stop.deleted){
                bus.departStops.push(stopData)
              }
              }
              if(stop.pickOrDrop === 'DO') {
                if(!stop.deleted){
                bus.destinationStops.push(stopData)
              }
            }
              return stop;
            });
          }
          return bus;
        });
        schedule.schedule_buses = _.orderBy(schedule.schedule_buses, (item) => {
          return item.departStops[0].time;
        });
      }
      console.log(schedule)
      this.setState({schedule: schedule});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Schedule Added successfully'});
      history.push('/dashboard/schedules');
    }
    if(props.stops && props.stops.length>0 && this.state.stops.length === 0) {
      this.setState({stops: props.stops}, () => {
        // if(this.state.buses.length > 0 && !queryStringToJSON(this.props.location.search).id) {
          // this.setDefaultStop();
        // }
      });
    }
    if(props.buses && props.buses.length>0 && this.state.buses.length === 0) {
      this.setState({buses: props.buses}, () => {
        // if(this.state.stops.length > 0 && !queryStringToJSON(this.props.location.search).id) {
          // this.setDefaultStop();
        // }
      });
    }
  }

  getDepartStops() {
    if(this.state.departCity === 'new york' && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'new york' || item.city === 'brooklyn')
      });    }
    else if(this.state.departCity && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'arlington' || item.city === 'bethesda' || item.city === 'washington')
      });
    }
    return this.state.stops;
  }

  getDestinationStops() {
    if(this.state.departCity === 'new york' && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'arlington' || item.city === 'bethesda' || item.city == 'washington')
      });    }
    else if(this.state.departCity && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'new york' || item.city === 'brooklyn')
      });
    }
    return this.state.stops;
  }

  checkStopExistence(stopId){
    if(stopId){
      let stop = _.find(this.state.stops, {id: stopId})
      if(stop && stop.id){
        return true
      }
    }
    return false;
  }

  getBusOptions() {
    let arr = [];
    for(let i = 0; i < this.state.buses.length; i++) {
      arr.push({label: this.state.buses[i].name, value: this.state.buses[i].id});
    }
    return arr;
  }

  getBusReservations(id) {
    return _.filter(this.state.legsAndBusesMapping, { scheduleBus: id }).length;
  }

  getCodeFromStops(cityName){
    console.log(this.state.stops)
   const data =  _.filter(this.state.stops, {city: cityName})
    
   if(data && data.length > 0 && data[0]){
    return data[0].id
   }

   return null;
   
  }

  getStopFromCode(code){
    const data =  _.filter(this.state.stops, {id: code})
     
    if(data && data.length > 0 && data[0]){
     return data[0].city.toLowerCase();
    }
 
    return null;
    
   }

   constructPricesMap(schedule_bus){
    let departStops = schedule_bus.departStops;
    let destinationStops = schedule_bus.destinationStops;
    let prices = schedule_bus.prices;
    console.log(schedule_bus)

    let combinationOfStops = []
    let price = null;
    departStops.forEach((departStop)=>{
        destinationStops.forEach((destinationStop)=>{

          let departStopValue = _.find(this.state.stops, {id: departStop.stop})
          let destinationStopValue = _.find(this.state.stops, {id: destinationStop.stop})
          if(prices && prices.length && departStopValue && destinationStopValue){
             price = _.find(prices, {from_stop_id: departStop.stop, to_stop_id: destinationStop.stop})
          }

          if(departStopValue && destinationStopValue){
            combinationOfStops.push({
                departStop: {...departStop, address: departStopValue.address}, 
                destinationStop: {...destinationStop, address: destinationStopValue.address},
                price: price && price.price || '',
                customRouteId: price && price.id

            })
          }
        
        })
    })
    
    return combinationOfStops;
}


  showEditPrice(schedule_bus, index){
    let departStops = schedule_bus.departStops;
    let destinationStops = schedule_bus.destinationStops;
    let prices = schedule_bus.prices;
    let priceOverride = schedule_bus.priceOverride;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.state.stops, priceOverride);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index});
  }

  updatePrice(priceMap, currentlySelectedBusIndex = this.state.currentSelectedBusIndex){
    let schedule = this.state.schedule;

    let newPrices = [];

    priceMap.map((price)=>{
      let p = {
        from_stop_id: price.departStop.stop,
        to_stop_id: price.destinationStop.stop, 
        price: price.price,
        id: price.customRouteId ? price.customRouteId: undefined
      }

      newPrices.push(p)      
    })

    schedule.schedule_buses[currentlySelectedBusIndex].prices = newPrices;

    this.setState({schedule})
    
  }

  
  updateStopPrice(e, index) {
    const { value } = e.target;
    let combinationOfStops = this.state.currentSelectedBus;
    combinationOfStops[index].price = value;
    this.setState({ currentSelectedBus: combinationOfStops });
  }



  updatePriceData(currentlySelectedBusIndex, schedule_bus){
    let departStops = schedule_bus.departStops;
    let destinationStops = schedule_bus.destinationStops;
    let prices = schedule_bus.prices;

    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.state.stops);
    this.updatePrice(stopCombination, currentlySelectedBusIndex);
  }



  render() {
    const { submitted, schedule } = this.state;
    const { loading } = this.props;
    this.getCodeFromStops()
    return (
      <div className="content">
        <Grid fluid style={{maxWidth: '700px'}}>
          {this.state.activeReservations > 0 && <div className="active-buses-msg">The existing schedule for this day has {this.state.activeReservations} active reservations ({this.state.activeSeats} seats)</div>}
          <Row>
            <Col md={12}>
              <Card
                title="Create New Schedule"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit} className="add-schedule-form">
                    <div className="special-check"><Checkbox
                      number='check1'
                      value={schedule.isSpecial}
                      onChange={this.handleCheckbox.bind(this)}
                      checked={schedule.isSpecial}
                      label='Special'
                      disabled={schedule.isSpecial}
                    /></div>
                    <Row>
                      {schedule.isSpecial && <Col md={6}>
                        <ControlLabel>Special Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.specialDate}
                          onChange={this.handleSpecialDate}
                        />
                      </Col>}
                      {schedule.isSpecial && <Col md={6}>
                        <FormGroup>
                          <ControlLabel>Name</ControlLabel>
                          <FormControl bsClass= "form-control" name="name" value={schedule.name} onChange={e => this.handleChange(e)}></FormControl>
                        </FormGroup>
                      </Col>}
                    </Row>
                    <Row className="error-row">
                      <Col md={4}></Col>
                      <Col md={4}>{submitted && schedule.isSpecial && !schedule.specialDate && <div className="error">Special date is required</div>}</Col>
                    </Row>
                    {!schedule.isSpecial && <Row>
                      <Col md={4}>
                        <ControlLabel>Start Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.start}
                          onChange={this.handleStartDate}
                          disabled={schedule.isSpecial}
                        />
                      </Col>
                      <Col md={4}>
                        <ControlLabel>End Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.end}
                          onChange={this.handleEndDate}
                          disabled={schedule.isSpecial}
                        />
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Day Of Week</ControlLabel>
                          <FormControl componentClass="select" bsClass= "form-control" name="dayOfWeek" value={schedule.dayOfWeek} onChange={this.handleChange} disabled={schedule.isSpecial}>
                            <option>Select</option>
                            <option>Sunday</option>
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>}
                    <Row className="error-row">
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.start && <div className="error">Start date is required</div>}</Col>
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.end && <div className="error">End date is required</div>}</Col>
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.dayOfWeek && <div className="error">Week day is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <ControlLabel>Buses In Schedule<Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addBus.bind(this)}><i className="fa fa-plus"></i>Add New Bus</Button></ControlLabel>
                      </Col>
                    </Row>
                    {schedule.schedule_buses.length === 0 && <div className='empty-stop-box'>
                      No Buses In Schedule
                    </div>}
                    {schedule.schedule_buses.map((item, index) => {
                      return(<div className={"bus-box " + (item.deleted ? `deleted deletedItem` : '')} key={index}>
                      <Row>
                        {
                           item.deleted && <Col md={12}>
                              <div style={{width: '100%'}}>
                                <Button bsStyle="danger" className="btn-fill" style={{width: "100%"}} type="button">Cancelled for the day</Button>
                              </div>
                           </Col>
                          }
                        </Row>
                        <Row>
                          <Col md={12}>
                            {this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0 && <div className="active-bus-msg">The existing bus for this day has {this.getBusReservations(item.id)} active reservations</div>}
                            <h4 className="bus-stop-number">{schedule.dayOfWeek}</h4>
                            {/*<div className="open-checkbox"><Checkbox
                              number='check44'
                              value={item.isOpen}
                              onChange={this.handleOpenCheckbox.bind(this, index)}
                              checked={item.isOpen}
                              label='Open For Reservation?'
                            /></div>*/}
                            <Button bsStyle="info" disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} className="btn-fill btn-right delete-bus" type="button" onClick={this.removeBus.bind(this, index)}>
                              <i className="fa fa-trash"></i>Remove Bus
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                              <h5 className="bus-stop-number">Departure Time: </h5> 
                              {item.departStops[0]['time'] && <Badge><span>{item.departStops[0]['time'].format('h:mm A')}</span></Badge>}
                          </Col>
                        </Row>
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          proprieties={[
                            {
                              label: "Bus Type",
                              bsClass: "form-control",
                              componentClass: "select",
                              value: item.bus_type,
                              name: 'bus_type',
                              disabled: this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0,
                              onChange: this.handleBusChange.bind(this, index),
                              options: this.getBusOptions()
                            },
                            {
                              label: "Edit Price",
                              type: "custom",
                              bsClass: "form-control",
                              value: "Edit",
                              name: 'edit_price',
                              custom: <span onClick={this.showEditPrice.bind(this, item, index)} className="btn btn-primary form-control edit-price">Edit Price</span>
                            },
                            {
                              label: "Letter",
                              bsClass: "form-control",
                              componentClass: "select",
                              value: item.letter,
                              name: 'letter',
                              disabled: this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0,
                              onChange: this.handleBusChange.bind(this, index),
                              options: this.getLetters(item.letter)
                            }
                          ]}
                        />
                        {submitted  && <div style={
                          {
                            display: 'flex',
                            justifyContent: 'space-between'
                          }
                        } className="error-row">
                          <Col  md={4}><div style={{marginLeft:"-8px"}} className="error">{!item.bus_type && 'Bus is required'} </div></Col>
                          <Col md={4}/>
                          {(!item.letter && item.deleted === false)&& <Col md={4}><div style={{marginLeft:"8px"}} className="error">Letter is required</div></Col>}

                        </div>}
                        {/*<FormInputs
                          ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                          proprieties={[
                            {
                              label: "Override First Special Seats",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override First Special Seats",
                              value: item.firstSpecialSeats,
                              name: 'firstSpecialSeats',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override First Special Seats Price",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override First Special Seats Price",
                              value: item.firstSpecialSeatsPrice,
                              name: 'firstSpecialSeatsPrice',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override Last Special Seats",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override Last Special Seats",
                              value: item.LastSpecialSeats,
                              name: 'LastSpecialSeats',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override Last Special Seats Price",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override Last Special Seats Price",
                              value: item.lastSpecialSeatsPrice,
                              name: 'lastSpecialSeatsPrice',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            }
                          ]}
                        />*/}
                        {/*<Row>
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>Bus Notes</ControlLabel>
                              <FormControl bsClass= "form-control" name="notes" value={item.notes} onChange={e => this.handleBusChange(index, e)}></FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="coupon-checkbox">
                            <Checkbox
                              number='check2'
                              value={item.couponAllowed}
                              onChange={this.handleCouponCheckbox.bind(this, index)}
                              checked={item.couponAllowed}
                              label='Coupons Allowed'
                            />
                          </Col>
                          {item.couponAllowed &&
                            <Col md={4}>
                              <FormGroup>
                                <ControlLabel>Coupon Limit</ControlLabel>
                                <FormControl bsClass= "form-control" type="number" name="couponLimit" value={item.couponLimit} onChange={e => this.handleBusChange(index, e)}></FormControl>
                              </FormGroup>
                            </Col>}
                        </Row>*/}
                        {/*<Row className="error-row">
                          <Col md={4}></Col>
                          <Col md={4}></Col>
                          <Col md={4}>{submitted && item.couponAllowed && item.couponLimit !== '' && item.couponLimit < 1 && <div className="error">Limit Must be greater than 0</div>}</Col>
                        </Row>*/}
                        <Row>
                          <Col md={12}>
                            <ControlLabel>Departure</ControlLabel>
                            <Button disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addDepartStop.bind(this, index)}><i className="fa fa-plus"></i>Add Departures Stop</Button>
                            {/* { !(this.state.departCity === 'new york') && <Button bsStyle="info" disabled={item.departStops.length >= 2} className="add-stop btn-fill" type="button" onClick={this.addBethesdaStop.bind(this, index)}><i className="fa fa-plus"></i>Add Departure Stop</Button>} */}

                          </Col>
                        </Row>
                        {item.departStops.length === 0 && <div className='empty-stop-box'>
                          No Depart Stops For This Bus
                        </div>}
                        {item.departStops.map((busStop, num) => {
                          return(<div className="stop-box" key={num}>
                            <Row className="stops-block">
                              <div className="stop-number">#{num+1}</div>
                              <Col md={6} className="stop-block">
                                <FormGroup>
                                  {/* <FormControl disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} componentClass="select" bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDepartStopChange(e, num, index)}> */}
                                  <FormControl disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0 && this.checkStopExistence(busStop.stop)} componentClass="select" bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDepartStopChange(e, num, index)}>
                                    <option value=''>Select</option>
                                    {
                                      this.getDepartStops().map((stop, key) => {
                                        return(<option key={key} value={stop.id}>{stop.address}</option>)
                                      })
                                    }
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Col md={6} className="stop-block-2">
                                <FormGroup>
                                  <DatePicker
                                    disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0}
                                    // disabled={item.departStops.length >= 2 && (busStop.stop === this.getCodeFromStops('bethesda'))}
                                    className="form-control"
                                    selected={busStop.time}
                                    onChange={(time) => this.handleDepartStopTime(time, num, index)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="LT"
                                    timeCaption="Time"
                                    placeholderText="Depart Time"
                                  />
                                </FormGroup>
                              </Col>
                              {/* {this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0 ? null : <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDepartStop.bind(this, num, index)}></i> : <span>Removed</span>}</div>} */}
                              {item.departStops.length >=2 && this.getBusReservations(item.id) == 0 && <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDepartStop.bind(this, num, index)}></i> : <span>Removed</span>}</div>}
                            </Row>
                            {submitted && (!busStop.stop || !busStop.time) && <Row className="error-row">
                              <Col md={6}>{submitted && !busStop.stop && <div className="error">Stop is required</div>}</Col>
                              <Col md={6}>{submitted && !busStop.time && num == 0 &&<div className="error">Time is required</div>}</Col>
                            </Row>}
                          </div>)
                        })}

                        <Row>
                          <Col md={12}>
                            <ControlLabel>Drop-off</ControlLabel>
                            {/* <Button disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addMiddleDestinationBethesda.bind(this, index)}><i className="fa fa-plus"></i>Add Drop-off Stop</Button> */}
                            {<Button  disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} bsStyle="info"  className="add-stop btn-fill" type="button" onClick={this.addDestinationStop.bind(this, index)}><i className="fa fa-plus"></i>Add Drop-off Stop</Button>}
                          </Col>
                        </Row>
                        {item.destinationStops.length === 0 && <div className='empty-stop-box'>
                          No Destination Stops For This Bus
                        </div>}
                        {item.destinationStops.map((busStop, num) => {
                          return(<div className="stop-box" key={num}>
                            <Row className="stops-block">
                              <div className="stop-number">#{num+1}</div>
                              <Col md={6} className="stop-block">
                                <FormGroup>
                                  {/* <FormControl disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0} componentClass="select" bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDestinationStopChange(e, num, index)}> */}
                                  <FormControl disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0 && this.checkStopExistence(busStop.stop)} componentClass="select" bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDestinationStopChange(e, num, index)}>
                                    <option value=''>Select</option>
                                    {
                                      this.getDestinationStops().map((stop, key) => {
                                        return(<option key={key} value={stop.id}>{stop.address}</option>)
                                      })
                                    }
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Col md={6} className="stop-block-2">
                                <FormGroup>
                                  <DatePicker
                                    disabled={this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0}
                                    // disabled
                                    className="form-control"
                                    selected={busStop.time}
                                    onChange={(time) => this.handleDestinationStopTime(time, num, index)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="LT"
                                    timeCaption="Time"
                                    placeholderText="Arrival Time"
                                  />
                                </FormGroup>
                              </Col>
                              {/* {this.state.activeReservations > 0 && !!item.id && this.getBusReservations(item.id) > 0 ? null : <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDestinationStop.bind(this, num, index)}></i> : <span>Removed</span>}</div>} */}
                              {this.getBusReservations(item.id) == 0 && item.destinationStops.length >=2  && <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDestinationStop.bind(this, num, index)}></i> : <span>Removed</span>}</div>}
                            </Row>
                            {submitted && (!busStop.stop || !busStop.time) && <Row className="error-row">
                              <Col md={6}>{submitted && !busStop.stop && <div className="error">Stop is required</div>}</Col>
                              {/* <Col md={6}>{submitted && !busStop.time && <div className="error">Time is required</div>}</Col> */}
                            </Row>}
                          </div>)
                        })}

                      </div>)
                    })}
                    <Row>
                      <Col md={6}>
                        {schedule.schedule_buses.length > 0 && <Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addBus.bind(this)}><i className="fa fa-plus"></i>Add New Bus</Button>}
                      </Col>
                      <Col md={6}>
                        <Button bsStyle="info" className="btn-right btn-fill save-btn save-schedule-btn" disabled={loading} fill type="submit">
                          Save Schedule
                        </Button>
                      </Col>
                    </Row>
                    {this.state.formError ? <Row className="error-row">
                      <Col md={12}>Please complete all form fields above to save</Col>
                    </Row> : ''}
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
        {<CustomPrice updatePrice={this.updatePrice.bind(this)} open={this.state.showModal} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={this.updateStopPrice.bind(this)}/>}

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success, item } = state.schedule;
  return {
    loading, error, success, stops: state.stops.items, buses: state.buses.items, me: state.authentication.me, schedule: item
  };
}
 
export default connect(mapStateToProps)(AddSchedule);
