import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";

import config from 'config';
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import busActions from 'actions/bus.actions';

class Buses extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, 1000);
    this.state = {
      criteria: {
        limit: config.pageSize,
        offset: 0,
        filtered: "",
        sorted: ""
      }
    };
  }

  componentWillUpdate(props) {
    if(props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
  }

  fetchData = (state, instance) => {
    let criteria = this.state.criteria;
    let newFilter = state.filtered.length > 0 ? JSON.stringify(state.filtered) : "";
    let newOrderer = state.sorted.length > 0 ? JSON.stringify(state.sorted) : "";
    if(criteria.filtered !== newFilter) {
      criteria['offset'] = 0;
      criteria['filtered'] = newFilter;
    }
    if(criteria.sorted !== newOrderer) {
      criteria['offset'] = 0;
      criteria['sorted'] = newOrderer;
    }
    if(criteria.limit !== state.pageSize) {
      criteria.limit = state.pageSize;
      criteria['offset'] = 0;
    }
    else {
      criteria['offset'] = Math.ceil(state.page * state.pageSize);
    }
    this.setState({criteria: criteria}, () => {
      this.props.dispatch(busActions.getBuses(criteria));
    });
  };

  deleteItem = item => {
    confirmAlert({
      title: 'Delete Bus',
      message: 'Are you sure you want to delete this bus?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(busActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const buses = this.props.buses || [];
    const loading = this.props.loading || false;
    const total = this.props.total || 0;
    const pageCount = Math.ceil(total / this.state.criteria.limit);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Buses"
                rightButton={
                  <NavLink to="/dashboard/bus"><Button type="button" fill bsStyle="info"><i className="fa fa-plus"></i>Add Bus</Button></NavLink>
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  <ReactTable
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name"
                      },
                      {
                        Header: "Company",
                        accessor: "company"
                      },
                      /*{
                        Header: "Driver",
                        accessor: "driver",
                        Cell: row => (
                          row.original.driver ? (<NavLink target="_blank" to={"/dashboard/users/"+row.original.driver.id}>{row.original.driver.firstName + " " + row.original.driver.lastName}</NavLink>) : ''
                        )
                      },*/
                      {
                        Header: "Type",
                        accessor: "type"
                      },
                      {
                        Header: "Capacity",
                        accessor: "capacity",
                        filterable: false
                      },
                      {
                        Header: "Default Price",
                        accessor: "defaultPrice",
                        filterable: false
                      },
                      /*{
                        Header: "Def. First Special Seats",
                        accessor: "defaultFirstSpecialSeats"
                      },
                      {
                        Header: "Def. Price First Special Seats",
                        accessor: "defaultFirstSpecialSeatsPrice"
                      },
                      {
                        Header: "Def. Last Special Seats",
                        accessor: "defaultLastSpecialSeats"
                      },
                      {
                        Header: "Def. Price Last Special Seats",
                        accessor: "defaultLastSpecialSeatsPrice"
                      },*/
                      {
                        Header: "Wifi",
                        accessor: "hasWifi",
                        Cell: row => (
                          <div>{row.original.hasWifi ? 'Yes' : 'No'}</div>
                        ),
                        filterable: false
                      },
                      {
                        Header: "Movies",
                        accessor: "hasMovies",
                        Cell: row => (
                          <div>{row.original.hasMovies ? 'Yes' : 'No'}</div>
                        ),
                        filterable: false
                      },
                      {
                        Header: "Power Outlets",
                        accessor: "hasPowerOutlets",
                        Cell: row => (
                          <div>{row.original.hasPowerOutlets ? 'Yes' : 'No'}</div>
                        ),
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        width: 100,
                        Cell: row => (
                          <div className="table-actions">
                            <NavLink to={"/dashboard/buses/"+row.original.id}><Button bsStyle="info" disabled={row.original.deleting} fill type="button"><i className="fa fa-edit"></i></Button></NavLink>
                            <Button bsStyle="danger" disabled={row.original.deleting} fill type="button" onClick={this.deleteItem.bind(this, row)}><i className="fa fa-trash"></i></Button>
                          </div>
                        )
                      },
                    ]}
                    manual
                    data={buses}
                    pages={pageCount} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchDataWithDebounce}
                    filterable
                    defaultPageSize={this.state.criteria.limit}
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { items, loading, total, error } = state.buses;
  return { buses: items, loading, total, error };
}

export default connect(mapStateToProps)(Buses);
