import { GET_GIFT_CARDS_REQUEST, GET_GIFT_CARDS_SUCCESS, GET_GIFT_CARDS_FAILURE, GET_GIFT_CARD_REQUEST, GET_GIFT_CARD_SUCCESS, GET_GIFT_CARD_FAILURE, DELETE_GIFT_CARD_REQUEST, DELETE_GIFT_CARD_SUCCESS, DELETE_GIFT_CARD_FAILURE, ADD_GIFT_CARD_REQUEST, ADD_GIFT_CARD_SUCCESS, ADD_GIFT_CARD_FAILURE, UPDATE_GIFT_CARD_REQUEST, UPDATE_GIFT_CARD_SUCCESS, UPDATE_GIFT_CARD_FAILURE } from '../constants';
import { giftCardService } from '../services/gift_card.services';
 
export default {
  add,
  update,
  getGiftCards,
  getGiftCard,
  delete: _delete
};
 
function add(giftCard) {
  return dispatch => {
    dispatch(request(giftCard));

    giftCardService.add(giftCard)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(giftCard) { return { type: ADD_GIFT_CARD_REQUEST, giftCard } }
  function success(giftCard) { return { type: ADD_GIFT_CARD_SUCCESS, giftCard } }
  function failure(error) { return { type: ADD_GIFT_CARD_FAILURE, error } }
}

function update(id, giftCard) {
  return dispatch => {
    dispatch(request(giftCard));

    giftCardService.update(id, giftCard)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(giftCard) { return { type: UPDATE_GIFT_CARD_REQUEST, giftCard } }
  function success() { return { type: UPDATE_GIFT_CARD_SUCCESS } }
  function failure(error) { return { type: UPDATE_GIFT_CARD_FAILURE, error } }
}
 
function getGiftCards(filters) {
  return dispatch => {
    dispatch(request());
 
    giftCardService.getGiftCards(filters)
      .then(
        giftCards => dispatch(success(giftCards)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_GIFT_CARDS_REQUEST } }
  function success(giftCards) { return { type: GET_GIFT_CARDS_SUCCESS, giftCards } }
  function failure(error) { return { type: GET_GIFT_CARDS_FAILURE, error } }
}

function getGiftCard(id) {
  return dispatch => {
    dispatch(request());
 
    giftCardService.getGiftCard(id)
      .then(
        giftCard => dispatch(success(giftCard)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_GIFT_CARD_REQUEST } }
  function success(giftCard) { return { type: GET_GIFT_CARD_SUCCESS, giftCard } }
  function failure(error) { return { type: GET_GIFT_CARD_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    giftCardService.delete(id)
      .then(
        giftCard => dispatch(success(giftCard.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_GIFT_CARD_REQUEST, id } }
  function success(id) { return { type: DELETE_GIFT_CARD_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_GIFT_CARD_FAILURE, id, error } }
}