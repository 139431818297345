import { GET_SCHEDULES_REQUEST, GET_SCHEDULES_SUCCESS, GET_SCHEDULES_FAILURE, GET_SCHEDULESUMMARY_SUCCESS, GET_SCHEDULESUMMARY_FAILURE, GET_SCHEDULE_REQUEST, GET_SCHEDULE_SUCCESS, GET_SCHEDULE_FAILURE, DELETE_SCHEDULE_REQUEST, DELETE_SCHEDULE_SUCCESS, DELETE_SCHEDULE_FAILURE, ADD_SCHEDULE_REQUEST, ADD_SCHEDULE_SUCCESS, ADD_SCHEDULE_FAILURE, UPDATE_SCHEDULE_REQUEST, UPDATE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FAILURE } from '../constants';
import { scheduleService } from '../services/schedule.services';
 
export default {
  add,
  update,
  getSchedules,
  getSchedule,
  getScheduleSummary,
  delete: _delete
};
 
function add(schedule) {
  return dispatch => {
    dispatch(request(schedule));

    scheduleService.add(schedule)
    .then(
      data => { 
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(schedule) { return { type: ADD_SCHEDULE_REQUEST, schedule } }
  function success(schedule) { return { type: ADD_SCHEDULE_SUCCESS, schedule } }
  function failure(error) { return { type: ADD_SCHEDULE_FAILURE, error } }
}

function update(id, schedule) {
  return dispatch => {
    dispatch(request(schedule));

    scheduleService.update(id, schedule)
    .then(
      data => { 
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };
 
  function request(schedule) { return { type: UPDATE_SCHEDULE_REQUEST, schedule } }
  function success(data) { return { type: UPDATE_SCHEDULE_SUCCESS, activeBuses: data } }
  function failure(error) { return { type: UPDATE_SCHEDULE_FAILURE, error } }
}
 
function getSchedules(filters) {
  return dispatch => {
    dispatch(request());
 
    scheduleService.getSchedules(filters)
      .then(
        schedule => dispatch(success(schedule)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_SCHEDULES_REQUEST } }
  function success(schedule) { return { type: GET_SCHEDULES_SUCCESS, schedule } }
  function failure(error) { return { type: GET_SCHEDULES_FAILURE, error } }
}

function getScheduleSummary(id, start, event, date) {
  return dispatch => {
    scheduleService.getScheduleSummary(id, date)
      .then(
        summary => dispatch(success(summary, start, event)),
        error => dispatch(failure(error.toString()))
      );
  };

  function success(summary, start, event) { return { type: GET_SCHEDULESUMMARY_SUCCESS, summary, start, event } }
  function failure(error) { return { type: GET_SCHEDULESUMMARY_FAILURE, error } }
}

function getSchedule(id, date = null) {
  return dispatch => {
    dispatch(request());
 
    scheduleService.getSchedule(id, date)
      .then(
        schedule => dispatch(success(schedule)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_SCHEDULE_REQUEST } }
  function success(schedule) { return { type: GET_SCHEDULE_SUCCESS, schedule } }
  function failure(error) { return { type: GET_SCHEDULE_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    scheduleService.delete(id)
      .then(
        schedule => dispatch(success(schedule.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_SCHEDULE_REQUEST, id } }
  function success(id) { return { type: DELETE_SCHEDULE_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_SCHEDULE_FAILURE, id, error } }
}