import { GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE, GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE, DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAILURE, BLOCK_CUSTOMER_REQUEST, BLOCK_CUSTOMER_SUCCESS, BLOCK_CUSTOMER_FAILURE, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_FAILURE, UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILURE } from '../constants';
import { customerService } from '../services/customer.services';
 
export default {
  add,
  update,
  getCustomers,
  getCustomer,
  delete: _delete,
  block
};
  
function add(customer) {
  return dispatch => {
    dispatch(request(customer));
    customerService.add(customer)
      .then(
        customer => { 
          dispatch(success());
          //dispatch(alertActions.success('Customer added successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };
 
  function request(customer) { return { type: ADD_CUSTOMER_REQUEST, customer } }
  function success(customer) { return { type: ADD_CUSTOMER_SUCCESS, customer } }
  function failure(error) { return { type: ADD_CUSTOMER_FAILURE, error } }
}

function update(id, customer) {
  return dispatch => {
    dispatch(request(customer));
    customerService.update(id, customer)
      .then(
        data => { 
          dispatch(success(data));
          //dispatch(alertActions.success('Customer updated successfully'));
        },
        error => {
          dispatch(failure(error.toString()));
          //dispatch(alertActions.error(error.toString()));
        }
      );
  };
 
  function request(customer) { return { type: UPDATE_CUSTOMER_REQUEST, customer } }
  function success(customer) { return { type: UPDATE_CUSTOMER_SUCCESS, customer } }
  function failure(error) { return { type: UPDATE_CUSTOMER_FAILURE, error } }
}
 
function getCustomers(filters) {
  return dispatch => {
    dispatch(request());
 
    customerService.getCustomers(filters)
      .then(
        customers => dispatch(success(customers)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_CUSTOMERS_REQUEST } }
  function success(customers) { return { type: GET_CUSTOMERS_SUCCESS, customers } }
  function failure(error) { return { type: GET_CUSTOMERS_FAILURE, error } }
}

function getCustomer(id) {
  return dispatch => {
    dispatch(request());
 
    customerService.getCustomer(id)
      .then(
        customer => dispatch(success(customer)),
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_CUSTOMER_REQUEST } }
  function success(customer) { return { type: GET_CUSTOMER_SUCCESS, customer } }
  function failure(error) { return { type: GET_CUSTOMER_FAILURE, error } }
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));
 
    customerService.delete(id)
      .then(
        customer => dispatch(success(customer.id)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: DELETE_CUSTOMER_REQUEST, id } }
  function success(id) { return { type: DELETE_CUSTOMER_SUCCESS, id } }
  function failure(id, error) { return { type: DELETE_CUSTOMER_FAILURE, id, error } }
}

function block(id, val) {
  console.log(val)
  return dispatch => {
    dispatch(request(id));
 
    customerService.update(id, {blocked: val})
      .then(
        user => dispatch(success(id, val)),
        error => dispatch(failure(id, error.toString()))
      );
  };
 
  function request(id) { return { type: BLOCK_CUSTOMER_REQUEST, id } }
  function success(id, val) { return { type: BLOCK_CUSTOMER_SUCCESS, id, blocked: val } }
  function failure(id, error) { return { type: BLOCK_CUSTOMER_FAILURE, id, error } }
}