import { GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE, ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, BLOCK_USER_REQUEST, BLOCK_USER_SUCCESS, BLOCK_USER_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_USERS_SUCCESS:
      return {
        loading: false,
        items: action.users.users,
        total: action.users.total
      };
    case GET_USERS_FAILURE:
      return {
        error: action.error
      };
    case GET_USER_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_USER_SUCCESS:
      return {
        loading: false,
        item: action.user
      };
    case GET_USER_FAILURE:
      return {
        error: action.error
      };
    case UPDATE_USER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_USER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case ADD_USER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_USER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_USER_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case DELETE_USER_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case DELETE_USER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }
 
          return user;
        })
      };
    case BLOCK_USER_REQUEST:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, blocking: true }
            : user
        )
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, blocked: action.blocked, blocking: false }
            : user
        )
      };
    case BLOCK_USER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }
 
          return user;
        })
      };
    default:
      return state
  }
}