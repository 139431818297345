import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputMask from 'react-input-mask';
import { reservationService } from '../../services/reservation.services';
import { customerService } from '../../services/customer.services';
import config from 'config';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

function queryStringToJSON(param) {            
  var pairs = param.slice(1).split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
}

const FUEL_SURCHARGE = 1.75;
const RESERVATION_FEE = 2.25;

class AddMainReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      pastDate: false,
      seats: 1,
      remark: '',
      paymentMethod: 'card',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      zip: '',
      methods: [
        {label: 'Credit Card', value: 'card'},
        {label: 'Ivy Media', value: 'Ivy'},
        {label: 'Points/Coupons', value: 'points'},
        {label: 'No Payment', value: 'none'},
        {label: 'Re-Schedule At The Bus', value: 'reschedule'},
        {label: 'Cust. Already Paid', value: 'alreadyPaid'},
        {label: 'Re-Schedule Old Trip', value: 'rescheduleOld'},
        {label: 'Promotional Code', value: 'promotionalCode'},
      ],
      seatOptions: [],
      cardHolder: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      price: '',
      result: null,
      credits: 0,
      points: 0,
      useCredits: false,
      usePoints: false,
      totalVouchers: 0,
      totalCredits: 0,
      payment: 0,
      voucherAmount: 0,
      vouchersForFreeTicketDepart: 6,
      vouchersForFreeTicketReturn: 6
    };

    this.handleChange = this.handleChange.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const name = e.target.name;

    if(name === 'firstName'){
       this.setState({'cardHolder': e.target.value + ' ' + this.state.lastName})
    }
    else if(name === 'lastName'){
       this.setState({'cardHolder': this.state.firstName + ' ' + e.target.value})
    }

    this.setState({ [e.target.name]: e.target.value }, () => {
      if(name === 'seats' || name === 'price' || name === 'paymentMethod') {
        this.setState({usePoints: false, useCredits: false});
        this.getSeatsPrice(this.state.seats, false, false)
      }
    });
  }

  applyCredit() {
    this.setState({useCredits: true, usePoints: false});
    this.getSeatsPrice(this.state.seats, true, false);
  }

  removeCredit() {
    this.setState({useCredits: false});
    this.getSeatsPrice(this.state.seats, false, false);
  }

  applyPoints() {
    this.setState({usePoints: true, useCredits: false});
    this.getSeatsPrice(this.state.seats, false, true);
  }

  removePoints() {
    this.setState({usePoints: false});
    this.getSeatsPrice(this.state.seats, false, false);
  }

  componentDidMount() {
    let years = [];
    let start = new Date().getFullYear();
    let end = start + 11;
    for (let i = start; i < end; i++) {
      years.push(i.toString().substr(2));
    }
    let seats = [];
    for(let i = 1; i <= 40; i++) {
      seats.push({label: i, value: i});
    }
    this.setState({ years: years, seatOptions: seats });
    if(queryStringToJSON(this.props.location.search).bus_type === 'Elite') {
      this.setState({vouchersForFreeTicketDepart: 12});
    }
    if(queryStringToJSON(this.props.location.search).return_bus_type === 'Elite') {
      this.setState({vouchersForFreeTicketReturn: 12});
    }
    if(queryStringToJSON(this.props.location.search).date) {
      let date = queryStringToJSON(this.props.location.search).date;
      let slot = queryStringToJSON(this.props.location.search).slot;
      if(moment(date + ' ' + slot, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'seconds') < 0) {
        this.setState({pastDate: true});
      }
    }
  }

  getCustomerDetails() {
    this.setState({credits: 0, points: 0, usePoints: 0, useCredits: 0, totalVouchers: 0, totalCredits: 0, payment: 0, voucherAmount: 0});
    if(!this.state.email) {
      return;
    }
    else {
      customerService.getByEmail(this.state.email).then((user) => {
        console.log(user);
        this.setState({
          points: user.points,
          credits: user.credits,
          firstName: user.firstName,
          lastName: user.lastName,
          cardHolder: `${user.firstName} ${user.lastName}`,
          phone: user.phone,
          zip: user.zip
        });
      })
      .catch(err => {
        this.setState({loading: false});
        console.log(err);
      })
    }
  }

  getCityCode(city) {
    return _.find(config.cities, {value: city}).code;
  }

  getSeatsPrice(seat, useCredits, usePoints) {
    let seats = seat;
    let data = [];
    let obj;
    let vouchers = this.state.points;
    let credits = 0;
    let totalVouchers = 0;
    let creditUsed = 0, paid = 0, payment = 0, voucherAmount = 0;

    let totalPrice = parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price) * seats;
    if(queryStringToJSON(this.props.location.search).return_slot) {
      totalPrice += parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price) * seats;
    }
    totalPrice = totalPrice + RESERVATION_FEE + FUEL_SURCHARGE;
    if(useCredits && this.state.credits > totalPrice) {
      credits = totalPrice;
    }
    if(useCredits && this.state.credits <= totalPrice) {
      credits = this.state.credits;
    }
    this.setState({totalCredits: credits});
    for (let i = 0; i < seats; i++) {
      creditUsed = 0;
      obj = {
        price: parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price),
        paid: 0,
        vouchers: 0,
        credit: 0
      };
      if(this.state.paymentMethod === 'card' || this.state.paymentMethod === 'Ivy') {
        if(usePoints && vouchers >= this.state.vouchersForFreeTicketDepart) {
          obj.vouchers = this.state.vouchersForFreeTicketDepart;
          vouchers -= this.state.vouchersForFreeTicketDepart;
          voucherAmount += parseFloat(queryStringToJSON(this.props.location.search).price);
          totalVouchers += this.state.vouchersForFreeTicketDepart;
        }
        else if(useCredits && credits > 0) {
          creditUsed = (credits > parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price)) ? parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price) : credits;
          paid = parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price) - creditUsed;
          obj.credit = creditUsed;
          obj.paid = paid;
          credits -= creditUsed;
        }
        else {
          obj.paid = parseFloat(this.state.price || queryStringToJSON(this.props.location.search).price);
        }
      }
      payment += obj.paid;
      data.push(obj);
    }
    if (queryStringToJSON(this.props.location.search).return_slot) {
      for (let i = 0; i < seats; i++) {
        obj = {
          return: true,
          price: parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price),
          paid: 0,
          vouchers: 0,
          credit: 0
        };
        if(this.state.paymentMethod === 'card' || this.state.paymentMethod === 'Ivy') {
          if(usePoints && vouchers >= this.state.vouchersForFreeTicketReturn) {
            obj.vouchers = this.state.vouchersForFreeTicketReturn;
            vouchers -= this.state.vouchersForFreeTicketReturn;
            totalVouchers += this.state.vouchersForFreeTicketReturn;
            voucherAmount += parseFloat(queryStringToJSON(this.props.location.search).return_price);
          }
          else if(useCredits && credits > 0) {
            creditUsed = (credits > parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price)) ? parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price) : credits;
            paid = parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price) - creditUsed;
            obj.credit = creditUsed;
            obj.paid = paid;
            credits -= creditUsed;
          }
          else {
            obj.paid = parseFloat(this.state.price || queryStringToJSON(this.props.location.search).return_price);
          }
        }
        payment += obj.paid;
        data.push(obj);
      }
    }
    if(usePoints || !useCredits || (useCredits && this.state.credits < totalPrice)) {
      payment = payment + RESERVATION_FEE + FUEL_SURCHARGE;
    }
    this.setState({totalVouchers: totalVouchers, payment: payment, voucherAmount: voucherAmount});
    return data;
  }

  checkDisabledPoints() {
    if(this.state.points >= 6 && queryStringToJSON(this.props.location.search).bus_type === 'Standard') {
      return false
    }
    if(this.state.points >= 12 && queryStringToJSON(this.props.location.search).bus_type === 'Elite') {
      return false
    }
    return true
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, error: '', result: null});
    if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.seats && (this.state.zip || this.state.paymentMethod === 'Ivy') && ((this.state.paymentMethod === 'Ivy' && this.state.price) || this.state.paymentMethod !== 'Ivy')) {
      let data = {
        schedule_bus: queryStringToJSON(this.props.location.search).schedule_bus,
        date: queryStringToJSON(this.props.location.search).date,
        status: 'active',
        slot: queryStringToJSON(this.props.location.search).slot,
        departCity: queryStringToJSON(this.props.location.search).departCity,
        departCityCode: this.getCityCode(queryStringToJSON(this.props.location.search).departCity),
        arriveCity: queryStringToJSON(this.props.location.search).arriveCity,
        seats: parseInt(this.state.seats, 10),
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        zip: this.state.zip || '',
        paymentMethod: this.state.paymentMethod,
        depart_stop: queryStringToJSON(this.props.location.search).depart_stop,
        arrive_stop: queryStringToJSON(this.props.location.search).arrive_stop,
        remark: this.state.remark,
        depart_price: queryStringToJSON(this.props.location.search).price,
        depart_amount: queryStringToJSON(this.props.location.search).price,
        letter: queryStringToJSON(this.props.location.search).letter,
        remainingSeats: queryStringToJSON(this.props.location.search).remainingSeats
      }
      if(queryStringToJSON(this.props.location.search).return_slot) {
        data.return_slot = queryStringToJSON(this.props.location.search).return_slot;
        data.return_depart_stop = queryStringToJSON(this.props.location.search).return_depart_stop;
        data.return_arrive_stop = queryStringToJSON(this.props.location.search).return_arrive_stop;
        data.returnCityCode = this.getCityCode(queryStringToJSON(this.props.location.search).arriveCity);
        data.return_schedule_bus = queryStringToJSON(this.props.location.search).return_schedule_bus;
        data.return_date = queryStringToJSON(this.props.location.search).return_date;
        data.return_price = queryStringToJSON(this.props.location.search).return_price;
        data.return_amount = queryStringToJSON(this.props.location.search).return_price;
        data.letter1 = queryStringToJSON(this.props.location.search).letter1;
        data.remainingSeatsReturn = queryStringToJSON(this.props.location.search).remainingSeatsReturn;
        data.returnDepartCity = queryStringToJSON(this.props.location.search).returnDepartCity;
        data.returnArriveCity = queryStringToJSON(this.props.location.search).returnArriveCity;
      }
      if(this.state.price) {
        data.depart_price = parseFloat(this.state.price);
        if(queryStringToJSON(this.props.location.search).return_slot) {
          data.return_price = parseFloat(this.state.price);
        }
      }
      data.seats = this.getSeatsPrice(data.seats, this.state.useCredits, this.state.usePoints);
      data.credit = this.state.totalCredits;
      data.voucher = this.state.totalVouchers;
      data.voucherAmount = this.state.voucherAmount;
      if(data.credit > 0) {
        data.paymentMethod = 'Tripperbus Credits'
      }
      if(data.voucherAmount > 0) {
        data.paymentMethod = 'Tripperbus Points'
      }
      if((this.state.paymentMethod === 'card' || this.state.paymentMethod === 'Ivy') && !this.state.useCredits && !this.state.usePoints) {
        data.payment = this.getTotal();
      }
      else {
        data.payment = this.state.payment;
      }
      if(this.state.paymentMethod === 'card' && data.payment) {
        if(!this.state.number || !this.state.cvv || !this.state.month || !this.state.year) {
          return;
        }
        data.number = this.state.number.split('-').join('');
        data.cvv = this.state.cvv;
        data.expiryDate = '' + this.state.month + '' + this.state.year;
        data.cardHolder = this.state.cardHolder || (this.state.firstName + ' ' + this.state.lastName);
        data.paymentMethod = 'card';
      }
      this.setState({loading: true});
      reservationService.addMainReservation(data)
      .then(result => {
        console.log(result);
        let obj = {
          departTrip: {
            code: result.departTripCode,
            address: queryStringToJSON(this.props.location.search).depart_address,
            time: queryStringToJSON(this.props.location.search).slot,
            date: queryStringToJSON(this.props.location.search).date,
            name: this.state.firstName + ' ' + this.state.lastName,
            seats: this.state.seats,
            email: this.state.email
          }
        }
        if(result.returnTripCode) {
          obj.returnTrip = {
            code: result.returnTripCode,
            address: queryStringToJSON(this.props.location.search).return_address,
            time: queryStringToJSON(this.props.location.search).return_slot,
            date: queryStringToJSON(this.props.location.search).return_date,
            name: this.state.firstName + ' ' + this.state.lastName
          };
        }
        this.setState({
          result: obj,
          loading: false
        });
        this.setState({
          submitted: false,
          seats: 1,
          paymentMethod: 'card',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          cardHolder: '',
          number: '',
          month: '',
          year: '',
          cvv: '',
          zip: '',
          price: '',
          remark: ''
        });
        window.scrollTo(0, 0);
      })
      .catch(err => {
        console.log(err);
        this.setState({loading: false, error: err});
      });
    }
  }

  getTotal() {
    let total = (this.state.price || queryStringToJSON(this.props.location.search).price)*this.state.seats + RESERVATION_FEE + FUEL_SURCHARGE;
    if(queryStringToJSON(this.props.location.search).return_slot) {
      total += (this.state.price || queryStringToJSON(this.props.location.search).return_price)*this.state.seats;
    }
    return total;
  }

  getStopSeats() {
    let search = queryStringToJSON(this.props.location.search);
    if(search.return_slot) {
      return 'Depart Stop Total: '+search.depart_stop_total + ', Depart Bus Total: ' + search.depart_bus_total + '. Return Stop Total: '+search.return_stop_total + ', Return Bus Total: ' + search.return_bus_total;
    }
    return 'Stop Total: '+search.depart_stop_total + ', Bus Total: ' + search.depart_bus_total;
  }

  getDates(){
    let search = queryStringToJSON(this.props.location.search);

    if(search.return_date){
      return `Depart Date: ${search.date} , Return Date ${search.return_date}` 
    }else{
      return `Depart Date: ${search.date}`
    }
  }

  render() {
    const { submitted } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          {this.state.result && this.state.result.departTrip && <Row>
            <Col md={12}>
              <div className="res-confirmed">
                <div>
                  <div>OUTBOUND RESERVATION #: {config.formatReservationNumber(this.state.result.departTrip.code)}</div>
                  <div>(Name: {this.state.result.departTrip.name} DOT: {moment(this.state.result.departTrip.date, 'YYYY-MM-DD').format('dddd MMMM DD YYYY')} From: {this.state.result.departTrip.address} At: {moment(this.state.result.departTrip.time, 'HH:mm:ss').format('hh:mm A')} Seats: {this.state.result.departTrip.seats})</div>
                </div>
                {this.state.result.returnTrip && <div>
                  <div>RETURN RESERVATION #: {config.formatReservationNumber(this.state.result.returnTrip.code)}</div>
                  <div>(Return DOT: {moment(this.state.result.returnTrip.date, 'YYYY-MM-DD').format('dddd MMMM DD YYYY')} From: {this.state.result.returnTrip.address} At: {moment(this.state.result.returnTrip.time, 'HH:mm:ss').format('hh:mm A')})</div>
                </div>}
                <div>Customer was just emailed the reservation information (to email address: {this.state.result.departTrip.email})</div>
              </div>
            </Col>
          </Row>}
          {this.state.pastDate && <Row>
            <Col md={12}>
              <div className="past-date">The date for this reservation is in the past</div>
            </Col>
          </Row>}
          <Row>
            <Col md={12}>
              <Card
                title="Add Reservation"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    {queryStringToJSON(this.props.location.search).return_slot && <div className="return-info">Bus From {queryStringToJSON(this.props.location.search).depart_address} on {moment(queryStringToJSON(this.props.location.search).slot, 'HH:mm:ss').format('hh:mm a')} and Returning From {queryStringToJSON(this.props.location.search).return_address} on {moment(queryStringToJSON(this.props.location.search).return_slot, 'HH:mm:ss').format('hh:mm a')}</div>}
                    <div className="return-info price-breakdown">Price Breakdown: From {queryStringToJSON(this.props.location.search).departCity} - To {queryStringToJSON(this.props.location.search).arriveCity}: {numeral(this.state.price || queryStringToJSON(this.props.location.search).price).format('$0.00')} per person. {queryStringToJSON(this.props.location.search).return_slot && ('Return From ' + queryStringToJSON(this.props.location.search).returnDepartCity + ' To ' + queryStringToJSON(this.props.location.search).returnArriveCity +': ' + numeral(this.state.price || queryStringToJSON(this.props.location.search).return_price).format('$0.00') + ' per person.')} (Also included in total {RESERVATION_FEE} Reservation fee & {FUEL_SURCHARGE} Fuel Surcharge). Total : {numeral(this.getTotal()).format('$0.00')}<div className="stop-seats">{this.getDates() +' , ' + this.getStopSeats()}</div></div>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: this.state.email,
                          name: 'email',
                          onChange: this.handleChange,
                          onBlur: this.getCustomerDetails
                        },
                        {
                          label: "First Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First Name",
                          value: this.state.firstName,
                          name: 'firstName',
                          onChange: this.handleChange
                        },
                        {
                          label: "Last Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last Name",
                          value: this.state.lastName,
                          name: 'lastName',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={4}>{submitted && !this.state.email && <div className="error">Email is required</div>}</Col>
                      <Col md={4}>{submitted && !this.state.firstName && <div className="error">First name is required</div>}</Col>
                      <Col md={4}>{submitted && !this.state.lastName && <div className="error">Last name is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Phone",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Phone",
                          value: this.state.phone,
                          name: 'phone',
                          onChange: this.handleChange
                        },
                        {
                          label: "Zip",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "zip",
                          value: this.state.zip,
                          name: 'zip',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !this.state.phone && <div className="error">Phone is required</div>}</Col>
                      <Col md={6}>{submitted && !this.state.zip && this.state.paymentMethod !== 'Ivy' && <div className="error">Zip is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Seats",
                          bsClass: "form-control",
                          componentClass: "select",
                          placeholder: "Seats",
                          value: this.state.seats,
                          name: 'seats',
                          options: this.state.seatOptions,
                          onChange: this.handleChange
                        },
                        {
                          label: "Payment Method",
                          type: "text",
                          componentClass: "select",
                          bsClass: "form-control",
                          placeholder: "Payment Method",
                          value: this.state.paymentMethod,
                          name: 'paymentMethod',
                          options: this.state.methods,
                          onChange: this.handleChange
                        },
                        {
                          label: (this.state.paymentMethod === 'Ivy' ? 'Price Per Seat Paid Through Ivy Media' : 'Custom Price Per Seat'),
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "price",
                          value: this.state.price,
                          name: 'price',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={4}>{submitted && !this.state.seats && <div className="error">Seats is required</div>}</Col>
                      <Col md={4}>{submitted && !this.state.paymentMethod && <div className="error">Method is required</div>}</Col>
                      <Col md={4}>{submitted && this.state.paymentMethod === 'Ivy' && !this.state.price && <div className="error">Price per seat is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Remark",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Remark",
                          value: this.state.remark,
                          name: 'remark',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    {this.state.paymentMethod === 'card' && this.state.credits > 0 &&
                      <div className="row clearfix">
                        <Col md={12}>
                          <div className="apply-credit-row">Available credits in user account: {numeral(this.state.credits).format('$0.00')}
                            {!this.state.useCredits ? <Button bsStyle="info" fill onClick={this.applyCredit.bind(this)}>Apply</Button> : ''}
                            {this.state.useCredits ? <Button bsStyle="danger" fill onClick={this.removeCredit.bind(this)}>Subtract</Button> : ''}
                          </div>
                        </Col>
                      </div>
                    }
                    {this.state.paymentMethod === 'card' && this.state.points > 0 &&
                      <div className="row clearfix">
                        <Col md={12}>
                          <div className="apply-credit-row">Available points in user account: {this.state.points}
                            {!this.state.usePoints ? <Button disabled={this.checkDisabledPoints()} bsStyle="info" fill onClick={this.applyPoints.bind(this)}>Apply</Button> : ''}
                            {this.state.usePoints ? <Button bsStyle="danger" fill onClick={this.removePoints.bind(this)}>Remove</Button> : ''}
                          </div>
                        </Col>
                      </div>
                    }
                    {this.state.useCredits && this.state.totalCredits > 0 ?
                      <div className="row clearfix">
                        <Col md={12}>
                          <div className="apply-credit-row">
                            Applied ${this.state.totalCredits} Worth of Credit
                          </div>
                        </Col>
                      </div> : ''
                    }
                    {(this.state.paymentMethod === 'card' && (this.state.payment > 0 || this.state.usePoints || this.state.useCredits)) ?
                      <div className="row clearfix">
                        <Col md={12}>
                          <div className="apply-credit-row">
                            Total Payment to be made : {this.state.useCredits || this.state.usePoints ? this.state.payment : this.getTotal()}
                          </div>
                        </Col>
                      </div> : ''
                    }
                    {this.state.paymentMethod === 'card' && ((!this.state.usePoints && !this.state.useCredits) || (this.state.payment > 0)) &&
                      <div>
                        <div className="row clearfix">
                          <div className="col-sm-6 form-group">
                            <label className="formLable">NAME ON CARD (If different from above)</label>
                            <input ref="cardHolder" name="cardHolder" required={this.state.payment > 0} value={this.state.cardHolder} onChange={this.handleChange.bind(this)} type="text" className="form-control" placeholder="Name On Card" />
                            {submitted && !this.state.cardHolder && <div className="error">Name is required</div>}
                          </div>
                          <div className="col-sm-6 form-group">
                            <label className="formLable">CREDIT CARD NUMBER</label>
                            <InputMask maskChar='' mask="9999-9999-9999-9999" ref="number" onChange={this.handleChange.bind(this)} value={this.state.number} name="number" required={this.state.payment > 0} className='form-control' placeholder="Credit Card Number" />
                            {submitted && !this.state.number && <div className="error">Number is required</div>}
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-sm-4 form-group">
                            <label className="formLable">EXMONTH</label>
                            <select ref="month" required={this.state.payment > 0} name="month" value={this.state.month} onChange={this.handleChange.bind(this)} className='form-control'>
                              <option value=''>MM</option>
                              {this.state.months.map((item, index) => {
                                return (
                                  <option key={index}>{item}</option>
                                )
                              })}
                            </select>
                            {submitted && !this.state.month && <div className="error">Month is required</div>}
                          </div>
                          <div className="col-sm-4 form-group">
                            <label className="formLable">EXYEAR</label>
                            <select ref="year" name="year" value={this.state.year} onChange={this.handleChange.bind(this)} required={this.state.payment > 0} className='form-control'>
                              <option value=''>YY</option>
                              {this.state.years.map((item, index) => {
                                return (
                                  <option key={index}>{item}</option>
                                )
                              })}
                            </select>
                            {submitted && !this.state.year && <div className="error">Year is required</div>}
                          </div>
                          <div className="col-sm-4 form-group">
                            <label className="formLable">SEC CODE</label>
                            <InputMask maskChar='' mask="9999" ref="cvv"required={this.state.payment > 0} onChange={this.handleChange.bind(this)} value={this.state.cvv} name="cvv" className='form-control' placeholder="SEC" />
                            {submitted && !this.state.cvv && <div className="error">Cvv is required</div>}
                          </div>
                        </div>
                      </div>
                    }
                    <Button bsStyle="info" disabled={this.state.loading} fill type="submit">
                      Add Reservation
                    </Button>
                    {this.state.loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    {this.state.error && <div className="error">{this.state.error}</div>}
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
 
export default AddMainReservation;
