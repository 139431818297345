import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
 
import { history } from '../helpers/history';
import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Login from "views/Login/Login.jsx";
import DriverSheet from "views/Main/DriverSheet";
 
class App extends React.Component {
  render() {
    return (
      <Router history={history}>
      	<Switch>
			    <Route path="/login" component={Login} />
			    <Route path="/dashboard" component={Dashboard} />
          <Route path="/driver-sheet" component={DriverSheet} />
			    <Redirect exact path="/" from="/" to="/dashboard" component={Dashboard} />
			  </Switch>
      </Router>
    );
  }
}
 
export default App;