import orderBy from 'lodash/orderBy';
import { GET_MAIN_REQUEST, GET_MAIN_SUCCESS, GET_MAIN_RETURN_SUCCESS, GET_MAIN_RETURN_REQUEST, GET_MAIN_FAILURE, MOVE_REQUEST, MOVE_SUCCESS, MOVE_FAILURE, SAVE_BUS_REQUEST, SAVE_BUS_SUCCESS, SAVE_BUS_FAILURE, GET_SHEET_REQUEST, GET_SHEET_SUCCESS, GET_SHEET_FAILURE, SET_MAIN_PROPS} from '../constants';
import { mainService } from '../services/main.services';
import { history } from '../helpers/history';
 
export default {
  setMainProps,
  getMain,
  getMainReturn,
  movePeople,
  getDriverSheet,
  saveScheduleBus,
  updateDriverSheet
};
 
function getMain(filters) {
  return dispatch => {
    dispatch(request());
 
    mainService.getMain(filters)
      .then(
        data => {
          let main = {};
          main.data = data;
          dispatch(success(main));
        },
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_MAIN_REQUEST } }
  function success(main) { return { type: GET_MAIN_SUCCESS, main } }
  function failure(error) { return { type: GET_MAIN_FAILURE, error } }
}

function getMainReturn(filters) {
  return dispatch => {
    dispatch(request());
 
    mainService.getMain(filters)
      .then(
        data => {
          let main = {};
          main.data = data;
          dispatch(success(main));
        },
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_MAIN_RETURN_REQUEST } }
  function success(main) { return { type: GET_MAIN_RETURN_SUCCESS, main } }
  function failure(error) { return { type: GET_MAIN_FAILURE, error } }
}

function setMainProps(filters) {
  return dispatch => {
    let main = {};
    main.departCity = filters.departCity;
    main.departDate = filters.departDate;
    main.arriveCity = filters.arriveCity;
    main.returnDate = filters.returnDate;
    main.returnDepartCity = filters.returnDepartCity;
    main.returnArriveCity = filters.returnArriveCity;
    dispatch(success(main));
    history.push('/dashboard/main-results')
  };
 
  function success(main) { return { type: SET_MAIN_PROPS, main } }
}

function getDriverSheet(filters) {
  return dispatch => {
    dispatch(request());
 
    mainService.getSheet(filters)
      .then(
        data => {
          let result = [];
          if (data && data.length) {
            result = data.map(item => ({...item, reservations: item.reservations}));
          }
          dispatch(success(result));
        },
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: GET_SHEET_REQUEST } }
  function success(data) { return { type: GET_SHEET_SUCCESS, data } }
  function failure(error) { return { type: GET_SHEET_FAILURE, error } }
}

function updateDriverSheet(data) {
  return { type: GET_SHEET_SUCCESS, data };
}

function movePeople(data) {
  return dispatch => {
    dispatch(request());
 
    mainService.movePeople(data)
      .then(
        data => {
          dispatch(success(data));
        },
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: MOVE_REQUEST } }
  function success(data) { return { type: MOVE_SUCCESS, data } }
  function failure(error) { return { type: MOVE_FAILURE, error } }
}

function saveScheduleBus(data) {
  return dispatch => {
    dispatch(request());
 
    mainService.saveScheduleBus(data)
      .then(
        data => {
          dispatch(success(data));
        },
        error => dispatch(failure(error.toString()))
      );
  };
 
  function request() { return { type: SAVE_BUS_REQUEST } }
  function success(data) { return { type: SAVE_BUS_SUCCESS, data } }
  function failure(error) { return { type: SAVE_BUS_FAILURE, error } }
}