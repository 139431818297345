import { combineReducers } from 'redux';
import authentication from './authentication.reducer';
import users from './user.reducer';
import customers from './customer.reducer';
import stops from './stop.reducer';
import coupons from './coupon.reducer';
import payments from './payment.reducer';
import orders from './order.reducer';
import orderItems from './order_item.reducer';
import reservations from './reservation.reducer';
import reservationRemarks from './reservation_remark.reducer';
import permissions from './permission.reducer';
import vouchers from './voucher.reducer';
import cashCredits from './cash_credit.reducer';
import giftCards from './gift_card.reducer';
import buses from './bus.reducer';
import schedule from './schedule.reducer';
import notices from './notice.reducer';
import topbar from './topbar.reducer';
import main from './main.reducer';
export default combineReducers({
  authentication,
  users,
  customers,
  stops,
  coupons,
  reservations,
  payments,
  reservationRemarks,
  permissions,
  buses,
  schedule,
  orderItems,
  cashCredits,
  giftCards,
  vouchers,
  orders,
  notices,
  topbar,
  main
});