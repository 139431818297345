import { GET_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE, GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE, ADD_ORDER_REQUEST, ADD_ORDER_SUCCESS, ADD_ORDER_FAILURE, UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE, DELETE_ORDER_REQUEST, DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ORDERS_SUCCESS:
      return {
        loading: false,
        items: action.orders.orders,
        total: action.orders.total
      };
    case GET_ORDERS_FAILURE:
      return {
        error: action.error
      };
    case GET_ORDER_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_ORDER_SUCCESS:
      return {
        loading: false,
        item: action.order
      };
    case GET_ORDER_FAILURE:
      return {
        error: action.error
      };
    case ADD_ORDER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_ORDER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_ORDER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_ORDER_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_ORDER_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_ORDER_REQUEST:
      // add 'deleting:true' property to order being deleted
      return {
        ...state,
        items: state.items.map(order =>
          order.id === action.id
            ? { ...order, deleting: true }
            : order
        )
      };
    case DELETE_ORDER_SUCCESS:
      // remove deleted order from state
      return {
        items: state.items.filter(order => order.id !== action.id)
      };
    case DELETE_ORDER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to order 
      return {
        ...state,
        items: state.items.map(order => {
          if (order.id === action.id) {
            // make copy of order without 'deleting:true' property
            const { deleting, ...orderCopy } = order;
            // return copy of order with 'deleteError:[error]' property
            return { ...orderCopy, deleteError: action.error };
          }
 
          return order;
        })
      };
    default:
      return state
  }
}