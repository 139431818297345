import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import mainActions from 'actions/main.actions';
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import config from 'config';
import _ from 'lodash';

import moment from 'moment-timezone';
import CustomPrice from "../../components/Popup/CustomPrice";
import { constructPricesMap } from "../../helpers/utility";
import stopActions from 'actions/stop.actions';

//moment.tz.setDefault('America/New_York');

class MovePeople extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departDate: moment(),
      departCity: '',
      arriveCity: '',
      returnDate: null,
      departingSchedules: [],
      arrivingSchedules: [],
      moveDepart: {},
      moveReturn: {},
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(stopActions.getStops({}));
    if(this.props.departingSchedules && this.props.departingSchedules.length) {
      let data = _.orderBy(this.props.departingSchedules, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
      this.setState({departingSchedules: data});
      let move = this.state.moveDepart;
      for(let i = 0; i < data.length; i++) {
        for(let k = 0; k < data[i].stops.length; k++) {
          move[data[i].stops[k].id] = {
            reason: '',
            noteChange: 'yes',
            email: 'yes',
            target: ''
          };
        }   
      }
      this.setState({moveDepart: move});
    }
    if(this.props.arrivingSchedules && this.props.arrivingSchedules.length) {
      let data = _.orderBy(this.props.arrivingSchedules, (item) => {
        return moment(item.stops[0].time, 'HH:mm:ss');
      });
      this.setState({arrivingSchedules: data});
      let move = this.state.moveReturn;
      for(let i = 0; i < data.length; i++) {
        for(let k = 0; k < data[i].stops.length; k++) {
          move[data[i].stops[k].id] = {
            reason: '',
            noteChange: 'yes',
            email: 'yes',
            target: ''
          };
        }   
      }
      this.setState({moveReturn: move});
    }
    if(this.props.departCity) {
      this.setState({
        departCity: this.props.departCity,
        arriveCity: this.props.arriveCity,
        departDate: moment(this.props.departDate, 'YYYY-MM-DD')
      });
      if(this.props.returnDate) {
        this.setState({returnDate: moment(this.props.returnDate, 'YYYY-MM-DD')});
      }
    }
  }

  getDestStop(stops) {
    let dest = null;
    for(let i = 0; i < stops.length; i++) {
      if(this.state.arriveCity === stops[i].stop.city) {
        dest = stops[i].id;
      }
    }
    return dest;
  }

  getDestStop1(stops) {
    let dest = null;
    for(let i = 0; i < stops.length; i++) {
      if(this.state.departCity === stops[i].stop.city) {
        dest = stops[i].id;
      }
    }
    return dest;
  }

  getMovableStops(stop, type) {
    let arr = [], stop1 = null;
    if(type === 1) {
      for(let i = 0; i < this.state.departingSchedules.length; i++) {
        for(let k = 0; k < this.state.departingSchedules[i].stops.length; k++) {
          stop1 = this.state.departingSchedules[i].stops[k];
          if(stop1.id !== stop.id && stop1.type === 'PU') {
            console.log(1)
            let obj = {
              label: 'to the ' + moment(stop1.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop1.stop.address + ' (Time ID: ' + stop1.timeId + ')',
              value: {
                stop: stop1.id,
                bus: this.state.departingSchedules[i].schedule_bus,
                time: stop1.time,
                label: 'Reservations moved from ' + moment(stop.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop.stop.address + ' to the ' + moment(stop1.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop1.stop.address,
                reservationMovedFromTime:  moment(stop.time, 'HH:mm:ss').format('hh:mm a'),
                reservationMovedToTime: moment(stop1.time, 'HH:mm:ss').format('hh:mm a'),
                reservationMovedFromAddress:  stop.stop.address,
                reservationMovedToAddress: stop1.stop.address
              }
            };
            let dest = this.getDestStop(this.state.departingSchedules[i].stops);
            if(dest) {
              obj.value.destination = dest;
              obj.value = JSON.stringify(obj.value);
              arr.push(obj);
            }
          }
        }   
      }
    }
    else {
      for(let i = 0; i < this.state.arrivingSchedules.length; i++) {
        for(let k = 0; k < this.state.arrivingSchedules[i].stops.length; k++) {
          stop1 = this.state.arrivingSchedules[i].stops[k];
          if(stop1.id !== stop && stop1.type === 'PU') {
            let obj = {
              label: 'to the ' + moment(stop1.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop1.stop.address + ' (Time ID: ' + stop1.timeId + ')',
              value: {
                stop: stop1.id,
                bus: this.state.arrivingSchedules[i].schedule_bus,
                time: stop1.time,
                label: 'Reservations moved from ' + moment(stop.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop.stop.address + ' to the ' + moment(stop1.time, 'HH:mm:ss').format('hh:mm a') + ' bus from ' + stop1.stop.address,
                reservationMovedFromTime:  moment(stop.time, 'HH:mm:ss').format('hh:mm a'),
                reservationMovedToTime: moment(stop1.time, 'HH:mm:ss').format('hh:mm a'),
                reservationMovedFromAddress:  stop.stop.address,
                reservationMovedToAddress: stop1.stop.address
              }
            };
            let dest = this.getDestStop1(this.state.arrivingSchedules[i].stops);
            if(dest) {
              obj.value.destination = dest;
              obj.value = JSON.stringify(obj.value);
              arr.push(obj);
            }
          }
        }   
      }
    }
    console.log(arr)
    return arr;
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  handleChange(stop, type, event) {
    if(type === 1) {
      let move = this.state.moveDepart;
      move[stop][event.target.name] = event.target.value;
      this.setState({moveDepart: move});
    }
    else {
      let move = this.state.moveReturn;
      move[stop][event.target.name] = event.target.value;
      this.setState({moveReturn: move});
    }
  }

  movePeople(e) {
    e.preventDefault();
    let body = {};
    let moveDepart = {}, moveReturn = {};
    for(let key in this.state.moveDepart) {
      if(this.state.moveDepart[key].target) {
        console.log(this.state.moveDepart[key].target)
        moveDepart[key] = this.state.moveDepart[key];
        moveDepart[key]['target'] = JSON.parse(this.state.moveDepart[key].target);
      }
    }
    if(Object.keys(moveDepart).length > 0) {
      console.log(moveDepart)
      body.moveDepart = moveDepart;
      body.departDate = this.props.departDate;
    }
    for(let key in this.state.moveReturn) {
      if(this.state.moveReturn[key].target) {
        moveReturn[key] = this.state.moveReturn[key];
        moveReturn[key]['target'] = JSON.parse(this.state.moveReturn[key].target);
      }
    }
    if(Object.keys(moveReturn).length > 0) {
      body.moveReturn = moveReturn;
      body.returnDate = this.props.returnDate;
    }
    if(body.moveDepart || body.moveReturn) {
      this.props.dispatch(mainActions.movePeople(body));
    }
  }

  showEditPrice(schedule_bus, index){
    let departStops = schedule_bus.stops.filter((stop)=> stop.type === "PU" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let destinationStops = schedule_bus.stops.filter((stop)=> stop.type === "DO" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let prices = schedule_bus.prices;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.props.stops);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index});
  }

  render() {
    const { departDate, returnDate, departCity, arriveCity, departingSchedules, arrivingSchedules } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Move People Mode"
                rightButton={
                  <NavLink to="/dashboard/main-results"><Button type="button" fill bsStyle="info">Back To Search Results</Button></NavLink>
                }
                content={
                  <div>
                    <Row className="main-box-container">
                      <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="border-black head">
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && !departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial && departingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {departingSchedules && departingSchedules[0] && departingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{departingSchedules[0].name}</div>
                            }
                            From {this.getCityLabel(departCity)} - {moment(departDate).format('dddd, MMM D YYYY')}
                          </div>
                          {departingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="border-black bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' &&
                                      <div className="move-inputs" key={i}>
                                        <div className="width-90 border-black">
                                          <div className="stop-time">Time ID: {stop.timeId}</div>
                                          <div className="stop-time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')} from {stop.stop.address}</div>
                                          <div className="form-group">
                                            <select name="target" className="form-control" value={this.state.moveDepart[stop.id].target} onChange={this.handleChange.bind(this, stop.id, 1)}>
                                              <option value=''>Move all these  {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} people to...</option>
                                              {
                                                this.getMovableStops(stop, 1).map((moveStop, index) => {
                                                  return (<option key={index} value={moveStop.value}>{moveStop.label}</option>)
                                                })
                                              }
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Note change in each reservation's notes:</label>
                                            <select className="form-control" name="noteChange" value={this.state.moveDepart[stop.id].noteChange} onChange={this.handleChange.bind(this, stop.id, 1)}>
                                              <option>yes</option>
                                              <option>no</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Reason for change:</label>
                                            <input className="form-control" name="reason" value={this.state.moveDepart[stop.id].reason} onChange={this.handleChange.bind(this, stop.id, 1)} />
                                          </div>
                                          <div className="form-group">
                                            <label>Email the people & give them above reason:</label>
                                            <select className="form-control" name="email" value={this.state.moveDepart[stop.id].email} onChange={this.handleChange.bind(this, stop.id, 1)}>
                                              <option>yes</option>
                                              <option>no</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="width-10 border-black">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className="row bus-info-grid">
                                  <div className="col-md-12">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {bus.capacity}</div>
                                  <div className="col-md-6">
                                  {bus.prices ?<div>Price:  <button onClick={()=>{this.showEditPrice(bus, index)}}>View Price</button> </div> :<span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp;
                                      {bus.bus_type.defaultPrice === bus.price && <Fragment>${bus.price} (unchanged)</Fragment>}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {bus.prices && bus.bus_type.defaultPrice !== bus.price ? <Fragment>${bus.price} (custom price for this route)</Fragment> : bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''}
                                    </span>}
                                  </div>
                                  <div className={'col-md-6 ' + (!bus.isOpen ? 'red-text-price' : '')}>Bus is <b>{bus.isOpen ? 'Open' : 'Closed'}</b></div>
                                  <div className="col-md-6"><b>Driver:</b> {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : 'Not Specified'}</div>
                                  <div className="col-md-6"><b>Company:</b> {bus.bus_type.company}</div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {bus.couponLimit ? bus.couponLimit : 0}</div>
                                  {bus.notes && <div className="col-md-6"><b>Notes:</b> {bus.notes}</div>}
                                  <div className="col-md-6"><b>Destinations: </b>
                                    {bus.stops.map((stop, i) => {
                                      return (
                                        stop.type === 'DO' && <span key={i}>{stop.stop.address}, </span>
                                      )
                                    })}
                                  </div>
                                  {/* <div className="total-row">Total: {bus.reservations} On Bus: {bus.checked} Max Capacity: {bus.capacity}</div> */}
                                  {/* <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div> */}
                                  {/* <div className="main-driver">Company: {bus.bus_type.company}</div> */}
                                  {/* <div className="main-price-label">Coupons Allowed:</div>
                                  <div className="main-price-value">{bus.couponLimit}</div> */}
                                  {/* <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                                  {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                                  {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                                  {bus.notes && <div className="main-price-value">{bus.notes}</div>} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                      {returnDate && <Col md={(returnDate ? 6 : 12)}>
                        <div className="main-schedule-box">
                          <div className="border-black head">
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && !arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial && arrivingSchedules[0].specialPrice &&
                              <div className="special-main-schedule">--Date has special schedule and price--</div>
                            }
                            {arrivingSchedules && arrivingSchedules[0] && arrivingSchedules[0].isSpecial &&
                              <div className="special-main-schedule">{arrivingSchedules[0].name}</div>
                            }
                            Leaving from {this.getCityLabel(arriveCity)} on <br/> {moment(returnDate).format('dddd, MMMM Do YYYY')}
                          </div>
                          {arrivingSchedules.map((bus, index) => {
                            return (
                              <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                                <div className="border-black bus-number">Bus #{index+1}:</div>
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'PU' && <div className="move-inputs" key={i}>
                                        <div className="width-90 border-black">
                                          <div className="stop-time">Time ID: {stop.timeId}</div>
                                          <div className="stop-time">{moment(stop.time, 'HH:mm:ss').format('hh:mm a')} from {stop.stop.address}</div>
                                          <div className="form-group">
                                            <select className="form-control" value={this.state.moveReturn[stop.id].target} onChange={this.handleChange.bind(this, stop.id)}>
                                              <option value=''>Move all these {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} people to...</option>
                                              {
                                                this.getMovableStops(stop).map((moveStop, index) => {
                                                  return (<option key={index} value={moveStop.value}>{moveStop.label}</option>)
                                                })
                                              }
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Note change in each reservation's notes:</label>
                                            <select className="form-control" value={this.state.moveReturn[stop.id].noteChange} onChange={this.handleChange.bind(this, stop.id)}>
                                              <option>yes</option>
                                              <option>no</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Reason for change:</label>
                                            <input className="form-control" value={this.state.moveReturn[stop.id].reason} onChange={this.handleChange.bind(this, stop.id)} />
                                          </div>
                                          <div className="form-group">
                                            <label>Email the people & give them above reason:</label>
                                            <select className="form-control" value={this.state.moveReturn[stop.id].email} onChange={this.handleChange.bind(this, stop.id)}>
                                              <option>yes</option>
                                              <option>no</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="width-10 border-black">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className="row bus-info-grid">
                                  <div className="col-md-12">Total: {bus.reservations} | On Bus: {bus.checked} | Max Capacity: {bus.capacity}</div>
                                  <div className="col-md-6">
                                    <span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp;
                                      {bus.bus_type.defaultPrice === bus.price && <Fragment>${bus.price} (unchanged)</Fragment>}
                                      {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''}
                                      {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''}
                                      {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''}
                                    </span>
                                  </div>
                                  <div className={'col-md-6 ' + (!bus.isOpen ? 'red-text-price' : '')}>Bus is <b>{bus.isOpen ? 'Open' : 'Closed'}</b></div>
                                  <div className="col-md-6"><b>Driver:</b> {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : 'Not Specified'}</div>
                                  <div className="col-md-6"><b>Company:</b> {bus.bus_type.company}</div>
                                  <div className="col-md-6"><b>Coupons Allowed:</b> {bus.couponLimit ? bus.couponLimit : 0}</div>
                                  {bus.notes && <div className="col-md-6"><b>Notes:</b> {bus.notes}</div>}
                                  <div className="col-md-6"><b>Destinations: </b>
                                    {bus.stops.map((stop, i) => {
                                      return (
                                        stop.type === 'DO' && <span key={i}>{stop.stop.address}, </span>
                                      )
                                    })}
                                  </div>
                                  {/* <div className="total-row">Total: {bus.reservations} On Bus: {bus.checked} Max Capacity: {bus.capacity}</div> */}
                                  {/* <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div> */}
                                  {/* <div className="main-driver">Company: {bus.bus_type.company}</div> */}
                                  {/* <div className="main-price-label">Coupons Allowed:</div>
                                  <div className="main-price-value">{bus.couponLimit}</div> */}
                                  {/* <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                                  {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSeats} seats)</div> : ''}
                                  {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                                  {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                                  {bus.notes && <div className="main-price-value">{bus.notes}</div>} */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Col>}
                      {<CustomPrice updatePrice={()=>{}} open={this.state.showModal} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={()=>{}} update={false}/>}
                    </Row>
                    {this.props.moveError && <div>{this.props.moveError}</div>}
                    {this.props.moveSuccess && <div>Changes Saved Successfully</div>}
                    <div className="move-btn"><Button bsStyle="info" fill onClick={this.movePeople.bind(this)} disabled={this.props.loading}>Save Changes</Button></div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, error, departingSchedules, arrivingSchedules, departCity, arriveCity, departDate, returnDate, moveError, moveSuccess } = state.main;
  return {
    loading,
    error,
    departingSchedules,
    arrivingSchedules,
    departCity,
    arriveCity,
    departDate,
    returnDate,
    moveError,
    moveSuccess,
    stops: state.stops.items, 

  };
}
 
export default connect(mapStateToProps)(MovePeople);
