import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Badge
} from "react-bootstrap";

import './Styles/Schedule.module.css';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import scheduleActions from 'actions/schedule.actions';
import stopActions from 'actions/stop.actions';
import busActions from 'actions/bus.actions';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import Popup from "reactjs-popup";
import CustomPrice from "../../components/Popup/CustomPrice";
import {constructPricesMap} from  "../../helpers/utility";

//moment.tz.setDefault('America/New_York');
class EditSchedule extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const activeDate = params.get('activeDate');
    this.props.dispatch(stopActions.getStops({}));
    this.props.dispatch(busActions.getBuses({}));
    this.props.dispatch(scheduleActions.getSchedule(this.props.match.params.id, activeDate));
    this.state = {
      submitted: false,
      formError: false,
      schedule: {
        dayOfWeek: '',
        isSpecial: false,
        specialDate: moment(),
        start: moment(),
        name: '',
        end: moment(),
        schedule_buses: []
      },
      stops: [],
      buses: [],
      availableLetters: [],
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      open: false,
      showModal: false,
      busChangeConfirmationValues: {index: null, name: '', value: ''},
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      deletedDepartStops: [],
      deletedDestinationStops: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleDepartStopChange = this.handleDepartStopChange.bind(this);
    this.handleDestinationStopChange = this.handleDestinationStopChange.bind(this);
    this.handleBusChange = this.handleBusChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleSpecialDate = this.handleSpecialDate.bind(this);
    this.handleDepartStopTime = this.handleDepartStopTime.bind(this);
    this.handleDestinationStopTime = this.handleDestinationStopTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  closeModal(){
    this.setState({open: false, busChangeConfirmationValues: {index: null, name: '', value: ''}});
    document.body.style.overflow='auto';
  }

  findBusTypeById(id){
    if(id){
      return _.find(this.state.buses, {id: id}).type
    }
  }

  getLetters(letter) {
    let arr = [];
    let usedLetters = this.state.schedule.schedule_buses.map((data)=>{
      return data.letter;
    })
    let filteredLetters = this.state.letters.filter((l)=>{
        if(l == letter){
          return true
        }
        return !usedLetters.includes(l);
    })

    for(let i = 0; i < filteredLetters.length; i++) {
      arr.push({label: filteredLetters[i], value: filteredLetters[i]});
    }

    return arr;
  }

  getCityNameFromUrl(param){
    let name = param.split("=");
    if(name.length == 2){
      return decodeURIComponent(name[1]);
    }

    return '';
  }

  getValueFromQueryParams(param, key){
    let searchParams = new URLSearchParams(param);
    return searchParams.get(key)
  }

  handleDepartStopChange(e, index, busIndex) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    let selectedValue = _.find(schedule.schedule_buses[busIndex].departStops, {stop: value})
    if(name === 'stop' && !selectedValue  || selectedValue && selectedValue.deleted == true) {
      schedule.schedule_buses[busIndex].departStops[index][name] = value;
this.setState({ schedule: schedule });
    }
else if(name !== 'stop') {
        schedule.schedule_buses[busIndex].departStops[index][name] = value;
        this.setState({ schedule: schedule }); 
      }
    }

  handleDestinationStopChange(e, index, busIndex) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    let selectedValueDepart = _.find(schedule.schedule_buses[busIndex].departStops, {stop: value})
    let selectedValueDestination = _.find(schedule.schedule_buses[busIndex].destinationStops, {stop: value})
    if((name === 'stop' && !selectedValueDestination && !selectedValueDepart) ||  (selectedValueDepart && selectedValueDepart.deleted  || selectedValueDestination && selectedValueDestination.deleted )  ) {
      schedule.schedule_buses[busIndex].destinationStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
    else if(name !== 'stop') {
      schedule.schedule_buses[busIndex].destinationStops[index][name] = value;
      this.setState({ schedule: schedule });
    }
  }

  openWarningAboutBusChange(){
    document.body.style.overflow='hidden';
    this.setState({open:true, })
  }

  setBusAfterConfirmation(){
    const {index, name, value} = this.state.busChangeConfirmationValues
    let schedule = this.state.schedule;
    schedule.schedule_buses[index][name] = value;
    this.setState({ schedule: schedule, open: false });
  }

   handleBusChange(index, e) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    console.log(name, value)
    if(!value) return


    if(name == 'bus_type' && this.findBusTypeById(value) === 'Elite' && schedule.schedule_buses[index].bookedSeats >= 34){
      this.setState({busChangeConfirmationValues: {index, name, value}})
      return this.openWarningAboutBusChange();
    }
    schedule.schedule_buses[index][name] = value;
    this.setState({ schedule: schedule });
  }

  handleStartDate(date) {
    let schedule = this.state.schedule;
    schedule.start = date;
    this.setState({ schedule: schedule });
  }

  handleEndDate(date) {
    let schedule = this.state.schedule;
    schedule.end = date;
    this.setState({ schedule: schedule });
  }

  handleSpecialDate(date) {
    let schedule = this.state.schedule;
    schedule.specialDate = date;
    schedule.start = date;
    schedule.end = date;
    this.setState({ schedule: schedule });
  }

  handleDepartStopTime(time, index, busIndex) {
    let schedule = this.state.schedule;
    
    // let incrementDuration = {
    //   'new york': {
    //     'arlington': 4.667,
    //     'bethesda': 4,
    //     'washington': 4
    //   },
    //   'arlington': {
    //     'new york': 4.667,
    //     'bethesda': 0.667,
    //     'washington': 0.667
    //   },
    //   'bethesda': {
    //     'new york': 4,
    //     'arlington': 0.667,
    //     'washington': 0.667
    //   },
    //   'washington': {
    //     'new york': 4,
    //     'arlington': 0.667,
    //     'bethesda': 0.667
    //   }
    // }

    
    schedule.schedule_buses[busIndex].departStops[index].time = time;
    
    // let mainDepartStop = this.getStopFromCode(schedule.schedule_buses[busIndex].departStops[index].stop) || 'new york';
    // if(schedule.schedule_buses[busIndex] && schedule.schedule_buses[busIndex].destinationStops[index]){
    //   schedule.schedule_buses[busIndex].destinationStops.map((stop, index)=>{
    //     let stopName = this.getStopFromCode(stop.stop);
    //       stop.time = moment(time).add('hours', incrementDuration[mainDepartStop][stopName]);
    //   })
    // }

    // if(
    //   (mainDepartStop== 'arlington')
    //     &&
    //     schedule.schedule_buses[busIndex].departStops.length >= 2
    //   ){
    //   schedule.schedule_buses[busIndex].departStops[1].time = moment(time).add(0.667, 'hours');
    // }
    this.setState({ schedule: schedule });
  }

  handleDestinationStopTime(time, index, busIndex) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[busIndex].destinationStops[index].time = time;
    this.setState({ schedule: schedule });
  }

  handleCheckbox = event => {
    let schedule = this.state.schedule;
    schedule.isSpecial = !schedule.isSpecial;
    if(schedule.isSpecial) {
      schedule.end = schedule.start;
    }
    this.setState({schedule: schedule});
  };

  handleCouponCheckbox(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].couponAllowed = !schedule.schedule_buses[index].couponAllowed;
    this.setState({schedule: schedule});
  }

  handleChange(e) {
    const { name, value } = e.target;
    let schedule = this.state.schedule;
    schedule[name] = value;
    this.setState({ schedule: schedule });
  }

  addDestinationStop(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].destinationStops.push({
      time: '',
      stop: ''
    });
    this.setState({schedule: schedule});
  }

  get_non_deleted_stops_length(stops){
      let count = 0;
      for(let i = 0; i < stops.length; i++){
        if(!stops[i].deleted){
          count+=1;
        }
      }
      return count;
  }

  addMiddleDestinationBethesda(index) {
    let schedule = this.state.schedule;
    if(this.get_non_deleted_stops_length(schedule.schedule_buses[index].destinationStops) <= 1){
      let deletedStop = _.find(schedule.schedule_buses[index].destinationStops, {deleted: true});
      const stopName = this.getStopFromCode(deletedStop.stop)
      let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
      deletedStop.deleted =  false
      if(stopName == 'bethesda'){
        deletedStop.time = moment(firstDepartureAddressTime).add(4,'hours');
    }
    else if(stopName == 'arlington'){
      deletedStop.time = moment(firstDepartureAddressTime).add(4.667,'hours');
    }
  }
  this.setState({schedule: schedule});
}

  addDepartStop(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].departStops.push({
      time: '',
      stop: ''
    });
    this.setState({schedule: schedule});
  }
  
  addBethesdaStop(index) {
    let schedule = this.state.schedule;
    if(this.get_non_deleted_stops_length(schedule.schedule_buses[index].departStops) <= 1){
      let deletedStop = _.find(schedule.schedule_buses[index].departStops, {deleted: true, });
      const stopName = this.getStopFromCode(deletedStop.stop)
      let firstDepartureAddressTime = schedule.schedule_buses[index].departStops[0].time
      deletedStop.deleted =  false
      if(stopName == 'bethesda'){
        deletedStop.time = moment(firstDepartureAddressTime).add(0.667,'hours');
    }
     
  }
    this.setState({schedule: schedule});
  }

  removeDepartStop(index, busIndex, id) {
    let schedule = this.state.schedule;
    if(id) {
      schedule.schedule_buses[busIndex].departStops[index].deleted = true;
      // schedule.schedule_buses[busIndex].departStops[index].time = moment(schedule.schedule_buses[busIndex].departStops[index].time).format('HH:mm:ss')

      schedule.schedule_buses[busIndex].deletedDepartStops.push(schedule.schedule_buses[busIndex].departStops[index]);
    }   
    schedule.schedule_buses[busIndex].departStops.splice(index, 1);
    
    this.updatePriceData(busIndex,   schedule.schedule_buses[busIndex])
    this.setState({schedule: schedule});
    
  }


  removeDestinationStop(index, busIndex, id) {
    let schedule = this.state.schedule;
    if(id) {
      schedule.schedule_buses[busIndex].destinationStops[index].deleted = true;
      // schedule.schedule_buses[busIndex].destinationStops[index].time = moment(schedule.schedule_buses[busIndex].destinationStops[index].time).format('HH:mm:ss')
      schedule.schedule_buses[busIndex].deletedDestinationStops.push(schedule.schedule_buses[busIndex].destinationStops[index]);

    }
    
    schedule.schedule_buses[busIndex].destinationStops.splice(index, 1);

    this.updatePriceData(busIndex,   schedule.schedule_buses[busIndex])
    this.setState({schedule: schedule});
  }

  handleOpenCheckbox(index) {
    let schedule = this.state.schedule;
    schedule.schedule_buses[index].isOpen = !schedule.schedule_buses[index].isOpen;
    this.setState({schedule: schedule});
  }

  addBus() {
    let schedule = this.state.schedule;    
    let remainingLetters = this.getLetters()
    let obj = {
      bus_type: '',
      schedule: '',
      priceOverride: '',
      firstSpecialSeats: '',
      lastSpecialSeats: '',
      firstSpecialSeatsPrice: '',
      lastSpecialSeatsPrice: '',
      departStops: [],
      destinationStops: [],
      couponAllowed: true,
      couponLimit: '',
      isOpen: true,
      notes: '',
      letter: remainingLetters && remainingLetters.length > 0 ? remainingLetters[0].value : "A"
    };

    if(this.state.buses.length > 0) {
      let busType = _.find(this.state.buses, {type: 'Standard'});
      if(busType){
        obj.bus_type = busType.id
      }
    }
    
    if(this.state.departCity === 'new york') {
      let defaultStop = _.find(this.state.stops, {city: 'new york'});
      let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
      let defaultStop2 = _.find(this.state.stops, {city: 'arlington'});
      obj.departStops = [
        {time: null,
        stop: defaultStop.id,
        deleted: false,
      }
      ];
      obj.destinationStops = [
        {
          time: null,
          stop: defaultStop1.id, 
          deleted: false
      },
        { time: null,
          stop: defaultStop2.id,
          deleted: false
        }
      ];
    }
    else if(this.state.departCity === 'arlington' || this.state.departCity === 'bethesda') {

      let defaultStop = _.find(this.state.stops, {city: 'arlington'});
      let defaultStop1 = _.find(this.state.stops, {city: 'bethesda'});
      let defaultStop2 = _.find(this.state.stops, {city: 'new york'});
        obj.departStops = [
          { time: null,
            stop: defaultStop.id,
            deleted: false,

          },
          {
            time: null,
            stop: defaultStop1.id,
            deleted: false,
          },
        ];
        obj.destinationStops = [
          {time: null,
            stop: defaultStop2.id,
            deleted: false,
          },
        ];
    }
    else {
      obj.departStops = [
        {
          time: null,
          stop: '', 
          deleted: false,
        }
      ];
      obj.destinationStops = [
        {
          time: null,
          stop: '',
          deleted: false,
        }
      ];
    }
    schedule.schedule_buses.push(obj);
    this.setState({schedule: schedule});
  }

  removeBus(index, id) {
    let schedule = this.state.schedule;
    if(id) {
      schedule.schedule_buses[index].deleted = true;
    }
    else {
      schedule.schedule_buses.splice(index, 1);
    }
    this.setState({schedule: schedule});
  }

  getDepartCity(id) {
    return _.find(this.state.stops, {id: id}).city;
  }

  getArriveCity(id) {
    return _.find(this.state.stops, {id: id}).city;
  }

  handleSubmit(e) {
    console.log("Submit")

    e.preventDefault();
    console.log("Submit")
    this.setState({ submitted: true, formError: false });
    const { dispatch } = this.props;
    if (this.state.schedule.start && this.state.schedule.end) {
      if(this.state.schedule.schedule_buses.length === 0) {
        this.setState({ formError: true });
        return;
      }
      if(this.state.schedule.schedule_buses.length > 0) {
        let invalidBus = false;
        this.state.schedule.schedule_buses.map(bus => {
          if(!bus.bus_type) {
            invalidBus = true;
          }
          if(!bus.letter && bus.deleted === false){
            invalidBus = true;
          }
          else if(!bus.couponAllowed && bus.couponLimit !== '' && bus.couponLimit < 1 ) {
            invalidBus = true;
          }
          else if(bus.departStops.length === 0) {
            invalidBus = true;
          }
          else if(bus.destinationStops.length === 0) {
            invalidBus = true;
          }
          else if(bus.departStops.length > 0){
            bus.departStops.map(stop => {
              if(!stop.stop || !stop.time) {
                invalidBus = true;
              }
              return stop;
            }); 
          }
          else if(bus.destinationStops.length > 0){
            bus.destinationStops.map(stop => {
              if(!stop.stop || !stop.time) {
                invalidBus = true;
              }
              return stop;
            }); 
          }
          return bus;
        });
        if(invalidBus) {
          this.setState({ formError: true });
          return;
        }
      }

      if(!this.state.schedule.isSpecial && !this.state.schedule.dayOfWeek) {
        this.setState({ formError: true });
        return;
      }

      let dataToSend = {
        dayOfWeek: this.state.schedule.dayOfWeek,
        start: moment(this.state.schedule.start).format('YYYY-MM-DD'),
        end: moment(this.state.schedule.end).format('YYYY-MM-DD')
      };
      if(this.state.schedule.isSpecial){
        dataToSend.isSpecial = true;
        dataToSend.specialDate = moment(this.state.schedule.specialDate).format('YYYY-MM-DD');
        dataToSend.name = this.state.schedule.name || '';
      }
      if(this.state.schedule.schedule_buses && this.state.schedule.schedule_buses.length > 0) {
        dataToSend.schedule_buses = JSON.parse(JSON.stringify(this.state.schedule.schedule_buses));
        dataToSend.schedule_buses.map(bus => {
          bus.priceOverride = bus.priceOverride || null;
          bus.firstSpecialSeats = bus.firstSpecialSeats || null;
          bus.lastSpecialSeats = bus.lastSpecialSeats || null;
          bus.firstSpecialSeatsPrice = bus.firstSpecialSeatsPrice || null;
          bus.lastSpecialSeatsPrice = bus.lastSpecialSeatsPrice || null;
          bus.couponLimit = bus.couponLimit || null;
          bus.schedule_bus_stops = [];
          if(bus.departStops && bus.departStops.length > 0) {
            for(let i = 0; i < bus.departStops.length; i++) {
              bus.schedule_bus_stops.push({
                time: moment(bus.departStops[i].time).format('HH:mm:ss'),
                pickOrDrop: 'PU',
                stop: bus.departStops[i].stop,
                id: bus.departStops[i].id,
                deleted: (bus.departStops[i].deleted || false)
              })
            }
          }
          if(bus.destinationStops && bus.destinationStops.length > 0) {
            for(let i = 0; i < bus.destinationStops.length; i++) {
              bus.schedule_bus_stops.push({
                time: moment(bus.destinationStops[i].time).format('HH:mm:ss'),
                pickOrDrop: 'DO',
                stop: bus.destinationStops[i].stop,
                id: bus.destinationStops[i].id,
                deleted: (bus.destinationStops[i].deleted || false)
              })
            }
          }
          if(bus.deletedDepartStops && bus.deletedDepartStops.length > 0 || bus.deletedDestinationStops && bus.deletedDestinationStops.length > 0){
            let deletedStops = [...bus.deletedDepartStops, ...bus.deletedDestinationStops]
            for(let i = 0; i < deletedStops.length; i++){
              bus.schedule_bus_stops.push({
                time: moment(deletedStops[i].time).format('HH:mm:ss'),
                pickOrDrop: deletedStops[i].pickOrDrop,
                stop: deletedStops[i].stop,
                id: deletedStops[i].id,
                deleted: (deletedStops[i].deleted || false)
              })
            }
            }
          
            
          delete bus['departStops'];
          delete bus['destinationStops'];
          delete bus['bookedSeats']
          delete bus['deletedDepartStops'];
          delete bus['deletedDestinationStops'];

          return bus;
        });
      }
      dispatch(
        scheduleActions.update(
          this.props.match.params.id,
          dataToSend
        )
      );
    }
  }

  getBusOptions() {
    let arr = [];
    for(let i = 0; i < this.state.buses.length; i++) {
      arr.push({label: this.state.buses[i].name, value: this.state.buses[i].id});
    }
    return arr;
  }

  componentWillUpdate(props) {
    console.log("Update")
    if(props.schedule && JSON.stringify(props.schedule) !== JSON.stringify(this.props.schedule)) {
      let schedule = props.schedule;
      schedule.start = schedule.start ? moment(schedule.start, 'YYYY-MM-DD') : moment();
      schedule.end = schedule.end ? moment(schedule.end, 'YYYY-MM-DD') : moment();
      schedule.isSpecial = schedule.isSpecial || false;
      schedule.specialDate = schedule.specialDate ? moment(schedule.specialDate, 'YYYY-MM-DD') : moment();
      schedule.dayOfWeek = schedule.dayOfWeek || '';
      if(schedule.schedule_buses && schedule.schedule_buses.length > 0) {
        schedule.schedule_buses.map(bus => {
          bus.priceOverride = bus.priceOverride || '';
          bus.firstSpecialSeats = bus.firstSpecialSeats || '';
          bus.lastSpecialSeats = bus.lastSpecialSeats || '';
          bus.firstSpecialSeatsPrice = bus.firstSpecialSeatsPrice || '';
          bus.lastSpecialSeatsPrice = bus.lastSpecialSeatsPrice || '';
          bus.departStops = [];
          bus.destinationStops = [];
          bus.deletedDepartStops = [];
          bus.deletedDestinationStops = [];
          if(!bus.couponLimit) {
            bus.couponLimit = '';
          }
          if(bus.schedule_bus_stops && bus.schedule_bus_stops.length > 0) {
            bus.schedule_bus_stops.map(stop => {
              const stopData = {
                time: moment(stop.time, 'HH:mm:ss'),
                stop: stop.stop,
                id: stop.id,
                deleted: stop.deleted,
                pickOrDrop: stop.pickOrDrop
              }
              if(stop.pickOrDrop === 'PU') {
                if(stop.deleted){
                bus.deletedDepartStops.push(stopData)
                }else{
                bus.departStops.push(stopData)
              }
              }
              if(stop.pickOrDrop === 'DO') {
                if(stop.deleted){
                bus.deletedDestinationStops.push(stopData)
                }else{
                bus.destinationStops.push(stopData)
              }
            }
              return stop;
            });
          }
          return bus;
        });
        schedule.schedule_buses = _.orderBy(schedule.schedule_buses, (item) => {
          return item.departStops[0].time;
        });
      }
      let name = this.getValueFromQueryParams(this.props.location.search, 'departCity')
      let activeDate = this.getValueFromQueryParams(this.props.location.search, 'activeDate')
      this.setState({schedule: schedule, departCity: name, activeDate: activeDate});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Schedule updated successfully'});
      history.push('/dashboard/schedules');
    }
    if(props.stops && props.stops.length>0 && this.state.stops.length === 0) {
      this.setState({stops: props.stops});
    }
    if(props.buses && props.buses.length>0 && this.state.buses.length === 0) {
      this.setState({buses: props.buses});
    }
  }


  getCodeFromStops(cityName){
   const data =  _.filter(this.state.stops, {city: cityName})
    
   if(data && data.length > 0 && data[0]){
    return data[0].id;
   }

   return null;
   
  }

  getStopFromCode(code){
   const data =  _.filter(this.state.stops, {id: code})
    
   if(data && data.length > 0 && data[0]){
    return data[0].city.toLowerCase();
   }

   return null;
   
  }

  getLatestData(e){
    if(e.nativeEvent.offsetY>0){
       this.props.dispatch(scheduleActions.getSchedule(this.props.match.params.id, this.state.activeDate))
    }
  }

  getDepartStops() {
    if(this.state.departCity === 'new york' && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'new york' || item.city === 'brooklyn')
      });    }
    else if(this.state.departCity && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'arlington' || item.city === 'bethesda' || item.city === 'washington')
      });
    }
    return this.state.stops;
  }

  getDestinationStops() {
    if(this.state.departCity === 'new york' && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'arlington' || item.city === 'bethesda' || item.city == 'washington')
      });    }
    else if(this.state.departCity && this.state.stops.length > 0) {
      return _.filter(this.state.stops, (item) => {
        return (item.city === 'new york' || item.city === 'brooklyn')
      });
    }
    return this.state.stops;
  }

  checkStopExistence(stopId){
    if(stopId){
      let stop = _.find(this.state.stops, {id: stopId})
      if(stop && stop.id){
        return true
      }
    }
    return false;
  }


  showEditPrice(schedule_bus, index){

    let departStops = schedule_bus.departStops;
    let destinationStops = schedule_bus.destinationStops;
    let prices = schedule_bus.prices;
    let priceOverride = schedule_bus.priceOverride;

    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.state.stops, priceOverride);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index});
  }

  updatePrice(priceMap, currentlySelectedBusIndex = this.state.currentSelectedBusIndex){
    let schedule = this.state.schedule;
    let newPrices = [];
    if(priceMap && priceMap.length){
      priceMap.map((price)=>{
        let p = {
          from_stop_id: price.departStop.stop,
          to_stop_id: price.destinationStop.stop, 
          price: price.price,
          id: price.customRouteId ? price.customRouteId: undefined
        }

        newPrices.push(p)      
      })


    schedule.schedule_buses[currentlySelectedBusIndex].prices = newPrices;

    this.setState({schedule})
  }
    console.log(priceMap)
    
  }

  
  updateStopPrice(e, index) {
    const { value } = e.target;
    let combinationOfStops = this.state.currentSelectedBus;
    combinationOfStops[index].price = value;
    this.setState({ currentSelectedBus: combinationOfStops });
  }

  updatePriceData(currentlySelectedBusIndex, schedule_bus){

    let departStops = schedule_bus.departStops;
    let destinationStops = schedule_bus.destinationStops;
    let prices = schedule_bus.prices;

    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.state.stops);

    this.updatePrice(stopCombination, currentlySelectedBusIndex);
  }

  render() {
    const { submitted, schedule } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid style={{maxWidth: '700px'}}>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Existing Schedule"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit} className="add-schedule-form">
                    <div className="special-check"><Checkbox
                      number='check1'
                      value={schedule.isSpecial}
                      onChange={this.handleCheckbox.bind(this)}
                      checked={schedule.isSpecial}
                      disabled={true}
                      label='Special'
                    /></div>
                    <Row>
                      {schedule.isSpecial && <Col md={6}>
                        <ControlLabel>Special Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.specialDate}
                          disabled={true}
                          onChange={this.handleSpecialDate}
                        />
                      </Col>}
                      {schedule.isSpecial && <Col md={6}>
                        <FormGroup>
                          <ControlLabel>Name</ControlLabel>
                          <FormControl bsClass= "form-control" name="name" value={schedule.name} onChange={e => this.handleChange(e)}></FormControl>
                        </FormGroup>
                      </Col>}
                    </Row>
                    <Row className="error-row">
                      <Col md={4}></Col>
                      <Col md={4}>{submitted && schedule.isSpecial && !schedule.specialDate && <div className="error">Special date is required</div>}</Col>
                    </Row>
                    {!schedule.isSpecial && <Row>
                      <Col md={4}>
                        <ControlLabel>Start Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.start}
                          onChange={this.handleStartDate}
                          disabled={true}
                        />
                      </Col>
                      <Col md={4}>
                        <ControlLabel>End Date</ControlLabel>
                        <DatePicker
                          className="form-control"
                          selected={schedule.end}
                          onChange={this.handleEndDate}
                          disabled={true}
                        />
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <ControlLabel>Day Of Week</ControlLabel>
                          <FormControl componentClass="select" bsClass= "form-control" name="dayOfWeek" value={schedule.dayOfWeek} onChange={this.handleChange} disabled={true}>
                            <option >Select</option>
                            <option>Sunday</option>
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                          </FormControl>
                        </FormGroup>
                      </Col>
                    </Row>}
                    <Row className="error-row">
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.start && <div className="error">Start date is required</div>}</Col>
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.end && <div className="error">End date is required</div>}</Col>
                      <Col md={4}>{submitted && !schedule.isSpecial && !schedule.dayOfWeek && <div className="error">Week day is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <ControlLabel>Buses In Schedule<Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addBus.bind(this)}><i className="fa fa-plus"></i>Add New Bus</Button></ControlLabel>
                      </Col>
                    </Row>
                    {schedule.schedule_buses.length === 0 && <div className='empty-stop-box'>
                      No Buses In Schedule
                    </div>}
                    {schedule.schedule_buses.map((item, index) => {
                      return(<div className={"bus-box " + (item.deleted ? `deleted deletedItem` : '')} key={index}>
                        <Row>
                        {
                           item.deleted && <Col md={12}>
                              <div style={{width: '100%'}}>
                                <Button bsStyle="danger" className="btn-fill" style={{width: "100%"}} type="button">Cancelled for the day</Button>
                              </div>
                           </Col>
                          }
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h4 className="bus-stop-number">{schedule.dayOfWeek}</h4>
                            {!schedule.isSpecial && <div className="open-checkbox"><Checkbox
                              number={'check44'+index}
                              value={item.isOpen}
                              onChange={this.handleOpenCheckbox.bind(this, index)}
                              checked={item.isOpen}
                              label='Open For Reservation?'
                            /></div>}
                            {!item.deleted ? <Button bsStyle="info" className="btn-fill btn-right delete-bus" type="button" onClick={this.removeBus.bind(this, index, item.id)}>
                              <i className="fa fa-trash"></i>Remove Bus
                            </Button> : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                              <h5 className="bus-stop-number">Departure Time: </h5> 
                              {item.departStops.length > 0 && item.departStops[0]['time'] ? <Badge><span>{item.departStops[0]['time'].format('h:mm A')}</span></Badge>: null}
                          </Col>
                        </Row>
                        <FormInputs
                          ncols={["col-md-4", "col-md-4", "col-md-4"]}
                          proprieties={[
                            {
                              label: "Bus Type",
                              bsClass: "form-control",
                              componentClass: "select",
                              value: item.bus_type,
                              name: 'bus_type',
                              onChange: this.handleBusChange.bind(this, index),
                              options: this.getBusOptions(),
                              // onClick: (e) =>{this.getLatestData(e)}

                            },
                            {
                              label: "Edit Price",
                              type: "custom",
                              bsClass: "form-control",
                              value: "Edit",
                              name: 'edit_price',
                              custom: <span onClick={this.showEditPrice.bind(this, item, index)} className="btn form-control edit-price">Edit Price</span>
                            },
                            {
                              label: "Letter",
                              bsClass: "form-control",
                              componentClass: "select",
                              value: item.letter,
                              name: 'letter',
                              onChange: this.handleBusChange.bind(this, index),
                              options: this.getLetters(item.letter)
                            }
                          ]}
                        />
                        {submitted  && <div style={
                          {
                            display: 'flex',
                            justifyContent: 'space-between'
                          }
                        } className="error-row">
                          <Col  md={4}><div style={{marginLeft:"-8px"}} className="error">{!item.bus_type && 'Bus is required'} </div></Col>
                          <Col md={4}/>
                          {!item.letter && item.deleted === false && <Col md={4}><div style={{marginLeft:"8px"}} className="error">Letter is required</div></Col>}

                        </div>}

                        {/*<FormInputs
                          ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                          proprieties={[
                            {
                              label: "Override First Special Seats",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override First Special Seats",
                              value: item.firstSpecialSeats,
                              name: 'firstSpecialSeats',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override First Special Seats Price",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override First Special Seats Price",
                              value: item.firstSpecialSeatsPrice,
                              name: 'firstSpecialSeatsPrice',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override Last Special Seats",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override Last Special Seats",
                              value: item.LastSpecialSeats,
                              name: 'LastSpecialSeats',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            },
                            {
                              label: "Override Last Special Seats Price",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Override Last Special Seats Price",
                              value: item.lastSpecialSeatsPrice,
                              name: 'lastSpecialSeatsPrice',
                              onChange: this.handleBusChange.bind(this, index),
                              disabled: (this.props.me.permissions.indexOf('prices') === -1)
                            }
                          ]}
                        />*/}
                        {/*<Row>
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>Bus Notes</ControlLabel>
                              <FormControl bsClass= "form-control" name="notes" value={item.notes} onChange={e => this.handleBusChange(index, e)}></FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="coupon-checkbox">
                            <Checkbox
                              number='check2'
                              value={item.couponAllowed}
                              onChange={this.handleCouponCheckbox.bind(this, index)}
                              checked={item.couponAllowed}
                              label='Coupons Allowed'
                            />
                          </Col>
                          {item.couponAllowed &&
                            <Col md={4}>
                              <FormGroup>
                                <ControlLabel>Coupon Limit</ControlLabel>
                                <FormControl bsClass= "form-control" type="number" name="couponLimit" value={item.couponLimit} onChange={e => this.handleBusChange(index, e)}></FormControl>
                              </FormGroup>
                            </Col>}
                        </Row>*/}
                        {/*<Row className="error-row">
                          <Col md={4}></Col>
                          <Col md={4}></Col>
                          <Col md={4}>{submitted && item.couponAllowed && item.couponLimit !== '' && item.couponLimit < 1 && <div className="error">Limit Must be greater than 0</div>}</Col>
                        </Row>*/}
                        <Row>
                          <Col md={12}>
                            <ControlLabel>Departure</ControlLabel>
                            {<Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addDepartStop.bind(this, index)}><i className="fa fa-plus"></i>Add Departure Stop</Button>}
                          </Col>
                        </Row>
                        {item.departStops.length === 0 && <div className='empty-stop-box'>
                          No Depart Stops For This Bus
                        </div>}
                        {item.departStops.map((busStop, num) => {
                          return(<div className={"stop-box " + (busStop.deleted ? 'deleted' : '')} key={num}>
                            <Row className="stops-block">
                              <div className="stop-number">#{num+1}</div>
                              <Col md={6} className="stop-block">
                                <FormGroup>
                                                                                                                                          {/*id for an arlington stop which cannot be deleted, we are just making it point to another arlington address which is the same */}
                                  <FormControl componentClass="select" disabled={busStop.id} bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDepartStopChange(e, num, index)}>

                                                                        <option value=''>Select</option>
                                    {
                                     this.getDepartStops().map((stop, key) => {
                                        return(<option key={key} value={stop.id}>{stop.address}</option>)
                                      })
                                    }
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Col md={6} className="stop-block-2">
                                <FormGroup>
                                  <DatePicker
                                    // disabled={item.departStops.length >= 2 && (busStop.stop === this.getCodeFromStops('bethesda'))}
                                    className="form-control"
                                    selected={busStop.time}
                                    onChange={(time) => this.handleDepartStopTime(time, num, index)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="LT"
                                    timeCaption="Time"
                                    placeholderText="Depart Time"
                                  />
                                </FormGroup>
                              </Col>
                              <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDepartStop.bind(this, num, index, busStop.id)}></i> : <span>Removed</span>}</div>
                            </Row>
                            {submitted && (!busStop.stop || !busStop.time) && <Row className="error-row">
                              <Col md={6}>{submitted && !busStop.stop && <div className="error">Stop is required</div>}</Col>
                              <Col md={6}>{submitted && !busStop.time && num == 0 &&<div className="error">Time is required</div>}</Col>
                            </Row>}
                          </div>)
                        })}

                        <Row>
                          <Col md={12}>
                            <ControlLabel>Drop-off</ControlLabel>
                            {
                              // <Button bsStyle="info" disable={this.get_non_deleted_stops_length(item.destinationStops) >= 2} className="add-stop btn-fill" type="button" onClick={this.addMiddleDestinationBethesda.bind(this, index)}><i className="fa fa-plus"></i>Add Drop-off Stop</Button>}
                              <Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addDestinationStop.bind(this, index)}><i className="fa fa-plus"></i>Add Drop-off Stop</Button>}
                          </Col>
                        </Row>
                        {item.destinationStops.length === 0 && <div className='empty-stop-box'>
                          No Destination Stops For This Bus
                        </div>}
                        {item.destinationStops.map((busStop, num) => {
                          return(<div className={"stop-box " + (busStop.deleted ? 'deleted' : '')} key={num}>
                            <Row className="stops-block">
                              <div className="stop-number">#{num+1}</div>
                              <Col md={6} className="stop-block">
                                <FormGroup>
                                  <FormControl disabled={busStop.id} componentClass="select" bsClass= "form-control" name="stop" value={busStop.stop} onChange={e => this.handleDestinationStopChange(e, num, index)}>
                                    <option value=''>Select</option>
                                    {
                                      this.getDestinationStops().map((stop, key) => {
                                        return(<option key={key} value={stop.id}>{stop.address}</option>)
                                      })
                                    }
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Col md={6} className="stop-block-2">
                                <FormGroup>
                                  <DatePicker
                                    className="form-control"
                                    selected={busStop.time}
                                    onChange={(time) => this.handleDestinationStopTime(time, num, index)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="LT"
                                    timeCaption="Time"
                                    placeholderText="Arrival Time"
                                  />
                                </FormGroup>
                              </Col>
                              <div className="stop-remove">{!busStop.deleted ? <i className="fa fa-times" onClick={this.removeDestinationStop.bind(this, num, index, busStop.id)}></i> : <span>Removed</span>}</div>
                            </Row>
                            {submitted && (!busStop.stop || !busStop.time) && <Row className="error-row">
                              <Col md={6}>{submitted && !busStop.stop && <div className="error">Stop is required</div>}</Col>
                              <Col md={6}>{submitted && !busStop.time && <div className="error">Time is required</div>}</Col>
                            </Row>}
                          </div>)
                        })}
                      </div>)
                    })}
                    <Row>
                      <Col md={6}>
                        {schedule.schedule_buses.length > 0 && <Button bsStyle="info" className="add-stop btn-fill" type="button" onClick={this.addBus.bind(this)}><i className="fa fa-plus"></i>Add New Bus</Button>}
                      </Col>
                      <Col md={6}>
                        <Button bsStyle="info" className="btn-right btn-fill save-btn save-schedule-btn" disabled={loading} fill type="submit">
                          Save Schedule
                        </Button>
                      </Col>
                    </Row>
                    {this.state.formError ? <Row className="error-row">
                      <Col md={12}>Please complete all form fields above to save</Col>
                    </Row> : ''}
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                    {this.props.activeBuses && this.props.activeBuses.length > 0 && <div className="active-buses-msg">--These buses which you removed have active reservations : {this.props.activeBuses.join(', ')}--</div>}
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>

        
        <div className="divSelector"></div>

          <Popup
            open={this.state.open}
            closeOnDocumentClick
            contentStyle={{top:'0'}}
            overlayStyle={{position:'sticky',alignItems: 'center', top: '0%', height: '100%', background: 'none'}}
            onClose = {this.closeModal.bind(this)}
          > 
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="thumbnail" style={{marginBottom: 0, border: 'none'}}> 
                  <div class="caption">
                    <h4>The are too many reservations to switch the bus type, reservations need to be moved before the bus type is changed</h4>
                    <p>If you are sure about the change, click the button below to proceed.</p>
                    <p>
                      <a class="btn btn-danger" role="button" onClick={()=>this.setState({open: false})}>Cancel</a>
                      <a class="btn text-primary noborder" role="button" onClick={()=>this.setBusAfterConfirmation()}>Proceed</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Popup>

          {<CustomPrice updatePrice={this.updatePrice.bind(this)} open={this.state.showModal} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={this.updateStopPrice.bind(this)}/>}

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success, activeBuses } = state.schedule;
  return {
    loading, schedule: item, error, success, stops: state.stops.items, buses: state.buses.items, activeBuses, me: state.authentication.me
  };
}
 
export default connect(mapStateToProps)(withRouter(EditSchedule));
