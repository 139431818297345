import { GET_PERMISSIONS_REQUEST, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE, ADD_PERMISSION_REQUEST, ADD_PERMISSION_SUCCESS, ADD_PERMISSION_FAILURE, DELETE_PERMISSION_REQUEST, DELETE_PERMISSION_SUCCESS, DELETE_PERMISSION_FAILURE, UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS, UPDATE_PERMISSION_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
      return {
        error: '',
        loading: true,
        items: []
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        error: '',
        loading: false,
        items: action.permissions
      };
    case GET_PERMISSIONS_FAILURE:
      return {
        error: action.error
      };
    case ADD_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: ''
      };
    case ADD_PERMISSION_SUCCESS:
      return {
        loading: false,
        success: true,
        error: '',
        items: [ ...state.items, action.permission]
      };
    case ADD_PERMISSION_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: ''
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_PERMISSION_REQUEST:
      // add 'deleting:true' property to permission being deleted
      return {
        ...state,
        loading: true,
        success: false
      };
    case DELETE_PERMISSION_SUCCESS:
      // remove deleted permission from state
      return {
        items: state.items.filter(permission => permission.id !== action.id),
        success: true
      };
    case DELETE_PERMISSION_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to permission 
      return {
        error: action.error
      };
    default:
      return state
  }
}