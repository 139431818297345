import { GET_RESERVATION_REMARKS_REQUEST, GET_RESERVATION_REMARKS_SUCCESS, GET_RESERVATION_REMARKS_FAILURE, GET_RESERVATION_REMARK_REQUEST, GET_RESERVATION_REMARK_SUCCESS, GET_RESERVATION_REMARK_FAILURE, ADD_RESERVATION_REMARK_REQUEST, ADD_RESERVATION_REMARK_SUCCESS, ADD_RESERVATION_REMARK_FAILURE, UPDATE_RESERVATION_REMARK_REQUEST, UPDATE_RESERVATION_REMARK_SUCCESS, UPDATE_RESERVATION_REMARK_FAILURE, DELETE_RESERVATION_REMARK_REQUEST, DELETE_RESERVATION_REMARK_SUCCESS, DELETE_RESERVATION_REMARK_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_RESERVATION_REMARKS_REQUEST:
      return {
        ...state,
        loading: true,
        items: [],
        total: 0
      };
    case GET_RESERVATION_REMARKS_SUCCESS:
      return {
        loading: false,
        items: action.reservationRemarks.reservationRemarks,
        total: action.reservationRemarks.total
      };
    case GET_RESERVATION_REMARKS_FAILURE:
      return {
        error: action.error
      };
    case GET_RESERVATION_REMARK_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_RESERVATION_REMARK_SUCCESS:
      return {
        loading: false,
        item: action.reservationRemark
      };
    case GET_RESERVATION_REMARK_FAILURE:
      return {
        error: action.error
      };
    case ADD_RESERVATION_REMARK_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        success: false,
      };
    case ADD_RESERVATION_REMARK_SUCCESS:
      return {
        loading: false,
        success: true,
        error: '',
        items: [...state.items, action.data]
      };
    case ADD_RESERVATION_REMARK_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_RESERVATION_REMARK_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_RESERVATION_REMARK_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_RESERVATION_REMARK_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_RESERVATION_REMARK_REQUEST:
      // add 'deleting:true' property to reservationRemark being deleted
      return {
        ...state,
        items: state.items.map(reservationRemark =>
          reservationRemark.id === action.id
            ? { ...reservationRemark, deleting: true }
            : reservationRemark
        )
      };
    case DELETE_RESERVATION_REMARK_SUCCESS:
      // remove deleted reservationRemark from state
      return {
        items: state.items.filter(reservationRemark => reservationRemark.id !== action.id)
      };
    case DELETE_RESERVATION_REMARK_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to reservationRemark 
      return {
        ...state,
        items: state.items.map(reservationRemark => {
          if (reservationRemark.id === action.id) {
            // make copy of reservationRemark without 'deleting:true' property
            const { deleting, ...reservationRemarkCopy } = reservationRemark;
            // return copy of reservationRemark with 'deleteError:[error]' property
            return { ...reservationRemarkCopy, deleteError: action.error };
          }
 
          return reservationRemark;
        })
      };
    default:
      return state
  }
}