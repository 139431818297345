import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-table/react-table.css";
import reservationActions from 'actions/reservation.actions';
import { reservationService } from 'services/reservation.services';
import { mainService } from 'services/main.services';
import customerActions from 'actions/customer.actions';
import { connect } from 'react-redux';
import config from 'config';
import cashCreditActions from 'actions/cash_credit.actions';
import voucherActions from 'actions/voucher.actions';
import Notes from "views/Main/Notes.jsx";
import Modal from 'react-responsive-modal';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import numeral from 'numeral';
import moment from 'moment-timezone';
//moment.tz.setDefault('America/New_York');

/*eslint-disable no-script-url*/

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(customerActions.getCustomer(this.props.match.params.id));
    this.props.dispatch(reservationActions.getReservations({user: this.props.match.params.id}));

    this.state = {
      submitted: false,
      noSchedule: false,
      customer: {
        receiveOffers: true,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      },
      value: '',
      addOrSubtract: 'add',
      creditOrPoint: 'points',
      reason: '',
      remark: '',
      waiveDifference: false,
      customAmount: '',
      applyCredits: '',
      applyPoints: 0,
      applyPointsAmount: 0,
      reservations: [],
      openNotes: false,
      notesData: null,
      selectedLeg: null,
      openCancel: false,
      openReschedule: false,
      schedules: null,
      seatsToCancel: '',
      openBook: false,
      openSchedule: false,
      openSuccess: false,
      cities: config.cities,
      departDate: moment(),
      departCity: '',
      arriveCity: '',
      departStop: '',
      arriveStop: '',
      slot: '',
      paymentMethod: 'card',
      methods: [
        {label: 'Credit Card', value: 'card'},
        {label: 'No Payment', value: 'none'},
        {label: 'Customer Already Paid', value: 'alreadyPaid'},
      ],
      cardHolder: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      credit: 0,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      seatsToReschedule: '',
      selectedBus: null,
      selectedStop: null,
      points: 0,
      credits: 0,
      adminPoints: [],
      adminCredits: [],
      coupons: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getActiveReservations(reservations) {
    return _.filter(reservations, {status: 'active'});
  }

  handleChange(e) {
    const { name, value } = e.target;
    let customer = this.state.customer;
    customer[name] = value;
    this.setState({ customer: customer });
  }

  addVouchers() {
    this.props.dispatch(
      voucherActions.add({
        user: this.props.match.params.id,
        points: (this.state.addOrSubtract === 'add' ? this.state.value : -(this.state.value)),
        description: this.state.reason,
        added_by: this.props.user.id
      })
    );
  }

  addCredits() {
    this.props.dispatch(
      cashCreditActions.add({
        user: this.props.match.params.id,
        amount: (this.state.addOrSubtract === 'add' ? this.state.value : -(this.state.value)),
        description: this.state.reason,
        added_by: this.props.user.id
      })
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.customer.email && this.state.customer.phone && this.state.customer.firstName && this.state.customer.lastName && this.state.customer.address && this.state.customer.city && this.state.customer.state && this.state.customer.zip && this.state.customer.country) {
      dispatch(
        customerActions.update(
          this.props.match.params.id,
          {
            email: this.state.customer.email,
            firstName: this.state.customer.firstName,
            lastName: this.state.customer.lastName,
            phone: this.state.customer.phone,
            address: this.state.customer.address,
            city: this.state.customer.city,
            state: this.state.customer.state,
            zip: this.state.customer.zip,
            country: this.state.customer.country,
            receiveOffers: this.state.customer.receiveOffers
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.customer && JSON.stringify(this.state.customer) !== JSON.stringify(props.customer)) {
      this.setState({customer: props.customer});
      if(props.customer.vouchers) {
        let points = 0;
        let adminPoints = [];
        props.customer.vouchers.map(item => {
          points = points + item.points;
          adminPoints.push(item);
          return item;
        });
        this.setState({points: points, adminPoints: adminPoints});
      }
      if(props.customer.cash_credits) {
        let credits = 0;
        let adminCredits = [];
        props.customer.cash_credits.map(item => {
          credits = credits + item.amount;
          adminCredits.push(item);
          return item;
        });
        this.setState({credits: credits, adminCredits: adminCredits});
      }
      if(props.customer.coupons) {
        let coupons = [];
        coupons = _.sortBy(props.customer.coupons, (item) => {
          return -moment(item.created_at);
        });
        this.setState({coupons: coupons});
      }
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Customer updated successfully'});
    }
    if(props.creditSuccess && !this.props.creditSuccess) {
      this.props.addNotification({type: 'success', message: 'Credits updated successfully'});
      this.setState({
        value: '',
        addOrSubtract: 'add',
        creditOrPoint: 'points',
        reason: ''
      });
      this.props.dispatch(customerActions.getCustomer(this.props.match.params.id));
    }
    if(props.voucherSuccess && !this.props.voucherSuccess) {
      this.props.addNotification({type: 'success', message: 'Points updated successfully'});
      this.setState({
        value: '',
        addOrRemove: 'add',
        creditOrPoint: 'points',
        reason: ''
      });
      this.props.dispatch(customerActions.getCustomer(this.props.match.params.id));
    }
    if(props.reservations && props.reservations.length>0 && this.state.reservations.length === 0) {
      let reservations = _.sortBy(props.reservations, (item) => {
        return -moment(item.slot+ ' ' + item.depart_stop.time, "YYYY-MM-DD HH:mm:ss");
      });
      this.setState({reservations: reservations});
    }
  }

  deleteItem = item => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(reservationActions.delete(item.original.id))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  handlePointsChange(e) {
    if(e.target.name === 'value') {
      this.setState({value: parseInt(e.target.value, 10)});
    }
    else {
      this.setState({[e.target.name]: e.target.value});
    }
  }

  handleCheckbox = event => {
    let customer = this.state.customer;
    customer.receiveOffers = !customer.receiveOffers;
    this.setState({customer: customer});
  };

  handleDepartDate(date) {
    this.setState({ departDate: date });
  }

  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  sortStops(data) {
    for(let i = 0; i < data.length; i++) {
      if(data[i].stops.length === 3) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
      }
      else if(data[i].stops.length === 2) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[0]
          ];
        }
      }
    }
    return data;
  }

  findSeats(e) {
    e.preventDefault();
    this.setState({ loading: true, noSchedule: false });
    const { departDate, departCity, arriveCity } = this.state;
    if (departDate && departCity) {
      let data = {
        departCity: departCity,
        departDate: moment(departDate).format('YYYY-MM-DD'),
        arriveCity: arriveCity
      }
      mainService.getMain(data)
      .then(data => {
        this.setState({ loading: false });
        console.log(data)
        if(data && data.schedules.length > 0) {
          let schedules = this.sortStops(data.schedules);
          schedules = _.orderBy(schedules, (item) => {
            return moment(item.stops[0].time, 'HH:mm:ss');
          });
          schedules = _.orderBy(schedules, (item) => {
            return item.deleted;
          });
          this.setState({openSchedule: true, schedules: schedules, openBook: false, customAmount: ''});
        }
        else {
          this.setState({noSchedule: true});
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({error: err, loading: false});
      })
    }
    else {
      return false;
    }
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  componentDidMount() {
    let years = [];
    let start = new Date().getFullYear();
    let end = start + 11;
    for (let i = start; i < end; i++) {
      years.push(i.toString().substr(2));
    }
    this.setState({ years: years });
  }

  getCityCode(city) {
    return _.find(config.cities, {value: city}).code;
  }

  getSeatsOptions() {
    let arr = [];
    if(!this.state.selectedLeg) {
      return arr;
    }
    for(let i = 1; i <= this.state.selectedLeg.total; i++) {
      arr.push({label: i, value: i});
    }
    return arr;
  }

  showNotes(stop, reservation) {
    let data = {
      reservation: reservation,
      date: reservation.slot,
      time: reservation.depart_stop.time,
      stop: stop
    };
    this.setState({openNotes: true, notesData: data});
  }

  closeSchedule() {
    this.setState({openSchedule: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null});
  }

  closeNotes() {
    this.setState({openNotes: false, notesData: null});
  }

  cancel(leg) {
    this.setState({openCancel: true, selectedLeg: leg});
  }

  closeCancel() {
    this.setState({openCancel: false, selectedLeg: null, seatsToCancel: ''});
  }

  reschedule(leg, stop) {
    let fullName = leg.firstName + ' ' + leg.lastName
    this.setState({openReschedule: true, selectedLeg: leg, selectedStop: stop, applyCredits: 0, applyPoints: 0, applyPointsAmount: 0, selectedBus: null, departStop: null, arriveStop: null, cardHolder: fullName});
  }

  closeReschedule() {
    this.setState({openReschedule: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null, applyPoints: 0, applyPointsAmount: 0});
  }

  applyCredit() {
    let useCredit = this.getAmount() > this.state.credits ? this.state.credits : this.getAmount();
    this.setState({applyCredits: useCredit, applyPoints: 0, applyPointsAmount: 0});
  }

  removeCredit() {
    this.setState({applyCredits: 0});
  }

  proceedToCancel() {
    if(this.state.seatsToCancel) {
      this.setState({loading: true});
      reservationService.cancelMainSeats(this.state.selectedLeg.reservationNumber, this.state.seatsToCancel)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        this.setState({loading: false});
      })
    }
  }

  proceedToReschedule() {
    if(this.state.seatsToReschedule) {
      this.setState({openBook: true, noSchedule: false});
    }
  }

  closeSuccess() {
    window.location.reload();
  }

  book(e) {
    e.preventDefault();
    this.setState({ submitted: true, error: '', code: '' });
    let data = {
      reservationNumber: this.state.selectedLeg.reservationNumber,
      schedule_bus: this.state.schedules[this.state.selectedBus].schedule_bus,
      date: moment(this.state.departDate).format('YYYY-MM-DD'),
      status: 'active',
      waiveDifference: this.state.waiveDifference,
      slot: this.state.slot,
      departCity: this.state.departCity,
      departCityCode: this.getCityCode(this.state.departCity),
      arriveCity: this.state.arriveCity,
      seats: parseInt(this.state.seatsToReschedule, 10),
      email: this.state.selectedLeg.user.email,
      paymentMethod: this.state.paymentMethod,
      depart_stop: this.state.departStop,
      arrive_stop: this.state.arriveStop,
      amount: this.getAmount(),
      payment: this.getAmount(),
      remark: this.state.remark,
      price: this.state.schedules[this.state.selectedBus].price,
      pricePerSeat: (!this.state.customAmount ? this.state.schedules[this.state.selectedBus].price : (this.getActiveReservations(this.state.selectedLeg.reservations)[0].order_item.amount + parseInt(this.state.customAmount, 10))),
      letter: this.state.schedules[this.state.selectedBus].letter,
      customAmount: parseInt(this.state.customAmount, 10),
      credits: 0,
      points: 0
    }
    if(this.getCredits()) {
      data.credits = this.getCredits();
      if(this.getCredits() < this.getAmount()) {
        data.payment = this.getAmount() - this.getCredits();
      }
      else {
        data.payment = 0;
      }
    }
    if(this.getRequiredPoints().points > 0 && this.getRequiredPoints().points <= this.state.points) {
      data.points = this.getRequiredPoints().points;
      data.pointsAmount = this.getRequiredPoints().pointsAmount;
      if(this.getRequiredPoints().pointsAmount < this.getAmount()) {
        data.payment = this.getAmount() - this.getRequiredPoints().pointsAmount;
      }
      else {
        data.payment = 0;
      }
    }
    if(!data.payment && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) {
      data.paymentMethod = 'Tripperbus Points';
    }
    if(!data.payment && (this.getActiveReservations(this.state.selectedLeg.reservations)[0].credit || this.getActiveReservations(this.state.selectedLeg.reservations)[0].paid)) {
      data.paymentMethod = 'Tripperbus Credits';
    }
    if(this.state.paymentMethod === 'card' && data.payment) {
      if(!this.state.number || !this.state.cvv || !this.state.month || !this.state.year) {
        return;
      }
      data.number = this.state.number.split('-').join('');
      data.cvv = this.state.cvv;
      data.expiryDate = '' + this.state.month + '' + this.state.year;
      data.cardHolder = this.state.cardHolder || (this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName);
      data.paymentMethod = 'card';
    }
    if(this.state.paymentMethod !== 'card' && data.paymentMethod !== 'Tripperbus Credits' && data.paymentMethod !== 'Tripperbus Points') {
      data.credits = 0;
      data.payment = 0;
      data.points = 0;
    }
    this.setState({loading: true});
    console.log(data)
    reservationService.rescheduleMainReservation(data)
    .then(result => {
      console.log(result);
      this.setState({loading: false, code: result.departTripCode, openSuccess: true, customAmount: ''});
    })
    .catch(err => {
      console.log(err);
      this.setState({loading: false, error: err});
    });
  }

  getSeatsWithPoints(seats) {
    let count = 0;
    for(let i = 0; i < this.state.seatsToReschedule; i++) {
      if(seats[i].vouchers) {
        count++;
      }
    }
    return count;
  }

  handleSeatsChange(e) {
    const { name, value } = e.target;
    if(name === 'departCity' && value !== 'new york') {
      this.setState({arriveCity: 'new york'});
    }
    else if(name === 'departCity' && value === 'new york') {
      this.setState({arriveCity: ''});
    }
    else if(name === 'arriveCity' && value === 'new york' && this.state.departCity === 'new york') {
      return;
    }
    this.setState({ [name]: value });
  }

  closeBook() {
    this.setState({openBook: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null, selectedBus: null, applyPoints: 0, applyPointsAmount: 0});
  }

  selectDepartStop(stop, index) {
    let arriveStop = '';
    for(let i = 0; i < this.state.schedules[index].stops.length; i++) {
      if(this.state.arriveCity === this.state.schedules[index].stops[i].stop.city) {
        arriveStop = this.state.schedules[index].stops[i].id;
      }
    }
    this.setState({departStop: stop.id, slot: stop.time, arriveStop: arriveStop, selectedBus: index});
  }

  selectArriveStop(stop, index) {
    if(index === this.state.selectedBus) {
      this.setState({arriveStop: stop});
    }
  }

  getPaid() {
    if(this.state.selectedLeg) {
      let val = this.getActiveReservations(this.state.selectedLeg.reservations)[0].order_item.amount*this.state.seatsToReschedule;
      if(this.state.selectedLeg.coupon) {
        if(this.state.selectedLeg.coupon.type === '$') {
          return val - this.state.selectedLeg.coupon.amount;
        }
        else {
          return val - ((val*this.state.selectedLeg.coupon.amount)/100);
        }
      }
      return val;
    }
    return 0;
  }

  getPricePaid() {
    if(this.state.selectedLeg) {
      let val = 0;
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          val += this.getActiveReservations(this.state.selectedLeg.reservations)[i].order_item.amount
        }
        else {
          val = val + this.state.selectedLeg.legPrice;
        }
      }
      return val/this.state.seatsToReschedule;
    }
    return 0;
  }

  getPricePaidBreakup() {
    let breakup = {
      paid: 0,
      credits: 0,
      points: 0
    };
    let response = '';
    if(this.state.selectedLeg) {
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid) {
          breakup.paid += this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid;
        }
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) {
          breakup.credits += this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit;
        }
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          breakup.points += this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers;
        }
      }
      if(breakup.paid) {
        response += `$${breakup.paid} Cash`;
        if(breakup.credits || breakup.points) {
          response += ' + ';
        }
      }
      if(breakup.credits) {
        response += `$${breakup.credits} Tripperbus Credits`;
        if(breakup.points) {
          response += ' + ';
        }
      }
      if(breakup.points) {
        response += `${breakup.points} Points`;
      }
    }
    response = response || '0';
    return {object: breakup, text: response};
  }

  waiveDifference() {
    this.setState({waiveDifference: !this.state.waiveDifference});
  }

  applyPoints() {
    let requiredPoints = this.getRequiredPoints();
    this.setState({applyPoints: requiredPoints.points, applyPointsAmount: requiredPoints.pointsAmount, applyCredits: 0});
  }

  removePoints() {
    this.setState({applyPoints: 0, applyPointsAmount: 0});
  }

  getRequiredPoints() {
    const res = {
      points: 0,
      pointsAmount: 0,
    };
    if(this.state.selectedBus !== null && this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite' && this.state.selectedLeg.schedule_bus.bus_type.type !== 'Elite') {
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          res.pointsAmount += (this.state.schedules[this.state.selectedBus].price - this.state.selectedLeg.legPrice);
          res.points += 6;
        }
        else if((this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid || this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) && this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite') {
          res.pointsAmount += (this.state.schedules[this.state.selectedBus].price - this.state.selectedLeg.legPrice);
          res.points += 12;
        }
        else if((this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid || this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) && this.state.schedules[this.state.selectedBus].bus_type.type !== 'Elite') {
          res.pointsAmount += (this.state.schedules[this.state.selectedBus].price - this.state.selectedLeg.legPrice);
          res.points += 6;
        }
      }
    }
    return res;
  }

  getCredits() {
    if (this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) {
      return 0;
    }
    return (this.getAmount() > this.state.credits ? this.state.credits : this.getAmount());
  }

  getAmount() {
    if(this.state.selectedBus !== null && this.state.selectedLeg) {
      if(this.state.customAmount) {
        return parseInt(this.state.customAmount, 10);
      }
      else if(this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.getRequiredPoints().points > this.state.points) {
        return (this.state.schedules[this.state.selectedBus].price*this.state.seatsToReschedule);
      }
      else if(this.state.schedules[this.state.selectedBus].price >= this.getPricePaid()) {
        let amount = (this.state.schedules[this.state.selectedBus].price*this.state.seatsToReschedule) - (this.getPricePaid()*this.state.seatsToReschedule);
        return amount;
      }
    }
    return 0;
  }

  getModValue(val) {
    if(val > 0) {
      return val;
    }
    return -val;
  }

  getVouchersMessage() {
    const breakup = this.getPricePaidBreakup().object;
    if(!breakup.paid && !breakup.credits) {
      return `Customer paid the total with ${breakup.points} points, new reservation is worth ${this.state.seatsToReschedule*12} points`;
    }
    if(breakup.paid || breakup.credits) {
      return `Customer paid the total with ${this.getPricePaidBreakup().text}, new reservation is worth ${this.state.seatsToReschedule*(this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite' ? 12 : 6)} points`;
    }
    return '';
  }

  getReservationNumberFromCoupon (coupon) {
    if (coupon.user_coupons && Array.isArray(coupon.user_coupons) && coupon.user_coupons.length) {
      const userCoupon = coupon.user_coupons[0];
      if (
        userCoupon &&
        userCoupon.reservation_group &&
        userCoupon.reservation_group.reservation_legs &&
        Array.isArray(userCoupon.reservation_group.reservation_legs) &&
        userCoupon.reservation_group.reservation_legs.length
      ) {
        const reservationLeg = userCoupon.reservation_group.reservation_legs[0];
        if (reservationLeg){
          return `Used for reservation #${reservationLeg.reservationNumber}`;
        }
      }

    }
    return ''
  }

  render() {
    const { submitted, customer } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={'Edit Customer (Points: '+ this.state.points + ' Credits: ' + numeral(this.state.credits).format('$0.00') + ')'}
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "First Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "First Name",
                          value: customer.firstName,
                          name: 'firstName',
                          onChange: this.handleChange
                        },
                        {
                          label: "Last Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Last Name",
                          value: customer.lastName,
                          name: 'lastName',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.firstName && <div className="error">First name is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.lastName && <div className="error">Last name is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: customer.email,
                          name: 'email',
                          onChange: this.handleChange
                        },
                        {
                          label: "Phone",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Phone",
                          value: customer.phone,
                          name: 'phone',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.email && <div className="error">Email is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.phone && <div className="error">Phone is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Address",
                          value: customer.address,
                          name: 'address',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !customer.address && <div className="error">Address is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "City",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "City",
                          value: customer.city,
                          name: 'city',
                          onChange: this.handleChange
                        },
                        {
                          label: "State",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "State",
                          value: customer.state,
                          name: 'state',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.city && <div className="error">City is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.state && <div className="error">State is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      proprieties={[
                        {
                          label: "ZIP Code",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "ZIP Code",
                          value: customer.zip,
                          name: 'zip',
                          onChange: this.handleChange
                        },
                        {
                          label: "Country",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Country",
                          value: customer.country,
                          name: 'country',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={6}>{submitted && !customer.zip && <div className="error">Zipcode is required</div>}</Col>
                      <Col md={6}>{submitted && !customer.country && <div className="error">Country is required</div>}</Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Checkbox
                          number='check1'
                          value={customer.receiveOffers}
                          onChange={this.handleCheckbox.bind(this)}
                          checked={customer.receiveOffers}
                          label='Would you like to receive offers from tripperbus?'
                        />
                      </Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Customer
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Add/Subtract Credits Or Points"
                content={
                  <div>
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      proprieties={[
                        {
                          label: "Add/Subtract",
                          bsClass: "form-control",
                          componentClass: "select",
                          value: this.state.addOrSubtract,
                          name: 'addOrSubtract',
                          onChange: this.handlePointsChange.bind(this),
                          options: [{
                            value: 'add', label: 'Add'
                          },{
                            value: 'subtract', label: 'Subtract'
                          }]
                        },
                        {
                          label: "Points/Credits",
                          bsClass: "form-control",
                          componentClass: "select",
                          value: this.state.creditOrPoint,
                          name: 'creditOrPoint',
                          onChange: this.handlePointsChange.bind(this),
                          options: [{
                            value: 'points', label: 'Points'
                          },{
                            value: 'credits', label: 'Credits'
                          }]
                        },
                        {
                          label: "Amount",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Amount",
                          value: this.state.value,
                          name: 'value',
                          onChange: this.handlePointsChange.bind(this)
                        },
                        {
                          label: "Reason",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Reason",
                          value: this.state.reason,
                          name: 'reason',
                          onChange: this.handlePointsChange.bind(this)
                        }
                      ]}
                    />
                    {this.state.creditOrPoint === 'credits' ? <Button disabled={!this.state.reason || !this.state.value || !this.state.creditOrPoint || !this.state.addOrSubtract || loading} bsStyle="info" fill type="button" onClick={this.addCredits.bind(this)}>
                      OK
                    </Button> : <Button disabled={!this.state.reason || !this.state.value || !this.state.creditOrPoint || !this.state.addOrSubtract || loading} bsStyle="info" fill type="button" onClick={this.addVouchers.bind(this)}>
                      OK
                    </Button>}
                  </div>
                }
              />
            </Col>
          </Row>
          {this.state.adminPoints.length > 0 && <Row>
            <Col md={12}>
              <Card
                title="Account Points"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Added/Subtracted</th>
                        <th>Points</th>
                        <th>Balance</th>
                        <th>Reason</th>
                        <th>Admin</th>
                        <th>Date</th>
                      </tr>
                      {this.state.adminPoints.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.points > 0 ? 'Added' : 'Subtracted'}</td>
                            <td>{item.points}</td>
                            <td>{item.running_total}</td>
                            <td>{item.description}</td>
                            <td>{item.added_by ? item.added_by.firstName : 'system'}</td>
                            <td>{moment(item.created_at).format('MM/DD/YYYY hh:mm A')}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>}
          {this.state.adminCredits.length > 0 && <Row>
            <Col md={12}>
              <Card
                title="Account Credits"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Added/Subtracted</th>
                        <th>Amount</th>
                        <th>Balance</th>
                        <th>Reason</th>
                        <th>Admin</th>
                        <th>Date</th>
                      </tr>
                      {this.state.adminCredits.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.amount > 0 ? 'Added' : 'Subtracted'}</td>
                            <td>{item.amount}</td>
                            <td>{item.running_total}</td>
                            <td>{item.description}</td>
                            <td>{item.added_by ? item.added_by.firstName : 'system'}</td>
                            <td>{moment(item.created_at).format('MM/DD/YYYY hh:mm A')}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>}
          {this.props.customer && <Row>
            <Col md={12}>
              <Card
                title="Complimentary coupons given by admin"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Code</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Admin</th>
                        <th>Used</th>
                        <th>Date</th>
                      </tr>
                      {this.state.coupons && this.state.coupons.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.code}</td>
                            <td>{item.amount}</td>
                            <td>{item.description}</td>
                            <td>{item.added_by ? item.added_by.firstName : 'system'}</td>
                            <td>{this.getReservationNumberFromCoupon(item)}</td>
                            <td>{moment(item.created_at).format('MM/DD/YYYY hh:mm A')}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>}
          <Row>
            <Col md={12}>
              <Card
                title="Reservations that exist under this account"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Reservation No.</th>
                        <th>Date/Time From</th>
                        <th>Seats</th>
                        <th>Payment Method</th>
                        <th>Credits Used</th>
                        <th>Points Used</th>
                        <th>Points Earned</th>
                        <th>Actions</th>
                      </tr>
                      {this.state.reservations && this.state.reservations.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.user.firstName + ' ' + item.user.lastName}</td>
                            <td>{config.formatReservationNumber(item.reservationNumber)}{item.type === 'Elite' && <div className="elite-res">Elite</div>}</td>
                            <td>{item.slot + ' at ' + moment(item.depart_stop.time, "HH:mm:ss").format("hh:mm A") + ' from ' + item.depart_stop.stop.address}</td>
                            <td>{item.total}</td>
                            <td>{item.paymentMethod}</td>
                            <td>{numeral(item.creditUsed).format('$0.00')}</td>
                            <td>{item.pointsUsed}</td>
                            <td>{item.pointsEarned}</td>
                            <td><div className="table-actions res-actions">
                              <a href="javascript:void(0)" className={(item.reservation_remarks ? 'highlight-note' : '')} onClick={this.showNotes.bind(this, item.depart_stop.stop.address, item)}>Notes</a>
                              {item.total > 0 && <a href="javascript:void(0)" onClick={this.reschedule.bind(this, item, item.depart_stop)}>Reschedule</a>}
                              {item.total > 0 && <a className="red" href="javascript:void(0)" onClick={this.cancel.bind(this, item)}>Cancel</a>}
                            </div></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              />
            </Col>
          </Row>
          {this.state.openNotes && <Notes
            open = {this.state.openNotes}
            onCloseModal = {this.closeNotes.bind(this)}
            data = {this.state.notesData}
          />}
          <Modal open={this.state.openCancel} onClose={this.closeCancel.bind(this)} center>
            <div className="cancelSeatsModal">
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "How many seats you would like to cancel?",
                    componentClass: "select",
                    bsClass: "form-control",
                    placeholder: "How many seats you would like to cancel?",
                    value: this.state.seatsToCancel,
                    name: 'seatsToCancel',
                    onChange: this.handleSeatsChange.bind(this),
                    options: this.getSeatsOptions()
                  }
                ]}
              />
              <Button bsStyle="info" disabled={this.state.loading || !this.state.seatsToCancel} fill type="button" onClick={this.proceedToCancel.bind(this)}>
                Cancel
              </Button>
              {this.state.loading && <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
          </Modal>
          <Modal open={this.state.openReschedule} onClose={this.closeReschedule.bind(this)} center>
            <div className="cancelSeatsModal">
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "How many seats you would like to reschedule?",
                    componentClass: "select",
                    bsClass: "form-control",
                    placeholder: "How many seats you would like to reschedule?",
                    value: this.state.seatsToReschedule,
                    name: 'seatsToReschedule',
                    onChange: this.handleSeatsChange.bind(this),
                    options: this.getSeatsOptions()
                  }
                ]}
              />
              <Button bsStyle="info" disabled={this.state.loading || !this.state.seatsToReschedule} fill type="button" onClick={this.proceedToReschedule.bind(this)}>
                Proceed
              </Button>
              {this.state.loading && <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
          </Modal>
          <Modal open={this.state.openBook} onClose={this.closeBook.bind(this)} center>
            <div className="cancelSeatsModal bookingModal">
              {this.state.selectedStop && this.state.selectedLeg && <h4 className="edit-existing-title">You are rescheduling {this.state.seatsToReschedule} seats of the existing reservation for {this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName}, leaving from {this.state.selectedStop.stop.address} at {moment(this.state.selectedStop.time, 'HH:mm:ss').format('hh:mm a')} on {this.state.selectedLeg.slot}<div>Customer Paid: {this.getPricePaidBreakup().text}</div></h4>}
              <form name="mainForm" onSubmit={this.findSeats.bind(this)}>
                <Row className="form-group">
                  <Col md={3}>
                    <label>Choose Date</label>
                  </Col>
                  <Col md={9}>
                    <DatePicker
                      className="form-control"
                      selected={this.state.departDate}
                      onChangeRaw={this.handleDateChangeRaw.bind(this)}
                      onChange={this.handleDepartDate.bind(this)}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={3}>
                    <label>Leaving From</label>
                  </Col>
                  <Col md={9}>
                    <select
                      className = "form-control"
                      value = {this.state.departCity}
                      name = "departCity"
                      onChange = {this.handleSeatsChange.bind(this)}
                    >
                      <option value=""></option>
                      {
                        this.state.cities.map((stop, key) => {
                          return(<option key={key} value={stop.value}>{stop.label}</option>)
                        })
                      }
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col md={3}>
                    <label>Arriving To</label>
                  </Col>
                  <Col md={9}>
                    <select
                      className = "form-control"
                      value = {this.state.arriveCity}
                      name = "arriveCity"
                      disabled = {this.state.arriveCity === 'new york' && this.state.departCity !== 'new york'}
                      onChange = {this.handleSeatsChange.bind(this)}
                    >
                      <option value=""></option>
                      {
                        this.state.cities.map((stop, key) => {
                          return(<option key={key} value={stop.value}>{stop.label}</option>)
                        })
                      }
                    </select>
                  </Col>
                </Row>
                <Button disabled={this.state.loading || !this.state.departDate || !this.state.departCity || !this.state.arriveCity} bsStyle="info" fill type="submit">
                  Go
                </Button>
                {this.state.loading && 
                  <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
                {this.state.error && <div className="error">{this.state.error}</div>}
                {this.state.noSchedule && <div className="error text-center">No schedules found for this date and destination</div>}
                <div className="clearfix" />
              </form>
            </div>
          </Modal>
          <Modal open={this.state.openSchedule} onClose={this.closeSchedule.bind(this)} center>
            {this.state.selectedStop && this.state.selectedLeg && this.state.schedules && <div className="cancelSeatsModal bookingModal">
              <h4 className="edit-existing-title">You are rescheduling {this.state.seatsToReschedule} seats of the existing reservation for {this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName}, leaving from {this.state.selectedStop.stop.address} at {moment(this.state.selectedStop.time, 'HH:mm:ss').format('hh:mm a')} on {this.state.selectedLeg.slot}<div>Customer Paid: {this.getPricePaidBreakup().text}</div></h4>
                <Row className="main-box-container">
                  <Col md={12}>
                    <div className="main-schedule-box">
                      <div className="border-black head">
                      {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && !this.state.schedules[0].specialPrice &&
                        <div className="special-main-schedule">--Date has special schedule--</div>
                      }
                      {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && this.state.schedules[0].specialPrice &&
                        <div className="special-main-schedule">--Date has special schedule and price--</div>
                      }
                      Leaving from {this.getCityLabel(this.state.departCity)} on <br/> {moment(this.state.departDate).format('dddd, MMMM Do YYYY')}
                      </div>
                      {this.state.schedules && this.state.schedules.map((bus, index) => {
                        return (
                          <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                            <div className="border-black bus-number">Bus #{index+1}:</div>
                            <div className="stops-group">
                              {bus.stops.map((stop, i) => {
                                return (
                                  stop.type === 'PU' &&
                                  <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                    {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} from {stop.stop.address}
                                    <input checked={this.state.departStop === stop.id} className="res-slot" type="radio" name="departStop" value={this.state.departStop} onChange={this.selectDepartStop.bind(this, stop, index)} />
                                  </div>
                                )
                              })}
                            </div>
                            <div className="border-black">
                              <div className="total-row">Total: {bus.reservations} {bus.bus_type.type === 'Elite' ? (<span className="elite-res">Elite</span>) : ''}{bus.bus_type.type === 'Elite' ? ' ' : ''} Max Capacity: {bus.capacity}</div>
                              <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div>
                              <div className="main-driver">Company: {bus.bus_type.company}</div>
                              <div className="main-price-label">Coupons Allowed:</div>
                              <div className="main-price-value">{bus.couponLimit}</div>
                              <div className={"main-price-label " + (bus.bus_type.defaultPrice !== bus.price ? "special-price" : "")}>Price:</div>
                              {bus.bus_type.defaultPrice === bus.price && <div className="main-price-value">${bus.price}(unchanged)</div>}
                              {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <div className="main-price-value special-price">${bus.price}(price for first {bus.firstSeats} seats)</div> : ''}
                              {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(price for last {bus.lastSeats} seats)</div> : ''}
                              {bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <div className="main-price-value special-price">${bus.price}(special price for whole bus)</div> : ''}
                              <div className="main-price-label">Bus is:</div>
                              <div className="main-price-value">{bus.isOpen ? 'Open' : 'Closed'}</div>
                              {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                              {bus.notes && <div className="main-price-value">{bus.notes}</div>}
                              <div>Destinations:
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'DO' &&
                                      <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} at {stop.stop.address}
                                        {(this.state.arriveStop === stop.id) && <i className='fa fa-check'></i>}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}  
                    </div>
                  </Col>
                </Row>
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "Add note to new reservation",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Remark",
                      value: this.state.remark,
                      name: 'remark',
                      onChange: this.handleSeatsChange.bind(this)
                    }
                  ]}
                />
                { this.state.selectedLeg && this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null ?
                  (<Fragment>
                    <Row>
                      <Col md={12}>
                        <h5 className="select-to-pay">Original Seats Cost: {numeral(this.state.selectedLeg.legPrice).format('$0.00')}</h5>
                      </Col>
                      <Col md={12}>
                        <h5 className="select-to-pay">Customer Paid: {this.getPricePaidBreakup().text} {this.getPricePaidBreakup().text === '0' ? '-' : ''} {(this.getPricePaidBreakup().text === '0' && this.state.selectedLeg.paymentMethod ? this.state.selectedLeg.paymentMethod : '')}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h5 className="select-to-pay">New Seats Price: ${this.state.schedules[this.state.selectedBus].price*this.state.seatsToReschedule}</h5>
                      </Col>
                    </Row>
                  </Fragment>)
                : ''}
                {this.getAmount() !== 0 && <div>
                  {/*<FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Override Difference Amount",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Amount",
                        value: this.state.customAmount,
                        name: 'customAmount',
                        onChange: this.handleChange,
                        min: 0
                      }
                    ]}
                  />*/}
                  {this.state.selectedLeg && !this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.selectedLeg.user.credits > 0 ? <Row>
                    <Col md={12}>
                      <div className="apply-credit-row">Applied Tripperbus Credit to cover the amount {numeral(this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount()).format('$0.00')}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="apply-credit-row">Customer's Tripperbus Credit Balance will be {numeral(this.state.selectedLeg.user.credits - (this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount())).format('$0.00')} after payment
                      </div>
                    </Col>
                  </Row> : ''}
                  {this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.points >= this.getRequiredPoints().points ? <Row>
                    <Col md={12}>
                      <div className="apply-credit-row">
                        Transferred {this.getRequiredPoints().points} Voucher Points to cover cost of new reservation
                      </div>
                    </Col>
                  </Row> : ''}
                  {this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.points < this.getRequiredPoints().points ? <Fragment>
                    <Row>
                      <Col md={12}>
                        <div className="select-to-pay">
                          {this.getVouchersMessage()}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="select-to-pay">
                          Payment must be done with credit card due to insufficient points, points in cancelled reservation will be returned.
                        </div>
                      </Col>
                    </Row>
                  </Fragment> : ''}
                  {/*{((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) && <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Payment Method",
                        type: "text",
                        componentClass: "select",
                        bsClass: "form-control",
                        placeholder: "Payment Method",
                        value: this.state.paymentMethod,
                        name: 'paymentMethod',
                        options: this.state.methods,
                        onChange: this.handleChange.bind(this)
                      }
                    ]}
                  />}*/}
                  {this.state.paymentMethod === 'card' && ((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) && <Fragment>
                    <Row>
                      <Col md={12}>
                        <h5 className="select-to-pay">There is a difference of {numeral(this.getAmount() - (this.getCredits() + this.getRequiredPoints().pointsAmount)).format('$0.00')} to be paid with Credit Card.</h5>
                      </Col>
                    </Row>
                  </Fragment>}
                  {this.state.paymentMethod === 'card' && ((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) &&
                    <div>
                      <div className="row clearfix">
                        <div className="col-sm-6 form-group">
                          <label className="formLable">NAME ON CARD (If different from above)</label>
                          <input ref="cardHolder" required name="cardHolder" value={this.state.cardHolder} onChange={this.handleChange.bind(this)} type="text" className="form-control" placeholder="Name On Card" />
                        </div>
                        <div className="col-sm-6 form-group">
                          <label className="formLable">CREDIT CARD NUMBER</label>
                          <InputMask maskChar='' mask="9999-9999-9999-9999" ref="number" onChange={this.handleChange.bind(this)} value={this.state.number} name="number" required className='form-control' placeholder="Credit Card Number" />
                          {this.state.submitted && !this.state.number && <div className="error">Number is required</div>}
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-sm-4 form-group">
                          <label className="formLable">EXMONTH</label>
                          <select ref="month" required name="month" value={this.state.month} onChange={this.handleChange.bind(this)}   className='form-control'>
                            <option value=''>MM</option>
                            {this.state.months.map((item, index) => {
                              return (
                                <option key={index}>{item}</option>
                              )
                            })}
                          </select>
                          {this.state.submitted && !this.state.month && <div className="error">Month is required</div>}
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="formLable">EXYEAR</label>
                          <select ref="year" required name="year" value={this.state.year} onChange={this.handleChange.bind(this)}  className='form-control'>
                            <option value=''>YY</option>
                            {this.state.years.map((item, index) => {
                              return (
                                <option key={index}>{item}</option>
                              )
                            })}
                          </select>
                          {this.state.submitted && !this.state.number && <div className="error">Year is required</div>}
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="formLable">SEC CODE</label>
                          <InputMask required maskChar='' mask="9999" ref="cvv" onChange={this.handleChange.bind(this)} value={this.state.cvv} name="cvv" className='form-control' placeholder="SEC" />
                          {this.state.submitted && !this.state.cvv && <div className="error">Cvv is required</div>}
                        </div>
                      </div>
                    </div>
                  }
                </div>}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.schedules[this.state.selectedBus].price < this.getPricePaid() && this.state.selectedLeg && !this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) ? <Row>
                  <Col md={12}>
                    {!this.state.waiveDifference ? <div className="apply-credit-row">
                      There are {numeral(this.getPricePaid()-this.state.schedules[this.state.selectedBus].price).format('$0.00')} credits left over from cancellation that will bank.
                    </div> : <div className="apply-credit-row">
                      The difference will not be returned.
                    </div>}
                  </Col>
                  <Col md={12} style={{margin: '5px 0'}}>
                    <label>
                      <input type="checkbox" name="waiveDifference" onChange={this.waiveDifference.bind(this)} /> Do not return the credit difference to customer. Void it.
                    </label>
                  </Col>
                </Row> : ''}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.schedules[this.state.selectedBus].price == this.getPricePaid()) ? <Row>
                  <Col md={12}>
                    <div className="apply-credit-row">
                      This reservation is an even exchange.
                    </div>
                  </Col>
                </Row> : ''}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.schedules[this.state.selectedBus].bus_type.type !== 'Elite' && this.state.selectedLeg.schedule_bus.bus_type.type === 'Elite') ? <Row>
                  <Col md={12}>
                    <div className="apply-credit-row">
                      New ticket is a downgrade, applying {6*this.getSeatsWithPoints(this.getActiveReservations(this.state.selectedLeg.reservations))} points from reservation and voiding the rest.
                    </div>
                  </Col>
                </Row> : ''}
                <Row><Col md={12}><Button disabled={this.state.loading || !this.state.departStop || !this.state.arriveStop} bsStyle="info" fill type="button" onClick={this.book.bind(this)}>
                  Reschedule
                </Button></Col></Row>
                {this.state.loading && 
                  <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
                {this.props.error && <div className="error">{this.props.error}</div>}
                <div className="clearfix" />
            </div>}
          </Modal>
          <Modal open={this.state.openSuccess} onClose={this.closeSuccess.bind(this)} center>
            <div className="cancelSeatsModal">
              <h4>Rescheduled successfully with reservation #{this.state.code}.</h4>
              <Button bsStyle="info" fill type="button" onClick={this.closeSuccess.bind(this)}>
                OK
              </Button>
            </div>
          </Modal>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.customers;
  return {
    loading, customer: item, error, success, reservations: sortReservations(state.reservations.items), creditSuccess: state.cashCredits.success,  voucherSuccess: state.vouchers.success, user: state.authentication.me
  };
}

function sortReservations(items) {
  if(!items || !items.length) {
    return [];
  }
  return _.sortBy(items, (item) => {
    return -(moment(item.slot+item.depart_stop.time, 'YYYY-MM-DDHH:mm:ss'));
  });
}
 
export default connect(mapStateToProps)(EditCustomer);
