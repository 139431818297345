import config from 'config';
import authHeader from '../helpers/authHeaders';
 
export const mainService = {
  getMain,
  movePeople,
  saveScheduleBus,
  getSheet,
  sendConfirmationMail,
  sendCustomMail,
  sendNote,
  sendCustomMailAndMessage,
  sendCustomMessage,
  markCheckedIn,
  uncheckAll,
  markCheckedInOne,
  complimentaryPartial,
  complimentaryOne,
  complimentaryAll,
  updateReservationLeg
};

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
 
function getMain(filters) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(filters)
  };
  return fetch(`${config.apiUrl}/main`, requestOptions).then(handleResponse);
}

function sendConfirmationMail(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/confirmation`, requestOptions).then(handleResponse);
}

function updateReservationLeg(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/reservation-leg/${id}`, requestOptions).then(handleResponse);
}

function sendCustomMail(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/mail`, requestOptions).then(handleResponse);
}

function sendCustomMessage(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/message`, requestOptions).then(handleResponse);
}

function sendCustomMailAndMessage(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/mail-message`, requestOptions).then(handleResponse);
}

function sendNote(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/note`, requestOptions).then(handleResponse);
}

function markCheckedIn(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/checked`, requestOptions).then(handleResponse);
}

function uncheckAll(leg, count) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(`${config.apiUrl}/main/uncheck/${leg}/${count}`, requestOptions).then(handleResponse);
}

function markCheckedInOne(leg, count) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  return fetch(`${config.apiUrl}/main/checked-one/${leg}/${count}`, requestOptions).then(handleResponse);
}

function complimentaryAll(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/complimentaryAll`, requestOptions).then(handleResponse);
}

function complimentaryOne(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/complimentaryOne`, requestOptions).then(handleResponse);
}

function complimentaryPartial(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/complimentaryPartial`, requestOptions).then(handleResponse);
}

function getSheet(filters) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  let qs = '?' + getQueryString(filters);
  return fetch(`${config.apiUrl}/main/driver-sheet/${qs}`, requestOptions).then(handleResponse);
}

function movePeople(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/move`, requestOptions).then(handleResponse);
}

function saveScheduleBus(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/main/save-bus`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
 
function handleResponse(response) {
  console.log(response.body);
  return response.text().then(text => {
    console.log(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
 
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}