import config from 'config';
import authHeader from '../helpers/authHeaders';
import _ from 'lodash';
 
export const scheduleService = {
  add,
  update,
  getSchedules,
  getSchedule,
  getScheduleSummary,
  delete: _delete
};

function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
 
function getSchedules(criteria) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let qs = '?' + getQueryString(criteria);
  return fetch(`${config.apiUrl}/schedule/calendar/monthly/${qs}`, requestOptions).then(handleResponse);
}

function getScheduleSummary(id, date) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/schedule/calendar/summary/${id}/${date}`, requestOptions).then(handleResponse);
}
 
async function getSchedule(id, date = null) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const resp =  await fetch(`${config.apiUrl}/schedule/${id}`, requestOptions).then(handleResponse);


  if(date){

    const payload = {          
      scheduleId: id,
      departDate: date,
      seats: 1
    }
    const requestOptionsForTicketsPerBus = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };
      const sb = await fetch(`${config.apiUrl}/schedule/searchScheduleById`, requestOptionsForTicketsPerBus).then(handleResponse)
      if(sb && sb.schedules && sb.schedules.length){
        resp.schedule_buses.map((data)=>{
          
          const reservations = _.find(sb.schedules, {schedule_bus_id: data.schedule_bus_stops[0].schedule_bus});
          data.bookedSeats = reservations && reservations.activeReservations ? reservations.activeReservations : 0;
         
        })
      }
}
  return resp;
}
 
function add(schedule) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(schedule)
  };
 
  return fetch(`${config.apiUrl}/schedule`, requestOptions).then(handleResponse);
}
 
function update(id, schedule) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(schedule)
  };
 
  return fetch(`${config.apiUrl}/schedule/${id}`, requestOptions).then(handleResponse);
}
 
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
 
  return fetch(`${config.apiUrl}/schedule/${id}`, requestOptions).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
 
function handleResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
 
      const error = (text && ((text.indexOf('{') > -1 && JSON.parse(text).error) || text)) || response.statusText || 'Something went wrong.';
      return Promise.reject(error);
    }
    const data = text && JSON.parse(text);
    return data;
  });
}