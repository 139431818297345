import { GET_TOPBAR_REQUEST, GET_TOPBAR_SUCCESS, GET_TOPBAR_FAILURE, UPDATE_TOPBAR_REQUEST, UPDATE_TOPBAR_SUCCESS, UPDATE_TOPBAR_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_TOPBAR_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_TOPBAR_SUCCESS:
      return {
        loading: false,
        item: action.topbar
      };
    case GET_TOPBAR_FAILURE:
      return {
        error: action.error
      };
    case UPDATE_TOPBAR_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_TOPBAR_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_TOPBAR_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    default:
      return state
  }
}