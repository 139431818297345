import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from '../constants';
import jwt from 'jwt-decode';

let me = localStorage.getItem('token') ? jwt(localStorage.getItem('token')) : null;
const initialState = me ? { loggedIn: true, me } : {};

 
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        me: jwt(action.token)
      };
    case LOGIN_FAILURE:
      return {
        error: action.error
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}