import React, { Component } from "react";
import { connect } from "react-redux";
import mainActions from "actions/main.actions";
import { mainService } from "services/main.services";
import moment from "moment-timezone";
import config from "config";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";

function queryStringToJSON(param) {
  var pairs = param.slice(1).split("&");
  var result = {};
  pairs.forEach(function (pair) {
    pair = pair.split("=");
    result[pair[0]] = decodeURIComponent(pair[1] || "");
  });
  return JSON.parse(JSON.stringify(result));
}

class DriverSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: {},
      seats: {},
      _notificationSystem: null,
    };
  }

  componentWillUpdate(props) {
    if (props.error) {
      this.notify({ type: "error", message: props.error });
    }
  }

  handleSeatsChange(leg, e) {
    let seats = this.state.seats;
    seats[leg] = e.target.value;
    this.setState({ seats: seats });
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    let criteria = queryStringToJSON(this.props.location.search);
    this.setState({ criteria: criteria }, () => {
      this.props.dispatch(mainActions.getDriverSheet(criteria));
    });
  }

  getTitle() {
    let criteria = queryStringToJSON(this.props.location.search);
    return (
      "Leaving on " +
      moment(criteria.date).format("dddd, MMMM Do YYYY") +
      " from " +
      criteria.departCity
    );
  }

  getStopTotal(reservations) {
    let count = 0;
    reservations.map((item) => {
      count += item.active;
      return item;
    });
    return count;
  }

  getBusTotal() {
    let count = 0;
    (this.props.sheet || []).map((item) => {
      for (let i = 0; i < item.reservations.length; i++) {
        count += item.reservations[i].active;
      }
      return item;
    });
    return count;
  }

  checkIn(id, count = 1, index, resIndex) {
    this.setState({ loading: true });
    mainService
      .markCheckedInOne(id, count)
      .then((result) => {
        this.setState({ loading: false });
        this.notify({ type: "success", message: "Checked In" });
        const newSheet = this.props.sheet.slice();
        const reservationIndex = newSheet[index]
          .reservations
          .findIndex(r => r.id === id);
        newSheet[index].reservations[reservationIndex].checked = parseInt(count);
        this.props.dispatch(mainActions.updateDriverSheet(newSheet));
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.notify({ type: "error", message: err });
        console.log(err);
      });
  }

  uncheckAll(id, count, index, resIndex) {
    this.setState({ loading: true });
    mainService
      .uncheckAll(id, count)
      .then((result) => {
        this.setState({ loading: false });
        this.notify({ type: "success", message: "Unchecked All" });
        const newSheet = this.props.sheet.slice();
        const reservationIndex = newSheet[index]
          .reservations
          .findIndex(r => r.id === id);
        newSheet[index].reservations[reservationIndex].checked = 0;
        this.props.dispatch(mainActions.updateDriverSheet(newSheet));
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.notify({ type: "error", message: err });
        console.log(err);
      });
  }

  getOptions(active) {
    let arr = [];
    for (let i = 1; i <= active; i++) {
      arr.push(i);
    }
    return arr;
  }

  notify(params) {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{params.message}</div>,
      level: params.type,
      position: "tr",
      autoDismiss: 10,
    });
  }

  reservationTable(res, num, index) {
    return (
      <tr key={num} className={res.active === 0 ? "strike" : ""}>
        {(res.active === 0 || res.active === res.checked) && <td></td>}
        {res.active !== 0 && res.active !== res.checked && (
          <td width={140}>
            <select
              value={this.state.seats[res.id]}
              onChange={this.handleSeatsChange.bind(this, res.id)}
              className="ava-seats-select"
            >
              {this.getOptions(res.active).map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            <button
              type="button"
              className="ava-seats-btn"
              disabled={this.state.loading}
              onClick={this.checkIn.bind(
                this,
                res.id,
                this.state.seats[res.id],
                index,
                num
              )}
            >
              Check In
            </button>
            {res.checked > 0 && (
              <div className="already-checked-in">
                Already In: {res.checked}
              </div>
            )}
          </td>
        )}
        <td width={20}>{res.active}</td>
        <td width={100}>
          {(res.lastName || res.user.lastName) +
            ", " +
            (res.firstName || res.user.firstName)}
        </td>
        <td width={200}>
          {config.formatReservationNumber(res.reservationNumber)}
        </td>
        <td width={120}>{res.paymentMethod}</td>
        <td>
          {res.reservation_remarks &&
            res.reservation_remarks.map((remark, j) => {
              return <p key={j}>{remark.note}</p>;
            })}
        </td>
        <td>
          {res.checked > 0 && (
            <button
              type="button"
              className="ava-seats-btn"
              disabled={this.state.loading}
              onClick={this.uncheckAll.bind(
                this,
                res.id,
                res.active,
                index,
                num
              )}
            >
              Uncheck All
            </button>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const sheet = this.props.sheet || [];
    return (
      <div align="center">
        <NotificationSystem ref="notificationSystem" style={style} />
        {sheet && (
          <table className="driver-sheet" cellSpacing={0} border={2}>
            <tbody>
              <tr>
                <td align="center" colSpan="7" className="sheet_bold">
                  <b>{this.getTitle()}</b>
                </td>
              </tr>
              <tr>
                <td className="sheet_bold">
                  <b>Check In</b>
                </td>
                <td className="sheet_bold">
                  <b>Seats</b>
                </td>
                <td className="sheet_bold">
                  <b>Name</b>
                </td>
                <td className="sheet_bold">
                  <b>Reservation #</b>
                </td>
                <td className="sheet_bold">
                  <b>Payment Method</b>
                </td>
                <td className="sheet_bold">
                  <b>Remarks</b>
                </td>
                <td className="sheet_bold">
                  <b>Actions</b>
                </td>
              </tr>
              <tr>
                <td align="center" colSpan="7" className="sheet_bold">
                  <br />
                </td>
              </tr>
            </tbody>
            {sheet.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td colSpan={7} style={{
                      background: '#80CBC4',
                      color: '#263238',
                    }}>
                      From {item.stop.address} at{" "}
                      {moment(item.stop.time, "HH:mm:ss").format("hh:mm a")}.
                      Stop Total: {this.getStopTotal(item.reservations)}
                    </td>
                  </tr>
                  {item.reservations
                    .filter((r) => r.active !== r.checked)
                    .map((res, num) => {
                      return this.reservationTable(res, num, index);
                    })}
                    {item.reservations.find((r) => r.active === r.checked) && <tr>
                      <td style={{
                        textAlign:"center",
                        fontWeight: 'bold',
                        fontSize: "16px",
                        background: "#FFD54F",
                        color: "#263238",
                      }} colSpan={7}>Already checked in</td>
                    </tr>}
                  {item.reservations
                    .filter((r) => r.active === r.checked)
                    .map((res, num) => {
                      return this.reservationTable(res, num, index);
                    })}
                  <tr>
                    <td colSpan={7}>
                      <br />
                    </td>
                  </tr>
                </tbody>
              );
            })}
            <tbody>
              <tr>
                <td className="align-center sheet_bold" colSpan={7}>
                  Bus Total: {this.getBusTotal()}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { sheet, loading, error } = state.main;
  return { sheet: sheet, loading, error };
};

export default connect(mapStateToProps)(DriverSheet);
