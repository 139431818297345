import React, { Component, Fragment } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import "react-table/react-table.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from "react-router-dom";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { connect } from 'react-redux';
import reservationActions from 'actions/reservation.actions';
import { reservationService } from 'services/reservation.services';
import { mainService } from 'services/main.services';
import Notes from "views/Main/Notes.jsx";
import moment from 'moment-timezone';
import Modal from 'react-responsive-modal';
import config from 'config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import numeral from 'numeral';
import _ from 'lodash';
import CustomPrice from "../../components/Popup/CustomPrice";
import { constructPricesMap } from "../../helpers/utility";
import stopActions from 'actions/stop.actions';


//moment.tz.setDefault('America/New_York');

/*eslint-disable no-script-url*/

function queryStringToJSON(param) {            
  var pairs = param.slice(1).split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return JSON.parse(JSON.stringify(result));
}

class MainReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: {},
      noSchedule: false,
      openNotes: false,
      openCancel: false,
      waiveDifference: false,
      openBook: false,
      openActionDetails: false,
      openSchedule: false,  
      departDate: moment(),
      openReschedule: false,
      cities: config.cities,
      departCity: '',
      arriveCity: '',
      departStop: '',
      arriveStop: '',
      slot: '',
      paymentMethod: 'card',
      methods: [
        {label: 'Credit Card', value: 'card'},
        {label: 'No Payment', value: 'none'},
        {label: 'Customer Already Paid', value: 'alreadyPaid'}
      ],
      cardHolder: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      remark: '',
      customAmount: '',
      credits: 0,
      points: 0,
      pointsAmount: 0,
      openSuccess: false,
      openSentSuccess: false,
      credit: 0,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      notesData: null,
      selectedLeg: null,
      selectedStop: null,
      selectedBus: null,
      actionRecord: null,
      seatsToCancel: '',
      seatsToReschedule: '',
      submitted: false,
      loading: false,
      body: '',
      text: '',
      subject: '',
      action: '',
      order: '',
      schedules: null,
      selected: [],
      reports: [],
      fields: {
        user: true,
        email: true,
        seats: true,
        legPrice: false,
        pointsUsed: false,
        pointsEarned: false,
        creditUsed: false,
        otherLeg: false,
        created_at: false,
        paid: false,
        reservationNumber: true,
        paymentGatewayId: false,
        user_ip: false,
        phone: true,
        action_records: false,
        made_by: false,
        checked: false,
        paymentMethod: false,
        notes: false,
        account: false,
        promo: false,
        arrival: false,
      },
      bulkActionInfo: '',
      currentSelectedBus: null,
      currentSelectedBusIndex: null,
      showModal: false,
      arriveCities: [],
      selectedRoutePrice: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if(name === 'departCity'){
      this.setState({ [name]: value });
      let arriveCities = [];
      if(e.target.value === 'new york' || e.target.value === 'brooklyn') {
        config.cities.map((item) => {
          if(!(['new york', 'brooklyn'].includes(item.value))) {
            arriveCities.push(item);
          }
          return item;
        });
      }
      else {
        config.cities.map((item) => {
          if(item.value === 'new york' || item.value == 'brooklyn') {
            arriveCities.push(item);
          }
          return item;
        });
      }
    this.setState({arriveCities})
  }else{
    this.setState({ [name]: value });
  }
  }

  getActiveReservations(reservations) {
    return _.filter(reservations, {status: 'active'});
  }

  handleOrder(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleDepartDate(date) {
    this.setState({ departDate: date });
  }

  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  sortStops(data) {
    for(let i = 0; i < data.length; i++) {
      if(data[i].stops.length === 3) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
        // check again now if second stop is also drop off (reading 0 index because 1 has been shifted to 0 above)
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[2],
            data[i].stops[0]
          ];
        }
      }
      else if(data[i].stops.length === 2) {
        if(data[i].stops[0].type === 'DO') {
          data[i].stops = [
            data[i].stops[1],
            data[i].stops[0]
          ];
        }
      }
    }
    return data;
  }

  findSeats(e) {
    e.preventDefault();
    this.setState({ loading: true, noSchedule: false });
    const { departDate, departCity, arriveCity } = this.state;
    if (departDate && departCity) {
      let data = {
        departCity: departCity,
        departDate: moment(departDate).format('YYYY-MM-DD'),
        arriveCity: arriveCity
      }
      mainService.getMain(data)
      .then(data => {
        this.setState({ loading: false });
        console.log(data)
        if(data && data.schedules.length > 0) {
          let schedules = this.sortStops(data.schedules);
          schedules = _.orderBy(schedules, (item) => {
            return moment(item.stops[0].time, 'HH:mm:ss');
          });
          schedules = _.orderBy(schedules, (item) => {
            return item.deleted;
          });
          this.setState({openSchedule: true, schedules: schedules, openBook: false, customAmount: ''});
        }
        else {
          this.setState({noSchedule: true});
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({error: err, loading: false});
      })
    }
    else {
      return false;
    }
  }

  toggleField(e) {
    let name = e.target.name;
    let fields = this.state.fields;
    if(this.state.fields[name]) {
      fields[name] = false;
      this.setState({fields: fields});
    }
    else {
      fields[name] = true;
      this.setState({fields: fields});
    }
  }

  getSeatsWithPoints(seats) {
    let count = 0;
    for(let i = 0; i < this.state.seatsToReschedule; i++) {
      if(seats[i].vouchers) {
        count++;
      }
    }
    return count;
  }

  componentWillUpdate(props) {
    if(props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
  }

  getCityLabel(city) {
    if(!city) {
      return '';
    }
    return _.find(config.cities, {value: city}).label;
  }

  componentDidMount() {
    this.props.dispatch(stopActions.getStops({}));
    let criteria = queryStringToJSON(this.props.location.search);
    this.setState({criteria: criteria}, () => {
      this.props.dispatch(reservationActions.getMainReservations(criteria));
    });
    let years = [];
    let start = new Date().getFullYear();
    let end = start + 11;
    for (let i = start; i < end; i++) {
      years.push(i.toString().substr(2));
    }
    this.setState({ years: years });
  }

  getCityCode(city) {
    return _.find(config.cities, {value: city}).code;
  }

  deleteItem = ids => {
    confirmAlert({
      title: 'Delete Reservation',
      message: 'Are you sure to you want to delete this reservation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(reservationActions.deleteReservations(ids))
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  addParams() {
    return this.props.location.search;
  }

  showNotes(stop, reservation) {
    let criteria = queryStringToJSON(this.props.location.search);
    let data = {
      reservation: reservation,
      date: criteria.date,
      time: criteria.slot,
      stop: stop
    };
    this.setState({openNotes: true, notesData: data});
  }

  closeNotes() {
    this.setState({openNotes: false, notesData: null});
  }

  showActionDetails(action) {
    this.setState({openActionDetails: true, actionRecord: action});
  }

  closeActionDetails() {
    this.setState({openActionDetails: false, actionRecord: null});
  }

  getTotal() {
    let count = 0;
    (this.props.mainItems || []).map(item => {
      for(let i = 0; i < item.reservations.length; i++) {
        count += item.reservations[i].total; 
      }
      return item;
    });
    return count;
  }  

  getStopTotal(reservations) {
    let count = 0;
    (reservations || []).map(item => {
      count += item.total;
      return item;
    });
    return count;
  }

  proceed() {
    if(this.state.selected.length > 0) {
      if(this.state.action === 'delete') {
        this.deleteItem(this.state.selected);
      }
      else if(this.state.action === 'email') {
        this.setState({loading: true});
        mainService.sendConfirmationMail({id: this.state.selected})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'customEmail') {
        if(!this.state.subject || !this.state.body) {
          return;
        }
        this.setState({loading: true});
        mainService.sendCustomMail({id: this.state.selected, subject: this.state.subject, message: this.state.body})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'text') {
        if(!this.state.text) {
          return;
        }
        this.setState({loading: true});
        mainService.sendCustomMessage({id: this.state.selected, text: this.state.text})
        .then(result => {
          this.setState({
            openSentSuccess: true,
            loading: false,
            bulkActionInfo: result.message,
            reports: result.report,
          });
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'customEmailAndText') {
        if(!this.state.subject || !this.state.body || !this.state.text) {
          return;
        }
        this.setState({loading: true});
        mainService.sendCustomMailAndMessage({id: this.state.selected, subject: this.state.subject, message: this.state.body, text: this.state.text})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'note') {
        if(!this.state.body) {
          return;
        }
        this.setState({loading: true});
        mainService.sendNote({id: this.state.selected, message: this.state.body})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'checked') {
        this.setState({loading: true});
        mainService.markCheckedIn({id: this.state.selected})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'complimentaryAll') {
        if(!this.state.body) {
          return;
        }
        this.setState({loading: true});
        mainService.complimentaryAll({id: this.state.selected, message: this.state.body})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'complimentaryOne') {
        if(!this.state.body) {
          return;
        }
        this.setState({loading: true});
        mainService.complimentaryOne({id: this.state.selected, message: this.state.body, credit: this.state.credit})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
      else if(this.state.action === 'complimentaryPartial') {
        if(!this.state.body) {
          return;
        }
        if(!this.state.credit) {
          return;
        }
        this.setState({loading: true});
        mainService.complimentaryPartial({id: this.state.selected, credit: this.state.credit, message: this.state.body})
        .then(result => {
          this.setState({openSentSuccess: true, loading: false, bulkActionInfo: result.message});
        })
        .catch(err => {
          this.setState({loading: false});
          console.log(err);
        })
      }
    }
  }

  closeSentSuccess() {
    this.setState({openSentSuccess: false, bulkActionInfo: ''});
    this.props.dispatch(reservationActions.getMainReservations(this.state.criteria));
  }

  getSeatsOptions() {
    let arr = [];
    if(!this.state.selectedLeg) {
      return arr;
    }
    for(let i = 1; i <= this.state.selectedLeg.total; i++) {
      arr.push({label: i, value: i});
    }
    return arr;
  }

  getRecordsSummary(leg) {
    let arr = [];
    if(leg.action_records && leg.action_records.length > 0) {
      for(let i = 0; i < leg.action_records.length; i++) {
        arr.push(leg.action_records[i].type + ': ' + moment(leg.action_records[i].created_at).format('MM/DD/YY') + ' at ' + moment(leg.action_records[i].created_at).format('hh:mm A'));
      }
    }
    return arr;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, error: '', code: '' });

    let data = {
      reservationNumber: this.state.selectedLeg.reservationNumber,
      schedule_bus: this.state.schedules[this.state.selectedBus].schedule_bus,
      date: moment(this.state.departDate).format('YYYY-MM-DD'),
      status: 'active',
      waiveDifference: this.state.waiveDifference,
      slot: this.state.slot,
      departCity: this.state.departCity,
      departCityCode: this.getCityCode(this.state.departCity),
      arriveCity: this.state.arriveCity,
      seats: parseInt(this.state.seatsToReschedule, 10),
      email: this.state.selectedLeg.user.email,
      paymentMethod: this.state.paymentMethod,
      depart_stop: this.state.departStop,
      arrive_stop: this.state.arriveStop,
      amount: this.getAmount(),
      remark: this.state.remark,
      payment: this.getAmount(),
      // price: this.state.selectedRoutePrice,
      price: this.state.selectedRoutePrice,
      pricePerSeat: (!this.state.customAmount ? this.state.selectedRoutePrice : (this.getActiveReservations(this.state.selectedLeg.reservations)[0].order_item.amount + parseInt(this.state.customAmount, 10))),
      letter: this.state.schedules[this.state.selectedBus].letter,
      customAmount: parseInt(this.state.customAmount, 10),
      credits: 0,
      points: 0
    }
    if(this.getCredits()) {
      data.credits = this.getCredits();
      if(this.getCredits() < this.getAmount()) {
        data.payment = this.getAmount() - this.getCredits();
      }
      else {
        data.payment = 0;
      }
    }
    if(this.getRequiredPoints().points > 0 && this.getRequiredPoints().points <= this.state.selectedLeg.user.points) {
      data.points = this.getRequiredPoints().points;
      data.pointsAmount = this.getRequiredPoints().pointsAmount;
      if(this.getRequiredPoints().pointsAmount < this.getAmount()) {
        data.payment = this.getAmount() - this.getRequiredPoints().pointsAmount;
      }
      else {
        data.payment = 0;
      }
    }
    if(!data.payment && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) {
      data.paymentMethod = 'Tripperbus Points';
    }
    if(!data.payment && (this.getActiveReservations(this.state.selectedLeg.reservations)[0].credit || this.getActiveReservations(this.state.selectedLeg.reservations)[0].paid)) {
      data.paymentMethod = 'Tripperbus Credits';
    }
    if(this.state.paymentMethod === 'card' && data.payment) {
      if(!this.state.number || !this.state.cvv || !this.state.month || !this.state.year) {
        return;
      }
      data.number = this.state.number.split('-').join('');
      data.cvv = this.state.cvv;
      data.expiryDate = '' + this.state.month + '' + this.state.year;
      data.cardHolder = this.state.cardHolder || (this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName);
      data.paymentMethod = 'card';
    }
    if(this.state.paymentMethod !== 'card' && data.paymentMethod !== 'Tripperbus Credits' && data.paymentMethod !== 'Tripperbus Points') {
      data.credits = 0;
      data.payment = 0;
      data.points = 0;
    }
    this.setState({loading: true});
    console.log(data);
    reservationService.rescheduleMainReservation(data)
    .then(result => {
      console.log(result);
      this.setState({loading: false, code: result.departTripCode, openSuccess: true, customAmount: ''});
    })
    .catch(err => {
      console.log(err);
      this.setState({loading: false, error: err});
    });
  }

  closeSuccess() {
    window.location.reload();
  }

  toggleSelectAll(e) {

    if(e.target.checked) {
      let selected = [];
      (this.props.mainItems || []).map(item => {
        if(item){
        item.reservations.map((res)=>{
          selected.push(res.id);
        })        
      }});
      this.setState({selected: selected});
    }
    else {
      this.setState({selected: []});
    }
  }

  toggleSelect(id) {
    let selected = this.state.selected;
    let index = this.state.selected.indexOf(id);
    if(index === -1) {
      selected.push(id);
    }
    else {
      selected.splice(index, 1);
    }
    this.setState({selected: selected});
  }

  handleSeatsChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  checkSelected(id) {
    let index = this.state.selected.indexOf(id);
    if(index > -1) {
      return true;
    }
    return false;
  }

  cancel(leg) {
    this.setState({openCancel: true, selectedLeg: leg});
  }

  closeCancel() {
    this.setState({openCancel: false, selectedLeg: null, seatsToCancel: ''});
  }

  reschedule(leg, stop) {
    let fullName = leg.firstName + ' ' + leg.lastName;
    this.setState({openReschedule: true, selectedLeg: leg, selectedStop: stop, credits: 0, points: 0, pointsAmount: 0, selectedBus: null, departStop: null, arriveStop: null, cardHolder: fullName});
  }

  switchLeg(leg, otherLeg) {
    let stop = {
      address: leg.depart_stop.stop.address,
      time: leg.depart_stop.time,
      id: leg.depart_stop.id
    };
    leg.otherLeg = otherLeg;
    this.setState({openReschedule: true, selectedLeg: leg, selectedStop: stop, credits: 0, points: 0, pointsAmount: 0, selectedBus: null, departStop: null, arriveStop: null});
  }

  switchCancelLeg(leg, otherLeg) {
    leg.otherLeg = otherLeg;
    this.setState({openCancel: true, selectedLeg: leg});
  }

  closeReschedule() {
    this.setState({openReschedule: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null, waiveDifference: false, points: 0, pointsAmount: 0});
  }

  closeSchedule() {
    this.setState({openSchedule: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null, waiveDifference: false, openReschedule: false, points: 0, pointsAmount: 0});
  }

  waiveDifference() {
    this.setState({waiveDifference: !this.state.waiveDifference});
  }

  proceedToCancel() {
    if(this.state.seatsToCancel) {
      this.setState({loading: true});
      reservationService.cancelMainSeats(this.state.selectedLeg.reservationNumber, this.state.seatsToCancel, this.state.selectedLeg.reservation_group)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        this.setState({loading: false});
      })
    }
  }

  proceedToReschedule() {
    if(this.state.seatsToReschedule) {
      this.setState({openBook: true, noSchedule: false});
    }
  }

  applyCredit() {
    let useCredit = this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount();
    this.setState({credits: useCredit, points: 0, pointsAmount: 0});
  }

  removeCredit() {
    this.setState({credits: 0});
  }

  applyPoints() {
    let requiredPoints = this.getRequiredPoints();
    this.setState({points: requiredPoints.points, pointsAmount: requiredPoints.pointsAmount, credits: 0});
  }

  removePoints() {
    this.setState({points: 0, pointsAmount: 0});
  }

  closeBook() {
    this.setState({openBook: false, selectedLeg: null, seatsToReschedule: '', selectedStop: null});
  }

  selectDepartStop(stop, index) {
    let arriveStop = '';
    let schedules =  this.state.schedules;
    for(let i = 0; i < this.state.schedules[index].stops.length; i++) {
      if(this.state.arriveCity === this.state.schedules[index].stops[i].stop.city) {
        arriveStop = this.state.schedules[index].stops[i].id;
      }
    }
    
    let selectedRoutePrice = this.getCustomPriceIfAvailable(this.state.schedules[index],stop.id, arriveStop);

    selectedRoutePrice = selectedRoutePrice ? selectedRoutePrice : this.state.schedules[index].price;

    this.setState({departCity: stop.stop.city, departStop: stop.id, slot: stop.time, arriveStop: arriveStop, selectedBus: index, schedules, selectedRoutePrice});
  }

  selectArriveStop(stop, index) {
    if(index === this.state.selectedBus) {
      this.setState({arriveStop: stop});
    }
  }

  getPaid() {
    if(this.state.selectedLeg) {
      let val = this.getActiveReservations(this.state.selectedLeg.reservations)[0].order_item.amount*this.state.seatsToReschedule;
      if(this.state.selectedLeg.coupon) {
        if(this.state.selectedLeg.coupon.type === '$') {
          return val - this.state.selectedLeg.coupon.amount;
        }
        else {
          return val - ((val*this.state.selectedLeg.coupon.amount)/100);
        }
      }
      return val;
    }
    return 0;
  }

  getPricePaid() {
    if(this.state.selectedLeg) {
      let val = 0;
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          val += this.getActiveReservations(this.state.selectedLeg.reservations)[i].order_item.amount
        }
        else {
          val = val + this.state.selectedLeg.legPrice;
        }
      }
      return val/this.state.seatsToReschedule;
    }
    return 0;
  }

  getPricePaidBreakup() {
    let breakup = {
      paid: 0,
      credits: 0,
      points: 0
    };
    let response = '';
    if(this.state.selectedLeg) {
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid) {
          breakup.paid += this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid;
        }
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) {
          breakup.credits += this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit;
        }
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          breakup.points += this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers;
        }
      }
      if(breakup.paid) {
        response += `$${breakup.paid} Cash`;
        if(breakup.credits || breakup.points) {
          response += ' + ';
        }
      }
      if(breakup.credits) {
        response += `$${breakup.credits} Tripperbus Credits`;
        if(breakup.points) {
          response += ' + ';
        }
      }
      if(breakup.points) {
        response += `${breakup.points} Points`;
      }
    }
    response = response || '0';
    return {object: breakup, text: response};
  }

  getRequiredPoints() {
    const res = {
      points: 0,
      pointsAmount: 0,
    };
    if(this.state.selectedBus !== null && this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite' && this.state.selectedLeg.schedule_bus.bus_type.type !== 'Elite') {
      for(let i = 0; i < this.state.seatsToReschedule; i++) {
        if(this.getActiveReservations(this.state.selectedLeg.reservations)[i].vouchers) {
          res.pointsAmount += (this.state.selectedRoutePrice - this.state.selectedLeg.legPrice);
          res.points += 6;
        }
        else if((this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid || this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) && this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite') {
          res.pointsAmount += (this.state.selectedRoutePrice - this.state.selectedLeg.legPrice);
          res.points += 12;
        }
        else if((this.getActiveReservations(this.state.selectedLeg.reservations)[i].paid || this.getActiveReservations(this.state.selectedLeg.reservations)[i].credit) && this.state.schedules[this.state.selectedBus].bus_type.type !== 'Elite') {
          res.pointsAmount += (this.state.selectedRoutePrice - this.state.selectedLeg.legPrice);
          res.points += 6;
        }
      }
    }
    return res;
  }

  getCredits() {
    if (this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) {
      return 0;
    }
    return (this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount());
  }

  getAmount() {
    if(this.state.selectedBus !== null && this.state.selectedLeg) {
      if(this.state.customAmount) {
        return parseInt(this.state.customAmount, 10);
      }
      else if(this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.getRequiredPoints().points > this.state.selectedLeg.user.points) {
        return (this.state.selectedRoutePrice*this.state.seatsToReschedule);
      }
      else if(this.state.selectedRoutePrice >= this.getPricePaid()) {
        let amount = (this.state.selectedRoutePrice*this.state.seatsToReschedule) - (this.getPricePaid()*this.state.seatsToReschedule);
        return amount;
      }
    }
    return 0;
  }

  sortItems(items) {
    if(!this.state.order) {
      return items;
    }
    else if(this.state.order === 'firstName' || this.state.order === 'email'){
      return _.orderBy(items, item => {
        return item.user[this.state.order].toLowerCase();
      });
    }
    else if(this.state.order === 'reservationNumber'){
      return _.orderBy(items, item => {
        return item[this.state.order];
      });
    }
    else if(this.state.order === 'slot'){
      return _.orderBy(items, item => {
        return new Date(item[this.state.order]);
      });
    }
    else if(this.state.order === 'created_at'){
      return _.orderBy(items, item => {
        return new Date(item[this.state.order]);
      });
    }
    else if(this.state.order === 'seats'){
      return _.orderBy(items, item => {
        return item.total;
      });
    }
  }

  getVouchersMessage() {
    const breakup = this.getPricePaidBreakup().object;
    if(!breakup.paid && !breakup.credits) {
      return `Customer paid the total with ${breakup.points} points, new reservation is worth ${this.state.seatsToReschedule*12} points`;
    }
    if(breakup.paid || breakup.credits) {
      return `Customer paid the total with ${this.getPricePaidBreakup().text}, new reservation is worth ${this.state.seatsToReschedule*(this.state.schedules[this.state.selectedBus].bus_type.type === 'Elite' ? 12 : 6)} points`;
    }
    return '';
  }


  showEditPrice(schedule_bus, index){
    let departStops = schedule_bus.stops.filter((stop)=> stop.type === "PU" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let destinationStops = schedule_bus.stops.filter((stop)=> stop.type === "DO" && !stop.deleted).map((stop)=>({...stop, stop: stop.stop.id}));
    let prices = schedule_bus.prices;
    let stopCombination = constructPricesMap(departStops, destinationStops, prices, this.props.stops);
    this.setState({showModal:true, currentSelectedBus: stopCombination, currentSelectedBusIndex: index});
  }

  getCustomPriceIfAvailable(bus, scheduledDepartStopId, scheduledArriveStopId){
    if(bus.prices && bus.prices.length > 0){
        for(let price of bus.prices){
            if(price.from_schedule_bus_stop_id === scheduledDepartStopId && price.to_schedule_bus_stop_id === scheduledArriveStopId){
                return price.price;
            }
        }
    }
    return null;

  }

  render() {
    const mainItems = this.props.mainItems || [];
    return (
      <div className="content">
        <Grid fluid>
          {mainItems && <Row>
            <Col md={12}>
              <Card
                title="Main Reservations"
                additionalComponent={
                  <div className="row acGrid">
                    <div><NavLink to={'/driver-sheet'+this.addParams()} target="_blank"><Button type="button" fill bsStyle="info">Click For Driver Sheet</Button></NavLink></div>
                    <div><NavLink to={'/dashboard/main-reservation'+this.addParams()} target="_blank"><Button type="button" fill bsStyle="info"><i className="fa fa-plus"></i>Add Reservation</Button></NavLink></div>
                  </div>
                }
                content={
                  <div>
                    <div className="addition-fields-checks">
                      <div className="showHide">Show/Hide Fields</div>
                      <div className="checks">
                        <label>
                          <input type="checkbox" name="created_at" onChange={this.toggleField.bind(this)} /> 
                          <span>Date Made</span>
                        </label>
                        <label>
                          <input type="checkbox" name="legPrice" onChange={this.toggleField.bind(this)} /> 
                          <span>Leg Price</span>
                        </label>
                        <label>
                          <input type="checkbox" name="paid" onChange={this.toggleField.bind(this)} /> 
                          <span>Transaction Amount</span>
                        </label>
                        <label>
                          <input type="checkbox" name="pointsUsed" onChange={this.toggleField.bind(this)} /> 
                          <span>Points Used</span>
                        </label>
                        <label>
                          <input type="checkbox" name="creditUsed" onChange={this.toggleField.bind(this)} /> 
                          <span>Credits Used</span>
                        </label>
                        <label>
                          <input type="checkbox" name="pointsEarned" onChange={this.toggleField.bind(this)} /> 
                          <span>Points Earned</span>
                        </label>
                        <label>
                          <input type="checkbox" name="paymentMethod" onChange={this.toggleField.bind(this)} />
                           <span>
                            Payment Method
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="paymentGatewayId" onChange={this.toggleField.bind(this)} /> 
                          <span>
                          Authorize.net Link
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="checked" onChange={this.toggleField.bind(this)} /> 
                          <span>
                            Checked In
                            </span>
                        </label>
                        <label>
                          <input type="checkbox" name="user_ip" onChange={this.toggleField.bind(this)} /> 
                          <span>
                          User IP
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="made_by" onChange={this.toggleField.bind(this)} /> 
                          <span>
         
                          Made By
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="notes" onChange={this.toggleField.bind(this)} /> 
                          <span>
                          Notes
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="account" onChange={this.toggleField.bind(this)} /> 
                          <span>
                            
                          Under Account
                          </span>
                        </label>
                        <label>
                          <input type="checkbox" name="promo" onChange={this.toggleField.bind(this)} /> 
                          <span>Promo Code</span>
                        </label>
                        <label>
                          <input type="checkbox" name="action_records" onChange={this.toggleField.bind(this)} /> 
                          <span>Action Records</span>
                        </label>
                        <label>
                          <input type="checkbox" name="otherLeg" onChange={this.toggleField.bind(this)} /> 
                          <span>Other leg</span>
                        </label>
                        <label>
                          <input type="checkbox" name="arrival" onChange={this.toggleField.bind(this)} /> 
                          <span>Arrival</span>
                        </label>
                      </div>
                      <div className="list-order">
                        <div className="row">
                          <div className="col-md-3">
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  label: "Order of Display",
                                  componentClass: "select",
                                  bsClass: "form-control",
                                  value: this.state.order,
                                  name: 'order',
                                  onChange: this.handleOrder.bind(this),
                                  options: [
                                    {label: 'Name', value: 'firstName'},
                                    {label: 'Reservation Number', value: 'reservationNumber'},
                                    {label: 'Email', value: 'email'},
                                    {label: 'Travel Date', value: 'slot'},
                                    {label: 'Date/Time Made', value: 'created_at'},
                                    {label: 'Seats', value: 'seats'}
                                  ]
                                }
                              ]}
                            />
                          </div>
                          {this.state.criteria ? <div className="col-md-3 max-capacity">Max Capacity: {this.state.criteria.capacity}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="reserve-wrapper">
                    <table className="table table-bordered">
                      {mainItems.map((item, index) => {
                        return (
                          item.stop && <tbody key={index}>
                            <tr>
                        {index === 0 && <th colSpan={20}>Bus from {item.stop.address} on {moment(queryStringToJSON(this.props.location.search).date, 'YYYY-MM-DD').format('dddd, MMMM DD YYYY')} at {moment(item.stop.time, 'HH:mm:ss').format('hh:mm A')}. Stop Total: {this.getStopTotal(item.reservations)}. {(queryStringToJSON(this.props.location.search).bus_type === 'Elite') ? <strong style={{'color': 'red'}}>Elite</strong> : ''}</th>}
                              {index !== 0 && <th colSpan={20}>This bus also picks from {item.stop.address} on {moment(item.stop.time, 'HH:mm:ss').format('hh:mm A')}. Stop Total: {this.getStopTotal(item.reservations)}</th>}
                            </tr>
                            {index === 0 && (queryStringToJSON(this.props.location.search).is_open === 'no') && <tr>
                              <th colSpan={20} className="close-bus-msg">This bus is now closed and cannot accept additional reservations unless opened by admin!</th>
                            </tr>}
                            {index === 0 && <tr>
                              <th style={{paddingLeft: '15px'}}>
                                <input type="checkbox" onChange={this.toggleSelectAll.bind(this)} />
                              </th>
                              {this.state.fields.user && <th>Name</th>}
                              {this.state.fields.email && <th>Email</th>}
                              {this.state.fields.phone && <th>Phone</th>}
                              {this.state.fields.seats && <th>Seats</th>}
                              {this.state.fields.arrival && <th>Arrival</th>}

                              {this.state.fields.legPrice && <th>Leg Price</th>}
                              {this.state.fields.pointsUsed && <th>Points Used</th>}
                              {this.state.fields.creditUsed && <th>Credits Used</th>}
                              {this.state.fields.paid && <th>Transaction Amount</th>}
                              {this.state.fields.pointsEarned && <th>Points Earned</th>}
                              {this.state.fields.paymentMethod && <th>Payment Method</th>}
                              {this.state.fields.paymentGatewayId && <th>Authorize.net Link</th>}
                              {this.state.fields.checked && <th>Checked In</th>}
                              {this.state.fields.user_ip && <th>User IP</th>}
                              {this.state.fields.created_at && <th>Date Made</th>}
                              {this.state.fields.made_by && <th>Made By</th>}
                              {this.state.fields.reservationNumber && <th>Reservation #</th>}
                              {this.state.fields.notes && <th>Notes</th>}
                              {this.state.fields.account && <th>Under Account</th>}
                              {this.state.fields.promo && <th>Promo Code</th>}
                              {this.state.fields.action_records && <th>Action Records</th>}
                              {this.state.fields.otherLeg && <th>Other Leg</th>}
                              <th>Actions</th>
                            </tr>}
                            {item.reservations && this.sortItems(item.reservations).map((res, num) => {
                              return (
                                res.status !== 'deleted' && <tr key={num}>
                                  <td><input type="checkbox" onChange={this.toggleSelect.bind(this, res.id)} checked={this.checkSelected(res.id)} /></td>
                                  {this.state.fields.user && <td><NavLink to={"/dashboard/search-reservations?column=firstName&value="+res.user.firstName+"&searchIn=reservations"}>{res.lastName + ", " + res.firstName}</NavLink></td>}
                                  {this.state.fields.email && <td><NavLink to={"/dashboard/search-reservations?column=email&value="+res.user.email+"&searchIn=reservations"}>{res.user.email}</NavLink></td>}
                                  {this.state.fields.phone && <td><NavLink to={"/dashboard/search-reservations?column=phone&value="+res.user.phone+"&searchIn=reservations"}>{res.user.phone}</NavLink></td>}
                                  {this.state.fields.seats && <td>{res.total}</td>}
                                  {this.state.fields.arrival && <td>{res.arrive_stop.stop.city}</td>}

                                  {this.state.fields.legPrice && <td>{res.legPrice}</td>}
                                  {this.state.fields.pointsUsed && <td>{res.pointsUsed}</td>}
                                  {this.state.fields.creditUsed && <td>{res.creditUsed}</td>}
                                  {this.state.fields.paid && <td>{numeral(res.transactionAmount).format('$0,0.00')}</td>}
                                  {this.state.fields.pointsEarned && <td>{res.pointsEarned}</td>}
                                  {this.state.fields.paymentMethod && <td>{(res.reservations && res.reservations[0] && res.reservations[0].order_item && res.reservations[0].order_item.order ? res.reservations[0].order_item.order.paymentMethod : '')}</td>}
                                  {this.state.fields.paymentGatewayId && <td>{res.paymentGatewayId}</td>}
                                  {this.state.fields.checked && <td>{res.checked}</td>}
                                  {this.state.fields.user_ip && <td>{res.user_ip}</td>}
                                  {this.state.fields.created_at && <td>{moment(res.created_at).format('MM/DD/YY hh:mm a')}</td>}
                                  {this.state.fields.made_by && <td>{res.made_by ? (res.made_by.firstName + " " + res.made_by.lastName) : ''}</td>}
                                  {this.state.fields.reservationNumber && <td>{config.formatReservationNumber(res.reservationNumber)}</td>}
                                  {this.state.fields.notes && <td>{res.reservation_remarks.map((remark, j) => {
                                    return (
                                      <div key={j}>{remark.createdBy === 'manual' ? (moment(remark.created_at).format('MM/DD/YY hh:mm A') + ' ' + remark.user.firstName) + ': ' : (moment(remark.created_at).format('MM/DD/YY hh:mm A') + ': ')}{remark.note}<br/><br/></div>
                                    )
                                  })}</td>}
                                  {this.state.fields.account && <td><NavLink to={"/dashboard/customers/"+res.user.id}>{(res.firstName || res.user.firstName) + " " + (res.lastName || res.user.lastName)}</NavLink></td>}
                                  {this.state.fields.promo && <td>{res.coupon ? <NavLink to={"/dashboard/coupons/"+res.coupon.id}>{res.coupon.code}</NavLink> : ''}</td>}
                                  {this.state.fields.action_records && <td>
                                    {this.getRecordsSummary(res).map((item, k) => {
                                      return (
                                        <div key={k}>
                                          <a href="javascript:void(0)" onClick={this.showActionDetails.bind(this, res.action_records[k])}>{item}</a>
                                        </div>
                                      )
                                    })}
                                  </td>}
                                  {this.state.fields.otherLeg && <td>{res.otherLeg && config.formatReservationNumber(res.otherLeg.reservationNumber)}</td>}
                                  <td>
                                    <div className="table-actions res-actions">
                                      <a href="javascript:void(0)" className={(res.reservation_remarks.length>0 ? 'highlight-note' : '')} onClick={this.showNotes.bind(this, item.stop.address, res)}>Notes</a>
                                      {res.total > 0 && <a href="javascript:void(0)" onClick={this.reschedule.bind(this, res, item.stop)}>Reschedule</a>}
                                      {res.total > 0 && <a className="red" href="javascript:void(0)" onClick={this.cancel.bind(this, res)}>Cancel</a>}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })}
                            {item.reservations.length === 0 && <tr><td colSpan={6}>No reservations for this stop</td></tr>}
                          </tbody>
                        )
                        })}
                      </table>
                      {this.props.loading && <div id="loader"></div>}
                    </div>
                    <h4 className="align-center">Total for whole bus: {this.getTotal()}</h4>
                    <div>
                      <Row className="res-actions-container">
                        <Col md={6}>
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "Do the following to all checked",
                                componentClass: "select",
                                bsClass: "form-control",
                                value: this.state.action,
                                name: 'action',
                                onChange: this.handleChange,
                                options: [
                                  {label: 'Delete', value: 'delete'},
                                  {label: 'Note', value: 'note'},
                                  {label: 'Re-email', value: 'email'},
                                  {label: 'Custom email only', value: 'customEmail'},
                                  {label: 'Text only', value: 'text'},
                                  {label: 'Custom email and text', value: 'customEmailAndText'},
                                  {label: 'Check in', value: 'checked'},
                                  {label: 'Complimentary seats as reserved', value: 'complimentaryAll'},
                                  {label: 'Complimentary one seat', value: 'complimentaryOne'},
                                  {label: 'Complimentary partial credit', value: 'complimentaryPartial'}
                                ],
                              }
                            ]}
                          />
                          {(this.state.action === 'complimentaryPartial' || this.state.action === 'complimentaryOne') && <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "How much credit you would like to add?",
                                bsClass: "form-control",
                                value: this.state.credit,
                                name: 'credit',
                                onChange: this.handleChange,
                                type: 'number',
                                placeholder: 'Credit Amount'
                              }
                            ]}
                          />}
                          {(this.state.action === 'complimentaryOne') && <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "Enter custom amount",
                                bsClass: "form-control",
                                value: this.state.credit,
                                name: 'credit',
                                onChange: this.handleChange,
                                type: 'number',
                                placeholder: 'Amount'
                              }
                            ]}
                          />}
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "Email subject entry box:",
                                bsClass: "form-control",
                                value: this.state.subject,
                                name: 'subject',
                                onChange: this.handleChange,
                                type: 'text',
                                placeholder: 'Subject',
                                disabled: (this.state.action === 'note' || this.state.action === 'delete' || this.state.action === 'email' || this.state.action === 'text' || this.state.action === 'checked' || this.state.action === 'complimentaryAll' || this.state.action === 'complimentaryOne' || this.state.action === 'complimentaryPartial')
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "Notes/Email body box:",
                                bsClass: "form-control",
                                componentClass: 'textarea',
                                value: this.state.body,
                                name: 'body',
                                onChange: this.handleChange,
                                placeholder: 'Body',
                                disabled: (this.state.action === 'delete' || this.state.action === 'email' || this.state.action === 'text' || this.state.action === 'checked')
                              }
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                label: "Text message box:",
                                bsClass: "form-control",
                                value: this.state.text,
                                componentClass: 'textarea',
                                name: 'text',
                                onChange: this.handleChange,
                                type: 'text',
                                placeholder: 'Text',
                                disabled: (this.state.action === 'note' || this.state.action === 'delete' || this.state.action === 'email' || this.state.action === 'customEmail' || this.state.action === 'checked' || this.state.action === 'complimentaryAll' || this.state.action === 'complimentaryOne' || this.state.action === 'complimentaryPartial')
                              }
                            ]}
                          />
                          <Button bsStyle="info" disabled={this.props.loading || this.state.loading || this.state.selected.length === 0} onClick={this.proceed.bind(this)} fill type="button">
                            OK
                          </Button>
                          {(this.state.loading || this.props.loading) && this.state.submitted && 
                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          }
                        </Col>
                        <Col md={6} className="action-rules">
                          <h4>Action Rules <span>(For the commands on the left)</span></h4>
                          <div className="rules">
                            <b>1) EMAIL SUBJECT ENTRY BOX:</b><br/>
                            Default subject lines (in emails only) are as follows:
                            <br/>Custom Emails: "Re: Reservation # XYZ123456"
                            <br/>Re-emailed Emails: "Re-Emailing Reservation Information"
                            <br/>Placed on hold: "Reservation Placed On Hold"
                            <br/>Complimentary Tickets: "Complimentary Ticket(s) Toward Future Travel"
                            <br/>By default you do not need to enter any subject and the default subject will be automatically filled out as above:
                            <br/>However, if you do want a custom subject line instead of the default ones listed above, type it in the subject box provided. <em>(Note: 60 chars. max)</em>
                            <br/><br/>

                            <b>2) NOTES/EMAILS BODY BOX, ALSO "REASON" BOX:</b>
                            <br/>If "Noting" or "Custom Emailing", you have to enter the body of the text you want to email or note in that box.
                            <br/>If "Placing On Hold", you may enter (optional) the reason you are doing the hold (if any) into that box.
                            Bear in mind that the customer sees it all in their email and that the words "Reason for this action:" is also in there prefilled, followed by the resaon you entered <br/>
                            If choosing "Complimentary Tickets", whether one or multiple, you have to enter the reason you are issuing the complimentary tickets into the following box, and in the email they will see "Reason for this action: whatever reason"<br/>
                            (P.S., emails are always automatically preceded with "Dear First Last Name",<br/>
                            and after the email body they are always automatically signed with your default email footers, so there's no need to finish off with Sincerely etc.)
                            <br/><br/>

                            <b>3) TEXT MESSAGE BOX:</b>
                            Only text messages belong in this box.<br/>
                            (Note that the maximum characters for a text message is 160, and you will see a count of characters you have avaliable as you type<br/><br/>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <Table>
                          <thead>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody>
                            {this.state.reports && this.state.reports.map(report => <tr>
                              <td></td>
                              <td>{report}</td>
                            </tr>)}
                          </tbody>
                        </Table>
                        </Col>
                      </Row>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>}
          {this.state.openNotes && <Notes
            open = {this.state.openNotes}
            onCloseModal = {this.closeNotes.bind(this)}
            data = {this.state.notesData}
          />}
          <Modal open={this.state.openCancel} onClose={this.closeCancel.bind(this)} center>
            <div className="cancelSeatsModal">
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "How many seats you would like to cancel?",
                    componentClass: "select",
                    bsClass: "form-control",
                    placeholder: "How many seats you would like to cancel?",
                    value: this.state.seatsToCancel,
                    name: 'seatsToCancel',
                    onChange: this.handleSeatsChange.bind(this),
                    options: this.getSeatsOptions()
                  }
                ]}
              />
              {this.state.selectedLeg && this.state.selectedLeg.otherLeg && <div className="other-leg-box">
                <div>The other leg of this reservation is the following:</div>
                <div>Reservation #: {config.formatReservationNumber(this.state.selectedLeg.otherLeg.reservationNumber)}</div>
                <div>Date: {moment(this.state.selectedLeg.otherLeg.slot, 'YYYY-MM-DD').format('dddd MMMM DD YYYY')}</div>
                <div>From: {this.state.selectedLeg.otherLeg.depart_stop.stop.address} at {moment(this.state.selectedLeg.depart_stop.time, 'HH:mm:ss').format('hh:mm:ss A')}</div>
                <a href="javascript:void(0)" onClick={this.switchCancelLeg.bind(this, this.state.selectedLeg.otherLeg, this.state.selectedLeg)}>Click here to pull up this other reservation for editing</a>
              </div>}
              <Button bsStyle="info" disabled={this.state.loading || !this.state.seatsToCancel} fill type="button" onClick={this.proceedToCancel.bind(this)}>
                Cancel
              </Button>
              {this.state.loading && <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
          </Modal>
          <Modal open={this.state.openReschedule} onClose={this.closeReschedule.bind(this)} center>
            <div className="cancelSeatsModal">
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "How many seats you would like to reschedule?",
                    componentClass: "select",
                    bsClass: "form-control",
                    placeholder: "How many seats you would like to reschedule?",
                    value: this.state.seatsToReschedule,
                    name: 'seatsToReschedule',
                    onChange: this.handleSeatsChange.bind(this),
                    options: this.getSeatsOptions()
                  }
                ]}
              />
              {this.state.selectedLeg && this.state.selectedLeg.otherLeg && <div className="other-leg-box">
                <div>The other leg of this reservation is the following:</div>
                <div>Reservation #: {config.formatReservationNumber(this.state.selectedLeg.otherLeg.reservationNumber)}</div>
                <div>Date: {moment(this.state.selectedLeg.otherLeg.slot, 'YYYY-MM-DD').format('dddd MMMM DD YYYY')}</div>
                <div>From: {this.state.selectedLeg.otherLeg.depart_stop.stop.address} at {moment(this.state.selectedLeg.depart_stop.time, 'HH:mm:ss').format('hh:mm:ss A')}</div>
                <a href="javascript:void(0)" onClick={this.switchLeg.bind(this, this.state.selectedLeg.otherLeg, this.state.selectedLeg)}>Click here to pull up this other reservation for editing</a>
              </div>}
              <Button bsStyle="info" disabled={this.state.loading || !this.state.seatsToReschedule} fill type="button" onClick={this.proceedToReschedule.bind(this)}>
                Proceed
              </Button>
              {this.state.loading && <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
          </Modal>
          <Modal open={this.state.openBook} onClose={this.closeBook.bind(this)} center>
            <div className="cancelSeatsModal bookingModal">
              {this.state.selectedStop && this.state.selectedLeg && <h4 className="edit-existing-title">You are rescheduling {this.state.seatsToReschedule} seats of the existing reservation for {this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName}, leaving from {this.state.selectedStop.address} at {moment(this.state.selectedStop.time, 'HH:mm:ss').format('hh:mm a')} on {this.state.selectedLeg.slot}<div>Customer Paid: {this.getPricePaidBreakup().text}</div></h4>}
              <form name="mainForm" onSubmit={this.findSeats.bind(this)}>
                <Row className="form-group">
                  <Col md={3}>
                    <label>Choose Date</label>
                  </Col>
                  <Col md={9}>
                    <DatePicker
                      className="form-control"
                      selected={this.state.departDate}
                      onChangeRaw={this.handleDateChangeRaw.bind(this)}
                      onChange={this.handleDepartDate.bind(this)}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={3}>
                    <label>Leaving From</label>
                  </Col>
                  <Col md={9}>
                    <select
                      className = "form-control"
                      value = {this.state.departCity}
                      name = "departCity"
                      onChange = {this.handleChange.bind(this)}
                    >
                      <option value=""></option>
                      {
                        this.state.cities.map((stop, key) => {
                          return(<option key={key} value={stop.value}>{stop.label}</option>)
                        })
                      }
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col md={3}>
                    <label>Arriving To</label>
                  </Col>
                  <Col md={9}>
                    <select
                      className = "form-control"
                      value = {this.state.arriveCity}
                      name = "arriveCity"
                      // disabled = {this.state.arriveCity === 'new york' && this.state.departCity !== 'new york'}
                      onChange = {this.handleChange.bind(this)}
                    >
                      <option value=""></option>
                      {
                            this.state.arriveCities.map((stop, key) => {
                              return(<option key={key} value={stop.value}>{stop.label}</option>)
                            })
                      }
                    </select>
                  </Col>
                </Row>
                <Button disabled={this.state.loading || !this.state.departDate || !this.state.departCity || !this.state.arriveCity} bsStyle="info" fill type="submit">
                  Go
                </Button>
                {this.state.loading && 
                  <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
                {this.state.error && <div className="error">{this.state.error}</div>}
                {this.state.noSchedule && <div className="error text-center">No schedules found for this date and destination</div>}
                <div className="clearfix" />
              </form>
            </div>

          </Modal>
          <Modal open={this.state.openSchedule} onClose={this.closeSchedule.bind(this)} center>
            <div className="cancelSeatsModal bookingModal">
              {this.state.selectedStop && this.state.selectedLeg && <h4 className="edit-existing-title">You are rescheduling{this.state.seatsToReschedule} seats of the existing reservation for {this.state.selectedLeg.user.firstName + ' ' + this.state.selectedLeg.user.lastName}, leaving from {this.state.selectedStop.address} at {moment(this.state.selectedStop.time, 'HH:mm:ss').format('hh:mm a')} on {this.state.selectedLeg.slot}<div>Customer Paid: {this.getPricePaidBreakup().text}</div></h4>}
                <Row className="main-box-container">
                  <Col md={12}>
                    <div className="main-schedule-box">
                      <div className="border-black head">
                      {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && !this.state.schedules[0].specialPrice &&
                        <div className="special-main-schedule">--Date has special schedule--</div>
                      }
                      {this.state.schedules && this.state.schedules[0] && this.state.schedules[0].isSpecial && this.state.schedules[0].specialPrice &&
                        <div className="special-main-schedule">--Date has special schedule and price--</div>
                      }
                      Leaving from {this.getCityLabel(this.state.departCity)} on <br/> {moment(this.state.departDate).format('dddd, MMMM Do YYYY')}
                      </div>
                      {this.state.schedules && this.state.schedules.map((bus, index) => {
                        return (
                          <div key={index} className={'bus-box-main ' + (bus.deleted ? 'deleted' : '')}>
                            <div className="border-black bus-number">Bus #{index+1}:</div>
                            <div className="stops-group">
                              {bus.stops.map((stop, i) => {
                                return (
                                  stop.type === 'PU' &&
                                  <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                    <span className="stop-address">
                                      {moment(stop.time, 'HH:mm:ss').format('hh:mm a')} from {stop.stop.address}
                                    </span>
                                      <input checked={this.state.departStop === stop.id} className="res-slot" type="radio" name="departStop" value={this.state.departStop} onChange={this.selectDepartStop.bind(this, stop, index)} />
                                  </div>
                                )
                              })}
                            </div>
                            <div className="border-black">
                              <div className="total-row">Total: {bus.reservations} {bus.bus_type.type === 'Elite' ? (<span className="elite-res">Elite</span>) : ''}{bus.bus_type.type === 'Elite' ? ' ' : ''} Max Capacity: {bus.capacity}</div>
                              <div className="main-driver">Driver: {bus.bus_type.driver ? bus.bus_type.driver.firstName + ' ' + bus.bus_type.driver.lastName : ''}</div>
                              <div className="main-driver">Company: {bus.bus_type.company}</div>
                              <div className="main-price-label">Coupons Allowed:</div>
                              <div className="main-price-value">{bus.couponLimit}</div>
                              {bus.prices ?<div className="main-price-value">Price:  <button onClick={()=>{this.showEditPrice(bus, index)}}>View Price</button> </div> :
                              <span className={(bus.bus_type.defaultPrice !== bus.price ? "red-text-price" : "")}><b>Price:</b>&nbsp;
                                      {bus.bus_type.defaultPrice === bus.price && <Fragment>${bus.price} (unchanged)</Fragment>}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.firstSeats ? <Fragment>${bus.price} (price for first {bus.firstSeats} seats)</Fragment> : ''} */}
                                      {/* {bus.bus_type.defaultPrice !== bus.price && bus.lastSeats ? <Fragment>${bus.price} (price for last {bus.lastSeats} seats)</Fragment> : ''} */}
                                      {bus.prices && bus.bus_type.defaultPrice !== bus.price ? <Fragment>${bus.price} (custom price for this route)</Fragment> : bus.bus_type.defaultPrice !== bus.price && !bus.firstSeats && !bus.lastSeats ? <Fragment>${bus.price} (special price for whole bus)</Fragment> : ''}
                                </span>}
                              <div className="main-price-label">Bus is:</div>
                              <div className="main-price-value">{bus.isOpen ? 'Open' : 'Closed'}</div>
                              {bus.notes && <div className="main-price-label">Bus Notes:</div>}
                              {bus.notes && <div className="main-price-value">{bus.notes}</div>}
                              <div>Destinations:
                                <div className="stops-group">
                                  {bus.stops.map((stop, i) => {
                                    return (
                                      stop.type === 'DO' &&
                                      <div key={i} className={'border-black stop-times '+ (stop.deleted ? 'deleted' : '')}>
                                        <span>{moment(stop.time, 'HH:mm:ss').format('hh:mm a')} at {stop.stop.address}</span>
                                        {(this.state.arriveStop === stop.id) && <i className='fa fa-check'></i>}
                                        <div className="view-res-link">{stop.reservations}</div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}  
                    </div>
                  </Col>
                </Row>
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "Add note to new reservation",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Remark",
                      value: this.state.remark,
                      name: 'remark',
                      onChange: this.handleChange
                    }
                  ]}
                />
                { this.state.selectedLeg && this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null ?
                  (<Fragment>
                    <Row>
                      <Col md={12} className='title'>
                        <h5 className="select-to-pay">Original Seats Cost: {numeral(this.state.selectedLeg.legPrice).format('$0.00')}</h5>
                      </Col>
                      <Col md={12} className='title'>
                        <h5 className="select-to-pay">Customer Paid: {this.getPricePaidBreakup().text} {this.getPricePaidBreakup().text === '0' ? '-' : ''} {(this.getPricePaidBreakup().text === '0' && this.state.selectedLeg.reservations && this.state.selectedLeg.reservations[0] && this.state.selectedLeg.reservations[0].order_item && this.state.selectedLeg.reservations[0].order_item.order ? this.state.selectedLeg.reservations[0].order_item.order.paymentMethod : '')}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='title'>
                        <h5 className="select-to-pay">New Seats Price: ${this.state.selectedRoutePrice*this.state.seatsToReschedule}</h5>
                      </Col>
                    </Row>
                  </Fragment>)
                : ''}
                {this.getAmount() !== 0 && <div>
                  {/*<FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Override Difference Amount",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Amount",
                        value: this.state.customAmount,
                        name: 'customAmount',
                        onChange: this.handleChange,
                        min: 0
                      }
                    ]}
                  />*/}
                  {this.state.selectedLeg && !this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.selectedLeg.user.credits > 0 && <Row>
                    <Col md={12}>
                      <div className="apply-credit-row">Applied Tripperbus Credit to cover the amount {numeral(this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount()).format('$0.00')}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="apply-credit-row">Customer's Tripperbus Credit Balance will be {numeral(this.state.selectedLeg.user.credits - (this.getAmount() > this.state.selectedLeg.user.credits ? this.state.selectedLeg.user.credits : this.getAmount())).format('$0.00')} after payment
                      </div>
                    </Col>
                  </Row>}
                  {this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.selectedLeg.user.points >= this.getRequiredPoints().points ? <Row>
                    <Col md={12}>
                      <div className="apply-credit-row">
                        Transferred {this.getRequiredPoints().points} Voucher Points to cover cost of new reservation
                      </div>
                    </Col>
                  </Row> : ''}
                  {this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.selectedLeg.user.points < this.getRequiredPoints().points ? <Fragment>
                    <Row>
                      <Col md={12}>
                        <div className="select-to-pay">
                          {this.getVouchersMessage()}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="select-to-pay">
                          Payment must be done with credit card due to insufficient points, points in cancelled reservation will be returned.
                        </div>
                      </Col>
                    </Row>
                  </Fragment> : ''}
                  {/*{((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) && <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Payment Method",
                        type: "text",
                        componentClass: "select",
                        bsClass: "form-control",
                        placeholder: "Payment Method",
                        value: this.state.paymentMethod,
                        name: 'paymentMethod',
                        options: this.state.methods,
                        onChange: this.handleChange.bind(this)
                      }
                    ]}
                  />}*/}
                  {this.state.paymentMethod === 'card' && ((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) && <Fragment>
                    <Row>
                      <Col md={12}>
                        <h5 className="select-to-pay">There is a difference of {numeral(this.getAmount() - (this.getCredits() + this.getRequiredPoints().pointsAmount)).format('$0.00')} to be paid with Credit Card.</h5>
                      </Col>
                    </Row>
                  </Fragment>}
                  {this.state.paymentMethod === 'card' && ((this.getCredits() + this.getRequiredPoints().pointsAmount) < this.getAmount()) &&
                    <div>
                      <div className="row clearfix">
                        <div className="col-sm-6 form-group">
                          <label className="formLable">NAME ON CARD (If different from above)</label>
                          <input ref="cardHolder" required name="cardHolder" value={this.state.cardHolder} onChange={this.handleChange.bind(this)} type="text" className="form-control" placeholder="Name On Card" />
                        </div>
                        <div className="col-sm-6 form-group">
                          <label className="formLable">CREDIT CARD NUMBER</label>
                          <InputMask maskChar='' mask="9999-9999-9999-9999" ref="number" onChange={this.handleChange.bind(this)} value={this.state.number} name="number" required className='form-control' placeholder="Credit Card Number" />
                          {this.state.submitted && !this.state.number && <div className="error">Number is required</div>}
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-sm-4 form-group">
                          <label className="formLable">EXMONTH</label>
                          <select ref="month" required name="month" value={this.state.month} onChange={this.handleChange.bind(this)}   className='form-control'>
                            <option value=''>MM</option>
                            {this.state.months.map((item, index) => {
                              return (
                                <option key={index}>{item}</option>
                              )
                            })}
                          </select>
                          {this.state.submitted && !this.state.month && <div className="error">Month is required</div>}
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="formLable">EXYEAR</label>
                          <select ref="year" required name="year" value={this.state.year} onChange={this.handleChange.bind(this)}  className='form-control'>
                            <option value=''>YY</option>
                            {this.state.years.map((item, index) => {
                              return (
                                <option key={index}>{item}</option>
                              )
                            })}
                          </select>
                          {this.state.submitted && !this.state.number && <div className="error">Year is required</div>}
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="formLable">SEC CODE</label>
                          <InputMask required maskChar='' mask="9999" ref="cvv" onChange={this.handleChange.bind(this)} value={this.state.cvv} name="cvv" className='form-control' placeholder="SEC" />
                          {this.state.submitted && !this.state.cvv && <div className="error">Cvv is required</div>}
                        </div>
                      </div>
                    </div>
                  }
                </div>}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.selectedRoutePrice < this.getPricePaid() && this.state.selectedLeg && !this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers) ? <Row>
                  <Col md={12}>
                    {!this.state.waiveDifference ? <div className="apply-credit-row">
                      There are {numeral((this.getPricePaid()-this.state.selectedRoutePrice) * this.state.seatsToReschedule).format('$0.00')} credits left over from cancellation that will bank.
                    </div> : <div className="apply-credit-row">
                      The difference will not be returned.
                    </div>}
                  </Col>
                  <Col md={12} style={{margin: '5px 0'}}>
                    <label>
                      <input type="checkbox" name="waiveDifference" onChange={this.waiveDifference.bind(this)} /> Do not return the credit difference to customer. Void it.
                    </label>
                  </Col>
                </Row> : ''}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.selectedRoutePrice == this.getPricePaid()) ? <Row>
                  <Col md={12}>
                    <div className="apply-credit-row">
                      This reservation is an even exchange.
                    </div>
                  </Col>
                </Row> : ''}
                {(this.state.schedules && this.state.schedules.length && this.state.selectedBus !== null && this.state.selectedLeg && this.getActiveReservations(this.state.selectedLeg.reservations)[0].vouchers && this.state.schedules[this.state.selectedBus].bus_type.type !== 'Elite' && this.state.selectedLeg.schedule_bus.bus_type.type === 'Elite') ? <Row>
                  <Col md={12}>
                    <div className="apply-credit-row">
                      New ticket is a downgrade, applying {6*this.getSeatsWithPoints(this.getActiveReservations(this.state.selectedLeg.reservations))} points from reservation and voiding the rest.
                    </div>
                  </Col>
                </Row> : ''}
                <Row><Col md={12}><Button disabled={this.state.loading || !this.state.departStop || !this.state.arriveStop} bsStyle="info" fill type="button" onClick={this.handleSubmit.bind(this)}>
                  Reschedule
                </Button></Col></Row>
                {this.state.loading && 
                  <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                }
                {this.props.error && <div className="error">{this.props.error}</div>}
                <div className="clearfix" />
            </div>
          </Modal>
          <Modal open={this.state.openSuccess} onClose={this.closeSuccess.bind(this)} center>
            <div className="cancelSeatsModal">
              <h4>Rescheduled successfully with reservation #{this.state.code}.</h4>
              <Button bsStyle="info" fill type="button" onClick={this.closeSuccess.bind(this)}>
                OK
              </Button>
            </div>
          </Modal>
          <Modal open={this.state.openSentSuccess} onClose={this.closeSentSuccess.bind(this)} center>
            <div className="cancelSeatsModal">
              <h4>{this.state.bulkActionInfo || 'Done.'}</h4>
              <Button bsStyle="info" fill type="button" onClick={this.closeSentSuccess.bind(this)}>
                OK
              </Button>
            </div>
          </Modal>
          <Modal open={this.state.openActionDetails} onClose={this.closeActionDetails.bind(this)} center>
            <div className="action-records-modal">
              <h3>Record Of action On Reservation</h3>
              {this.state.actionRecord && <div className="action-records">
                <div className="action-record">
                  <label>Action Done:</label>
                  <span>{this.state.actionRecord.type}</span>
                </div>
                <div className="action-record">
                  <label>Date & Time Sent:</label>
                  <span>{moment(this.state.actionRecord.created_at).format('MMM DD, YYYY')} @ {moment(this.state.actionRecord.created_at).format('hh:mm A')}</span>
                </div>
                <div className="action-record">
                  <label>Admin Representative:</label>
                  <span>{this.state.actionRecord.admin.firstName + ' ' + this.state.actionRecord.admin.lastName}</span>
                </div>
                {this.state.actionRecord.type !== 'Custom Text Message' && <div className="action-record">
                  <label>Sent To Email Address:</label>
                  <span>{this.state.actionRecord.emailAddress}</span>
                </div>}
                {(this.state.actionRecord.type === 'Custom Text Message' || this.state.actionRecord.type === 'Custom Emailed & Text Message') && <div className="action-record">
                  <label>Sent To Phone Number:</label>
                  <span>{this.state.actionRecord.phone}</span>
                </div>}
                {this.state.actionRecord.type !== 'Custom Text Message' && <div className="action-record">
                  <label>Email Subject:</label>
                  <span>{this.state.actionRecord.subject}</span>
                </div>}
                {(this.state.actionRecord.type === 'Custom Emailed' || this.state.actionRecord.type === 'Custom Emailed & Text Message') && <div className="action-record">
                  <label>Email Body:</label>
                  <span>{this.state.actionRecord.body}</span>
                </div>}
                {(this.state.actionRecord.type === 'Custom Text Message' || this.state.actionRecord.type === 'Custom Emailed & Text Message') && <div className="action-record">
                  <label>Text Message:</label>
                  <span>{this.state.actionRecord.text}</span>
                </div>}
              </div>}

              <Button bsStyle="info" fill type="button" onClick={this.closeActionDetails.bind(this)}>
                Close
              </Button>
            </div>
          </Modal>

          <Modal open={this.state.showModal} onClose={this.closeSuccess.bind(this)} center>
            <div className="cancelSeatsModal">
              {<CustomPrice updatePrice={()=>{}} open={true} combinationOfStops={this.state.currentSelectedBus} close={()=>this.setState({showModal:false})} updateStopPrice={()=>{}} update={false}/>}
            </div>
          </Modal>

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { mainItems, loading, error } = state.reservations;
  return { mainItems, loading, error, stops: state.stops.items };
}

export default connect(mapStateToProps)(MainReservations);
