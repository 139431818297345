import { GET_GIFT_CARDS_REQUEST, GET_GIFT_CARDS_SUCCESS, GET_GIFT_CARDS_FAILURE, GET_GIFT_CARD_REQUEST, GET_GIFT_CARD_SUCCESS, GET_GIFT_CARD_FAILURE, ADD_GIFT_CARD_REQUEST, ADD_GIFT_CARD_SUCCESS, ADD_GIFT_CARD_FAILURE, UPDATE_GIFT_CARD_REQUEST, UPDATE_GIFT_CARD_SUCCESS, UPDATE_GIFT_CARD_FAILURE, DELETE_GIFT_CARD_REQUEST, DELETE_GIFT_CARD_SUCCESS, DELETE_GIFT_CARD_FAILURE } from '../constants';
 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_GIFT_CARDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_GIFT_CARDS_SUCCESS:
      return {
        loading: false,
        items: action.giftCards.giftCards,
        total: action.giftCards.total
      };
    case GET_GIFT_CARDS_FAILURE:
      return {
        error: action.error
      };
    case GET_GIFT_CARD_REQUEST:
      return {
        loading: true,
        item: null
      };
    case GET_GIFT_CARD_SUCCESS:
      return {
        loading: false,
        item: action.giftCard
      };
    case GET_GIFT_CARD_FAILURE:
      return {
        error: action.error
      };
    case ADD_GIFT_CARD_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case ADD_GIFT_CARD_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case ADD_GIFT_CARD_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case UPDATE_GIFT_CARD_REQUEST:
      return {
        loading: true,
        error: '',
        success: false,
      };
    case UPDATE_GIFT_CARD_SUCCESS:
      return {
        loading: false,
        success: true,
        error: ''
      };
    case UPDATE_GIFT_CARD_FAILURE:
      return {
        error: action.error,
        loading: false,
        success: false
      };
    case DELETE_GIFT_CARD_REQUEST:
      // add 'deleting:true' property to giftCard being deleted
      return {
        ...state,
        items: state.items.map(giftCard =>
          giftCard.id === action.id
            ? { ...giftCard, deleting: true }
            : giftCard
        )
      };
    case DELETE_GIFT_CARD_SUCCESS:
      // remove deleted giftCard from state
      return {
        items: state.items.filter(giftCard => giftCard.id !== action.id)
      };
    case DELETE_GIFT_CARD_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to giftCard 
      return {
        ...state,
        items: state.items.map(giftCard => {
          if (giftCard.id === action.id) {
            // make copy of giftCard without 'deleting:true' property
            const { deleting, ...giftCardCopy } = giftCard;
            // return copy of giftCard with 'deleteError:[error]' property
            return { ...giftCardCopy, deleteError: action.error };
          }
 
          return giftCard;
        })
      };
    default:
      return state
  }
}