import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import orderItemActions from 'actions/order_item.actions';
import { connect } from 'react-redux';

//import avatar from "assets/img/faces/face-3.jpg";

class EditOrderItem extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(orderItemActions.getOrderItem(this.props.match.params.id));

    this.state = {
      submitted: false,
      order_item: {
        amount: '',
        type: '',
        description: '',
        order: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    let order_item = this.state.order_item;
    order_item[name] = value;
    this.setState({ order_item: order_item });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { dispatch } = this.props;
    if (this.state.order_item.type && this.state.order_item.amount && this.state.order_item.description) {
      dispatch(
        orderItemActions.update(
          this.props.match.params.id,
          {
            type: this.state.order_item.type,
            amount: this.state.order_item.amount,
            description: this.state.order_item.description
          }
        )
      );
    }
  }

  componentWillUpdate(props) {
    if(props.order_item && JSON.stringify(this.props.order_item) !== JSON.stringify(props.order_item)) {
      let order_item = props.order_item;
      this.setState({order_item: order_item});
    }
    if(props.error && !this.props.error) {
      this.props.addNotification({type: 'error', message: props.error});
    }
    if(props.success && !this.props.success) {
      this.props.addNotification({type: 'success', message: 'Order Item updated successfully'});
    }
  }

  render() {
    const { submitted, order_item } = this.state;
    const { loading } = this.props;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Order Item"
                content={
                  <form name="profileForm" onSubmit={this.handleSubmit}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      proprieties={[
                        {
                          label: "Order",
                          type: "text",
                          disabled: true,
                          bsClass: "form-control",
                          placeholder: "Order",
                          value: order_item.order,
                          name: 'order',
                          onChange: this.handleChange
                        },
                        {
                          label: "Type",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Type",
                          value: order_item.type,
                          name: 'type',
                          onChange: this.handleChange
                        },
                        {
                          label: "Value",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Value",
                          value: order_item.amount,
                          name: 'amount',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={4}>{submitted && !order_item.order && <div className="error">Order is required</div>}</Col>
                      <Col md={4}>{submitted && !order_item.type && <div className="error">Type is required</div>}</Col>
                      <Col md={4}>{submitted && !order_item.amount && <div className="error">Value is required</div>}</Col>
                    </Row>
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          label: "Description",
                          type: "number",
                          componentClass: "textarea",
                          bsClass: "form-control",
                          placeholder: "Description",
                          value: order_item.description,
                          name: 'description',
                          onChange: this.handleChange
                        }
                      ]}
                    />
                    <Row className="error-row">
                      <Col md={12}>{submitted && !order_item.description && <div className="error">Description is required</div>}</Col>
                    </Row>
                    <Button bsStyle="info" disabled={loading} fill type="submit">
                      Update Order Item
                    </Button>
                    {loading && submitted && 
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, item, error, success } = state.orderItems;
  return {
    loading, order_item: item, error, success
  };
}
 
export default connect(mapStateToProps)(EditOrderItem);
